import { pluralize } from "@cashbook/util-general"
import {
  DataLoadingFallback,
  PageMeta,
  Text,
  Heading,
  Box,
  Stack,
  Inline,
  ArrowLeftIcon,
  Switch,
  PlusIcon,
  DuplicateIcon,
  Button,
  DotsVerticalIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  LabelIcon,
  Alert,
  UserLabelIcon,
  Circle,
  UsersFilledIcon,
} from "@cashbook/web-components"
import { useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import {
  AddCategoryFieldInDialog,
  EditCategoryFieldInDialog,
  DeleteCategoryFieldInDialog,
  useToggleCategoryField,
  Book,
  ImportCategoryFieldInDialog,
  MemberAvatar,
} from "../Books"
import { useBook, BOOK_PERMISSIONS } from "@cashbook/data-store/books"
import ErrorBoundary from "../ErrorBoundary"
import {
  AddNewPartyFieldOptions,
  EmptyStatePartyField,
} from "../Books/EntryFields"
import { SearchBar } from "../common"

export default function BookFieldCategorySettingsPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_book_details"
      >
        <BookFieldCategorySettings
          key={bookId}
          bookId={bookId}
          businessId={businessId}
        />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function BookFieldCategorySettings({
  bookId,
  businessId,
}: {
  bookId: string
  businessId: string
}) {
  const { book, checkIfAuthenticatedMemberCan } = useBook(bookId)
  const canUpdate = useMemo(
    () => checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.UPDATE_ENTRY_FIELDS),
    [checkIfAuthenticatedMemberCan]
  )
  return (
    <BookFieldSettingsDetails
      canUpdate={canUpdate}
      book={book}
      businessId={businessId}
      fieldLabel="Category"
      fields={book.categories}
      fieldDisabled={Boolean(book.preferences?.categoriesDisabled)}
      useToggleField={useToggleCategoryField}
      AddFieldInDialogComponent={AddCategoryFieldInDialog}
      ImportFieldInDialog={ImportCategoryFieldInDialog}
      EditFieldInDialogComponent={EditCategoryFieldInDialog}
      DeleteFieldInDialogComponent={DeleteCategoryFieldInDialog}
    />
  )
}

export function BookFieldSettingsDetails({
  book,
  canUpdate,
  fieldLabel,
  fieldDisabled,
  fields,
  fieldKey,
  businessId,
  useToggleField,
  AddFieldOptions,
  AddFieldInDialogComponent,
  ImportFieldInDialog,
  DeleteFieldInDialogComponent,
  EditFieldInDialogComponent,
  EmptyState,
}: {
  book: Book
  businessId: string
  canUpdate: boolean
  fieldLabel: string
  fields?: Book["categories"]
  fieldDisabled: boolean
  fieldKey?: "categories" | "parties" | "paymentModes"
  useToggleField: typeof useToggleCategoryField
  AddFieldInDialogComponent: typeof AddCategoryFieldInDialog
  ImportFieldInDialog: typeof ImportCategoryFieldInDialog
  DeleteFieldInDialogComponent: typeof DeleteCategoryFieldInDialog
  EditFieldInDialogComponent: typeof EditCategoryFieldInDialog
  AddFieldOptions?: typeof AddNewPartyFieldOptions
  EmptyState?: typeof EmptyStatePartyField
}) {
  const toggleField = useToggleField(book)

  const [search, setSearch] = useState<string>("")

  const filteredFields = useMemo(() => {
    return fields?.filter((field) =>
      field.name.toLowerCase().includes(search.toLowerCase())
    )
  }, [fields, search])
  // The book has been deleted (but sub-collections are not!!)
  // https://firebase.google.com/docs/firestore/manage-data/delete-data#delete_documents
  if (!book.name) return null
  return (
    <>
      <PageMeta>
        <title>
          {fieldLabel} Field - {book.name}
        </title>
      </PageMeta>
      <Box
        key={book.id}
        bgColor="white"
        paddingTop="6"
        paddingBottom="18"
        paddingX={{ xs: "4", md: "8" }}
        minHeight={{ xs: "screen", sm: "0" }}
      >
        <Box maxWidth="xl">
          <Stack gap="8">
            <Stack gap="6">
              {!canUpdate ? (
                <Alert status="warning">
                  You don't have permissions to update fields in this book.
                </Alert>
              ) : null}
              <Box display={{ xs: "none", md: "block" }}>
                <Stack gap="6">
                  <Heading fontSize="sm" fontWeight="medium" color="gray500">
                    Entry Field | {fieldLabel}
                  </Heading>
                  <Inline gap="6" alignItems="center">
                    <Link
                      to={`/businesses/${businessId}/cashbooks/${book.id}/settings/fields`}
                    >
                      <ArrowLeftIcon />
                    </Link>
                    <Box flex="1">
                      <Heading fontSize="md" fontWeight="semibold">
                        {fieldLabel}
                      </Heading>
                    </Box>
                  </Inline>
                </Stack>
              </Box>
              <Box paddingX="6" paddingY="4" rounded="md" borderWidth="1">
                <Inline alignItems="center" gap="8">
                  <Stack gap="2" flex="1">
                    <Heading as="h3" fontSize="base" fontWeight="semibold">
                      Show {fieldLabel} Field
                    </Heading>
                    <Box maxWidth="sm">
                      {fieldDisabled ? (
                        <Text fontWeight="medium" fontSize="sm" color="gray500">
                          {pluralize(fieldLabel)} are turned off for this book.
                          Turn on {pluralize(fieldLabel).toLowerCase()} to start
                          using {pluralize(fieldLabel).toLowerCase()} in this
                          book
                        </Text>
                      ) : (
                        <Text fontWeight="medium" fontSize="sm" color="gray500">
                          {pluralize(fieldLabel)} are enabled for this book. You
                          can customise {pluralize(fieldLabel).toLowerCase()}{" "}
                          for this book below
                        </Text>
                      )}
                    </Box>
                  </Stack>
                  <Box
                    display="inlineBlock"
                    paddingX="3"
                    paddingY="1"
                    rounded="md"
                  >
                    <Switch
                      label={`Switch for ${fieldLabel} Field`}
                      on={!fieldDisabled}
                      onToggle={toggleField}
                      disabled={!canUpdate}
                    />
                  </Box>
                </Inline>
              </Box>
            </Stack>
            {!fields?.length ? (
              canUpdate ? (
                AddFieldOptions ? (
                  <>
                    <AddFieldOptions
                      book={book}
                      canUpdate={canUpdate}
                      fieldDisabled={fieldDisabled}
                      businessId={businessId}
                    />
                    {EmptyState ? (
                      <EmptyState />
                    ) : (
                      <Stack gap="14">
                        <Stack gap="6" alignItems="center">
                          <Stack
                            size="18"
                            rounded="full"
                            borderWidth="4"
                            borderColor="blue50"
                            bgColor="blue100"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {fieldKey === "parties" ? (
                              <UserLabelIcon color="blue900" size="8" />
                            ) : (
                              <LabelIcon color="blue900" size="8" />
                            )}
                          </Stack>
                          <Stack gap="3" alignItems="center">
                            <Heading fontWeight="semibold" fontSize="lg">
                              No {pluralize(fieldLabel)} Found
                            </Heading>
                            <Text color="gray500" fontWeight="medium">
                              Add new or import from other books
                            </Text>
                          </Stack>
                        </Stack>
                        <Stack gap="4">
                          <AddFieldInDialogComponent book={book}>
                            {({ add }) => (
                              <Button
                                size="lg"
                                level="primary"
                                fullWidth
                                onClick={() => add()}
                                disabled={fieldDisabled}
                              >
                                <PlusIcon />
                                Add New {fieldLabel}
                              </Button>
                            )}
                          </AddFieldInDialogComponent>
                          <ImportFieldInDialog
                            book={book}
                            businessId={businessId}
                          >
                            {({ onImport }) => (
                              <Button
                                size="lg"
                                fullWidth
                                disabled={fieldDisabled}
                                onClick={onImport}
                              >
                                <DuplicateIcon /> Import from other books{" "}
                              </Button>
                            )}
                          </ImportFieldInDialog>
                        </Stack>
                      </Stack>
                    )}
                  </>
                ) : (
                  <Box
                    padding="4"
                    maxWidth="md"
                    width="full"
                    marginX="auto"
                    style={{
                      filter: fieldDisabled ? "grayscale(1)" : undefined,
                      opacity: fieldDisabled ? ".5" : "1",
                    }}
                  >
                    {fieldDisabled ? (
                      <Box position="absolute" inset="0" zIndex="100"></Box>
                    ) : null}
                    <Stack gap="14">
                      <Stack gap="6" alignItems="center">
                        <Stack
                          size="18"
                          rounded="full"
                          borderWidth="4"
                          borderColor="blue50"
                          bgColor="blue100"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {fieldKey === "parties" ? (
                            <UserLabelIcon color="blue900" size="8" />
                          ) : (
                            <LabelIcon color="blue900" size="8" />
                          )}
                        </Stack>
                        <Stack gap="3" alignItems="center">
                          <Heading fontWeight="semibold" fontSize="lg">
                            No {pluralize(fieldLabel)} Found
                          </Heading>
                          <Text color="gray500" fontWeight="medium">
                            Add new or import from other books
                          </Text>
                        </Stack>
                      </Stack>
                      <Stack gap="4">
                        <AddFieldInDialogComponent book={book}>
                          {({ add }) => (
                            <Button
                              size="lg"
                              level="primary"
                              fullWidth
                              onClick={() => add()}
                              disabled={fieldDisabled}
                            >
                              <PlusIcon />
                              Add New {fieldLabel}
                            </Button>
                          )}
                        </AddFieldInDialogComponent>
                        <ImportFieldInDialog
                          book={book}
                          businessId={businessId}
                        >
                          {({ onImport }) => (
                            <Button
                              size="lg"
                              fullWidth
                              disabled={fieldDisabled}
                              onClick={onImport}
                            >
                              <DuplicateIcon /> Import from other books{" "}
                            </Button>
                          )}
                        </ImportFieldInDialog>
                      </Stack>
                    </Stack>
                  </Box>
                )
              ) : null
            ) : (
              <Box
                style={{
                  filter: fieldDisabled ? "grayscale(1)" : undefined,
                  opacity: fieldDisabled ? ".5" : "1",
                }}
              >
                {fieldDisabled ? (
                  <Box position="absolute" inset="0" zIndex="100"></Box>
                ) : null}
                <Stack gap="8">
                  {canUpdate ? (
                    AddFieldOptions ? (
                      <AddFieldOptions
                        book={book}
                        canUpdate={canUpdate}
                        fieldDisabled={fieldDisabled}
                        businessId={businessId}
                      />
                    ) : (
                      <Stack gap="4">
                        <AddFieldInDialogComponent book={book}>
                          {({ add }) => (
                            <Button
                              size="lg"
                              align="left"
                              onClick={() => add()}
                              disabled={fieldDisabled || !canUpdate}
                            >
                              <PlusIcon />
                              Add New {fieldLabel}
                            </Button>
                          )}
                        </AddFieldInDialogComponent>
                        <ImportFieldInDialog
                          book={book}
                          businessId={businessId}
                        >
                          {({ onImport }) => (
                            <Button
                              size="lg"
                              align="left"
                              onClick={onImport}
                              disabled={!canUpdate}
                            >
                              <DuplicateIcon />
                              Import from other books
                            </Button>
                          )}
                        </ImportFieldInDialog>
                      </Stack>
                    )
                  ) : null}
                  <Stack gap="6">
                    <Heading
                      as="h4"
                      fontSize="base"
                      fontWeight="semibold"
                      color="gray500"
                    >
                      {pluralize(fieldLabel, fields)} from this book (
                      {fields?.length})
                    </Heading>
                    {fields?.length > 5 ? (
                      <SearchBar
                        value={search}
                        maxWidth="full"
                        label={`Search by name...`}
                        onChange={(value) => setSearch(value)}
                      />
                    ) : null}
                    <Stack as="ol" gap="4">
                      {filteredFields?.length ? (
                        filteredFields?.map((field) => {
                          const { uuid, name, type, phoneNumber } = field
                          return (
                            <Inline
                              key={uuid}
                              as="li"
                              alignItems="center"
                              gap="4"
                              borderWidth="1"
                              rounded="md"
                              paddingX="4"
                              paddingY="2"
                            >
                              {fieldKey && fieldKey === "parties" ? (
                                <MemberAvatar id={uuid} name={name} />
                              ) : null}
                              <Stack flex="1" gap="1">
                                <Text className="break-all">{name}</Text>
                                {type || phoneNumber ? (
                                  <Inline
                                    color="gray500"
                                    gap={type && phoneNumber ? "1" : "0"}
                                  >
                                    <Text>{phoneNumber}</Text>
                                    {type && phoneNumber && <Text>.</Text>}
                                    <Text textTransform="capitalize">
                                      {type}
                                    </Text>
                                  </Inline>
                                ) : null}
                              </Stack>
                              <Box>
                                {canUpdate ? (
                                  <DeleteFieldInDialogComponent
                                    book={book}
                                    field={field}
                                  >
                                    {({ onDelete }) => (
                                      <EditFieldInDialogComponent
                                        book={book}
                                        field={field}
                                      >
                                        {({ edit }) => (
                                          <Menu>
                                            <MenuButton inline>
                                              <DotsVerticalIcon className="text-gray-500" />
                                            </MenuButton>
                                            <MenuList align="bottom-right">
                                              <MenuItem
                                                action="rename"
                                                onClick={edit}
                                              >
                                                {fieldKey === "parties"
                                                  ? "Edit"
                                                  : "Rename"}
                                              </MenuItem>
                                              <MenuItem
                                                action="delete"
                                                onClick={onDelete}
                                              >
                                                Delete
                                              </MenuItem>
                                            </MenuList>
                                          </Menu>
                                        )}
                                      </EditFieldInDialogComponent>
                                    )}
                                  </DeleteFieldInDialogComponent>
                                ) : null}
                              </Box>
                            </Inline>
                          )
                        })
                      ) : (
                        <Stack gap="16" paddingTop="2">
                          <AddFieldInDialogComponent
                            book={book}
                            initialName={search}
                          >
                            {({ add }) => (
                              <Inline
                                gap="4"
                                rounded="md"
                                paddingX="4"
                                paddingY="2"
                                alignItems="center"
                                height="12"
                                as="button"
                                onClick={() => add(search)}
                                disabled={!canUpdate}
                                backgroundColor="surfacePrimaryLowest"
                              >
                                <PlusIcon color="iconPrimary" />
                                <Text fontSize="b3">Add ‘{search}’</Text>
                              </Inline>
                            )}
                          </AddFieldInDialogComponent>
                          <Stack
                            gap="6"
                            textAlign="center"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Circle>
                              {fieldKey === "parties" ? (
                                <UsersFilledIcon />
                              ) : (
                                <LabelIcon />
                              )}
                            </Circle>
                            <Stack gap="2">
                              <Text fontSize="s3">
                                No {pluralize(`${fieldLabel}`).toLowerCase()}{" "}
                                found!
                              </Text>
                              <Text fontSize="b3" color="textMedium">
                                Check spelling or try some other search term
                              </Text>
                            </Stack>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            )}
          </Stack>
        </Box>
      </Box>
    </>
  )
}
