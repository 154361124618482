import {
  Navigate,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import ErrorBoundary from "../ErrorBoundary"
import { SuspenseWithPerf } from "reactfire"
import {
  Box,
  DataLoadingFallback,
  Heading,
  InformationCircleIcon,
  Inline,
  PageMeta,
  Stack,
  Text,
} from "@cashbook/web-components"
import { useBusiness } from "@cashbook/data-store/businesses"
import CashbookCard from "../Payments/cashbook_card.webp"
import CashBookIos from "../Payments/cashbook_app_ios.webp"
import CashBookAndroid from "../Payments/cashbook_app_android.webp"
import config from "../config"
import { PAYMENTS_ICON_NAMES, PaymentsIcons } from "../Payments"
import {
  useUserJourney,
  useUserPaymentsProfile,
} from "@cashbook/data-store/payments"
import { useMemo, useSyncExternalStore } from "react"
import classNames from "classnames"
import { useMount } from "@cashbook/util-general"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import { showPaymentsStore } from "@cashbook/data-store/storage"

export default function PaymentsPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading payments details..." />}
        traceId="loading_payments_details"
      >
        <PaymentsLayout key={businessId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

export function RedirectToPaymentsDashboard({
  routePrefix = "",
}: {
  routePrefix?: string
}) {
  const { businessId } = useParams()
  if (!businessId) return <Navigate to={`${routePrefix}/`} />
  return (
    <Navigate
      to={`${routePrefix}/businesses/${businessId}/payments/dashboard`}
      replace
    />
  )
}

type PAYMENTS_TAB_IDS =
  | "dashboard"
  | "allTransactions"
  | "masterWallet"
  | "staffWallets"
const PAYMENTS_DASHBOARD_OPTIONS: {
  to: string
  title: string
  id: PAYMENTS_TAB_IDS
}[] = [
  { to: "dashboard", title: "Dashboard", id: "dashboard" },
  {
    to: "all-transactions?type=expenses",
    title: "All Transactions",
    id: "allTransactions",
  },
  { to: "master-wallet", title: "Master Wallet", id: "masterWallet" },
  { to: "staff-wallets", title: "Member Cards", id: "staffWallets" },
]
function PaymentsLayout({ businessId }: { businessId: string }) {
  const pathname = window.location.href
  const [searchParams] = useSearchParams()
  const { business, authTeamMemberDetails } = useBusiness(businessId)
  const { payments } = business

  useUserJourney(businessId, authTeamMemberDetails.role.id)
  useUserPaymentsProfile(businessId, authTeamMemberDetails.uid)

  const removeGlobalHeader = useMemo(() => {
    if (searchParams.get("header") === "none") {
      return true
    }
    return false
  }, [searchParams])

  const showPayments = useSyncExternalStore(
    showPaymentsStore.subscribe,
    showPaymentsStore.getShowPaymentsList
  )

  return (
    <>
      <PageMeta>
        <title>{business.name}</title>
      </PageMeta>
      <Box key={businessId} display="flex" flexDirection="col" height="full">
        {removeGlobalHeader ? null : (
          <PaymentsHeaderLayout
            path={pathname}
            businessName={business.name}
            paymentEnabled={payments?.enabled}
          />
        )}
        <Box flexGrow="1">
          {payments?.enabled ? (
            <Outlet />
          ) : showPayments[businessId] ? (
            <PaymentsNotEnabled />
          ) : null}
        </Box>
      </Box>
    </>
  )
}

function PaymentsHeaderLayout({
  path,
  businessName,
  paymentEnabled,
}: {
  path: string
  businessName: string
  paymentEnabled?: boolean
}) {
  const navigate = useNavigate()
  useMount(() => {
    trackEvent(TrackingEvents.PAYMENTS_TAB_SWITCHED, {
      currentTab:
        PAYMENTS_DASHBOARD_OPTIONS.find((option) =>
          Boolean(path.includes(option.to.split("?")[0]))
        )?.id || "dashboard",
    })
  })
  return (
    <Box
      as="header"
      flexGrow="0"
      borderBottomWidth="1"
      borderColor="borderDividers"
    >
      <Box paddingX="8">
        <Stack>
          <Inline
            gap="4"
            backgroundColor="surfaceWarningLowest"
            paddingY="4"
            paddingX="4"
            alignItems="center"
            marginTop="4"
          >
            <InformationCircleIcon color="iconWarning" height="5" width="5" />
            <Stack gap="2" color="textWarning">
              <Text fontSize="b1">
                Card services will be discontinued soon. Please spend your
                remaining funds. UPI wallets coming soon
              </Text>
            </Stack>
          </Inline>
          <Inline gap="3" alignItems="center" paddingY="6">
            <Heading as="h4" fontSize="s1" fontWeight="semibold">
              Payments
            </Heading>
            <Text as="span" fontSize="b3" color="textMedium">
              ({businessName})
            </Text>
          </Inline>
          {paymentEnabled ? (
            <Inline as="ul" gap="6" overflowY="auto">
              {PAYMENTS_DASHBOARD_OPTIONS.map((option) => {
                const isActivePath = Boolean(
                  path.includes(option.to.split("?")[0])
                )
                return (
                  <Box
                    key={option.to}
                    as="button"
                    padding="3"
                    roundedTop="md"
                    whiteSpace="nowrap"
                    borderColor="borderPrimary"
                    borderBottomWidth={isActivePath ? "2" : undefined}
                    backgroundColor={
                      isActivePath ? "surfacePrimaryLowest" : undefined
                    }
                    className={classNames({
                      "hover:bg-blue-5": !isActivePath,
                    })}
                    onClick={() => {
                      if (!isActivePath) {
                        navigate(option.to)
                        trackEvent(TrackingEvents.PAYMENTS_TAB_SWITCHED, {
                          currentTab: option.id,
                          previousTab:
                            PAYMENTS_DASHBOARD_OPTIONS.find(() => isActivePath)
                              ?.id || "dashboard",
                        })
                      }
                    }}
                  >
                    <Text
                      fontSize="s4"
                      color={isActivePath ? "textPrimary" : "textMedium"}
                    >
                      {option.title}
                    </Text>
                  </Box>
                )
              })}
            </Inline>
          ) : null}
        </Stack>
      </Box>
    </Box>
  )
}

const cashbookPaymentsFeatures: Array<{
  id: string
  title: string
  icon: PAYMENTS_ICON_NAMES
}> = [
  {
    id: "staffWallet",
    icon: "moneyTransfer",
    title: "Add money to staff card",
  },
  {
    id: "autoReports",
    title: "Get notified of every expense",
    icon: "notifiedExpenses",
  },
  {
    id: "staffWalletLimits",
    icon: "upiWalletLimits",
    title: "Set limits on Cards",
  },
]

function PaymentsNotEnabled() {
  return (
    <Box paddingY="6">
      <Box
        paddingY="8"
        paddingX="10"
        marginX="8"
        backgroundColor="white"
        rounded="lg"
        borderWidth="1"
        borderColor="borderOutline"
        className="max-h-fit"
      >
        <Inline gap="12" alignItems="center">
          <Inline gap="6" alignItems="center">
            <Box as="a" href={"https://youtu.be/xi_CcQnAE1k"} target="_blank">
              <img
                src={CashbookCard}
                className="w-full max-w-[268px]"
                alt={`${config.appTitle} Card`}
              />
            </Box>
            <Stack gap="6">
              <Stack gap="2">
                <Text as="h2" fontSize="s1">
                  Activate ‘Payments’ on{" "}
                  <Text color="textPrimary" as="span">
                    {config.appTitle} Mobile App
                  </Text>{" "}
                  for Your Business!
                </Text>
                <Text fontSize="b3" color="textMedium">
                  You will be able to access {config.appTitle} payments on web
                </Text>
              </Stack>
              <Inline as="ul" gap="10">
                {cashbookPaymentsFeatures.map((feat) => (
                  <Stack
                    key={feat.id}
                    gap="4"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    className="max-w-[102px]"
                  >
                    <PaymentsIcons size="8" name={feat.icon} />
                    <Text fontSize="c2">{feat.title}</Text>
                  </Stack>
                ))}
              </Inline>
            </Stack>
          </Inline>
          <Box borderColor="borderOutline" className="border-l  h-48" />
          <Stack
            gap="6"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Text fontSize="b3">Scan QR to download or open CashBook App</Text>
            <Inline gap="16">
              <Stack gap="2">
                <Box height="16" width="16">
                  <img
                    src={CashBookAndroid}
                    alt={`Download ${config.appTitle} app on your android devices`}
                  />
                </Box>
                <Text fontSize="c2">Android</Text>
              </Stack>
              <Stack gap="2">
                <Box height="16" width="16">
                  <img
                    src={CashBookIos}
                    alt={`Download ${config.appTitle} app on your ios devices`}
                  />
                </Box>
                <Text fontSize="c2">iOS</Text>
              </Stack>
            </Inline>
          </Stack>
        </Inline>
      </Box>
    </Box>
  )
}
