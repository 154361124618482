import { sleep, useMount } from "@cashbook/util-general"
import {
  CollectionReference,
  Timestamp,
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore"
import { useCallback, useEffect, useMemo, useReducer, useState } from "react"
import { useFirestore } from "reactfire"
import {
  BusinessTransactionsPayload,
  getBusinessTransactions,
  getMasterWalletBalance,
  getUserJourney,
} from "./services"
import { PaymentsTransaction } from "./types"
import { usePagination } from "./utils"
import { useFormik } from "formik"
import { showPaymentsStore } from "@cashbook/data-store/storage"

type QuestionIds =
  | "staffSize"
  | "isGSTRegistered"
  | "haveCurrentAccount"
  | "gstNumber"
  | "currentAccountType"

type TJoinWaitList = {
  [key in QuestionIds]?: string | boolean
}

function useWaitListCollection() {
  const store = useFirestore()
  return collection(store, "PaymentsWaitlist") as CollectionReference<
    TJoinWaitList & { businessId: string }
  >
}

async function checkDocumentExists(businessId: string) {
  const store = getFirestore()
  const documentRef = doc(collection(store, "PaymentsWaitlist"), businessId)
  const documentSnapshot = await getDoc(documentRef)
  return documentSnapshot.exists()
}

export function useDoesWaitListExist(
  businessId: string,
  authMemberIsOwner: boolean
) {
  const [doesExist, setDoesExist] = useState<boolean>(false)

  const getWaitListDocStatus = useCallback(async () => {
    try {
      const docExist = await checkDocumentExists(businessId)
      setDoesExist(docExist)
    } catch (e) {
      const err = e as Error
      setDoesExist(false)
      throw err
    }
  }, [businessId])

  useEffect(() => {
    if (authMemberIsOwner) {
      getWaitListDocStatus()
    }
  }, [authMemberIsOwner, getWaitListDocStatus])

  return { doesExist }
}

export function useJoinWaitList(businessId: string) {
  const waitListCollection = useWaitListCollection()
  const joinWaitList = useCallback(
    async function joinWaitList({ ...rest }: TJoinWaitList) {
      const joinWaitListRef = doc(waitListCollection, businessId)
      try {
        await setDoc(joinWaitListRef, {
          businessId,
          ...rest,
        } as never)
        sleep(1000)
        return joinWaitListRef.id
      } catch (e) {
        const err = e as Error
        throw err
      }
    },
    [waitListCollection, businessId]
  )
  return {
    joinWaitList,
  }
}

export type VkycStatus = {
  aadhaar: boolean
  pan: boolean
  exists: boolean
  vcipidstatus?: string
  details?: {
    video_call_completed_at: Date | Timestamp | null
  }
  completed?: boolean
}

export type UserJourney = {
  role: "owner" | "staff" | "partner"
  min_kyc: boolean
  can_join_waitlist?: boolean
  wallet_issued?: number
  va_txn_done?: false
  can_enable_payments?: boolean
  can_enable_wallet?: boolean
  vkyc: VkycStatus
  entity_id?: string
  show_payments_tab?: boolean
}

export function useUserJourney(
  businessId: string,
  role: "owner" | "staff" | "partner"
) {
  const getUserJourneyForPayments = useCallback(async () => {
    if (role === "staff") return
    try {
      const { data } = await getUserJourney<{
        data: UserJourney
      }>({ businessId })
      if (data.role === "owner") {
        showPaymentsStore.updateList(
          businessId,
          Boolean(data.show_payments_tab)
        )
      }
      return data
    } catch (e) {
      const err = e as Error
      throw err
    }
  }, [businessId, role])
  useMount(() => {
    getUserJourneyForPayments()
  })
}

//Reducer
type Master_Wallet_Details = {
  balance: string
  account_no?: string
  ifsc_code?: string
  created_at?: Timestamp | Date
}
type TransactionDetails = {
  totalExpenses?: number
  fetching?: boolean
  fetchingMore?: boolean
  totalTransactions?: number
  error?: Error | string | null
  transactions?: PaymentsTransaction[]
}
type DashboardState = {
  error?: string | Error | null
  status: "init" | "in_progress" | "success" | "failed"
  masterWalletDetails?: Master_Wallet_Details
  transactionDetails?: TransactionDetails
}

type TYPE_AND_PAYLOAD =
  | { type: "FETCHING_INITIAL_DATA" }
  | {
      type: "FETCHED_INITIAL_DATA"
      payload: {
        masterWallet: Master_Wallet_Details
        transactions: TransactionDetails
      }
    }
  | {
      type: "FETCHING_DATA_FAILED"
      payload: { error: Error }
    }
  | { type: "RESET_FETCHED_TRANSACTIONS" }
  | {
      type: "FETCHED_TRANSACTIONS_DATA"
      payload: { transactions: TransactionDetails }
    }
  | {
      type: "FETCHING_TRANSACTIONS_DATA_FAILED"
      payload: { error: Error | string | null }
    }

const reducer = (
  state: DashboardState,
  action: TYPE_AND_PAYLOAD
): DashboardState => {
  switch (action.type) {
    case "FETCHING_INITIAL_DATA":
      return {
        ...state,
        status: "in_progress",
        error: null,
        masterWalletDetails: undefined,
        transactionDetails: undefined,
      }
    case "FETCHED_INITIAL_DATA":
      return {
        ...state,
        status: "success",
        error: null,
        masterWalletDetails: action.payload.masterWallet,
        transactionDetails: action.payload.transactions,
      }
    case "FETCHING_DATA_FAILED":
      return {
        ...state,
        status: "failed",
        error: action.payload.error,
        masterWalletDetails: undefined,
        transactionDetails: undefined,
      }
    case "RESET_FETCHED_TRANSACTIONS":
      return {
        ...state,
        transactionDetails: {
          fetching: true,
          fetchingMore: false,
          totalExpenses: undefined,
          totalTransactions: undefined,
          transactions: undefined,
        },
      }
    case "FETCHED_TRANSACTIONS_DATA":
      return {
        ...state,
        transactionDetails: {
          fetching: false,
          fetchingMore: false,
          totalExpenses: action.payload.transactions.totalExpenses,
          transactions: action.payload.transactions.transactions,
          totalTransactions: action.payload.transactions.totalTransactions,
        },
      }
    case "FETCHING_TRANSACTIONS_DATA_FAILED":
      return {
        ...state,
        transactionDetails: {
          fetching: false,
          fetchingMore: false,
          totalExpenses: undefined,
          totalTransactions: undefined,
          transactions: undefined,
          error: action.payload.error,
        },
      }
    default:
      return state
  }
}

const initialDashboardState: DashboardState = {
  status: "init",
  masterWalletDetails: undefined,
  error: null,
  transactionDetails: undefined,
}

//Reducer ends here

type BusinessTransactionsFilters = {
  from_datetime?: Date
  to_datetime?: Date
}

export function useDashboard(
  businessId: string,
  initialParamsProps?: BusinessTransactionsFilters
) {
  const [state, dispatch] = useReducer(reducer, initialDashboardState)
  const initialTransactionsParams = useMemo(() => {
    if (!initialParamsProps) return {}
    return {
      ...initialParamsProps,
    }
  }, [initialParamsProps])

  const { pagination } = usePagination({
    skip: 0,
    take: 5,
    totalItems: 0,
  })

  const { values: params, setValues } = useFormik<BusinessTransactionsFilters>({
    initialValues: initialTransactionsParams,
    onSubmit: () => undefined,
  })

  const callApis = useCallback(async () => {
    dispatch({ type: "FETCHING_INITIAL_DATA" })
    try {
      const payload: BusinessTransactionsPayload = {
        businessId,
        skip: pagination.skip,
        take: pagination.take,
      }
      if (params.from_datetime) {
        payload.from_datetime = params.from_datetime.toISOString()
      }
      if (params.to_datetime) {
        payload.to_datetime = params.to_datetime.toISOString()
      }
      const masterWalletApi = getMasterWalletBalance<{
        data: {
          balance: {
            balance: string
          }
          business: {
            account_no?: string
            ifsc_code?: string
            created_at?: Timestamp | Date
          }
        }
      }>(businessId)
      const businessTransactionsApi = getBusinessTransactions<{
        count: number
        data: PaymentsTransaction[]
        totalCredit?: number
        totalDebit?: number
      }>(payload)
      Promise.all([masterWalletApi, businessTransactionsApi])
        .then((responses) => {
          const [masterWallet, businessTransactions] = responses
          if (!masterWallet || !businessTransactions) {
            throw new Error("Something went wrong while fetching data!")
          }
          const masterWalletDetails: Master_Wallet_Details = {
            balance: masterWallet.data.balance.balance,
            account_no: masterWallet.data.business.account_no,
            ifsc_code: masterWallet.data.business.ifsc_code,
            created_at: masterWallet.data.business.created_at,
          }
          const transactionsDetails: TransactionDetails = {
            totalExpenses:
              (businessTransactions?.totalDebit || 0) -
              (businessTransactions.totalCredit || 0),
            totalTransactions: businessTransactions.count,
            transactions: businessTransactions.data,
          }
          dispatch({
            type: "FETCHED_INITIAL_DATA",
            payload: {
              masterWallet: masterWalletDetails,
              transactions: transactionsDetails,
            },
          })
        })
        .catch((e) => {
          dispatch({
            type: "FETCHING_DATA_FAILED",
            payload: { error: e as Error },
          })
        })
    } catch (e) {
      dispatch({ type: "FETCHING_DATA_FAILED", payload: { error: e as Error } })
    }
  }, [
    businessId,
    pagination.skip,
    pagination.take,
    params.from_datetime,
    params.to_datetime,
  ])

  useMount(() => {
    callApis()
  })

  const getTransactions = useCallback(async () => {
    try {
      const constructedPayload: BusinessTransactionsPayload = {
        businessId,
        skip: pagination.skip,
        take: pagination.take,
      }
      if (params.from_datetime) {
        constructedPayload.from_datetime = params.from_datetime.toISOString()
      }
      if (params.to_datetime) {
        constructedPayload.to_datetime = params.to_datetime.toISOString()
      }
      const response = await getBusinessTransactions<{
        count: number
        data: PaymentsTransaction[]
        totalCredit?: number
        totalDebit?: number
      }>(constructedPayload)
      const transactionsDetails: TransactionDetails = {
        totalExpenses:
          (response?.totalDebit || 0) - (response.totalCredit || 0),
        totalTransactions: response.count,
        transactions: response.data,
      }
      dispatch({
        type: "FETCHED_TRANSACTIONS_DATA",
        payload: { transactions: transactionsDetails },
      })
    } catch (e) {
      const error = e as Error
      dispatch({
        type: "FETCHING_TRANSACTIONS_DATA_FAILED",
        payload: { error: error },
      })
    }
  }, [
    businessId,
    pagination.skip,
    pagination.take,
    params.from_datetime,
    params.to_datetime,
  ])

  useEffect(() => {
    if (state.transactionDetails?.fetching) {
      getTransactions()
    }
  }, [state.transactionDetails?.fetching, getTransactions])

  function handleDurationChange(value1?: Date, value2?: Date) {
    setValues({ ...params, from_datetime: value1, to_datetime: value2 })
    dispatch({ type: "RESET_FETCHED_TRANSACTIONS" })
  }

  function retry() {
    setValues({})
    callApis()
  }

  return {
    error: state.error,
    status: state.status,
    masterWalletDetails: state.masterWalletDetails,
    totalExpenses: state.transactionDetails?.totalExpenses,
    fetchingTransactions: state.transactionDetails?.fetching,
    businessTransactions: state.transactionDetails?.transactions,
    transactionsCount: state.transactionDetails?.totalTransactions,
    fetchingMoreTransactions: state.transactionDetails?.fetchingMore,

    //Filters
    params,
    refreshPage: retry,
    handleDurationChange,
  }
}
