import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import {
  useFirebaseApp,
  AuthProvider,
  FirestoreProvider,
  FunctionsProvider,
  RemoteConfigProvider,
  AnalyticsProvider,
  useInitRemoteConfig,
} from "reactfire"
import {
  RedirectToTransactionsFromIndexPage,
  BookDetailsPageLayout,
} from "./pages/BookDetailsPage"
import BookTransactionsPage from "./pages/BookTransactionsPage"
import LoginPage from "./pages/LoginPage"
import ProfilePage from "./pages/ProfilePage"
import OnboardingPage from "./pages/OnboardingPage"
import { BusinessLayout, DashboardLayout } from "./DashboardLayout"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getFunctions } from "firebase/functions"
import { getAnalytics } from "firebase/analytics"
import { getRemoteConfig, fetchAndActivate } from "firebase/remote-config"
import { getDeviceOS, useDeviceType } from "@cashbook/device-info"
import config from "./config"
import { configureTracking } from "@cashbook/util-tracking"
import SharedBookInvitationDetailsPage from "./pages/SharedBookInvitationDetails"
import AcceptSharedBookInvitationPage from "./pages/AcceptSharedBookInvitation"
import HelpDocsListPage, { HelpDocsLayout } from "./pages/HelpDocsListPage"
import HelpDocDetailsPage from "./pages/HelpDocDetailsPage"
import AddMemberToBookPage from "./pages/AddMemberToBook"
import ChangePhoneNumber from "./pages/ChangePhoneNumber"
import PremiumBenefits from "./pages/PremiumBenefits"
import BookTransactionsImportPage from "./pages/BookTransactionsImportPage"
import SmBookTransactionsPage from "./pages/sm/BookTransactionsPage"
import SmHelpDocsListPage from "./pages/sm/HelpDocsListPage"
import SmHelpDocDetailsPage from "./pages/sm/HelpDocDetailsPage"
import SmProfilePage from "./pages/sm/ProfilePage"
import raf from "raf"
import BookSettingsPage, {
  RedirectToMembersFromIndexPage,
} from "./pages/BookSettingsPage"
import BookMemberSettingsPage from "./pages/BookMemberSettingsPage"
import BookFieldSettingsPage from "./pages/BookFieldSettingsPage"
import BookFieldCategorySettingsPage from "./pages/BookFieldCategorySettingsPage"
import BookFieldPaymentModeSettingsPage from "./pages/BookFieldPaymentModeSettingsPage"
import {
  Box,
  InformationWarningIcon,
  Inline,
  SpinnerIcon,
  Stack,
  Text,
  getButtonClassName,
} from "@cashbook/web-components"
import ErrorBoundary from "./ErrorBoundary"
import { NotificationsProvider } from "@cashbook/data-store/notifications"
import LogoutPage from "./pages/Logout"
import { GuestRoutes, ProtectedRoutes } from "./Auth"
import { RedirectUnlessProfileIsCompleted } from "./Profile"
import SmBookSettingsPage, {
  SmEnsureBookExistsPage,
} from "./pages/sm/BookSettingsPage"
import SmBusinessSettingsPage from "./pages/sm/BusinessSettingsPage"
import SmBookMemberSettingsPage from "./pages/sm/BookMemberSettingsPage"
import SmBookEditorRoleSettingsPage from "./pages/sm/BookEditorRoleSettingsPage"
import SmBookFieldSettingsPage from "./pages/sm/BookFieldSettingsPage"
import SmBookFieldCategorySettingsPage from "./pages/sm/BookFieldCategorySettingsPage"
import SmBookFieldPartySettingsPage from "./pages/sm/BookFieldPartySettingsPage"
import SmBookFieldPaymentModeSettingsPage from "./pages/sm/BookFieldPaymentModeSettingsPage"
import SmBookTransactionDetailsPage, {
  SmEnsureTransactionExistsPage,
} from "./pages/sm/BookTransactionDetailsPage"
import SmAddTransactionPage from "./pages/sm/AddTransactionPage"
import SmEditTransactionPage from "./pages/sm/EditTransactionPage"
import SmAddMemberToBookPage from "./pages/sm/AddMemberToBookPage"
import SmChangePhoneNumberPage from "./pages/sm/ChangePhoneNumberPage"
import BookEditorRoleSettingsPage from "./pages/BookEditorRoleSettingsPage"
import BookFieldPartySettingsPage from "./pages/BookFieldPartySettingsPage"
import BillDetailsPage from "./pages/BillDetails"
import EmailVerificationDetails from "./pages/EmailVerificationDetails"
import BusinessesListPage, {
  RedirectToCashbooksOfBusiness,
  RedirectToFirstBusiness,
} from "./pages/BusinessesListPage"
import BusinessSettingsPage, {
  RedirectToTeamFromIndexPage,
} from "./pages/BusinessSettingsPage"
import BusinessTeamSettingsPage from "./pages/BusinessTeamSettingsPage"
import BusinessTeamMemberPage from "./pages/BusinessTeamMember"
import BusinessCashbooksPage from "./pages/BusinessCashbooksPage"
import BusinessProfilePage from "./pages/BusinessProfilePage"
import BusinessLevelSettingsPage from "./pages/BusinessSettings"
import { BusinessDetailsPageLayout } from "./pages/BusinessDetailsPageLayout"
import SmBusinessesListPage, {
  SmEnsureBusinessExistsPage,
} from "./pages/sm/BusinessesListPage"
import SmBusinessCashbooksPage from "./pages/sm/SmBusinessCashbooksPage"
import SmBusinessTeamSettings from "./pages/sm/BusinessTeamSettings"
import SmBusinessTeamMemberSettings from "./pages/sm/BusinessTeamMember"
import SmBusinessProfile from "./pages/sm/BusinessProfilePage"
import SmBusinessLevelSettings from "./pages/sm/BusinessLevelSettingsPage"
import AddFirstBusiness from "./pages/AddFIrstBusiness"
import NewBusinessTransition from "./pages/TransitionToNewBusiness"
import SmTransitionToNewBusiness from "./pages/sm/TransitionToNewBusiness"
import { NotSupportedComponent } from "./AppNotSupported"
import PaymentsPage, { RedirectToPaymentsDashboard } from "./pages/PaymentsPage"
import PaymentsDashboardPage from "./pages/PaymentsDashboardPage"
import MasterWalletPage from "./pages/MasterWalletPage"
import OverallBusinessPage from "./pages/OverallBusiness"
import StaffWalletsPage from "./pages/StaffWalletsPage"
import { PaymentsProtectedRoutes } from "./Payments"
import StaffWalletPage from "./pages/StaffWallet"
import { WalletLimitsPage } from "./pages/WalletLimits"
import BookPartiesImportPage from "./pages/BookPartiesImportPage"
import { ChangeEmailAddress } from "./pages/ChangeEmailAddress"
import BookCustomFieldSettingsPage from "./pages/BookCustomFieldSettings"
import { useEffect } from "react"

raf.polyfill()

const deviceOs = getDeviceOS()

export default function CasbookWebApp() {
  const firebaseApp = useFirebaseApp()
  // initialize the sdks with the normal Firebase SDK functions
  const auth = getAuth(firebaseApp)
  const firestore = getFirestore(firebaseApp)
  const cloudFns = getFunctions(
    firebaseApp,
    config.firebaseConfig.cloudFunctionsRegion
  )
  const analytics = getAnalytics(firebaseApp)

  useEffect(() => {
    if (config.appEnv === "development") {
      configureTracking({ channels: "console" })
    } else {
      configureTracking({
        channels: "google",
        config: { analytics },
      })
    }
  }, [analytics])

  const { status: remoteConfigStatus, data: remoteConfigInstance } =
    useInitRemoteConfig(async (firebaseApp) => {
      const remoteConfig = getRemoteConfig(firebaseApp)
      remoteConfig.defaultConfig = {
        [config.firebaseConfig.supportPhoneNumberKey]:
          config.supportPhoneNumber.replace(/[^\d]/gi, ""),
      }
      remoteConfig.settings = {
        minimumFetchIntervalMillis: 3600000, // 1hr
        fetchTimeoutMillis: 60000, // 1m
      }
      try {
        await fetchAndActivate(remoteConfig)
      } catch (e) {
        return remoteConfig
      }
      return remoteConfig
    })
  const { isSmallDevice } = useDeviceType()
  const location = useLocation()

  // redirect to mobile version if necessary
  if (isSmallDevice) {
    const pathname = location.pathname
    if (pathname.indexOf("/m") !== 0) {
      return (
        <Navigate to={`/m${location.pathname}${location.search}`} replace />
      )
    }
  }

  // redirect to desktop version if necessary
  if (!isSmallDevice) {
    const pathname = location.pathname
    if (pathname.indexOf("/m") === 0) {
      return (
        <Navigate
          to={`${location.pathname.slice(2)}${location.search}`}
          replace
        />
      )
    }
  }
  if (remoteConfigStatus === "loading") {
    return (
      <span>
        <SpinnerIcon /> Loading Application Configuration...
      </span>
    )
  }
  if (remoteConfigStatus === "error" && !remoteConfigInstance) {
    return (
      <NotSupportedComponent
        error={new Error("We could not fetch Remote config properly.")}
      />
    )
  }
  return (
    <RemoteConfigProvider sdk={remoteConfigInstance}>
      <AuthProvider sdk={auth}>
        <AnalyticsProvider sdk={analytics}>
          <FirestoreProvider sdk={firestore}>
            <FunctionsProvider sdk={cloudFns}>
              <ErrorBoundary>
                <NotificationsProvider
                  vapidKey={config.firebaseConfig.vapidKey}
                >
                  {isSmallDevice ? <RemovingMobileWeb /> : null}
                  <Routes>
                    <Route
                      path="/m/login"
                      element={
                        <GuestRoutes redirectTo="/m/businesses">
                          <LoginPage />
                        </GuestRoutes>
                      }
                    />
                    <Route
                      path="/m/logout"
                      element={
                        <ProtectedRoutes>
                          <LogoutPage />
                        </ProtectedRoutes>
                      }
                    />
                    <Route
                      path="/m/onboarding"
                      element={
                        <ProtectedRoutes>
                          <OnboardingPage />
                        </ProtectedRoutes>
                      }
                    />
                    <Route path="/m/bills" element={<BillDetailsPage />} />
                    <Route
                      path="/m/email-verify"
                      element={<EmailVerificationDetails />}
                    />
                    <Route
                      path="/m"
                      element={
                        <ProtectedRoutes>
                          <RedirectUnlessProfileIsCompleted redirectTo="/m/onboarding" />
                        </ProtectedRoutes>
                      }
                    >
                      <Route path="businesses">
                        <Route
                          path=":businessId"
                          element={<SmEnsureBusinessExistsPage />}
                        >
                          <Route path="cashbooks">
                            <Route
                              path=":bookId"
                              element={<SmEnsureBookExistsPage />}
                            >
                              <Route path="transactions">
                                <Route
                                  path="create"
                                  element={<SmAddTransactionPage />}
                                />
                                <Route
                                  path=":transactionId"
                                  element={<SmEnsureTransactionExistsPage />}
                                >
                                  <Route
                                    path="edit"
                                    element={<SmEditTransactionPage />}
                                  />
                                  <Route
                                    path=""
                                    element={<SmBookTransactionDetailsPage />}
                                  />
                                </Route>
                                <Route
                                  path=""
                                  element={<SmBookTransactionsPage />}
                                />
                              </Route>
                              <Route path="settings">
                                <Route path="members">
                                  <Route
                                    path="invite"
                                    element={<SmAddMemberToBookPage />}
                                  />
                                  <Route
                                    path=""
                                    element={<SmBookMemberSettingsPage />}
                                  />
                                </Route>
                                <Route path="fields">
                                  <Route
                                    path="categories"
                                    element={
                                      <SmBookFieldCategorySettingsPage />
                                    }
                                  />
                                  <Route
                                    path="payment-modes"
                                    element={
                                      <SmBookFieldPaymentModeSettingsPage />
                                    }
                                  />
                                  <Route
                                    path="parties"
                                    element={<SmBookFieldPartySettingsPage />}
                                  />
                                  <Route
                                    path=""
                                    element={<SmBookFieldSettingsPage />}
                                  />
                                </Route>
                                <Route path="roles">
                                  <Route
                                    path="editor"
                                    element={<SmBookEditorRoleSettingsPage />}
                                  />
                                  <Route
                                    path=""
                                    element={<Navigate to="editor" replace />}
                                  />
                                </Route>
                                <Route
                                  path=""
                                  element={<SmBookSettingsPage />}
                                />
                              </Route>
                              <Route
                                path="import-transactions"
                                element={<BookTransactionsImportPage />}
                              />
                              <Route
                                path=""
                                element={
                                  <RedirectToTransactionsFromIndexPage routePrefix="/m" />
                                }
                              />
                            </Route>
                            <Route
                              path=""
                              element={<SmBusinessCashbooksPage />}
                            />
                          </Route>
                          <Route path="business-settings">
                            <Route path="team">
                              <Route
                                path=":teamMemberId"
                                element={<SmBusinessTeamMemberSettings />}
                              />
                              <Route
                                path=""
                                element={<SmBusinessTeamSettings />}
                              />
                            </Route>
                            <Route
                              path="profile"
                              element={<SmBusinessProfile />}
                            />
                            <Route
                              path="settings"
                              element={<SmBusinessLevelSettings />}
                            />
                            <Route
                              path=""
                              element={<SmBusinessSettingsPage />}
                            />
                          </Route>
                          <Route
                            path=""
                            element={<RedirectToCashbooksOfBusiness />}
                          />
                        </Route>
                        <Route path="" element={<SmBusinessesListPage />} />
                      </Route>
                      <Route path="docs">
                        <Route
                          path=":docId"
                          element={<SmHelpDocDetailsPage />}
                        />
                        <Route path="" element={<SmHelpDocsListPage />} />
                      </Route>
                      <Route path="profile" element={<SmProfilePage />} />
                      <Route
                        path="change-phone-number"
                        element={<SmChangePhoneNumberPage />}
                      />
                      <Route
                        path="change-email-address"
                        element={<ChangeEmailAddress />}
                      />
                      <Route path="premium" element={<PremiumBenefits />} />
                      <Route
                        path="accept-invite"
                        element={
                          <ProtectedRoutes redirectBack>
                            <AcceptSharedBookInvitationPage />
                          </ProtectedRoutes>
                        }
                      />
                      <Route
                        path=""
                        element={<Navigate to="/m/businesses" replace />}
                      />
                      <Route
                        path="/m/invite"
                        element={<SharedBookInvitationDetailsPage />}
                      />
                      <Route path="/m/new-business-transition">
                        <Route
                          path=":businessId"
                          element={
                            <ProtectedRoutes>
                              <SmTransitionToNewBusiness />
                            </ProtectedRoutes>
                          }
                        />
                      </Route>
                    </Route>
                    {/* Desktop or Primary routes. When adding any routes, please make sure to add a mobile version as well */}
                    <Route
                      path="/invite"
                      element={<SharedBookInvitationDetailsPage />}
                    />
                    <Route path="/bills" element={<BillDetailsPage />} />
                    <Route
                      path="/email-verify"
                      element={<EmailVerificationDetails />}
                    />
                    <Route
                      path="/accept-invite"
                      element={
                        <ProtectedRoutes redirectBack>
                          <AcceptSharedBookInvitationPage />
                        </ProtectedRoutes>
                      }
                    />
                    <Route
                      path="/logout"
                      element={
                        <ProtectedRoutes>
                          <LogoutPage />
                        </ProtectedRoutes>
                      }
                    />
                    <Route
                      path="/login"
                      element={
                        <GuestRoutes>
                          <LoginPage />
                        </GuestRoutes>
                      }
                    />
                    <Route
                      path="/onboarding"
                      element={
                        <ProtectedRoutes>
                          <OnboardingPage />
                        </ProtectedRoutes>
                      }
                    />
                    <Route
                      path="/add-first-business"
                      element={
                        <ProtectedRoutes>
                          <RedirectUnlessProfileIsCompleted>
                            <DashboardLayout />
                          </RedirectUnlessProfileIsCompleted>
                        </ProtectedRoutes>
                      }
                    >
                      <Route path="" element={<AddFirstBusiness />} />
                    </Route>
                    <Route
                      path="/new-business-transition/:businessId"
                      element={
                        <ProtectedRoutes>
                          <NewBusinessTransition />
                        </ProtectedRoutes>
                      }
                    />

                    <Route
                      path="/"
                      element={
                        <ProtectedRoutes>
                          <RedirectUnlessProfileIsCompleted>
                            <DashboardLayout />
                          </RedirectUnlessProfileIsCompleted>
                        </ProtectedRoutes>
                      }
                    >
                      <Route path="/profile" element={<ProfilePage />} />
                      <Route path="/premium" element={<PremiumBenefits />} />
                      <Route
                        path="/change-phone-number"
                        element={<ChangePhoneNumber />}
                      />
                      <Route
                        path="/change-email-address"
                        element={<ChangeEmailAddress />}
                      />
                      {/* Business Layer (Desktop) */}
                      <Route path="/businesses" element={<BusinessLayout />}>
                        <Route
                          path=":businessId"
                          element={<BusinessDetailsPageLayout />}
                        >
                          <Route
                            path="cashbooks"
                            element={<BusinessDetailsPageLayout />}
                          >
                            {/* BOOK LEVEL ROUTES */}
                            <Route
                              path=":bookId"
                              element={<BookDetailsPageLayout />}
                            >
                              <Route
                                path="transactions"
                                element={<BookTransactionsPage />}
                              />
                              <Route
                                path="settings"
                                element={<BookSettingsPage />}
                              >
                                <Route path="members">
                                  <Route
                                    path="invite"
                                    element={<AddMemberToBookPage />}
                                  />
                                  <Route
                                    path=""
                                    element={<BookMemberSettingsPage />}
                                  />
                                </Route>
                                <Route path="fields">
                                  <Route
                                    path="parties"
                                    element={<BookFieldPartySettingsPage />}
                                  />
                                  <Route
                                    path="categories"
                                    element={<BookFieldCategorySettingsPage />}
                                  />
                                  <Route
                                    path="payment-modes"
                                    element={
                                      <BookFieldPaymentModeSettingsPage />
                                    }
                                  />
                                  <Route
                                    path="custom-fields"
                                    element={<BookCustomFieldSettingsPage />}
                                  />
                                  <Route
                                    path=""
                                    element={<BookFieldSettingsPage />}
                                  />
                                </Route>
                                <Route path="roles">
                                  <Route
                                    path="editor"
                                    element={<BookEditorRoleSettingsPage />}
                                  />
                                  <Route
                                    path=""
                                    element={<Navigate to="editor" replace />}
                                  />
                                </Route>
                                <Route
                                  path=""
                                  element={<RedirectToMembersFromIndexPage />}
                                />
                              </Route>
                              <Route
                                path="import-parties"
                                element={<BookPartiesImportPage />}
                              />
                              <Route
                                path="import-transactions"
                                element={<BookTransactionsImportPage />}
                              />
                              <Route
                                path=""
                                element={
                                  <RedirectToTransactionsFromIndexPage />
                                }
                              />
                            </Route>
                            <Route
                              path=""
                              element={<BusinessCashbooksPage />}
                            />
                          </Route>
                          <Route
                            path="payments"
                            element={
                              <PaymentsProtectedRoutes>
                                <PaymentsPage />
                              </PaymentsProtectedRoutes>
                            }
                          >
                            <Route
                              path="dashboard"
                              element={<PaymentsDashboardPage />}
                            />
                            <Route
                              path="master-wallet"
                              element={<MasterWalletPage />}
                            />
                            <Route
                              path="all-transactions"
                              element={<OverallBusinessPage />}
                            />
                            <Route path="staff-wallets">
                              <Route path=":staffId">
                                <Route
                                  path="wallet-limits"
                                  element={<WalletLimitsPage />}
                                />
                                <Route element={<StaffWalletPage />} path="" />
                              </Route>
                              <Route element={<StaffWalletsPage />} path="" />
                            </Route>
                            <Route
                              path=""
                              element={<RedirectToPaymentsDashboard />}
                            />
                          </Route>
                          <Route
                            path="business-settings"
                            element={<BusinessSettingsPage />}
                          >
                            <Route path="team">
                              <Route
                                path=":teamMemberId"
                                element={<BusinessTeamMemberPage />}
                              />
                              <Route
                                path=""
                                element={<BusinessTeamSettingsPage />}
                              />
                            </Route>
                            <Route
                              path="profile"
                              element={<BusinessProfilePage />}
                            />
                            <Route
                              path="settings"
                              element={<BusinessLevelSettingsPage />}
                            />
                            <Route
                              path=""
                              element={<RedirectToTeamFromIndexPage />}
                            />
                          </Route>
                          <Route
                            path=""
                            element={<RedirectToCashbooksOfBusiness />}
                          />
                        </Route>
                        <Route path="" element={<BusinessesListPage />} />
                      </Route>
                      <Route path="docs" element={<HelpDocsLayout />}>
                        <Route path=":docId" element={<HelpDocDetailsPage />} />
                        <Route path="" element={<HelpDocsListPage />} />
                      </Route>
                      <Route path="" element={<RedirectToFirstBusiness />} />
                    </Route>
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </NotificationsProvider>
              </ErrorBoundary>
            </FunctionsProvider>
          </FirestoreProvider>
        </AnalyticsProvider>
      </AuthProvider>
    </RemoteConfigProvider>
  )
}

function RemovingMobileWeb() {
  const { pathname } = useLocation()
  const pathToExclude = !pathname.includes("/m/bills")
  return !pathToExclude ? null : (
    <Stack>
      <Box bgColor="surfaceWarningLowest" paddingX="3" paddingY="4">
        <Inline gap="3" alignItems="center">
          <InformationWarningIcon size="6" color="iconWarning" />
          <Text fontSize="c1" color="textWarning">
            Sorry, mobile web support is ending in a few weeks, but{" "}
            {config.appTitle}
            data will remain secure.
          </Text>
        </Inline>
      </Box>
      <Box bgColor="surfacePrimary" padding="4">
        <Inline alignItems="center" justifyContent="between">
          <Text fontSize="c3" color="white">
            Get our iOS/Android app for uninterrupted services. Thank you.
          </Text>
          <Box
            padding="0"
            textAlign="right"
            bgColor="surfaceDefault"
            rounded="md"
            className="w-26 align-middle"
          >
            <Text
              as="a"
              fontSize="c1"
              href={`${
                deviceOs === "ios"
                  ? config.appStoreDownloadLink
                  : config.appDownloadLink
              }`}
              className={getButtonClassName({ size: "sm" })}
              tabIndex={-1}
              target="_blank"
              color="textPrimary"
            >
              Download App
            </Text>
          </Box>
        </Inline>
      </Box>
    </Stack>
  )
}
