const endpoints = {
  masterWalletBalance: "business/balance",
  masterWalletTransactions: "transaction/va/list",
  businessTransactions: "transaction/business/list",
  getAllParties: "party/business/list",
  downloadBusinessTransactionsReport: "transaction/business/list/download",
  getTransactionsAttachment: "transaction/attachment/get-url",
  transactionDetails: "transaction/details",
  updateAttachment: "transaction/attachment/create",
  deleteAttachment: "transaction/attachment/delete",
  userJourney: "journey/user",
  userProfile: "user/profile",
  walletSummary: "transaction/user/summary",
  walletTransactions: "transaction/user/list",
  walletLimits: "transaction/limits",
  userPreferences: "user/preferences",
  walletLimitPreferences: "user/wallet-limit-preferences",
  userCard: "user/cards",
  userCardDetails: "user/cards/details",
  deactivatedWallets: "business/deactivated-wallets",
  updateTransaction: "transaction/update-details",
  cashbackTransactions: "transaction/business/cashback/list",
  checkWalletEnabled: "user/check-wallet-enabled",
  copyTransactions: "transaction/copy-to-book",
}

export default endpoints
