import { Link, useParams } from "react-router-dom"
import ErrorBoundary from "../ErrorBoundary"
import { SuspenseWithPerf } from "reactfire"
import {
  AddMoneyIcon,
  ArrowRightIcon,
  BankGradientIcon,
  BankPlaceholderIcon,
  Box,
  Button,
  CardDeactivatedIcon,
  CardPendingIcon,
  CheckIcon,
  ControlExpensesIcon,
  CopyIcon,
  CopyToClipboard,
  DataLoadingFallback,
  Inline,
  PageMeta,
  SearchSelect,
  SkeletonRows,
  SkeletonTitle,
  SpinnerIcon,
  Stack,
  SwipeAndPayIcon,
  Text,
  Time,
  Tooltip,
  TransactionDate,
  WhatsAppIcon,
} from "@cashbook/web-components"
import {
  PaymentTransactionTypes,
  PaymentsTransaction,
  WalletStatusFilterTypes,
  transformDate,
  useWallet,
} from "@cashbook/data-store/payments"
import {
  Avatar,
  BreadCrumbsForPayments,
  DateSelectForPayments,
  GeneralErrorHandler,
  PaymentsIcons,
  TransactionDetailsInModal,
  ViewTransactionAttachmentInModal,
  getHelperIconForStatus,
  getStatusTitle,
  getTextColorForStatus,
  getTransactionModeOfPayment,
  walletLimits,
} from "../Payments"
import { WhatsAppContactLink } from "../HelpDocs"
import config from "../config"
import { toast } from "react-hot-toast"
import { Amount } from "../support/Intl"
import { SyntheticEvent, useCallback, useMemo, useState } from "react"
import {
  TBusinessUser,
  getBusinessRoleDetailsForMember,
} from "@cashbook/data-store/businesses"
import { userWalletStore } from "@cashbook/data-store/storage"
import { CashbookPrepaidCard } from "../Payments/StaffWallet"
import { NotInWeb } from "../support"
import classNames from "classnames"
import { pluralize, useMount } from "@cashbook/util-general"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"

export default function StaffWalletPage() {
  const { staffId, businessId } = useParams()
  if (!staffId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={
          <DataLoadingFallback label="Loading staff wallet details..." />
        }
        traceId="loading_staff_wallets_details"
      >
        <StaffWallet key={staffId} staffId={staffId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

const walletHeaderTabs: Array<{ id: PaymentTransactionTypes; title: string }> =
  [
    { id: "EXPENSES", title: "Expenses" },
    { id: "B2C", title: "Added to Card" },
  ]

function StaffWallet({
  businessId,
  staffId,
}: {
  businessId: string
  staffId: string
}) {
  const {
    user,
    error,
    status,
    params,
    lastPage,
    business,
    authUser,
    cardWidget,
    cardDetails,
    currentPage,
    userProfile,
    widgetFetching,
    transactions,
    totalExpenses,

    areFiltersApplied,
    transactionsCount,
    fetchingTransactions,
    fetchingMoreTransactions,

    fetchMore,
    refreshPage,
    getCardWidget,
    handleDateChange,
    handleParamsChange,
    handleCategoryChange,
    getTeamMemberInfoForId,
    getWalletBalanceForDeactivatedWallet,
  } = useWallet(businessId, staffId)

  const userWallet = userWalletStore.getUserWallet()

  const [deactivatedWalletBalance, setDeactivatedWalletBalance] =
    useState<number>(0)

  const showLimitsAndCardSetting: boolean = useMemo(() => {
    if (user.kycStatus !== "init") {
      if (authUser.uid === user.uid) {
        return true
      }
      const role = getBusinessRoleDetailsForMember(business, user.uid)
      if (
        role.id === "staff" &&
        (authUser.role.id === "partner" || authUser.role.id === "owner")
      ) {
        return true
      }
      if (role.id === "partner" && authUser.role.id === "owner") {
        return true
      }
    }
    return false
  }, [user.kycStatus, user.uid, authUser.uid, authUser.role.id, business])

  const showCardDetails = authUser.uid === user.uid

  const { from_datetime, to_datetime } = params

  const inviteText = `I have created card for you to make business expense for ${business.name}. Here is the link to activate card`

  const fetchDeactivatedWalletBalance = useCallback(
    async (userId: string) => {
      const balance = await getWalletBalanceForDeactivatedWallet(userId)
      setDeactivatedWalletBalance(balance || 0)
    },
    [getWalletBalanceForDeactivatedWallet]
  )

  useMount(() => {
    if (userWallet.deactivation && userWallet.walletId) {
      fetchDeactivatedWalletBalance(userWallet.walletId)
    }
  })

  const isLoggedInUser = user.uid === authUser.uid

  return (
    <>
      <PageMeta>
        <title>
          {" "}
          {user?.name} (Card) - {business.name}
        </title>
      </PageMeta>
      <Box key={businessId} height="full">
        <BreadCrumbsForPayments
          position="relative"
          title={
            isLoggedInUser
              ? "Your Card"
              : `${userProfile?.name || user?.name}'s Card`
          }
          removeBorderLine
          links={[
            { to: "payments", title: "Payments" },
            { to: "staff-wallets", title: "Member Cards" },
            {
              to: staffId,
              title: isLoggedInUser
                ? "Your Card"
                : userProfile?.name || user?.name || "Staff Card Details",
            },
          ]}
        />
        <Box paddingX="8">
          {user && (user?.kycStatus === "init" || user.deactivation) ? (
            <Stack gap="10">
              <Box
                maxWidth="3xl"
                rounded="md"
                borderWidth="1"
                borderColor="borderOutline"
              >
                <Inline
                  paddingY="4"
                  paddingX="5"
                  alignItems="center"
                  justifyContent="between"
                  borderBottomWidth="1"
                  borderColor="borderOutline"
                  backgroundColor="surfaceWarningLowest"
                >
                  <Inline alignItems="center" gap="4">
                    {userWallet?.deactivation === "completed" ? (
                      <CardDeactivatedIcon color="iconWarning" />
                    ) : (
                      <CardPendingIcon color="iconWarning" />
                    )}
                    <Stack gap="2">
                      <Text fontSize="s3" color="textWarning">
                        {userWallet?.deactivation === "init"
                          ? "Card Deactivation Pending"
                          : userWallet?.deactivation === "completed"
                          ? "Card Deactivated"
                          : "Card activation pending!"}
                      </Text>
                      <Text fontSize="b3">
                        {userWallet?.deactivation
                          ? `As ${
                              user.name?.split(" ")[0]
                            } is no longer a part of this business`
                          : `Ask ${
                              user.name?.split(" ")[0]
                            } to complete KYC to activate card`}
                      </Text>
                    </Stack>
                  </Inline>
                </Inline>
                {userWallet?.deactivation ? (
                  <Stack gap="4" padding="6">
                    <Text fontSize="c2" color="textMedium">
                      Current Card Balance
                    </Text>
                    <Amount
                      currency="inr"
                      fontSize="h4"
                      amount={deactivatedWalletBalance}
                    />
                  </Stack>
                ) : (
                  <Inline padding="6" gap="6" alignItems="center">
                    <Box
                      borderWidth="1"
                      borderColor="borderOutline"
                      rounded="md"
                    >
                      <Stack gap="2" padding="6">
                        <Text fontSize="s1">
                          <span role="img" aria-label="greeting">
                            👋🏼
                          </span>{" "}
                          Hey {user?.name?.split(" ")[0]},
                        </Text>
                        <Text
                          className="line-clamp-3"
                          fontSize="c2"
                          color="textMedium"
                        >
                          {inviteText + " - " + config.appDownloadLink}
                        </Text>
                      </Stack>
                      <CopyToClipboard>
                        {({ copied, copy }) => (
                          <Inline
                            gap="2"
                            as="button"
                            paddingY="3"
                            paddingX="6"
                            width="full"
                            color="textPrimary"
                            alignItems="center"
                            justifyContent="center"
                            onClick={() => {
                              toast.success("Link copied successfully.")
                              copy(inviteText + " - " + config.appDownloadLink)
                            }}
                            backgroundColor="surfacePrimaryLowest"
                          >
                            {copied ? (
                              <CheckIcon color="iconSuccess" />
                            ) : (
                              <CopyIcon />
                            )}
                            <Text fontSize="bt">
                              Copy to share with {user?.name}
                            </Text>
                          </Inline>
                        )}
                      </CopyToClipboard>
                    </Box>
                    <Text fontSize="b4">OR</Text>
                    <WhatsAppContactLink
                      fullWidth
                      size="lg"
                      level="primary"
                      phoneNumber={user.phoneNumber}
                      text={inviteText + " - " + config.appDownloadLink}
                    >
                      <WhatsAppIcon />
                      Share Activation Link
                    </WhatsAppContactLink>
                  </Inline>
                )}
              </Box>
              {userWallet?.deactivation ? (
                <TransactionsTable
                  status={params.status}
                  category={params.category}
                  totalExpenses={totalExpenses}
                  to_datetime={to_datetime}
                  from_datetime={from_datetime}
                  showFetchMore={currentPage !== lastPage}
                  transactions={transactions}
                  transactionsCount={transactionsCount}
                  areFiltersApplied={areFiltersApplied}
                  fetchingTransactions={fetchingTransactions}
                  fetchingMoreTransactions={fetchingMoreTransactions}
                  userIds={{
                    userId: user.uid,
                    authUserId: authUser.uid,
                  }}
                  fetchMore={fetchMore}
                  handleDateChange={handleDateChange}
                  handleParamsChange={handleParamsChange}
                  handleChange={handleCategoryChange}
                  getBusinessUser={getTeamMemberInfoForId}
                />
              ) : null}
            </Stack>
          ) : status === "in_progress" ? (
            <Stack gap="8">
              <Stack
                borderWidth="1"
                padding="8"
                rounded="md"
                maxWidth="2xl"
                gap="3"
                borderColor="borderOutline"
              >
                <SkeletonTitle />
                <SkeletonTitle width="full" />
                <SkeletonTitle width="full" />
              </Stack>
              <Inline gap="6" maxWidth="2xl">
                <Stack
                  borderWidth="1"
                  padding="8"
                  rounded="md"
                  width="full"
                  gap="3"
                  borderColor="borderOutline"
                >
                  <SkeletonTitle />
                  <SkeletonTitle width="full" />
                  <SkeletonTitle width="full" />
                </Stack>
                <Stack
                  borderWidth="1"
                  padding="8"
                  rounded="md"
                  width="full"
                  gap="3"
                  borderColor="borderOutline"
                >
                  <SkeletonTitle />
                  <SkeletonTitle width="full" />
                  <SkeletonTitle width="full" />
                </Stack>
              </Inline>
              <Box as="table">
                <SkeletonRows numOfRows={10} numOfCols={6} />
              </Box>
            </Stack>
          ) : error ? (
            <Box width="full" paddingY="24">
              <GeneralErrorHandler onRetry={refreshPage} />
            </Box>
          ) : userProfile ? (
            <Stack gap="10">
              <Inline gap="8">
                <Stack maxWidth="2xl" width="full" gap="6">
                  <Box borderWidth="1" rounded="md" borderColor="borderOutline">
                    <Inline
                      paddingY="4"
                      paddingX="6"
                      alignItems="center"
                      justifyContent="between"
                      gap="2"
                      borderBottomWidth="1"
                      borderColor="borderOutline"
                      backgroundColor="surfaceNeutralLowest"
                    >
                      <Inline alignItems="center" gap="4">
                        <Avatar
                          size="8"
                          fontSize="b1"
                          id={userProfile.uid}
                          name={userProfile.name}
                        />
                        <Text fontSize="s3" className="line-clamp-1">
                          {userProfile.name}
                          {isLoggedInUser ? " (You) " : null}
                        </Text>
                      </Inline>
                      {Boolean(userProfile.upi?.address) && (
                        <Text fontSize="b3" className="line-clamp-1">
                          {userProfile.upi?.address}
                        </Text>
                      )}
                    </Inline>
                    <Inline
                      padding="6"
                      alignItems="center"
                      justifyContent="between"
                      gap="2"
                    >
                      <Stack gap="4">
                        <Text fontSize="c2" color="textMedium">
                          Current Card Balance
                        </Text>
                        <Stack gap="2">
                          <Amount
                            currency="inr"
                            fontSize="h4"
                            amount={userProfile.balance || 0}
                          />
                          <Inline gap="1" alignItems="center">
                            <Text fontSize="c2" color="textMedium">
                              Monthly Limit:{" "}
                            </Text>
                            <Amount
                              currency="inr"
                              fontSize="c2"
                              color="textMedium"
                              amount={walletLimits[user.kycStatus]}
                            />
                          </Inline>
                        </Stack>
                      </Stack>
                      <NotInWeb heading="Use mobile app to use this feature">
                        {({ onDisplay }) => (
                          <Button
                            onClick={() => {
                              onDisplay()
                              trackEvent(TrackingEvents.ADD_MONEY_CLICKED, {
                                entity: "memberWallet",
                                from:
                                  user.uid === userProfile.uid
                                    ? "staffWalletScreen"
                                    : "yourWalletScreen",
                              })
                            }}
                            status="success"
                            size="lg"
                          >
                            <AddMoneyIcon />
                            Add Money
                          </Button>
                        )}
                      </NotInWeb>
                    </Inline>
                  </Box>
                  {showLimitsAndCardSetting ? (
                    <Inline gap="6">
                      <Inline
                        alignItems="center"
                        rounded="md"
                        borderWidth="1"
                        borderColor="borderOutline"
                        width="full"
                        padding="4"
                        justifyContent="between"
                        as={Link}
                        to="wallet-limits?header=none"
                      >
                        <Inline gap="4" alignItems="center">
                          <ControlExpensesIcon size="10" />
                          <Stack gap="2">
                            <Text fontSize="s4">Card Limits</Text>
                            <Text fontSize="c2" color="textMedium">
                              Per transaction, Daily
                            </Text>
                          </Stack>
                        </Inline>
                        <ArrowRightIcon />
                      </Inline>
                      <Inline
                        alignItems="center"
                        rounded="md"
                        borderWidth="1"
                        borderColor="borderOutline"
                        width="full"
                        padding="4"
                        justifyContent="between"
                        as={Link}
                        to="wallet-limits?header=none"
                      >
                        <Inline gap="4" alignItems="center">
                          <SwipeAndPayIcon size="10" />
                          <Stack gap="2">
                            <Text fontSize="s4">Card Settings</Text>
                            <Text fontSize="c2" color="textMedium">
                              Online Transactions
                            </Text>
                          </Stack>
                        </Inline>
                        <ArrowRightIcon />
                      </Inline>
                    </Inline>
                  ) : null}
                </Stack>
                {showCardDetails && cardDetails ? (
                  <Stack
                    gap="2"
                    width="full"
                    maxWidth="sm"
                    rounded="lg"
                    textAlign="center"
                    alignItems="center"
                    backgroundColor="backgroundLight2"
                  >
                    <CashbookPrepaidCard
                      nameOnCard={
                        userProfile.fullName ||
                        cardDetails.userNameOnCard ||
                        user.name ||
                        ""
                      }
                      widget={cardWidget}
                      widgetLoading={widgetFetching}
                      onViewCardDetails={(kit) => getCardWidget(kit)}
                      cardDetails={cardDetails}
                    />
                  </Stack>
                ) : null}
              </Inline>
              <Stack paddingTop="6">
                <TransactionsTable
                  status={params.status}
                  category={params.category}
                  totalExpenses={totalExpenses}
                  to_datetime={to_datetime}
                  from_datetime={from_datetime}
                  showFetchMore={currentPage !== lastPage}
                  transactions={transactions}
                  transactionsCount={transactionsCount}
                  areFiltersApplied={areFiltersApplied}
                  fetchingTransactions={fetchingTransactions}
                  fetchingMoreTransactions={fetchingMoreTransactions}
                  userIds={{
                    userId: user.uid,
                    authUserId: authUser.uid,
                  }}
                  fetchMore={fetchMore}
                  handleDateChange={handleDateChange}
                  handleParamsChange={handleParamsChange}
                  handleChange={handleCategoryChange}
                  getBusinessUser={getTeamMemberInfoForId}
                />
              </Stack>
            </Stack>
          ) : null}
        </Box>
      </Box>
    </>
  )
}

const walletTransactionStatusFilter: {
  id: WalletStatusFilterTypes
  label: string
}[] = [
  { id: "ALL", label: "All" },
  { id: "SUCCESS", label: "Success" },
  { id: "FAILURE", label: "Failed" },
  { id: "REFUNDED", label: "Refunded" },
]

function TransactionsTable({
  status,
  userIds,
  category,
  to_datetime,
  transactions,
  from_datetime,
  totalExpenses,
  showFetchMore,
  areFiltersApplied,
  transactionsCount,
  fetchingTransactions,
  fetchingMoreTransactions,
  fetchMore,
  handleChange,
  getBusinessUser,
  handleDateChange,
  handleParamsChange,
}: {
  status?: WalletStatusFilterTypes
  to_datetime?: Date
  from_datetime?: Date
  totalExpenses?: number
  showFetchMore: boolean
  transactions: PaymentsTransaction[]
  category?: PaymentTransactionTypes
  transactionsCount?: number
  areFiltersApplied: boolean
  fetchingTransactions?: boolean
  fetchingMoreTransactions?: boolean
  userIds: {
    userId?: string
    authUserId?: string
  }
  fetchMore: () => void
  handleDateChange: (date1?: Date, date2?: Date, label?: string) => void
  handleChange: (optionId: PaymentTransactionTypes) => void
  handleParamsChange: (key: string, value?: unknown) => void
  getBusinessUser?: (id: string) => TBusinessUser | null
}) {
  const statusLabel: string = useMemo(() => {
    switch (status) {
      case "REJECTED":
        return "Declined"
      case "EXPIRED":
        return "Expired"
      case "FAILURE":
        return "Failed"
      case "PENDING":
        return "Pending"
      case "SUCCESS":
        return "Success"
      case "REFUNDED":
        return "Refunded"
      default:
        return "Status: All"
    }
  }, [status])
  return (
    <Stack gap="6">
      <Inline as="ul" gap="6" borderBottomWidth="1" borderColor="borderOutline">
        {walletHeaderTabs.map((option) => (
          <Box as="li" key={option.id}>
            <Box
              as="button"
              onClick={() => {
                handleChange(option.id)
              }}
              padding="3"
              roundedTop="md"
              color={category === option.id ? "textPrimary" : "textMedium"}
              borderBottomWidth={category === option.id ? "2" : "0"}
              borderColor="borderPrimary"
              backgroundColor={
                category === option.id ? "surfacePrimaryLowest" : "transparent"
              }
              className={classNames({
                "hover:bg-blue-5": category !== option.id,
              })}
            >
              <Text fontSize="s4">{option.title}</Text>
            </Box>
          </Box>
        ))}
      </Inline>
      <Stack gap="6">
        {category === "EXPENSES" ? (
          <Stack gap="6">
            <Inline gap="4" alignItems="center">
              <Box>
                <DateSelectForPayments
                  to_datetime={to_datetime}
                  from_datetime={from_datetime}
                  onChange={(from, to, label) => {
                    handleDateChange(from, to, label)
                  }}
                />
              </Box>
              <Box>
                <SearchSelect
                  searchDisabled
                  hasValue={Boolean(status)}
                  onChange={(option) => {
                    handleParamsChange(
                      "status",
                      option && option.id !== "ALL" ? option.id : undefined
                    )
                  }}
                  options={walletTransactionStatusFilter}
                  value={status ? status : "ALL"}
                  label={statusLabel}
                />
              </Box>
            </Inline>
            <Stack
              rounded="md"
              borderWidth="1"
              maxWidth="2xl"
              padding="6"
              gap="2"
              borderColor="borderOutline"
            >
              <Text fontSize="c2" color="textMedium">
                Total Expenses
              </Text>
              <Amount
                currency="inr"
                amount={totalExpenses && totalExpenses > 0 ? totalExpenses : 0}
                color="textCashOut"
                fontSize="s1"
              />
            </Stack>
          </Stack>
        ) : null}
        <Stack gap="4" paddingBottom="12">
          {transactions.length ? (
            <Text fontSize="c2" color="textMedium">
              1-{transactions.length} of {transactionsCount}{" "}
              {pluralize("transaction", transactionsCount)}
            </Text>
          ) : null}
          {category === "EXPENSES" ? (
            <>
              {fetchingTransactions ? (
                <Box as="table" width="full" position="relative">
                  <SkeletonRows numOfRows={4} numOfCols={6} />
                </Box>
              ) : transactions.length ? (
                <>
                  <Box as="table" width="full" position="relative">
                    <Box as="thead">
                      <tr>
                        <Box
                          as="th"
                          position="sticky"
                          top="0"
                          paddingX="3"
                          paddingY="4"
                          bgColor="backgroundLight3"
                          className={`w-[130px]`}
                        >
                          <Text fontSize="s4">Date & Time</Text>
                        </Box>
                        <Box
                          as="th"
                          position="sticky"
                          top="0"
                          paddingX="3"
                          paddingY="4"
                          className="z-[1] w-[152px]"
                          bgColor="backgroundLight3"
                        >
                          <Inline justifyContent="end" paddingX="2">
                            <Text fontSize="s4">Transaction ID</Text>
                          </Inline>
                        </Box>
                        <Box
                          as="th"
                          position="sticky"
                          top="0"
                          paddingX="3"
                          paddingY="4"
                          className="w-[40px]"
                          bgColor="backgroundLight3"
                        >
                          <Text fontSize="s4">Mode</Text>
                        </Box>
                        <Box
                          as="th"
                          position="sticky"
                          top="0"
                          paddingX="3"
                          paddingY="4"
                          className="max-[152px]"
                          bgColor="backgroundLight3"
                        >
                          <Text fontSize="s4">Party</Text>
                        </Box>
                        <Box
                          as="th"
                          position="sticky"
                          top="0"
                          paddingX="3"
                          paddingY="4"
                          bgColor="backgroundLight3"
                        >
                          <Inline justifyContent="end">
                            <Text fontSize="s4">Amount</Text>
                          </Inline>
                        </Box>
                        <Box
                          as="th"
                          position="sticky"
                          top="0"
                          paddingX="3"
                          paddingY="4"
                          className="z-[1]"
                          bgColor="backgroundLight3"
                        >
                          <Inline justifyContent="center">
                            <Text fontSize="s4">Bill</Text>
                          </Inline>
                        </Box>
                      </tr>
                    </Box>
                    <Box as="tbody">
                      {transactions.map((transaction) => (
                        <TransactionItem
                          key={transaction.id}
                          transaction={transaction}
                        />
                      ))}
                    </Box>
                  </Box>
                  {showFetchMore ? (
                    <Box>
                      <Button
                        level="primary"
                        onClick={fetchMore}
                        disabled={fetchingMoreTransactions}
                      >
                        <Text fontSize="bt">Show More Transactions</Text>
                        {fetchingMoreTransactions && <SpinnerIcon />}
                      </Button>
                    </Box>
                  ) : null}
                </>
              ) : (
                <Stack gap="8" className="py-32">
                  <Stack
                    height="full"
                    alignItems="center"
                    justifyContent="center"
                    gap="2"
                  >
                    <Text fontSize="s1">No expenses found</Text>
                    <Text fontSize="b3" color="textMedium">
                      No transaction has been made
                    </Text>
                  </Stack>
                  {areFiltersApplied ? (
                    <Box alignSelf="center">
                      <Button
                        level="primary"
                        onClick={() => {
                          handleChange("EXPENSES")
                        }}
                      >
                        Reset Filters
                      </Button>
                    </Box>
                  ) : null}
                </Stack>
              )}
            </>
          ) : category === "B2C" ? (
            <>
              {fetchingTransactions ? (
                <Box as="table">
                  <SkeletonRows numOfRows={6} numOfCols={5} />
                </Box>
              ) : transactions.length ? (
                <Box as="table" width="full" position="relative">
                  <Box as="thead" className="whitespace-pre">
                    <tr>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        bgColor="backgroundLight3"
                        className={`w-[130px]`}
                      >
                        <Text fontSize="s4">Date & Time</Text>
                      </Box>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        className="min-w-[150px]"
                        bgColor="backgroundLight3"
                      >
                        <Text fontSize="s4">Transaction by</Text>
                      </Box>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        className="min-w-[150px]"
                        bgColor="backgroundLight3"
                      >
                        <Text fontSize="s4">From</Text>
                      </Box>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        className="min-w-[150px]"
                        bgColor="backgroundLight3"
                      >
                        <Text fontSize="s4">To</Text>
                      </Box>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        className={`w-[150px]`}
                        bgColor="backgroundLight3"
                      >
                        <Inline justifyContent="end">
                          <Text fontSize="s4">Amount</Text>
                        </Inline>
                      </Box>
                    </tr>
                  </Box>
                  <Box as="tbody">
                    {transactions.map((transaction, i) => (
                      <InternalTransactionItem
                        key={transaction.id}
                        transaction={transaction}
                        authUserId={userIds.authUserId || ""}
                        isLastTransaction={i === transactions.length - 1}
                        memberId={userIds.userId}
                        getBusinessUser={getBusinessUser}
                      />
                    ))}
                  </Box>
                </Box>
              ) : (
                <Stack
                  gap="2"
                  paddingY="24"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontSize="s1">No transactions found</Text>
                  <Text fontSize="b3" color="textMedium">
                    No card transfer has been made in this card
                  </Text>
                </Stack>
              )}
            </>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  )
}

function TransactionItem({
  transaction,
}: {
  transaction: PaymentsTransaction
}) {
  const {
    timestamp,
    party,
    created_at,
    id,
    type,
    tx_id,
    amount,
    status,
    ext_tx_id,
    attachments,
    transaction_type,
    business_id,
  } = transaction

  const [attachmentId, setAttachmentId] = useState<number | undefined>(
    attachments?.length ? Number(attachments?.[0]?.id) : undefined
  )

  const transactionId = tx_id || ext_tx_id

  const transactionType = useMemo(() => {
    return getTransactionModeOfPayment(transaction_type)
  }, [transaction_type])

  const partyInvolved: {
    id: string
    name: string
    type: "individual" | "merchant"
  } = useMemo(() => {
    return {
      id: party?.id || id,
      name: party?.name || "",
      type: party?.entity_type === "ENTITY" ? "merchant" : "individual",
    }
  }, [id, party?.entity_type, party?.id, party?.name])

  function onAttachmentAdded(id: number) {
    setAttachmentId(id)
  }

  function onAttachmentDeleted() {
    setAttachmentId(undefined)
  }

  return (
    <TransactionDetailsInModal
      transactionId={id}
      attachmentId={attachmentId}
      transaction_type={transaction_type}
      businessId={business_id || "missing"}
      onAttachmentAdded={(id) => onAttachmentAdded(Number(id))}
      onAttachmentDeleted={onAttachmentDeleted}
    >
      {({ open }) => (
        <Box
          as="tr"
          borderBottomWidth="1"
          className="hover:bg-gray-100 group z-0"
          cursor="pointer"
          onClick={open}
        >
          <Box as="td" paddingX="3" paddingY="4" className="whitespace-pre">
            <Stack gap="2">
              <TransactionDate
                fontSize="b3"
                timeStamp={transformDate(timestamp || created_at)}
              />
              <Time
                fontSize="c2"
                color="textMedium"
                timeStamp={transformDate(timestamp || created_at)}
                format="hh:mm a"
              />
              {status !== "SUCCESS" && status !== "REFUNDED" ? (
                <Inline
                  gap="1"
                  alignItems="center"
                  color={getTextColorForStatus({ status })}
                >
                  {getHelperIconForStatus({ status, size: "4" })}
                  <Text fontSize="c2">{getStatusTitle({ status, type })}</Text>
                </Inline>
              ) : null}
            </Stack>
          </Box>
          {transactionId ? (
            <Box as="td" paddingX="3" paddingY="4" className="whitespace-pre">
              <Inline gap="2" zIndex="0" justifyContent="end" paddingX="2">
                <Tooltip event="onHover" content={transactionId}>
                  <Text
                    color="textHigh"
                    fontSize="b3"
                    className="line-clamp-1 break-all"
                  >
                    ...
                    {transactionId.substring(
                      transactionId.length - 10,
                      transactionId.length
                    ) || "N/A"}
                  </Text>
                </Tooltip>
                <CopyToClipboard>
                  {({ copy, copied }) => (
                    <Box
                      onClick={(e: SyntheticEvent) => {
                        e.stopPropagation()
                        copy(transactionId)
                        toast.success("Transaction ID copied to clipboard")
                      }}
                    >
                      <Tooltip event="onHover" content={"Copy to Clipboard"}>
                        {copied ? (
                          <CheckIcon size="4" color="iconSuccess" />
                        ) : (
                          <CopyIcon size="4" color="iconMedium" />
                        )}
                      </Tooltip>
                    </Box>
                  )}
                </CopyToClipboard>
              </Inline>
            </Box>
          ) : null}
          <Box as="td" paddingX="3" paddingY="4" className=" whitespace-pre">
            <PaymentsIcons size="4" color="iconMedium" name={transactionType} />
          </Box>
          <Box as="td" paddingX="3" paddingY="4" className="max-w-[152px]">
            {partyInvolved.name.length ? (
              <Inline alignItems="center" gap="2">
                <Avatar
                  id={partyInvolved.id}
                  name={partyInvolved.name}
                  size="6"
                  fontSize="c2"
                  type={partyInvolved.type}
                />
                <Text fontSize="b3" className="line-clamp-1">
                  {partyInvolved.name}
                </Text>
              </Inline>
            ) : null}
          </Box>
          <Box as="td" paddingX="2" paddingY="5">
            <Inline justifyContent="end" paddingX="2">
              <Amount
                type={transaction.type === "DEBIT" ? "cash-out" : "cash-in"}
                amount={Number(amount)}
                currency="inr"
                fontWeight="semibold"
              />
            </Inline>
          </Box>
          <Box
            as="td"
            paddingX="3"
            paddingY="5"
            className="align-middle"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {status === "SUCCESS" ? (
              <>
                {attachmentId ? (
                  <ViewTransactionAttachmentInModal attachmentId={attachmentId}>
                    {({ open }) => (
                      <Tooltip event="onHover" content={"Attachment Preview"}>
                        <Box
                          size="8"
                          onClick={(e: SyntheticEvent) => {
                            e.stopPropagation()
                            open()
                          }}
                        >
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 33 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M26.7144 4.66669L24.7144 2.66669L22.7144 4.66669L20.7144 2.66669L18.7144 4.66669L16.7144 2.66669L14.7144 4.66669L12.7144 2.66669L10.7144 4.66669L8.71436 2.66669L6.71436 4.66669L4.71436 2.66669V29.3334L6.71436 27.3334L8.71436 29.3334L10.7144 27.3334L12.7144 29.3334L14.7144 27.3334L16.7144 29.3334L18.7144 27.3334L20.7144 29.3334L22.7144 27.3334L24.7144 29.3334L26.7144 27.3334L28.7144 29.3334V2.66669L26.7144 4.66669ZM26.0477 25.4534H7.38102V6.54669H26.0477V25.4534ZM8.71436 20H24.7144V22.6667H8.71436V20ZM8.71436 14.6667H24.7144V17.3334H8.71436V14.6667ZM8.71436 9.33335H24.7144V12H8.71436V9.33335Z"
                              fill="#757575"
                            />
                          </svg>
                        </Box>
                      </Tooltip>
                    )}
                  </ViewTransactionAttachmentInModal>
                ) : (
                  <Tooltip event="onHover" content={"Bill Missing"}>
                    <Box>
                      <svg
                        height="33"
                        width="32"
                        viewBox="0 0 33 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.1428 7.98658L26.1828 25.3332H6.10282L16.1428 7.98658ZM3.79615 23.9999C2.76949 25.7732 4.04949 27.9999 6.10282 27.9999H26.1828C28.2362 27.9999 29.5162 25.7732 28.4895 23.9999L18.4495 6.65324C17.4228 4.87991 14.8628 4.87991 13.8362 6.65324L3.79615 23.9999ZM14.8095 14.6666V17.3332C14.8095 18.0666 15.4095 18.6666 16.1428 18.6666C16.8762 18.6666 17.4762 18.0666 17.4762 17.3332V14.6666C17.4762 13.9332 16.8762 13.3332 16.1428 13.3332C15.4095 13.3332 14.8095 13.9332 14.8095 14.6666ZM14.8095 21.3332H17.4762V23.9999H14.8095V21.3332Z"
                          fill="#BD610D"
                        />
                      </svg>
                    </Box>
                  </Tooltip>
                )}
              </>
            ) : null}
          </Box>
        </Box>
      )}
    </TransactionDetailsInModal>
  )
}

type TransactionUser = {
  id: string
  name: string
  icon?: "bank" | "master_wallet"
}
function InternalTransactionItem({
  memberId,
  authUserId,
  transaction,
  isLastTransaction,
  getBusinessUser,
}: {
  authUserId: string
  memberId?: string
  isLastTransaction?: boolean
  transaction: PaymentsTransaction
  getBusinessUser?: (id: string) => TBusinessUser | null
}) {
  const { id, created_by, transaction_type, business_id } = transaction

  const transactionBy = useMemo(() => {
    const bUser = getBusinessUser?.(created_by || "")
    if (authUserId === bUser?.id) {
      return { ...bUser, name: "You" }
    }
    return bUser
  }, [getBusinessUser, created_by, authUserId])

  const userInvolved = useMemo(() => {
    const bUser = getBusinessUser?.(memberId || "")
    if (authUserId === bUser?.id) {
      return { ...bUser, name: "Your Card" }
    }
    return bUser
  }, [memberId, getBusinessUser, authUserId])

  const transactionFrom: TransactionUser = useMemo(() => {
    switch (transaction_type) {
      case "B2B":
      case "VIRTUAL_ACCOUNT_CREDIT":
        return { id, name: "Bank Account", icon: "bank" }
      case "C2B":
        return { id: userInvolved?.id || id, name: userInvolved?.name || "" }
      default:
        return { id, name: "Master Wallet", icon: "master_wallet" }
    }
  }, [transaction_type, id, userInvolved?.id, userInvolved?.name])

  const transactionTo: TransactionUser = useMemo(() => {
    switch (transaction_type) {
      case "B2B":
      case "VIRTUAL_ACCOUNT_CREDIT":
      case "C2B":
        return { id, name: "Master Wallet", icon: "master_wallet" }
      default:
        return {
          id: userInvolved?.id || id,
          name: userInvolved?.name || "",
        }
    }
  }, [transaction_type, id, userInvolved?.id, userInvolved?.name])

  return (
    <TransactionDetailsInModal
      memberId={memberId}
      transactionId={id || "missing"}
      transaction_type={transaction_type}
      businessId={business_id || "missing"}
    >
      {({ open }) => (
        <Box
          as="tr"
          borderBottomWidth={isLastTransaction ? undefined : "1"}
          borderColor="borderDividers"
          onClick={open}
          className="cursor-pointer hover:bg-gray-100"
        >
          <Box as="td" paddingX="3" paddingY="4" className="whitespace-pre">
            <Stack gap="2">
              <TransactionDate
                fontSize="b3"
                timeStamp={transformDate(transaction.timestamp)}
              />
              <Time
                fontSize="c2"
                color="textMedium"
                timeStamp={transformDate(transaction.timestamp)}
                format="hh:mm a"
              />
            </Stack>
          </Box>
          <Box
            as="td"
            paddingX="3"
            paddingY="4"
            className="align-middle max-w-[180px]"
          >
            {transactionBy?.name ? (
              <Inline alignItems="center" gap="2">
                <Box>
                  <Avatar
                    id={transactionBy?.id || "missing"}
                    name={transactionBy?.name}
                    size="6"
                    fontSize="c2"
                  />
                </Box>

                <Text fontSize="b3" className="w-full line-clamp-1">
                  {transactionBy?.name}
                </Text>
              </Inline>
            ) : (
              <Text fontSize="b3">-</Text>
            )}
          </Box>
          <Box as="td" paddingX="3" paddingY="4" className="align-middle">
            <Inline alignItems="center" gap="2">
              <Box>
                {transactionFrom.icon ? (
                  transactionFrom.icon === "bank" ? (
                    <BankPlaceholderIcon color="iconLow" />
                  ) : (
                    <BankGradientIcon />
                  )
                ) : (
                  <Avatar
                    id={transactionFrom?.id || id}
                    name={transactionFrom?.name}
                    size="6"
                    fontSize="c2"
                  />
                )}
              </Box>
              <Text fontSize="b3" className="w-full line-clamp-1">
                {transactionFrom?.name}
              </Text>
            </Inline>
          </Box>
          <Box
            as="td"
            paddingX="3"
            paddingY="4"
            className="align-middle max-w-[180px]"
          >
            <Inline alignItems="center" gap="2">
              <Box>
                {transactionTo.icon ? (
                  transactionTo.icon === "bank" ? (
                    <BankPlaceholderIcon color="iconLow" />
                  ) : (
                    <BankGradientIcon />
                  )
                ) : (
                  <Avatar
                    id={transactionTo?.id || id}
                    name={transactionTo?.name}
                    size="6"
                    fontSize="c2"
                  />
                )}
              </Box>
              <Text fontSize="b3" className="w-full line-clamp-1">
                {transactionTo?.name}
              </Text>
            </Inline>
          </Box>
          <Box as="td" paddingX="3" paddingY="4" className="align-middle">
            <Inline justifyContent="end">
              <Amount
                type={transaction.type === "DEBIT" ? "cash-out" : "cash-in"}
                amount={Number(transaction.amount)}
                currency="inr"
                fontWeight="semibold"
              />
            </Inline>
          </Box>
        </Box>
      )}
    </TransactionDetailsInModal>
  )
}
