import {
  Box,
  Button,
  CheckIcon,
  Circle,
  CopyIcon,
  CopyToClipboard,
  InformationCircleIcon,
  Inline,
  Modal,
  ModalBody,
  Stack,
  Text,
  useOverlayTriggerState,
} from "@cashbook/web-components"
import React from "react"
import { toast } from "react-hot-toast"
// import { WhatsAppContactLink } from "../HelpDocs"
// import config from "../config"

type HowToAddMoneyKeys = "Net Banking"
const howToAddMoney: {
  [key in HowToAddMoneyKeys]: Array<string>
} = {
  "Net Banking": [
    `Go to your banking’s App or website.`,
    "Click on the Fund Transfer.",
    "Copy the Account Number & IFSC code and add as beneficiary.",
    "Transfer the amount through NEFT/IMPS/RTGS.",
    "Money will be credited to Master Wallet.",
  ],
}

export function ViewMasterWalletDetailsInModal({
  from,
  ifscCode,
  businessName,
  accountNumber,
  children,
}: {
  ifscCode?: string
  accountNumber?: string
  businessName: string
  from: "details" | "addMoney"
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        placement="right"
        title={
          from === "details"
            ? "Master Wallet Details"
            : "Add Money to Master Wallet"
        }
        isDismissable
      >
        <ModalBody autoMaxHeight>
          <Stack gap="10" marginBottom="12">
            <Inline
              gap="4"
              backgroundColor="surfaceWarningLowest"
              paddingY="4"
              paddingX="4"
              alignItems="center"
            >
              <InformationCircleIcon color="iconWarning" />
              <Stack gap="2" color="textWarning">
                <Text fontSize="b3">
                  Recommendation: Don't add additional funds as Card Service
                  will be discontinued soon.
                </Text>
              </Stack>
            </Inline>
            <Stack gap="4">
              <Text fontSize="b1">Use these details to add money</Text>
              <Box
                rounded="md"
                borderLeftWidth="1"
                borderRightWidth="1"
                borderBottomWidth="1"
                borderColor="borderOutline"
                borderTopWidth="4"
                style={{
                  borderTopColor: "#4863D4",
                }}
              >
                <Box gap="6">
                  <Stack gap="6" padding="6">
                    <Stack gap="2">
                      <Text fontSize="b3" color="textMedium">
                        Banking Name
                      </Text>
                      <Text fontSize="s3">{businessName}</Text>
                    </Stack>
                    <Stack gap="2">
                      <Text fontSize="b3" color="textMedium">
                        Account Number
                      </Text>
                      <Inline justifyContent="between" alignItems="center">
                        <Text fontSize="s3">{accountNumber}</Text>
                        <CopyToClipboard>
                          {({ copied, copy }) => (
                            <Button
                              inline
                              onClick={() => {
                                toast.success(
                                  "Account number copied to clipboard"
                                )
                                copy(`${accountNumber}`)
                              }}
                            >
                              {copied ? (
                                <CheckIcon color="green500" />
                              ) : (
                                <CopyIcon />
                              )}
                            </Button>
                          )}
                        </CopyToClipboard>
                      </Inline>
                    </Stack>
                    <Stack gap="2">
                      <Text fontSize="b3" color="textMedium">
                        IFSC Code
                      </Text>
                      <Inline justifyContent="between" alignItems="center">
                        <Text fontSize="s3">{ifscCode}</Text>
                        <CopyToClipboard>
                          {({ copied, copy }) => (
                            <Button
                              inline
                              onClick={() => {
                                toast.success("IFSC code copied to clipboard")
                                copy(`${ifscCode}`)
                              }}
                            >
                              {copied ? (
                                <CheckIcon color="green500" />
                              ) : (
                                <CopyIcon />
                              )}
                            </Button>
                          )}
                        </CopyToClipboard>
                      </Inline>
                    </Stack>
                  </Stack>
                  {/* <Box
                    paddingY="4"
                    paddingX="6"
                    backgroundColor="surfaceWarningLowest"
                  >
                    <Inline gap="4">
                      <NotificationIcon />
                      <Stack gap="2" color="textWarning">
                        <Text fontSize="b3">
                          Tip: Adding money from Current a/c will keep your
                          accounting clean
                        </Text>
                        <ViewBenefitsOfCurrentAccount
                          businessName={businessName}
                        >
                          {({ open }) => (
                            <Text
                              as="a"
                              fontSize="b3"
                              color="textWarning"
                              className="underline cursor-pointer underline-offset-2"
                              onClick={open}
                            >
                              Know More
                            </Text>
                          )}
                        </ViewBenefitsOfCurrentAccount>
                      </Stack>
                    </Inline>
                  </Box> */}
                </Box>
              </Box>
            </Stack>
            <Stack gap="4">
              <Text fontSize="s3">How to add money via Net Banking?</Text>
              <Box>
                <Box
                  paddingY="6"
                  paddingX="4"
                  borderWidth="1"
                  roundedBottom="md"
                  borderColor="borderOutline"
                >
                  <Stack as="ul" gap="6">
                    {howToAddMoney["Net Banking"].map((step, i) => (
                      <Inline key={step} alignItems="center" gap="4">
                        <Circle
                          size="6"
                          color="textSuccess"
                          backgroundColor="surfaceSuccessLowest"
                        >
                          <Text fontSize="b3">{i + 1}</Text>
                        </Circle>
                        <Text fontSize="b3" color="textMedium">
                          {step}
                        </Text>
                      </Inline>
                    ))}
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </ModalBody>
      </Modal>
    </>
  )
}

// function NotificationIcon() {
//   return (
//     <svg
//       width="36"
//       height="36"
//       viewBox="0 0 36 36"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <g clipPath="url(#NotificationIcon)">
//         <rect width="36" height="36" rx="18" fill="white" />
//         <g clipPath="url(#NotificationIcon1)">
//           <path
//             d="M26.9861 16.994C26.4892 16.994 26.0861 16.5909 26.0861 16.094C26.0861 13.5695 25.1033 11.1971 23.3186 9.41142C22.9667 9.05959 22.9667 8.49088 23.3186 8.13888C23.6704 7.78704 24.2393 7.78704 24.5911 8.13888C26.7161 10.2637 27.886 13.089 27.886 16.094C27.886 16.5909 27.4829 16.994 26.9861 16.994Z"
//             fill="#C93B3B"
//           />
//           <path
//             d="M8.98588 16.994C8.48919 16.994 8.08594 16.5909 8.08594 16.094C8.08594 13.089 9.25598 10.2637 11.3808 8.13888C11.7327 7.78704 12.3016 7.78704 12.6534 8.13888C13.0054 8.49088 13.0054 9.05959 12.6534 9.41142C10.8687 11.1962 9.88599 13.5695 9.88599 16.094C9.88599 16.5909 9.48274 16.994 8.98588 16.994Z"
//             fill="#C93B3B"
//           />
//           <path
//             d="M21.5858 25.2007C21.5858 27.1889 19.9741 28.8006 17.9859 28.8006C15.9976 28.8006 14.3857 27.1889 14.3857 25.2007C14.3857 23.2124 15.9976 21.6006 17.9859 21.6006C19.9741 21.6006 21.5858 23.2124 21.5858 25.2007Z"
//             fill="#FFA000"
//           />
//           <path
//             d="M17.986 10.8001C17.4892 10.8001 17.0859 10.3971 17.0859 9.9002V8.10014C17.0859 7.60345 17.4892 7.2002 17.986 7.2002C18.4827 7.2002 18.886 7.60345 18.886 8.10014V9.9002C18.886 10.3971 18.4827 10.8001 17.986 10.8001Z"
//             fill="#FFA000"
//           />
//           <path
//             d="M26.4276 22.4212C25.0667 21.271 24.2864 19.5907 24.2864 17.8096V15.3004C24.2864 11.8264 21.4595 9.00049 17.9864 9.00049C14.5132 9.00049 11.6863 11.8264 11.6863 15.3004V17.8096C11.6863 19.5907 10.906 21.2719 9.53724 22.4293C9.18705 22.7281 8.98633 23.1646 8.98633 23.6254C8.98633 24.494 9.6928 25.2005 10.5614 25.2005H25.4114C26.2799 25.2005 26.9864 24.494 26.9864 23.6254C26.9864 23.1646 26.7857 22.7281 26.4276 22.4212Z"
//             fill="#FFC107"
//           />
//         </g>
//       </g>
//       <defs>
//         <clipPath id="NotificationIcon">
//           <rect width="36" height="36" fill="white" />
//         </clipPath>
//         <clipPath id="NotificationIcon1">
//           <rect
//             width="21.6"
//             height="21.6"
//             fill="white"
//             transform="translate(7.2002 7.2002)"
//           />
//         </clipPath>
//       </defs>
//     </svg>
//   )
// }

// const benefitsOfCurrentAccount = [
//   `In case of current account, the money added to ${config.appTitle} Master Wallet will remain a business transaction.`,
//   "You won’t have any problem during tax filing.",
//   "If you add money from savings account, then it will create problems in your accounting.",
// ]

// function ViewBenefitsOfCurrentAccount({
//   businessName,
//   children,
// }: {
//   businessName: string
//   children: (props: { open: () => void }) => React.ReactNode
// }) {
//   const state = useOverlayTriggerState({})
//   return (
//     <>
//       {children({
//         open: state.open,
//       })}
//       <Modal
//         isOpen={state.isOpen}
//         onClose={state.close}
//         title="Benefits of Current a/c"
//       >
//         <ModalBody>
//           <Stack gap="10">
//             <Stack gap="6">
//               <Text as="h3" fontSize="s3">
//                 Benefits of adding money to master wallet from Current a/c?
//               </Text>
//               <Stack as="ul" gap="4">
//                 {benefitsOfCurrentAccount.map((benefit, i) => (
//                   <Inline key={benefit} fontSize="b3" gap="1">
//                     <Text>{i + 1}.</Text>
//                     <Text>{benefit}</Text>
//                   </Inline>
//                 ))}
//               </Stack>
//             </Stack>
//             <Inline
//               padding="6"
//               backgroundColor="surfaceNeutralLowest"
//               alignItems="center"
//               justifyContent="between"
//             >
//               <Stack gap="2">
//                 <Text fontSize="s3">Need more support?</Text>
//                 <Text fontSize="b3" color="textMedium">
//                   Send us a message
//                 </Text>
//               </Stack>
//               <WhatsAppContactLink
//                 size="lg"
//                 level="primary"
//                 text={`Hi, I need some support for my Business - ${businessName} (Payments Activated) . For version Cashbook-Web (${config.appVersion})`}
//               />
//             </Inline>
//           </Stack>
//         </ModalBody>
//       </Modal>
//     </>
//   )
// }
