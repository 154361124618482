import {
  BOOK_PERMISSIONS,
  TBook,
  TBookEntryField,
  TTransaction,
  checkIfMemberCan,
  isSharedBook,
  usePartyOrContact,
  useTransactionsCollection,
} from "@cashbook/data-store/books"
import { pluralize } from "@cashbook/util-general"
import {
  Alert,
  Box,
  CBButton,
  CancelIcon,
  Circle,
  Inline,
  LabelIcon,
  Modal,
  ModalBody,
  ModalFooter,
  PlusIcon,
  SearchIcon,
  Stack,
  Text,
  UsersFilledIcon,
  formikOnSubmitWithErrorHandling,
  useOverlayTriggerState,
} from "@cashbook/web-components"
import { Form, Formik } from "formik"
import React, { useCallback, useMemo, useState } from "react"
import {
  AddCategoryFieldInDialog,
  AddPartyFieldInDialog,
  AddPaymentModeFieldInDialog,
} from "../Books"
import { useProfile } from "@cashbook/data-store/users"
import classNames from "classnames"
import { Timestamp, doc, writeBatch } from "firebase/firestore"
import { useFirestore } from "reactfire"
import { Optional } from "utility-types"
import { getServerTimestamp } from "@cashbook/util-dates"
import { toast } from "react-hot-toast"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import { Radio } from "../common"

type TActionType = "category" | "paymentMode" | "parties"

function getLabel(action: TActionType | null, explicitLabelForParty?: string) {
  switch (action) {
    case "category":
      return "Category"
    case "paymentMode":
      return "Payment Mode"
    default:
      return explicitLabelForParty || "Party"
  }
}

export default function PerformChangeFieldsInModal({
  children,
  ...props
}: Omit<
  React.ComponentProps<typeof ChangeFieldsAction>,
  "action" | "onClose" | "label"
> & {
  children: (props: {
    handleAction: (action: TActionType) => void
  }) => React.ReactNode
}) {
  const [action, setAction] = useState<TActionType | null>(null)
  const onBack = useCallback(() => {
    setAction(null)
  }, [])
  const { partyOrContact } = usePartyOrContact()
  const label = getLabel(action, partyOrContact)
  return (
    <>
      {children({
        handleAction: (action: TActionType) => {
          setAction(action)
        },
      })}
      <Modal
        isOpen={Boolean(action)}
        onClose={onBack}
        placement="right"
        isDismissable
        title={`Choose ${label}`}
      >
        {action ? (
          <ChangeFieldsAction
            action={action}
            label={label}
            onClose={() => setAction(null)}
            {...props}
          />
        ) : null}
      </Modal>
    </>
  )
}

function ChangeFieldsAction({
  book,
  label,
  action,
  transactions,
  onClose,
  onSuccess,
}: {
  book: TBook
  label: string
  action: TActionType
  transactions: TTransaction[]
  onClose: () => void
  onSuccess: () => void
}) {
  const { user } = useProfile()

  const fields = useMemo(() => {
    if (action === "category") return book.categories
    if (action === "paymentMode") return book.paymentModes
    if (action === "parties") return book.parties
    return []
  }, [action, book.categories, book.parties, book.paymentModes])

  const checkCanUpdateFields = checkIfMemberCan(
    book,
    user.uid,
    BOOK_PERMISSIONS.UPDATE_ENTRY_FIELDS
  )

  const AddFieldInDialog =
    action === "category"
      ? AddCategoryFieldInDialog
      : action === "paymentMode"
      ? AddPaymentModeFieldInDialog
      : AddPartyFieldInDialog

  const [search, setValue] = useState("")

  const filteredFields = useMemo(() => {
    return search?.length
      ? fields?.filter((field) =>
          field.name.toLowerCase().includes(search.toLowerCase())
        )
      : fields
  }, [fields, search])

  const [selectedField, setSelectedField] = useState<
    TBookEntryField | undefined
  >(undefined)

  return (
    <>
      <ModalBody>
        {fields?.length ? (
          <Stack gap="4">
            <Text fontSize="b3">{pluralize(`${label}`, 2)} in this book</Text>
            {fields.length > 5 ? (
              <Box flex="1">
                <Inline
                  position="relative"
                  rounded="md"
                  height="10"
                  paddingRight="2"
                  alignItems="stretch"
                  gap="2"
                  maxWidth="lg"
                  width="full"
                  borderWidth="1"
                  borderColor="borderOutline"
                  className="bg-opacity-20 focus-within:border-blue-900 focus-within:ring-1 ring-blue-900"
                >
                  <input
                    type="search"
                    name="search"
                    placeholder={`Search ${label}`}
                    value={search}
                    onChange={(e) => setValue(e.target.value)}
                    className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
                  />
                  <Inline
                    as="button"
                    type="button"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => {
                      if (search) setValue("")
                    }}
                  >
                    {search ? (
                      <CancelIcon color="gray900" />
                    ) : (
                      <SearchIcon color="gray500" />
                    )}
                  </Inline>
                </Inline>
              </Box>
            ) : null}
            {filteredFields?.length ? (
              <Stack as="ul" gap="2" paddingTop="2">
                <Inline
                  as="li"
                  borderWidth="1"
                  width="full"
                  paddingX="4"
                  paddingY="3"
                  gap="4"
                  onClick={() => {
                    setSelectedField(undefined)
                  }}
                  cursor="pointer"
                  borderColor={
                    !selectedField ? "surfacePrimaryLowest" : "borderOutline"
                  }
                  rounded="md"
                  className={classNames({
                    "hover:bg-[#F5F5F5]": selectedField,
                    "cursor-not-allowed": !selectedField,
                  })}
                  backgroundColor={
                    !selectedField ? "surfacePrimaryLowest" : "transparent"
                  }
                  alignItems="center"
                >
                  <Radio isSelected={!selectedField} />
                  <Text fontSize="b3">No {label}</Text>
                </Inline>
                {filteredFields.map((field) => {
                  const { uuid, name, type, phoneNumber } = field
                  const isSelected = uuid === selectedField?.uuid
                  return (
                    <Inline as="li" key={uuid}>
                      <Inline
                        as="label"
                        borderWidth={"1"}
                        width="full"
                        paddingX="4"
                        paddingY="3"
                        gap="4"
                        onClick={() => {
                          setSelectedField(field)
                        }}
                        className={classNames({
                          "hover:bg-[#F5F5F5]": !isSelected,
                          "cursor-not-allowed": isSelected,
                        })}
                        cursor="pointer"
                        borderColor={
                          isSelected ? "surfacePrimaryLowest" : "borderOutline"
                        }
                        rounded="md"
                        backgroundColor={
                          isSelected ? "surfacePrimaryLowest" : "transparent"
                        }
                        alignItems="center"
                      >
                        <Radio isSelected={isSelected} />
                        <Stack gap="2">
                          <Text fontSize={action === "parties" ? "s4" : "b3"}>
                            {name}
                          </Text>
                          {type || phoneNumber ? (
                            <Inline
                              color="gray500"
                              gap={type && phoneNumber ? "1" : "0"}
                            >
                              <Text>{phoneNumber}</Text>
                              {type && phoneNumber && <Text>.</Text>}
                              <Text textTransform="capitalize">{type}</Text>
                            </Inline>
                          ) : null}
                        </Stack>
                      </Inline>
                    </Inline>
                  )
                })}
              </Stack>
            ) : (
              <Stack gap="16" paddingTop="2">
                <AddFieldInDialog
                  book={book}
                  initialName={search}
                  onSuccess={(field) => {
                    setSelectedField(field)
                  }}
                >
                  {({ add }) => (
                    <Inline
                      gap="4"
                      rounded="md"
                      paddingX="4"
                      paddingY="2"
                      alignItems="center"
                      height="12"
                      as="button"
                      onClick={() => add(search)}
                      disabled={!checkCanUpdateFields}
                      backgroundColor="surfacePrimaryLowest"
                    >
                      <PlusIcon color="iconPrimary" />
                      <Text fontSize="b3">Add ‘{search}’</Text>
                    </Inline>
                  )}
                </AddFieldInDialog>
                <Stack
                  gap="6"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Circle>
                    {action === "parties" ? <UsersFilledIcon /> : <LabelIcon />}
                  </Circle>
                  <Stack gap="2">
                    <Text fontSize="s3">
                      No {pluralize(`${label}`).toLowerCase()} found!
                    </Text>
                    <Text fontSize="b3" color="textMedium">
                      Check spelling or try some other search term
                    </Text>
                  </Stack>
                  <Box paddingTop="2" textAlign="left">
                    <Alert status="info">
                      <Text
                        color="textAlt2"
                        fontSize="c2"
                      >{`Go to “Book Settings > Entry Field > ${label} to import category from different book in the same business.`}</Text>
                    </Alert>
                  </Box>
                </Stack>
              </Stack>
            )}
          </Stack>
        ) : (
          <Stack
            gap="6"
            paddingY="6"
            justifyContent="center"
            alignItems="center"
          >
            <Circle>
              {action === "parties" ? <UsersFilledIcon /> : <LabelIcon />}
            </Circle>
            <Stack gap="2" textAlign="center">
              <Text fontSize="s3">No {pluralize(`${label}`, 2)} found!</Text>
              <Text fontSize="b3" color="textMedium">
                Add new or import from other books
              </Text>
            </Stack>
            <Stack
              paddingTop="2"
              gap="8"
              alignItems="center"
              justifyContent="center"
            >
              <AddFieldInDialog
                book={book}
                onSuccess={(field) => {
                  setSelectedField(field)
                }}
              >
                {({ add }) => (
                  <Box>
                    <CBButton
                      disabled={!checkCanUpdateFields}
                      level="primary"
                      onClick={() => add()}
                    >
                      <PlusIcon />
                      Add New
                    </CBButton>
                  </Box>
                )}
              </AddFieldInDialog>
              <Alert status="info">
                <Text
                  color="textAlt2"
                  fontSize="c2"
                >{`Go to “Book Settings > Entry Field > ${label} to import category from different book in the same business.`}</Text>
              </Alert>
            </Stack>
          </Stack>
        )}
      </ModalBody>
      {fields?.length ? (
        <ModalFooter>
          <ConfirmationModal
            book={book}
            userId={user.uid}
            action={action}
            label={label}
            transactions={transactions}
            selectedField={selectedField}
            onSuccess={(numOfTransactions, fieldName) => {
              onClose()
              onSuccess()
              toast.success(
                fieldName
                  ? `'${fieldName}' ${label?.toLowerCase()} updated in ${numOfTransactions} ${pluralize(
                      "entry",
                      numOfTransactions
                    )}`
                  : `${label} removed from ${numOfTransactions} ${pluralize(
                      "entry",
                      numOfTransactions
                    )}`
              )
            }}
          >
            {({ open }) => (
              <CBButton level="primary" size="lg" onClick={open}>
                Update
              </CBButton>
            )}
          </ConfirmationModal>
          <AddFieldInDialog
            book={book}
            onSuccess={(field) => {
              setSelectedField(field)
            }}
          >
            {({ add }) => (
              <CBButton
                size="lg"
                disabled={!checkCanUpdateFields}
                onClick={() => add()}
              >
                Add New
              </CBButton>
            )}
          </AddFieldInDialog>
        </ModalFooter>
      ) : (
        <ModalFooter>
          <CBButton size="lg" onClick={onClose}>
            Close
          </CBButton>
        </ModalFooter>
      )}
    </>
  )
}

function ConfirmationModal({
  book,
  userId,
  action,
  label,
  transactions,
  selectedField,
  children,
  onSuccess,
}: {
  userId: string
  book: TBook
  label: string
  action: TActionType
  selectedField?: TBookEntryField
  transactions: TTransaction[]
  onSuccess?: (numOfTransactionUpdated: number, fieldName?: string) => void
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const store = useFirestore()
  const bookTransactionCollection = useTransactionsCollection(book.id)
  const state = useOverlayTriggerState({})

  const pointers = useMemo(() => {
    return [
      `You have selected ${transactions.length} ${pluralize(
        "entry",
        transactions.length
      )}`,
      !selectedField?.name
        ? `All selected ${pluralize(
            "entry",
            transactions.length
          )} will not have any ${label?.toLowerCase()} assigned`
        : `Selected ${pluralize(
            "entry",
            transactions.length
          )} will be added to '${selectedField?.name}' ${label?.toLowerCase()}`,
    ]
  }, [selectedField?.name, transactions.length, label])

  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title={`Change ${label}`}
      >
        <Formik
          initialValues={{
            transactions: transactions as TTransaction[],
            selectedField: selectedField as TBookEntryField | undefined,
          }}
          onSubmit={formikOnSubmitWithErrorHandling(
            async ({ transactions }) => {
              const batches = [writeBatch(store)]
              let documentOperationsCounts = 0
              let sameCreator = userId
              let sameField = selectedField?.uuid
              transactions.forEach((t) => {
                const fieldId =
                  action === "category"
                    ? t.categoryId
                    : action === "paymentMode"
                    ? t.paymentModeId
                    : t.partyId
                if (sameCreator !== t.createdBy) {
                  sameCreator = t.createdBy || ""
                }
                if (sameField !== fieldId) {
                  sameField = fieldId || ""
                }
                const data: Optional<TTransaction> = {
                  date: t.date,
                  updatedAt: getServerTimestamp() as Timestamp,
                  updatedBy: userId,
                }
                if (action === "category") {
                  data.categoryId = selectedField?.uuid || null
                }
                if (action === "paymentMode") {
                  data.paymentModeId = selectedField?.uuid || null
                }
                if (action === "parties") {
                  data.partyId = selectedField?.uuid || null
                }
                const operationsInThisTransaction = 1
                if (
                  documentOperationsCounts + operationsInThisTransaction >
                  50
                ) {
                  batches.push(writeBatch(store))
                  documentOperationsCounts = 0
                }
                documentOperationsCounts += operationsInThisTransaction
                batches[batches.length - 1].update(
                  doc(bookTransactionCollection, t.id),
                  {
                    ...data,
                  } as never
                )
              })
              await Promise.all(batches.map((batch) => batch.commit()))
              if (action === "category") {
                trackEvent(TrackingEvents.MULTI_ENTRY_CATEGORY_CHANGED, {
                  sharedBook: isSharedBook(book),
                  entryCount: transactions.length,
                  sameCreator: sameCreator === userId,
                  sameCategory: sameField === selectedField?.uuid,
                })
              } else if (action === "paymentMode") {
                trackEvent(TrackingEvents.MULTI_ENTRY_PAYMENT_MODE_CHANGED, {
                  sharedBook: isSharedBook(book),
                  entryCount: transactions.length,
                  sameCreator: sameCreator === userId,
                  samePaymentMode: sameField === selectedField?.uuid,
                })
              } else {
                trackEvent(TrackingEvents.MULTI_ENTRY_PARTY_CHANGED, {
                  sharedBook: isSharedBook(book),
                  entryCount: transactions.length,
                  sameCreator: sameCreator === userId,
                  sameParty: sameField === selectedField?.uuid,
                })
              }
              state.close()
              onSuccess?.(transactions.length, selectedField?.name)
            }
          )}
        >
          {({ status, isSubmitting }) => (
            <Form noValidate>
              <ModalBody>
                <Stack as="ul" gap="4">
                  <Text fontSize="b3">Are you sure?</Text>
                  {pointers.map((pointer) => (
                    <Inline as="li" gap="4" alignItems="center" key={pointer}>
                      <Circle size="2" backgroundColor="iconLow" />
                      <Text fontSize="b3">{pointer}</Text>
                    </Inline>
                  ))}
                </Stack>
                {status ? <Alert status="error">{status}</Alert> : null}
              </ModalBody>
              <ModalFooter>
                <CBButton type="submit" size="lg" loading={isSubmitting}>
                  Yes, Change
                </CBButton>
                <CBButton onClick={state.close} size="lg">
                  Cancel
                </CBButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
