import {
  Alert,
  Button,
  FormField,
  formikOnSubmitWithErrorHandling,
  Modal,
  ModalBody,
  ModalFooter,
  Stack,
  Box,
  useOverlayTriggerState,
  TrashIcon,
  CancelIcon,
  DataLoadingFallback,
  InformationCircleIcon,
  Text,
  SearchIcon,
  Heading,
  PlusIcon,
  Input,
  SearchSelect,
  TSelectableBaseOption,
  PhoneInput,
  parsePhoneNumber,
  Inline,
  isPossiblePhoneNumber,
  ArrowRightIcon,
  ExcelVectorIcon,
  Circle,
  BackupIcon,
  CopyIcon,
  UsersOutlinedIcon,
  CBButton,
  Switch,
} from "@cashbook/web-components"
import { FieldProps, Form, Formik, useFormik } from "formik"
import { useCallback, useEffect, useMemo, useReducer, useState } from "react"
import {
  useUpdateBookPreferences,
  isSharedBook,
  useBook,
  BOOK_PERMISSIONS,
  TBook,
  TBookEntryFields,
  useTransactionsSearch,
  useAddCategory,
  useAddPaymentMode,
  useImportCategories,
  useImportPaymentModes,
  useUpdateCategory,
  useUpdatePaymentMode,
  useDeleteCategory,
  useDeletePaymentMode,
  useFetchCategorySuggestions as useFetchCategorySuggestionsWithoutCaching,
  getAllPartyTypes,
  T_AVAILABLE_PARTY_TYPES,
  useAddParty,
  useUpdateParty,
  useDeleteParty,
  useImportParties,
  useUpdatePartyPhoneNumber,
  getRoleDetailsForMember,
  useBusinessBooksSearchForMember,
  checkIfMemberCan,
  TBookCustomField,
  useCustomField,
  TBookCustomFields,
} from "@cashbook/data-store/books"
import * as Validator from "yup"
import { PhoneNumberValidator, pluralize } from "@cashbook/util-general"
import toast from "react-hot-toast"
import { SuspenseWithPerf, useUser } from "reactfire"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import { NoBooksIcon } from "./NoBooksIcon"
import { Optional } from "utility-types"
import { usePartyOrContact } from "@cashbook/data-store/books"
import { useProfile } from "@cashbook/data-store/users"
import { T_AVAILABLE_BUSINESS_ROLES } from "@cashbook/data-store/businesses"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useSyncedStorageState } from "@cashbook/data-store/storage"
import "./animate.css"
import { SearchBar } from "../common"

type EntryFieldKey = "parties" | "categories" | "paymentModes"

const updatePartyValidationSchema = Validator.object().shape({
  name: Validator.string()
    .required()
    .max(191, "Maximum 191 characters allowed for the member's name."),
  phoneNumber: PhoneNumberValidator,
})

const customFieldValidationSchema = Validator.object().shape({
  fieldName: Validator.string()
    .required()
    .max(20, "Maximum 20 characters allowed for the member's name."),
})

export function useToggleCategoryField(book: TBook) {
  const update = useUpdateBookPreferences(book)
  return useCallback(async () => {
    const isShared = isSharedBook(book)
    const isDisabledOld = book.preferences?.categoriesDisabled
    await update({ categoriesDisabled: !isDisabledOld })
    if (!isDisabledOld) {
      trackEvent(TrackingEvents.CATEGORY_FIELD_DISABLED, {
        sharedBook: isShared,
      })
    } else {
      trackEvent(TrackingEvents.CATEGORY_FIELD_ENABLED, {
        sharedBook: isShared,
      })
    }
  }, [book, update])
}

export function useTogglePartyField(book: TBook) {
  const update = useUpdateBookPreferences(book)
  return useCallback(async () => {
    const isShared = isSharedBook(book)
    const isDisabledOld = Boolean(book.preferences?.partyDisabled)
    await update({ partyDisabled: !isDisabledOld })
    if (!isDisabledOld) {
      trackEvent(TrackingEvents.PARTY_FIELD_DISABLED, {
        sharedBook: isShared,
      })
    } else {
      trackEvent(TrackingEvents.PARTY_FIELD_ENABLED, {
        sharedBook: isShared,
      })
    }
  }, [book, update])
}

export function useTogglePaymentModeField(book: TBook) {
  const update = useUpdateBookPreferences(book)
  return useCallback(async () => {
    const isShared = isSharedBook(book)
    const isDisabledOld = Boolean(book.preferences?.paymentModesDisabled)
    await update({ paymentModesDisabled: !isDisabledOld })
    if (!isDisabledOld) {
      trackEvent(TrackingEvents.PAYMENT_MODE_FIELD_DISABLED, {
        sharedBook: isShared,
      })
    } else {
      trackEvent(TrackingEvents.PAYMENT_MODE_FIELD_ENABLED, {
        sharedBook: isShared,
      })
    }
  }, [book, update])
}

export function AddNewCustomFieldInDialog({
  children,
  ...props
}: Omit<React.ComponentProps<typeof CustomFieldInDialog>, "onSubmit"> & {
  book: TBook
  onCancel?: () => void
  children: (props: { add: (initialName?: string) => void }) => React.ReactNode
  onSuccess?: (field: TBookEntryFields[number]) => void
  actionOrigin?:
    | "entryFieldSettings"
    | "chooseParty"
    | "chooseCategory"
    | "choosePaymentMode"
}) {
  const state = useOverlayTriggerState({})
  const [name, setName] = useState<string>("")
  const { add: addCustomField } = useCustomField(props.book)
  return (
    <>
      {children({
        add: (initialName?: string) => {
          state.open()
          setName(initialName || "")
        },
      })}
      <Modal
        title={`Add New Custom Field`}
        isOpen={state.isOpen}
        onClose={state.close}
        size="sm"
      >
        <CustomFieldInDialog
          {...props}
          initialName={name}
          onSuccess={state.close}
          onSubmit={(values) =>
            addCustomField(
              {
                name: values.name,
                required: values.required,
              },
              "entryFieldSettings"
            )
          }
        />
      </Modal>
    </>
  )
}

function CustomFieldInDialog({
  initialName = "",
  onSubmit,
  onSuccess,
}: {
  initialName?: string
  onSubmit: (data: TBookCustomField) => Promise<TBookCustomFields[number]>
  onSuccess?: () => void
}) {
  return (
    <Formik
      initialValues={{
        fieldName: initialName as string,
        required: false as boolean,
      }}
      validationSchema={customFieldValidationSchema}
      validateOnBlur={false}
      onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
        await onSubmit({
          name: values.fieldName,
          required: values.required,
        })
        toast.success(
          `Custom Field "${values.fieldName}" added successfully in this book`
        )

        onSuccess?.()
      })}
    >
      {({ status, values, isSubmitting, setFieldValue, submitForm }) => {
        return (
          <Form noValidate>
            <ModalBody>
              <Stack>
                <FormField
                  type="text"
                  name="fieldName"
                  label={`Field Name`}
                  placeholder={"e.g. Bill no"}
                  required
                  autoFocus
                  hideAsterisk
                  pattern="^[a-zA-Z0-9 ]+$"
                />
                <FormField
                  name="required"
                  noMargin
                  renderInput={() => (
                    <Stack gap="2">
                      <Text>
                        Required Field?{" "}
                        <Text as="span" fontSize="c3" color="textMedium">
                          (This field will be{" "}
                          {values.required ? "required" : "optional"} to add
                          when creating entry)
                        </Text>
                      </Text>
                      <Switch
                        id="required"
                        label="Required"
                        on={values.required}
                        onToggle={() =>
                          setFieldValue("required", !values.required)
                        }
                      />
                    </Stack>
                  )}
                />
              </Stack>
              {status && <Alert status="error">{status}</Alert>}
            </ModalBody>
            <ModalFooter>
              <CBButton
                loading={isSubmitting}
                level="primary"
                size="lg"
                disabled={!values.fieldName.length}
                onClick={submitForm}
              >
                {isSubmitting ? "Saving..." : "Save"}
              </CBButton>
            </ModalFooter>
          </Form>
        )
      }}
    </Formik>
  )
}

export function DeleteCustomFieldInDialog({
  children,
  ...props
}: Omit<
  React.ComponentProps<typeof DeleteCustomFieldForm>,
  "onSubmit" | "closeModal"
> & {
  children: (props: { onDelete: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({ onDelete: state.open })}
      <Modal
        title={`Delete Custom Field ?`}
        isOpen={state.isOpen}
        onClose={state.close}
        size="sm"
      >
        <SuspenseWithPerf
          traceId="entried_tagged"
          fallback={<DataLoadingFallback label="Counting tagged entries..." />}
        >
          <DeleteCustomFieldForm {...props} closeModal={state.close} />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

function DeleteCustomFieldForm({
  field,
  book,
  closeModal,
}: {
  field: TBookCustomField
  book: TBook
  closeModal: () => void
}) {
  const { deleteField } = useCustomField(book)
  return (
    <Formik
      initialValues={{
        uuid: field.fieldName,
        cashbookId: book.id,
      }}
      onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
        await deleteField({ field: values.uuid })
        trackEvent(TrackingEvents.CUSTOM_FIELD_DELETED, {
          sharedBook: isSharedBook(book),
        })
        toast.success(`Custom Field for "${field.name}" removed from this book`)
      })}
    >
      {({ isSubmitting, status }) => (
        <Form noValidate>
          <ModalBody>
            <Stack gap="4">
              <Alert status="warning">
                Are you sure? This cannot be undone
              </Alert>
              <Box
                padding="4"
                borderWidth="1"
                borderColor="gray100"
                rounded="md"
              >
                <Text>
                  This custom field ({field.name}) will be deleted with all the
                  data entered using this field
                </Text>
              </Box>
            </Stack>
            {status && <Alert status="error">{status}</Alert>}
          </ModalBody>
          <ModalFooter>
            <CBButton
              type="submit"
              disabled={isSubmitting}
              status="danger"
              level="secondary"
              size="lg"
              autoFocus
            >
              <TrashIcon /> {isSubmitting ? "Deleting..." : "Yes, Delete"}
            </CBButton>
            <CBButton
              disabled={isSubmitting}
              level="tertiary"
              size="lg"
              onClick={closeModal}
            >
              <CancelIcon /> Cancel
            </CBButton>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

export function EditCustomFieldInDialog({
  children,
  ...props
}: Omit<React.ComponentProps<typeof EditCustomFieldForm>, "closeModal"> & {
  children: (props: { onEdit: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({ onEdit: state.open })}
      <Modal
        title={`Update Custom Field ?`}
        isOpen={state.isOpen}
        onClose={state.close}
        size="sm"
      >
        <SuspenseWithPerf
          traceId="entried_tagged"
          fallback={<DataLoadingFallback label="Counting tagged entries..." />}
        >
          <EditCustomFieldForm {...props} closeModal={state.close} />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

function EditCustomFieldForm({
  field,
  book,
  closeModal,
}: {
  field: TBookCustomField
  book: TBook
  closeModal: () => void
}) {
  const { update } = useCustomField(book)

  return (
    <Formik
      initialValues={{
        fieldName: field.name as string,
        required: field.required as boolean,
      }}
      validationSchema={customFieldValidationSchema}
      onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
        await update({
          field: field.fieldName,
          name: values.fieldName,
          required: values.required,
        })
        trackEvent(TrackingEvents.CUSTOM_FIELD_UPDATED, {
          nameChanged: values.fieldName !== field.name,
          fieldRequirementChanged: values.required !== field.required,
          sharedBook: isSharedBook(book),
        })
        closeModal()
        toast.success(`"${field.name}" field updated in the book`)
      })}
    >
      {({ values, status, isSubmitting, setFieldValue }) => {
        const eligible =
          values.fieldName !== field.name || values.required !== field.required
        return (
          <Form noValidate>
            <ModalBody>
              <Stack>
                <FormField
                  type="text"
                  name="fieldName"
                  label={`Field Name`}
                  placeholder={"e.g. Bill no"}
                  required
                  autoFocus
                  hideAsterisk
                  pattern="^[a-zA-Z0-9 ]+$"
                />
                <FormField
                  name="required"
                  noMargin
                  renderInput={() => (
                    <Stack gap="2">
                      <Text>
                        Required Field?{" "}
                        <Text as="span" fontSize="c3" color="textMedium">
                          (This field will be{" "}
                          {values.required ? "required" : "optional"} to add
                          when creating entry)
                        </Text>
                      </Text>
                      <Switch
                        id="required"
                        label="Required"
                        on={values.required}
                        onToggle={() =>
                          setFieldValue("required", !values.required)
                        }
                      />
                    </Stack>
                  )}
                />
              </Stack>
              {status && <Alert status="error">{status}</Alert>}
            </ModalBody>
            <ModalFooter>
              <CBButton
                loading={isSubmitting}
                level="primary"
                size="lg"
                disabled={!eligible}
                type="submit"
              >
                {isSubmitting ? "Updating..." : "Update"}
              </CBButton>
            </ModalFooter>
          </Form>
        )
      }}
    </Formik>
  )
}

export function AddPartyFieldInDialog({
  book,
  children,
  onCancel,
  onSuccess,
  actionOrigin,
  ...props
}: Omit<React.ComponentProps<typeof PartyFieldInDialog>, "onSubmit"> & {
  book: TBook
  onCancel?: () => void
  children?: (props: { add: (initialName?: string) => void }) => React.ReactNode
  onSuccess?: (field: TBookEntryFields[number]) => void
  actionOrigin?:
    | "entryFieldSettings"
    | "chooseParty"
    | "chooseCategory"
    | "choosePaymentMode"
}) {
  type TState = {
    isOpen: boolean
    initialName?: string
  }
  const [state, setState] = useReducer(
    (state: TState, action: Optional<TState>) => ({ ...state, ...action }),
    { isOpen: false }
  )
  const { data: user } = useUser()
  const close = useCallback(() => {
    setState({ isOpen: false })
  }, [])
  const cancel = useCallback(() => {
    close()
    onCancel?.()
  }, [close, onCancel])
  const addParty = useAddParty(book, actionOrigin || "entryFieldSettings")
  const { partyOrContact } = usePartyOrContact()
  const canUpdateField = checkIfMemberCan(
    book,
    user?.uid,
    BOOK_PERMISSIONS.UPDATE_ENTRY_FIELDS
  )
  return (
    <>
      {children ? (
        children({
          add: (initialName) => {
            trackEvent(TrackingEvents.ADD_PARTY_BUTTON_CLICKED, {
              from: actionOrigin || "entryFieldSettings",
            })
            setState({ isOpen: true, initialName })
          },
        })
      ) : (
        <Button
          level="primary"
          size="lg"
          onClick={() => setState({ isOpen: true })}
        >
          <PlusIcon /> Add New {partyOrContact}
        </Button>
      )}
      <Modal
        title={`Add New ${partyOrContact}`}
        isOpen={state.isOpen}
        onClose={cancel}
        size="sm"
      >
        <PartyFieldInDialog
          {...props}
          authUserPhoneNumber={user?.phoneNumber}
          canUpdateField={canUpdateField}
          initialName={state.initialName}
          onSuccess={(field) => {
            close()
            onSuccess?.(field)
          }}
          onSubmit={async (values) => addParty(values)}
        />
      </Modal>
    </>
  )
}

export function AddNewPartyFieldOptions({
  book,
  canUpdate,
  fieldDisabled,
  businessId,
}: {
  book: TBook
  canUpdate?: boolean
  fieldDisabled?: boolean
  businessId: string
}) {
  const navigate = useNavigate()

  const [visitedAddParty, setVisitedAddParty] = useSyncedStorageState<boolean>(
    "visitedAddParty",
    false
  )
  const { partyOrContact } = usePartyOrContact()
  return (
    <Stack gap="4">
      <Heading as="h4" fontSize="base" fontWeight="semibold" color="gray500">
        Add New {partyOrContact}
      </Heading>
      <Inline gap="4" alignItems="center">
        <Stack
          gap="3"
          width="full"
          padding="4"
          rounded="md"
          alignItems="center"
          justifyContent="center"
          borderWidth="1"
          textAlign="center"
          as="button"
          disabled={fieldDisabled || !canUpdate}
          position="relative"
          onClick={() => {
            navigate(
              `/businesses/${businessId}/cashbooks/${book.id}/import-parties`
            )
            if (!visitedAddParty) {
              setVisitedAddParty(true)
            }
          }}
        >
          {!visitedAddParty ? (
            <Box
              position="absolute"
              top="0"
              right="0"
              paddingY="1"
              paddingX="2"
              roundedTopRight="md"
              roundedBottomLeft="md"
              backgroundColor="surfaceSuccess"
            >
              <Text fontSize="c2" color="textOnSurface">
                New
              </Text>
            </Box>
          ) : null}
          <Circle backgroundColor="surfaceSuccessLowest">
            <BackupIcon color="iconCashIn" />
          </Circle>
          <Text fontSize="s4" color="textCashIn">
            Import From
            <br /> CSV
          </Text>
        </Stack>
        <ImportPartyFieldInDialog book={book} businessId={businessId}>
          {({ onImport }) => (
            <Stack
              gap="3"
              width="full"
              padding="4"
              rounded="md"
              as="button"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
              borderWidth="1"
              onClick={onImport}
              disabled={fieldDisabled || !canUpdate}
            >
              <Circle>
                <CopyIcon color="iconPrimary" />
              </Circle>
              <Text fontSize="s4" color="textPrimary">
                Import From <br />
                Books
              </Text>
            </Stack>
          )}
        </ImportPartyFieldInDialog>

        <AddPartyFieldInDialog book={book}>
          {({ add }) => (
            <Stack
              gap="3"
              width="full"
              padding="4"
              as="button"
              rounded="md"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
              borderWidth="1"
              onClick={() => add()}
              disabled={fieldDisabled || !canUpdate}
            >
              <Circle>
                <PlusIcon color="iconPrimary" />
              </Circle>
              <Text fontSize="s4" color="textPrimary">
                Add
                <br /> Manually
              </Text>
            </Stack>
          )}
        </AddPartyFieldInDialog>
      </Inline>
    </Stack>
  )
}

export function EmptyStatePartyField() {
  const { partiesOrContacts } = usePartyOrContact()
  return (
    <Stack gap="4">
      <Heading as="h4" fontSize="base" fontWeight="semibold" color="gray500">
        {" "}
        {partiesOrContacts} from this book (0)
      </Heading>
      <Stack
        gap="4"
        padding="6"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Circle>
          <UsersOutlinedIcon />
        </Circle>
        <Text fontSize="s4">No {partiesOrContacts} Found</Text>
        <Text fontSize="s4" color="textMedium">
          Import from CSV or other books or Add New Manually
        </Text>
      </Stack>
    </Stack>
  )
}

export function EditPartyFieldInDialog({
  book,
  children,
  actionOrigin,
  ...props
}: Omit<
  React.ComponentProps<typeof EditPartyFieldForm>,
  "party" | "onSubmit"
> & {
  book: TBook
  field: TBookEntryFields[number]
  actionOrigin?: "entryFieldSettings" | "chooseParty"
  children: (props: { edit: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const updateParty = useUpdateParty(book, actionOrigin)
  const { uuid, name, type, phoneNumber } = props.field
  const { partyOrContact } = usePartyOrContact()
  return (
    <>
      {children({ edit: state.open })}
      <Modal
        title={`Update ${partyOrContact}`}
        isOpen={state.isOpen}
        onClose={state.close}
        size="sm"
      >
        <EditPartyFieldForm
          {...props}
          book={book}
          party={{ uuid, name, type: type || "customer", phoneNumber }}
          onSuccess={state.close}
          onSubmit={async (values) => await updateParty(values)}
        />
      </Modal>
    </>
  )
}

export function DeletePartyFieldInDialog({
  book,
  ...props
}: Omit<
  React.ComponentProps<typeof DeleteFieldFormInDialog>,
  "onSubmit" | "fieldLabel" | "fieldKey"
>) {
  const deleteParty = useDeleteParty(book, props.field)
  const { partyOrContact } = usePartyOrContact()
  return (
    <DeleteFieldFormInDialog
      {...props}
      book={book}
      fieldKey="parties"
      fieldLabel={partyOrContact}
      onSubmit={async (values) => {
        await deleteParty(values)
      }}
    />
  )
}

export function ImportPartyFieldInDialog({
  book,
  children,
  ...props
}: Omit<
  React.ComponentProps<typeof ImportFieldFormInDialog>,
  "onSubmit" | "fieldLabel" | "fieldKey"
>) {
  const importParties = useImportParties(book)
  const { partyOrContact } = usePartyOrContact()
  return (
    <ImportFieldFormInDialog
      {...props}
      book={book}
      fieldLabel={partyOrContact}
      fieldKey="parties"
      onSubmit={async (values) => {
        await importParties({
          cashbookId: values.cashbookId,
          parties: values.fields,
        })
      }}
    >
      {({ onImport, ...props }) =>
        children({
          ...props,
          onImport: (...args) => {
            trackEvent(TrackingEvents.IMPORT_PARTY_BUTTON_CLICKED, {
              sharedBook: isSharedBook(book),
              partyCount: Number(book.parties?.length || 0),
            })
            onImport(...args)
          },
        })
      }
    </ImportFieldFormInDialog>
  )
}

export function AddCategoryFieldInDialog({
  book,
  actionOrigin = "entryFieldSettings",
  ...props
}: Omit<
  React.ComponentProps<typeof AddFieldFormInDialog>,
  "onSubmit" | "fieldLabel" | "fieldNamePlaceholder"
> & {
  book: TBook
}) {
  const addCategory = useAddCategory(book, actionOrigin)
  return (
    <AddFieldFormInDialog
      {...props}
      fieldLabel="Category"
      fieldNamePlaceholder="e.g. Salary, EMI, Food, Travel"
      onSubmit={async (values) => addCategory(values)}
    />
  )
}

export function ImportCategoryFieldInDialog({
  book,
  children,
  ...props
}: Omit<
  React.ComponentProps<typeof ImportFieldFormInDialog>,
  "onSubmit" | "fieldLabel" | "fieldKey"
>) {
  const importCategories = useImportCategories(book)
  return (
    <ImportFieldFormInDialog
      {...props}
      book={book}
      fieldLabel="Category"
      fieldKey="categories"
      onSubmit={async (values) => {
        await importCategories({
          cashbookId: values.cashbookId,
          names: values.fields.map((field) => field.name),
        })
      }}
    >
      {({ onImport, ...props }) =>
        children({
          ...props,
          onImport: (...args) => {
            trackEvent(TrackingEvents.IMPORT_CATEGORY_BUTTON_CLICKED, {
              sharedBook: isSharedBook(book),
              categoryCount: Number(book.categories?.length || 0),
            })
            onImport(...args)
          },
        })
      }
    </ImportFieldFormInDialog>
  )
}

export function EditCategoryFieldInDialog(
  props: Omit<
    React.ComponentProps<typeof EditFieldFormInDialog>,
    "onSubmit" | "fieldLabel" | "fieldKey"
  >
) {
  const updateCategory = useUpdateCategory(props.book, props.field)
  return (
    <EditFieldFormInDialog
      {...props}
      fieldLabel="Category"
      fieldKey="categories"
      onSubmit={async (values) => {
        await updateCategory({
          taggedEntries: values.taggedEntries,
          name: values.name,
        })
      }}
    />
  )
}

export function AddPaymentModeFieldInDialog({
  book,
  actionOrigin = "entryFieldSettings",
  ...props
}: Omit<
  React.ComponentProps<typeof AddFieldFormInDialog>,
  "onSubmit" | "fieldLabel" | "fieldNamePlaceholder"
> & {
  book: TBook
}) {
  const addField = useAddPaymentMode(book, actionOrigin)
  return (
    <AddFieldFormInDialog
      {...props}
      fieldLabel="Payment Mode"
      fieldNamePlaceholder="e.g. Net Banking, Credit Card"
      onSubmit={async (values) => addField(values)}
    />
  )
}

export function ImportPaymentModeFieldInDialog({
  book,
  children,
  ...props
}: Omit<
  React.ComponentProps<typeof ImportFieldFormInDialog>,
  "onSubmit" | "fieldLabel" | "fieldKey"
>) {
  const importPaymentModes = useImportPaymentModes(book)
  return (
    <ImportFieldFormInDialog
      {...props}
      book={book}
      fieldLabel="Payment Mode"
      fieldKey="paymentModes"
      onSubmit={async (values) => {
        await importPaymentModes({
          cashbookId: values.cashbookId,
          names: values.fields.map((field) => field.name),
        })
      }}
    >
      {({ onImport, ...props }) =>
        children({
          ...props,
          onImport: (...args) => {
            trackEvent(TrackingEvents.IMPORT_PAYMENT_MODE_BUTTON_CLICKED, {
              sharedBook: isSharedBook(book),
              paymentModeCount: Number(book.paymentModes?.length || 0),
            })
            onImport(...args)
          },
        })
      }
    </ImportFieldFormInDialog>
  )
}

export function EditPaymentModeFieldInDialog(
  props: Omit<
    React.ComponentProps<typeof EditFieldFormInDialog>,
    "onSubmit" | "fieldLabel" | "fieldKey"
  >
) {
  const updatePaymentMode = useUpdatePaymentMode(props.book, props.field)
  return (
    <EditFieldFormInDialog
      {...props}
      fieldLabel="Payment Mode"
      fieldKey="paymentModes"
      onSubmit={async (values) => {
        await updatePaymentMode({
          name: values.name,
          taggedEntries: values.taggedEntries,
        })
      }}
    />
  )
}

export function DeleteCategoryFieldInDialog({
  book,
  ...props
}: Omit<
  React.ComponentProps<typeof DeleteFieldFormInDialog>,
  "onSubmit" | "fieldLabel" | "fieldKey"
>) {
  const deleteCategory = useDeleteCategory(book, props.field)
  return (
    <DeleteFieldFormInDialog
      {...props}
      book={book}
      fieldKey="categories"
      fieldLabel="Category"
      onSubmit={async (values) => {
        await deleteCategory({
          taggedEntries: values.taggedEntries,
        })
      }}
    />
  )
}

export function DeletePaymentModeFieldInDialog({
  book,
  ...props
}: Omit<
  React.ComponentProps<typeof DeleteFieldFormInDialog>,
  "onSubmit" | "fieldLabel" | "fieldKey"
>) {
  const deletePaymentMode = useDeletePaymentMode(book, props.field)
  return (
    <DeleteFieldFormInDialog
      {...props}
      book={book}
      fieldLabel="Payment Mode"
      fieldKey="paymentModes"
      onSubmit={async (values) => {
        await deletePaymentMode({
          taggedEntries: values.taggedEntries,
        })
      }}
    />
  )
}

function AddFieldFormInDialog({
  fieldLabel,
  fieldNamePlaceholder,
  children,
  initialName,
  onSubmit,
  onCancel,
  onSuccess,
}: {
  fieldLabel: string
  fieldNamePlaceholder: string
  initialName?: string
  children: (props: { add: (initialName?: string) => void }) => React.ReactNode
  onSubmit: (data: { name: string }) => Promise<TBookEntryFields[number]>
  onCancel?: () => void
  onSuccess?: (field: TBookEntryFields[number]) => void
  actionOrigin?:
    | "entryFieldSettings"
    | "chooseParty"
    | "chooseCategory"
    | "choosePaymentMode"
}) {
  type TState = { initialName?: string; isOpen: boolean }
  const [state, setState] = useReducer(
    (state: TState, action: Optional<TState>) => ({ ...state, ...action }),
    { initialName: "", isOpen: false }
  )
  const close = useCallback(() => {
    setState({ isOpen: false, initialName: "" })
  }, [])
  const cancel = useCallback(() => {
    close()
    onCancel?.()
  }, [close, onCancel])
  return (
    <>
      {children({
        add: (initialName) => setState({ initialName, isOpen: true }),
      })}
      <Modal
        title={`Add New ${fieldLabel}`}
        isOpen={state.isOpen}
        onClose={cancel}
        size="sm"
      >
        <Formik
          initialValues={{ name: state.initialName || initialName || "" }}
          validationSchema={Validator.object().shape({
            name: Validator.string()
              .required(`Please provide a name for the field`)
              .max(191, "Name should be bellow 191 characters"),
          })}
          validateOnBlur={false}
          onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
            const field = await onSubmit({
              ...values,
              name: values.name.trim(),
            })
            close()
            toast.success(`"${values.name}" ${fieldLabel} added.`)
            onSuccess?.(field)
          })}
        >
          {({ status, isSubmitting }) => (
            <Form noValidate>
              <ModalBody>
                <FormField
                  type="text"
                  name="name"
                  label={`${fieldLabel} Name`}
                  placeholder={fieldNamePlaceholder}
                  required
                  autoFocus
                />
                {status ? <Alert status="error">{status}</Alert> : null}
              </ModalBody>
              <ModalFooter>
                <Button type="submit" disabled={isSubmitting} size="lg">
                  {isSubmitting ? "Saving..." : "Save"}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

function EditFieldFormInDialog({
  children,
  ...props
}: React.ComponentProps<typeof EditFieldForm> & {
  children: (props: { edit: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({ edit: state.open })}
      <Modal
        title={`Rename ${props.fieldLabel}`}
        isOpen={state.isOpen}
        onClose={state.close}
        size="sm"
      >
        <SuspenseWithPerf
          traceId="entried_tagged"
          fallback={<DataLoadingFallback label="Counting tagged entries..." />}
        >
          <EditFieldForm
            {...props}
            onSubmit={async (...args) => {
              await props.onSubmit(...args)
              state.close()
            }}
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

function EditFieldForm({
  book,
  fieldLabel,
  field,
  onSubmit,
  fieldKey,
}: {
  book: TBook
  fieldLabel: string
  fieldKey: EntryFieldKey
  field: TBookEntryFields[number]
  onSubmit: (data: {
    cashbookId: string
    uuid: string
    name: string
    taggedEntries: number
  }) => Promise<unknown>
}) {
  const taggedEntriesCount = useEntriesTaggedForField({ book, field, fieldKey })
  const {
    values,
    setFieldValue,
    isSubmitting,
    submitForm,
    status,
    handleChange,
  } = useFormik({
    initialValues: {
      cashbookId: book.id,
      uuid: field.uuid,
      name: field.name || "",
      taggedEntries: taggedEntriesCount,
      confirmationStatus: "init" as "init" | "waiting" | "confirmed",
    },
    validationSchema: Validator.object().shape({
      name: Validator.string()
        .required(`Please provide a name for the field`)
        .max(191, "Name should be bellow 191 characters"),
    }),
    validateOnBlur: false,
    onSubmit: formikOnSubmitWithErrorHandling(async (values) => {
      await onSubmit({ ...values, name: values.name.trim() })
      toast.success(`"${values.name}" ${fieldLabel} updated.`)
    }),
  })
  return values.confirmationStatus === "init" ||
    values.confirmationStatus === "confirmed" ? (
    <form
      noValidate
      key="details"
      onSubmit={(e) => {
        e.preventDefault()
        setFieldValue("confirmationStatus", "waiting")
      }}
    >
      <ModalBody>
        <Stack gap="1">
          <label htmlFor="name" className="text-sm font-medium cursor-pointer">
            {fieldLabel} Name
          </label>
          <Input
            type="text"
            name="name"
            id="name"
            required
            autoFocus={values.confirmationStatus === "init"}
            value={values.name}
            onChange={handleChange}
          />
        </Stack>
        {status ? <Alert status="error">{status}</Alert> : null}
      </ModalBody>
      <ModalFooter>
        <Button type="submit" disabled={isSubmitting} size="lg">
          {isSubmitting ? "Updating..." : "Update"}
        </Button>
      </ModalFooter>
    </form>
  ) : (
    <form
      noValidate
      key="confirmation"
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <ModalBody>
        <Stack gap="4" marginBottom="4">
          <Alert status="warning">Are you sure? This cannot be undone</Alert>
          <TaggedEntriesInfo
            count={taggedEntriesCount}
            field={field}
            fieldLabel={fieldLabel}
          />
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          level="primary"
          size="lg"
          autoFocus
          onClick={() => {
            setFieldValue("confirmationStatus", "confirmed")
            submitForm()
          }}
        >
          Yes
        </Button>
        <Button
          type="button"
          disabled={isSubmitting}
          size="lg"
          onClick={() => setFieldValue("confirmationStatus", "init")}
        >
          No
        </Button>
      </ModalFooter>
    </form>
  )
}

function DeleteFieldFormInDialog({
  fieldLabel,
  children,
  ...props
}: Omit<React.ComponentProps<typeof DeleteFieldForm>, "closeModal"> & {
  children: (props: { onDelete: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({ onDelete: state.open })}
      <Modal
        title={`Delete ${fieldLabel} ?`}
        isOpen={state.isOpen}
        onClose={state.close}
        size="sm"
      >
        <SuspenseWithPerf
          traceId="entried_tagged"
          fallback={<DataLoadingFallback label="Counting tagged entries..." />}
        >
          <DeleteFieldForm
            closeModal={state.close}
            fieldLabel={fieldLabel}
            {...props}
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

function DeleteFieldForm({
  book,
  fieldLabel,
  fieldKey,
  onSubmit,
  field,
  closeModal,
}: {
  book: TBook
  fieldLabel: string
  fieldKey: EntryFieldKey
  initialName?: string
  closeModal: () => void
  onSubmit: (data: {
    cashbookId: string
    uuid: string
    taggedEntries: number
  }) => Promise<unknown>
  field: TBookEntryFields[number]
}) {
  const taggedEntriesCount = useEntriesTaggedForField({ book, fieldKey, field })
  return (
    <Formik
      initialValues={{
        uuid: field.uuid,
        cashbookId: book.id,
        taggedEntries: taggedEntriesCount,
      }}
      onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
        await onSubmit(values)
        closeModal()
        toast.success(`"${field.name}" removed from this book`)
      })}
    >
      {({ isSubmitting, status }) => (
        <Form noValidate>
          <ModalBody>
            <Stack gap="4">
              <Alert status="warning">
                Are you sure? This cannot be undone
              </Alert>
              <TaggedEntriesInfo
                count={taggedEntriesCount}
                field={field}
                fieldLabel={fieldLabel}
              />
              {status ? <Alert status="error">{status}</Alert> : null}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              disabled={isSubmitting}
              status="error"
              level="secondary"
              size="lg"
              autoFocus
            >
              <TrashIcon /> {isSubmitting ? "Deleting..." : "Yes, Delete"}
            </Button>
            <Button
              disabled={isSubmitting}
              level="tertiary"
              size="lg"
              onClick={closeModal}
            >
              <CancelIcon /> Cancel
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

function TaggedEntriesInfo({
  fieldLabel,
  field,
  count,
}: {
  fieldLabel: string
  field: TBookEntryFields[number]
  count: number
}) {
  if (count === 0)
    return (
      <Box>
        <InformationCircleIcon /> No entries are tagged with "{field.name}"{" "}
        {fieldLabel.toLowerCase()}
      </Box>
    )
  return (
    <Box padding="4" borderWidth="1" borderColor="gray100" rounded="md">
      <Stack as="ul" gap="4" className="list-disc ml-4">
        <li>
          {count} {pluralize("entry", count)} in this book{" "}
          {pluralize("is", count)} tagged with this {fieldLabel.toLowerCase()}
        </li>
        <li>
          {fieldLabel} will be changed for all the entries in this book which
          are tagged with "{field.name}"
        </li>
      </Stack>
    </Box>
  )
}

function ImportFieldFormInDialog({
  fieldLabel,
  children,
  onSubmit,
  ...props
}: Omit<React.ComponentProps<typeof ImportFieldForm>, "onCancel"> & {
  fieldLabel: string
  children: (props: { onImport: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({ onImport: state.open })}
      <Modal
        title={`Import ${pluralize(fieldLabel)}`}
        isOpen={state.isOpen}
        onClose={state.close}
        placement="right"
        isDismissable
      >
        <ImportFieldForm
          fieldLabel={fieldLabel}
          {...props}
          onCancel={state.close}
          onSubmit={async (...args) => {
            await onSubmit(...args)
            state.close()
          }}
        />
      </Modal>
    </>
  )
}

function checkIfEntryFieldAlreadyExist(
  book: TBook,
  name: string,
  fieldKey: EntryFieldKey,
  number?: string
) {
  const fields = book[fieldKey]
  let doesExist = false
  fields?.forEach((field) => {
    if (
      fieldKey === "parties" &&
      (field.name === name || (number?.length && field.phoneNumber === number))
    ) {
      doesExist = true
      return
    }
    if (field.name === name) {
      doesExist = true
      return
    }
  })
  return doesExist
}

function ImportFieldForm({
  book,
  fieldKey,
  fieldLabel,
  businessId,
  onSubmit,
  onCancel,
}: {
  book: TBook
  businessId: string
  fieldKey: EntryFieldKey
  fieldLabel: string
  onCancel: () => void
  onSubmit: (data: {
    cashbookId: string
    fields: Array<{
      name: string
      type?: T_AVAILABLE_PARTY_TYPES
      phoneNumber?: string
    }>
  }) => Promise<unknown>
}) {
  const { user } = useProfile()
  const {
    allBooks,
    books: filteredBooks,
    params,
    setParamValue,
    handleParamsChange,
  } = useBusinessBooksSearchForMember(businessId, user.uid)
  const anyBookHasFields = useMemo(() => {
    return allBooks.some((b) => Number(b[fieldKey]?.length) > 0)
  }, [allBooks, fieldKey])
  const books = useMemo(() => {
    return (
      filteredBooks
        .filter((b) => b.id !== book.id)
        .concat([])
        // push the books with no fields to the end
        .sort((b1, b2) => {
          if (b1[fieldKey]?.length && !b2[fieldKey]?.length) return -1
          if (b2[fieldKey]?.length && !b1[fieldKey]?.length) return 1
          return 0
        })
    )
  }, [filteredBooks, fieldKey, book])

  const { status, values, isSubmitting, setFieldValue, submitForm } = useFormik(
    {
      initialValues: {
        cashbookId: book.id,
        book: undefined as undefined | TBook,
        step: "select_book" as "select_book" | "select_fields",
        search: "" as string,
        fields: [] as Array<{
          name: string
          type?: T_AVAILABLE_PARTY_TYPES
          phoneNumber?: string
        }>,
      },
      validateOnBlur: false,
      onSubmit: formikOnSubmitWithErrorHandling(
        async ({ cashbookId, fields }) => {
          await onSubmit({
            cashbookId: cashbookId,
            fields: fields,
          })
          toast.success(
            `${fields.length} ${pluralize(
              fieldLabel,
              fields
            ).toLowerCase()} imported.`
          )
        }
      ),
    }
  )

  useEffect(() => {
    if (values.book?.id) {
      const validFields = values.book[fieldKey]?.filter((field) => {
        const { name, phoneNumber } = field
        return !checkIfEntryFieldAlreadyExist(book, name, fieldKey, phoneNumber)
      })
      setFieldValue("fields", validFields)
    }
  }, [book, fieldKey, values.book, setFieldValue])

  if (allBooks.length <= 1) {
    return (
      <>
        <ModalBody>
          <Box style={{ paddingTop: "80px", paddingBottom: "80px" }}>
            <Stack gap="6" alignItems="center">
              <NoBooksIcon />
              <Stack textAlign="center" gap="4" maxWidth="sm">
                <Heading as="h4" fontSize="md" fontWeight="semibold">
                  No Books Found
                </Heading>
                <Text color="gray500">
                  You don't have any other book, please create new books to
                  import {pluralize(fieldLabel).toLowerCase()} from
                </Text>
              </Stack>
            </Stack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button size="lg" level="primary" onClick={onCancel}>
            Ok, Got it
          </Button>
        </ModalFooter>
      </>
    )
  }
  const AddFieldFormInDialog =
    fieldKey === "categories"
      ? AddCategoryFieldInDialog
      : fieldKey === "parties"
      ? AddPartyFieldInDialog
      : AddPaymentModeFieldInDialog

  return (
    <>
      <form noValidate key="import_fields" onSubmit={(e) => e.preventDefault()}>
        <ModalBody>
          {values.step === "select_book" ? (
            <Stack gap="8">
              {anyBookHasFields ? (
                <div className="flex relative bg-opacity-20 rounded focus-within:border-blue-900 focus-within:ring-1 ring-blue-900 h-10 pr-2 items-stretch gap-2 max-w-lg w-full border">
                  <input
                    type="search"
                    name="q"
                    placeholder="Search books..."
                    value={params.q}
                    onChange={handleParamsChange}
                    className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
                  />
                  <button
                    type="button"
                    className="flex items-center justify-center text-gray-500"
                    onClick={() => {
                      if (params.q) setParamValue("q", "")
                    }}
                  >
                    {params.q ? <CancelIcon /> : <SearchIcon />}
                  </button>
                </div>
              ) : (
                <Box>
                  <Alert status="warning">
                    {pluralize(fieldLabel)} are not available
                  </Alert>
                </Box>
              )}
              <Box
                position="relative"
                style={{
                  filter: !anyBookHasFields ? "grayscale(1)" : undefined,
                  opacity: !anyBookHasFields ? ".5" : "1",
                }}
              >
                {!anyBookHasFields ? (
                  <Box position="absolute" inset="0" zIndex="100"></Box>
                ) : null}
                <Stack gap="6">
                  <Text fontWeight="medium">
                    Select a book to import{" "}
                    {pluralize(fieldLabel).toLowerCase()} from
                  </Text>
                  <Stack gap="4" as="ul">
                    {!books.length ? (
                      <Box as="li">
                        <Text color="gray500">
                          No books for this search. Please try a different
                          search.
                        </Text>
                      </Box>
                    ) : (
                      books.map((b) => (
                        <Inline
                          as="li"
                          key={b.id}
                          gap="3"
                          alignItems="center"
                          cursor="pointer"
                          onClick={() => {
                            setFieldValue("book", b)
                          }}
                          paddingBottom="4"
                          borderBottomWidth="1"
                          borderColor="gray100"
                          opacity={!b[fieldKey]?.length ? "50" : "100"}
                        >
                          <Input
                            name="book"
                            type="radio"
                            id={`book_${b.id}`}
                            value={b.id}
                            onChange={() => {
                              setFieldValue("book", b)
                            }}
                            checked={b.id === values.book?.id}
                            disabled={!b[fieldKey]?.length}
                            className={"border border-gray-500"}
                          />
                          <Stack gap="2" marginLeft="2">
                            <Text fontSize="md" fontWeight="semibold">
                              {b.name}
                            </Text>
                            <Text color="gray500" fontWeight="medium">
                              {!b[fieldKey]?.length
                                ? `No ${pluralize(fieldLabel)}`
                                : `${b[fieldKey]?.length} ${pluralize(
                                    fieldLabel,
                                    b[fieldKey]
                                  )}`}
                            </Text>
                          </Stack>
                        </Inline>
                      ))
                    )}
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          ) : (
            <Stack gap="6">
              <Text fontWeight="medium">
                Select {pluralize(fieldLabel).toLowerCase()} you want to import
                to {book.name}
              </Text>
              <SearchBar
                value={values.search}
                label="Search by name..."
                onChange={(value) => setFieldValue("search", value)}
              />
              <Stack as="ol" gap="4">
                {values.book &&
                  values.book[fieldKey]
                    ?.filter((field) =>
                      field.name
                        .toLowerCase()
                        .includes(values.search.toLowerCase())
                    )
                    .map((field) => {
                      const { name, uuid, type, phoneNumber } = field
                      const isDisabled = checkIfEntryFieldAlreadyExist(
                        book,
                        name,
                        fieldKey,
                        phoneNumber
                      )
                      const isChecked = isDisabled
                        ? false
                        : values.fields.some((field) => field.name === name)
                      return (
                        <Box as="li" borderWidth="1" rounded="md" key={name}>
                          <Inline
                            as="label"
                            padding="3"
                            width="full"
                            cursor="pointer"
                            alignItems="center"
                            gap="3"
                            backgroundColor={isDisabled ? "gray100" : undefined}
                          >
                            <Input
                              type="checkbox"
                              name="fields"
                              id={`field_${uuid}`}
                              value={name}
                              disabled={isDisabled}
                              onChange={() => {
                                setFieldValue(
                                  "fields",
                                  isChecked
                                    ? values.fields.filter(
                                        (fieldItem) =>
                                          fieldItem.name !== field.name
                                      )
                                    : [
                                        ...values.fields,
                                        { name, type, phoneNumber },
                                      ]
                                )
                              }}
                              className={"border border-gray-500"}
                              checked={isChecked}
                            />
                            <Box marginLeft="2">
                              <Text
                                as="span"
                                fontSize="md"
                                color={isDisabled ? "gray500" : undefined}
                              >
                                {name}
                              </Text>
                              {type?.length || phoneNumber?.length ? (
                                <Inline gap="2" paddingTop="1">
                                  <Text as="span" fontSize="sm" color="gray500">
                                    {phoneNumber}
                                  </Text>
                                  <Text
                                    as="span"
                                    fontSize="sm"
                                    color="gray500"
                                    textTransform="capitalize"
                                  >
                                    {type}
                                  </Text>
                                </Inline>
                              ) : null}
                            </Box>
                          </Inline>
                        </Box>
                      )
                    })}
              </Stack>
            </Stack>
          )}
          {status ? (
            <Box marginTop="4">
              <Alert status="error">{status}</Alert>
            </Box>
          ) : null}
        </ModalBody>
        <ModalFooter>
          {!anyBookHasFields ? (
            <AddFieldFormInDialog book={book} onSuccess={onCancel}>
              {({ add }) => (
                <Button onClick={() => add()} level="primary" size="lg">
                  <PlusIcon /> Add New {fieldLabel}
                </Button>
              )}
            </AddFieldFormInDialog>
          ) : values.step === "select_book" ? (
            <Button
              type="button"
              level="primary"
              key="next"
              size="lg"
              disabled={!values.book}
              onClick={() => {
                setFieldValue("step", "select_fields")
              }}
            >
              Next
            </Button>
          ) : (
            <Button
              type="submit"
              disabled={isSubmitting || !values.fields?.length}
              key="import"
              onClick={submitForm}
            >
              {isSubmitting
                ? "Importing..."
                : `Import ${values.fields.length} ${pluralize(
                    fieldLabel,
                    values.fields
                  )}`}
            </Button>
          )}
        </ModalFooter>
      </form>
    </>
  )
}

function useEntriesTaggedForField({
  book,
  fieldKey,
  field,
}: {
  book: TBook
  fieldKey: EntryFieldKey
  field: TBookEntryFields[number]
}) {
  const { user } = useProfile()
  const { id } = getRoleDetailsForMember(book, user.uid)
  const businessRole: T_AVAILABLE_BUSINESS_ROLES = useMemo(() => {
    if (id === "owner") {
      return "owner"
    } else if (id === "partner") {
      return "partner"
    } else {
      return "staff"
    }
  }, [id])
  const { totalFilteredTransactions } = useTransactionsSearch(
    book,
    {
      [fieldKey]: [field],
    },
    businessRole
  )
  return totalFilteredTransactions
}

export function SelectParty({
  bookId,
  footer,
  entryType,
  ...props
}: Omit<
  React.ComponentProps<typeof SelectField>,
  | "book"
  | "onAddField"
  | "AddFieldForm"
  | "fieldKey"
  | "fieldLabel"
  | "fetchOptions"
  | "actionOrigin"
  | "canCreate"
> & {
  bookId: string
}) {
  const { book, checkIfAuthenticatedMemberCan } = useBook(bookId)
  const canAdd = useMemo(
    () => checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.ADD_PARTY_ENTRY_FIELD),
    [checkIfAuthenticatedMemberCan]
  )
  const addField = useAddParty(book, "chooseParty")
  const { partyOrContact } = usePartyOrContact()
  return (
    <SelectField
      {...props}
      canCreate={canAdd}
      actionOrigin="chooseParty"
      fieldKey="parties"
      fieldLabel={partyOrContact}
      book={book}
      entryType={entryType}
      onAddField={addField}
      AddFieldForm={AddPartyFieldInDialog}
      footer={footer}
    />
  )
}

export function SelectCategory({
  bookId,
  entryType,
  ...props
}: Omit<
  React.ComponentProps<typeof SelectField>,
  | "book"
  | "onAddField"
  | "AddFieldForm"
  | "fieldKey"
  | "fieldLabel"
  | "fetchOptions"
  | "actionOrigin"
  | "canCreate"
> & {
  bookId: string
}) {
  const { book, checkIfAuthenticatedMemberCan } = useBook(bookId)
  const fetchCategorySuggestions = useFetchCategorySuggestions(bookId, {
    entryType,
  })
  const canAdd = useMemo(
    () => checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.UPDATE_ENTRY_FIELDS),
    [checkIfAuthenticatedMemberCan]
  )
  const addField = useAddCategory(book, "chooseCategory")
  return (
    <SelectField
      {...props}
      canCreate={canAdd}
      actionOrigin="chooseCategory"
      fieldKey="categories"
      fieldLabel="Category"
      book={book}
      entryType={entryType}
      fetchSuggestions={fetchCategorySuggestions}
      onAddField={addField}
      AddFieldForm={AddCategoryFieldInDialog}
    />
  )
}

export function SelectPaymentMode({
  bookId,
  entryType,
  ...props
}: Omit<
  React.ComponentProps<typeof SelectField>,
  | "book"
  | "onAddField"
  | "AddFieldForm"
  | "fieldKey"
  | "fieldLabel"
  | "fetchOptions"
  | "actionOrigin"
  | "canCreate"
> & {
  bookId: string
}) {
  const { book, checkIfAuthenticatedMemberCan } = useBook(bookId)
  const fetchPaymentSuggestions = useFetchPaymentModeSuggestions(bookId, {
    entryType,
  })
  const canAdd = useMemo(
    () => checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.UPDATE_ENTRY_FIELDS),
    [checkIfAuthenticatedMemberCan]
  )
  const addField = useAddPaymentMode(book, "choosePaymentMode")
  return (
    <SelectField
      {...props}
      canCreate={canAdd}
      entryType={entryType}
      actionOrigin="choosePaymentMode"
      fieldKey="paymentModes"
      fieldLabel="Payment Mode"
      book={book}
      fetchSuggestions={fetchPaymentSuggestions}
      onAddField={addField}
      AddFieldForm={AddPaymentModeFieldInDialog}
    />
  )
}

export type TSelectFieldOption = TSelectableBaseOption &
  TBookEntryFields[number] & {
    isSuggestion?: boolean
  }

export function makeFieldOption(
  field: TBookEntryFields[number]
): TSelectFieldOption {
  return { id: field.uuid, label: field.name, ...field }
}

function SelectField({
  book,
  onChange,
  fieldKey,
  fieldLabel,
  value,
  fetchSuggestions,
  AddFieldForm,
  onAddField,
  actionOrigin,
  canCreate,
  entryType,
  footer,
  focusInputOnKey,
}: {
  book: TBook
  fieldKey: EntryFieldKey
  fieldLabel: string
  onChange: (
    value: TSelectFieldOption | null,
    fromSuggestions?: boolean
  ) => void
  value: TSelectFieldOption | null
  fetchSuggestions?: () => Promise<TBookEntryFields>
  AddFieldForm: typeof AddCategoryFieldInDialog | typeof AddPartyFieldInDialog
  onAddField: ReturnType<typeof useAddCategory>
  actionOrigin: "chooseParty" | "chooseCategory" | "choosePaymentMode"
  canCreate?: boolean
  entryType: "cash-in" | "cash-out"
  focusInputOnKey?: string
  footer?: React.ReactNode
}) {
  const existingFields = book[fieldKey]
  type TState = {
    isCreating: boolean
    errors: Error | null
  }
  const [state, setState] = useReducer(
    (state: TState, action: Optional<TState>) => ({ ...state, ...action }),
    { isCreating: false, errors: null }
  )
  const fetchOptions = useCallback(async () => {
    let suggestions =
      fetchSuggestions && canCreate ? await fetchSuggestions() : []
    if (existingFields?.length) {
      suggestions = suggestions.filter(
        (s) => !existingFields.find((f) => f.name === s.name)
      )
    }
    return ([] as Array<TSelectFieldOption>)
      .concat(
        existingFields?.length && fetchSuggestions && canCreate
          ? [
              {
                id: "in_this_book",
                uuid: "in_this_book",
                label: "In This Book",
                name: "In This Book",
                heading: true,
              },
            ]
          : []
      )
      .concat(
        (existingFields || []).map((field) => ({
          ...makeFieldOption(field),
          heading: false,
        }))
      )
      .concat(
        suggestions.length
          ? [
              {
                id: "suggestions",
                uuid: "suggestions",
                label: "Suggestions",
                name: "Suggestions",
                heading: true,
              },
            ]
          : []
      )
      .concat(
        suggestions.map((field) => ({
          ...makeFieldOption(field),
          heading: false,
          isSuggestion: true,
        }))
      )
  }, [fetchSuggestions, existingFields, canCreate])
  return (
    <AddFieldForm
      actionOrigin={actionOrigin}
      book={book}
      onCancel={() => setState({ isCreating: false })}
      onSuccess={(field) => {
        onChange(makeFieldOption(field))
        clearSuggestionCacheForKey(fieldKey, book.id, entryType)
        setState({ isCreating: false })
      }}
    >
      {({ add }) => (
        <SearchSelect
          searchPlaceholder="Search or Select"
          control="input"
          value={value}
          focusInputOnKey={focusInputOnKey}
          onChange={(option) => {
            if (option) {
              if ((option as never as { isSuggestion: boolean }).isSuggestion) {
                const existingField = existingFields?.find(
                  (c) => c.name.trim() === option.label.trim()
                )
                if (existingField) {
                  // no need to add new category
                  onChange(makeFieldOption(existingField))
                  return
                }
                setState({ isCreating: true })
                onAddField({ name: option.label, fromSuggestions: true })
                  .then((field) => {
                    toast.success(`"${option.label}" ${fieldLabel} added.`)
                    onChange(makeFieldOption(field), true)
                    clearSuggestionCacheForKey(fieldKey, book.id, entryType)
                  })
                  .catch((error) => {
                    toast.error(
                      error?.message ||
                        "Something went wrong. Please try after some time."
                    )
                  })
                  .then(() => {
                    setState({ isCreating: false })
                  })
                return
              }
            }
            onChange(option)
          }}
          createOptionLabel={
            canCreate
              ? (q) =>
                  q.length > 2
                    ? `Add "${q}" as New ${fieldLabel}`
                    : `Add New ${fieldLabel}`
              : undefined
          }
          onCreateOption={(q) => {
            add(q)
            setState({ isCreating: true })
          }}
          isCreating={state.isCreating}
          fetchOptions={
            fieldKey === "parties" && !existingFields?.length
              ? undefined
              : fetchOptions
          }
          footer={footer}
        />
      )}
    </AddFieldForm>
  )
}

const __CACHED_SUGGESTIONS__: { [key: string]: TBookEntryFields } = {}
function generateKeyForCache(
  fieldKey: EntryFieldKey,
  bookId: string,
  entryType: "cash-in" | "cash-out"
) {
  return [fieldKey, bookId, entryType].join("-")
}

function clearSuggestionCacheForKey(
  fieldKey: EntryFieldKey,
  bookId: string,
  entryType: "cash-in" | "cash-out"
) {
  const key = generateKeyForCache(fieldKey, bookId, entryType)
  delete __CACHED_SUGGESTIONS__[key]
}

export function useFetchCategorySuggestions(
  bookId: string,
  { entryType }: { entryType: "cash-in" | "cash-out" }
) {
  const fetchCategories = useFetchCategorySuggestionsWithoutCaching(bookId, {
    entryType,
  })
  return useCallback(
    async function fetchSuggestions() {
      const key = generateKeyForCache("categories", bookId, entryType)
      if (__CACHED_SUGGESTIONS__[key]) {
        return __CACHED_SUGGESTIONS__[key]
      }
      const suggestions = await fetchCategories()
      __CACHED_SUGGESTIONS__[key] = suggestions
      return suggestions
    },
    [bookId, entryType, fetchCategories]
  )
}

export function useFetchPaymentModeSuggestions(
  bookId: string,
  { entryType }: { entryType: "cash-in" | "cash-out" }
) {
  return useCallback(
    async function fetchSuggestions() {
      const key = generateKeyForCache("paymentModes", bookId, entryType)
      if (__CACHED_SUGGESTIONS__[key]) {
        return __CACHED_SUGGESTIONS__[key]
      }
      const suggestions = [
        "Cash",
        "Online",
        "PhonePe",
        "Paytm",
        "Google Pay",
        "Bank",
        "Net Banking",
        "Debit Card",
        "Credit Card",
        "Cheque",
      ].map((cat) => ({
        uuid: bookId + cat,
        name: cat,
      }))
      __CACHED_SUGGESTIONS__[key] = suggestions
      return suggestions
    },
    [bookId, entryType]
  )
}

type TState = {
  initialName?: string
  phoneNumber?: string
  type?: T_AVAILABLE_PARTY_TYPES
}
function PartyFieldInDialog({
  initialName,
  canUpdateField,
  authUserPhoneNumber,
  onSubmit,
  onSuccess,
}: {
  initialName?: string
  canUpdateField?: boolean
  authUserPhoneNumber?: string | null
  onSubmit: (data: {
    name: string
    type?: T_AVAILABLE_PARTY_TYPES
    phoneNumber?: string
  }) => Promise<TBookEntryFields[number]>
  onSuccess?: (field: TBookEntryFields[number]) => void
}) {
  const [visitedAddParty, setVisitedAddParty] = useSyncedStorageState<boolean>(
    "visitedAddParty",
    false
  )
  const { bookId, businessId } = useParams()
  const [state, setState] = useReducer(
    (state: TState, action: Optional<TState>) => ({ ...state, ...action }),
    {
      initialName: initialName || "",
      phoneNumber: "",
      type: "customer",
    }
  )
  const { phoneNumber: myPhoneNumber } = {
    phoneNumber: authUserPhoneNumber || "",
  }
  const defaultCountry = useMemo(
    () =>
      (myPhoneNumber ? parsePhoneNumber(myPhoneNumber)?.country : "IN") || "IN",
    [myPhoneNumber]
  )
  const { partyOrContact, partiesOrContacts } = usePartyOrContact()

  return (
    <Formik
      initialValues={{
        name: state.initialName || "",
        phoneNumber: state.phoneNumber || "",
        type: state.type,
      }}
      validationSchema={updatePartyValidationSchema}
      validateOnBlur={false}
      onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
        const field = await onSubmit({
          name: values.name.trim(),
          type: values.type,
          phoneNumber: values.phoneNumber,
        })
        toast.success(`"${values.name}" ${partyOrContact} added.`)
        onSuccess?.(field)
      })}
    >
      {({ status, isSubmitting }) => (
        <Form noValidate>
          {canUpdateField ? (
            <Inline
              paddingX="8"
              paddingY="3"
              gap="3"
              as={Link}
              to={`/businesses/${businessId}/cashbooks/${bookId}/import-parties`}
              alignItems="center"
              backgroundColor="surfaceSuccessLowest"
              onClick={() => setVisitedAddParty(true)}
            >
              <Box>
                <ExcelVectorIcon size="8" />
              </Box>
              <Inline flex="1" alignItems="center" gap="3">
                <Text color="textCashIn" fontSize="s4">
                  Import all {partiesOrContacts.toLowerCase()} in bulk via CSV
                </Text>
                {!visitedAddParty ? (
                  <Box
                    paddingY="1"
                    paddingX="2"
                    backgroundColor="surfaceSuccess"
                    rounded="md"
                  >
                    <Text fontSize="c2" color="textOnSurface">
                      New
                    </Text>
                  </Box>
                ) : null}
              </Inline>
              {visitedAddParty ? (
                <ArrowRightIcon color="iconSuccess" />
              ) : (
                <Box paddingRight="3">
                  <ArrowAnimation />
                </Box>
              )}
            </Inline>
          ) : null}

          <ModalBody>
            <FormField
              type="text"
              name="name"
              label={`${partyOrContact} Name`}
              placeholder={"e.g. Rajesh, Vivek, Saif, John"}
              required
              autoFocus
            />
            <Stack gap="3">
              <Inline as="label" htmlFor="phoneNumber">
                Mobile Number <Text color="gray500"> (Optional)</Text>
              </Inline>
              <FormField
                name="phoneNumber"
                renderInput={({
                  field: { onChange, ...otherFieldProps },
                  form,
                }: FieldProps<string>) => (
                  <Box className="max-w-xs">
                    <PhoneInput
                      {...otherFieldProps}
                      id="phoneNumber"
                      onChange={(phoneNumber) =>
                        form.setFieldValue(otherFieldProps.name, phoneNumber)
                      }
                      defaultCountry={defaultCountry}
                    />
                  </Box>
                )}
              />
            </Stack>
            <Stack gap="3">
              <Box>{partyOrContact} Type</Box>
              <Inline gap="2">
                {getAllPartyTypes().map(({ id, title }) => {
                  return (
                    <Box
                      as="label"
                      key={id}
                      display="inlineBlock"
                      paddingX={{ xs: "2", sm: "4" }}
                      paddingY="2"
                      rounded="full"
                      cursor="pointer"
                      borderWidth="1"
                      borderColor={"gray100"}
                      bgColor={state.type !== id ? undefined : "blue100"}
                      color={state.type === id ? "blue900" : undefined}
                    >
                      <FormField
                        label={
                          <Text as="span" fontWeight="medium" fontSize="base">
                            {title}
                          </Text>
                        }
                        type="radio"
                        name={"type"}
                        value={id}
                        id={`type.${id}`}
                        noMargin
                        invisibleInput
                        hideError
                        onClick={() => setState({ ...state, type: id })}
                      />
                    </Box>
                  )
                })}
              </Inline>
              {status ? <Alert status="error">{status}</Alert> : null}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" disabled={isSubmitting} size="lg">
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

function EditPartyFieldForm({
  party,
  book,
  onSubmit,
  onSuccess,
}: {
  party: TBookEntryFields[number]
  book: TBook
  onSubmit: (data: {
    partyId: string
    name: string
    type?: T_AVAILABLE_PARTY_TYPES
    phoneNumber?: string
    taggedEntries: number
  }) => Promise<TBookEntryFields[number]>
  onSuccess?: (field: TBookEntryFields[number]) => void
  actionOrigin?:
    | "entryFieldSettings"
    | "chooseParty"
    | "chooseCategory"
    | "choosePaymentMode"
}) {
  type TState = {
    initialName?: string
    phoneNumber?: string
    type?: T_AVAILABLE_PARTY_TYPES
    confirmationStatus: "waiting" | "confirmed"
  }
  const [state, setState] = useReducer(
    (state: TState, action: Optional<TState>) => ({ ...state, ...action }),
    {
      initialName: party?.name || "",
      phoneNumber: party?.phoneNumber || "",
      type: party?.type || "customer",
      confirmationStatus: "waiting",
    }
  )
  const taggedEntriesCount = useEntriesTaggedForField({
    book,
    field: party,
    fieldKey: "parties",
  })
  const { data: user } = useUser()
  const { phoneNumber: myPhoneNumber } = user || { phoneNumber: "" }
  const defaultCountry = useMemo(
    () =>
      (myPhoneNumber ? parsePhoneNumber(myPhoneNumber)?.country : "IN") || "IN",
    [myPhoneNumber]
  )
  const { partyOrContact } = usePartyOrContact()
  return (
    <Formik
      initialValues={{
        name: state.initialName || "",
        phoneNumber: state.phoneNumber || "",
        type: state.type,
      }}
      validationSchema={updatePartyValidationSchema}
      validateOnBlur={false}
      onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
        const field = await onSubmit({
          partyId: party.uuid,
          name: values.name.trim(),
          type: values.type,
          phoneNumber: values.phoneNumber,
          taggedEntries: taggedEntriesCount,
        })
        toast.success(`"${values.name}" ${partyOrContact} updated.`)
        onSuccess?.(field)
      })}
    >
      {({ status, isSubmitting, values }) => (
        <Form noValidate>
          {state.confirmationStatus === "waiting" ? (
            <ModalBody>
              <FormField
                type="text"
                name="name"
                label={`${partyOrContact} Name`}
                placeholder={"e.g. Rajesh, Vivek, Saif, John"}
                required
                autoFocus
              />
              <Stack gap="3">
                <Inline as="label" htmlFor="phoneNumber">
                  Mobile Number <Text color="gray500"> (Optional)</Text>
                </Inline>
                <FormField
                  name="phoneNumber"
                  renderInput={({
                    field: { onChange, ...otherFieldProps },
                    form,
                  }: FieldProps<string>) => (
                    <Box className="max-w-xs">
                      <PhoneInput
                        {...otherFieldProps}
                        id="phoneNumber"
                        onChange={(phoneNumber) =>
                          form.setFieldValue(otherFieldProps.name, phoneNumber)
                        }
                        defaultCountry={defaultCountry}
                      />
                    </Box>
                  )}
                />
              </Stack>
              <Stack gap="3">
                <Box>{partyOrContact} Type</Box>
                <Inline gap="2">
                  {getAllPartyTypes().map(({ id, title }) => {
                    return (
                      <Box
                        as="label"
                        key={id}
                        display="inlineBlock"
                        paddingX={{ xs: "4", sm: "4" }}
                        paddingY="2"
                        rounded="full"
                        cursor="pointer"
                        borderWidth="1"
                        borderColor={"gray100"}
                        bgColor={values.type !== id ? undefined : "blue100"}
                        color={values.type === id ? "blue900" : undefined}
                      >
                        <FormField
                          label={
                            <Text as="span" fontWeight="medium" fontSize="base">
                              {title}
                            </Text>
                          }
                          type="radio"
                          name={"type"}
                          value={id}
                          id={`type.${id}`}
                          noMargin
                          invisibleInput
                          hideError
                        />
                      </Box>
                    )
                  })}
                </Inline>
                {status ? <Alert status="error">{status}</Alert> : null}
              </Stack>
            </ModalBody>
          ) : (
            <ModalBody>
              {" "}
              <Stack gap="4" marginBottom="4">
                <Alert status="warning">
                  Are you sure? This cannot be undone
                </Alert>
                <TaggedEntriesInfo
                  count={taggedEntriesCount}
                  field={party}
                  fieldLabel={partyOrContact}
                />
              </Stack>
              {status ? <Alert status="error">{status}</Alert> : null}
            </ModalBody>
          )}
          {state.confirmationStatus === "waiting" ? (
            <ModalFooter>
              <Button
                type="button"
                disabled={
                  isSubmitting ||
                  !values.name.length ||
                  (values.phoneNumber &&
                    !isPossiblePhoneNumber(values.phoneNumber)) ||
                  (values.name === state.initialName &&
                    values.phoneNumber === state.phoneNumber &&
                    values.type === state.type)
                }
                size="lg"
                level="primary"
                onClick={(e) => {
                  e.preventDefault()
                  setState({ ...state, confirmationStatus: "confirmed" })
                }}
              >
                Update
              </Button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <Button type="submit" level="primary" size="lg" autoFocus>
                {isSubmitting ? "Updating..." : "Confirm"}
              </Button>
              <Button
                type="button"
                size="lg"
                onClick={() =>
                  setState({ ...state, confirmationStatus: "waiting" })
                }
              >
                Cancel
              </Button>
            </ModalFooter>
          )}
        </Form>
      )}
    </Formik>
  )
}

export function AskForPartyPhoneNumber({
  bookId,
  partyId,
  partyName,
  onClose,
  onSuccess,
}: {
  bookId: string
  partyId: string
  partyName: string
  onClose: () => void
  onSuccess: (phoneNumber: string) => void
}) {
  const updatePartyPhoneNumber = useUpdatePartyPhoneNumber(bookId)
  const { data: user } = useUser()
  const { phoneNumber: myPhoneNumber } = user || { phoneNumber: "" }
  const defaultCountry = useMemo(
    () =>
      (myPhoneNumber ? parsePhoneNumber(myPhoneNumber)?.country : "IN") || "IN",
    [myPhoneNumber]
  )
  return (
    <Modal
      title={`Enter mobile number for ${partyName}`}
      isOpen
      size="sm"
      onClose={onClose}
    >
      <ModalBody>
        <Stack gap="3">
          <Heading as="h5">
            We need mobile number of {partyName} to share this entry with them
            via Free SMS
          </Heading>
          <Formik
            initialValues={{
              phoneNumber: "",
            }}
            validationSchema={Validator.object().shape({
              phoneNumber: PhoneNumberValidator.required(),
            })}
            validateOnBlur={false}
            onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
              await updatePartyPhoneNumber({
                partyId: partyId,
                name: partyName,
                phoneNumber: values.phoneNumber,
              })
              toast.success(`Mobile number added.`)
              onClose()
              onSuccess(values.phoneNumber)
            })}
          >
            {({ status, isSubmitting }) => (
              <Form noValidate>
                <FormField
                  name="phoneNumber"
                  renderInput={({
                    field: { onChange, ...otherFieldProps },
                    form,
                  }: FieldProps<string>) => (
                    <Box className="max-w-xs">
                      <PhoneInput
                        {...otherFieldProps}
                        id="phoneNumber"
                        onChange={(phoneNumber) =>
                          form.setFieldValue(otherFieldProps.name, phoneNumber)
                        }
                        defaultCountry={defaultCountry}
                      />
                    </Box>
                  )}
                />
                {status ? <Alert status="error">{status}</Alert> : null}
                <ModalFooter>
                  <Button type="submit" level="primary" size="lg" autoFocus>
                    {isSubmitting ? "Updating..." : "Update"}
                  </Button>
                  <Button type="button" size="lg" onClick={() => onClose()}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Stack>
      </ModalBody>
    </Modal>
  )
}

type ENTRY_FIELDS = Array<{
  to: "parties" | "categories" | "payment-modes" | "custom-fields"
  label: string
  description: string
  disabled?: boolean
}>

export const getAllEntryFields = (book: TBook, partyOrContact: string) => {
  return {
    entryFields: [
      {
        to: "parties",
        label: `${partyOrContact} field`,
        description: "Rename, delete, reorder, add new or hide",
        disabled: book?.preferences?.partyDisabled,
      },
      {
        to: "categories",
        label: "Category field",
        description: "Rename, delete, reorder, add new or hide",
        disabled: book.preferences?.categoriesDisabled,
      },
      {
        to: "payment-modes",
        label: "Payment Mode field",
        description: "Rename, delete, reorder, add new or hide",
        disabled: book.preferences?.paymentModesDisabled,
      },
      {
        to: "custom-fields",
        label: "Custom fields",
        description: "Edit, delete and add new",
        disabled: false,
      },
    ] as ENTRY_FIELDS,
    trackEntryFieldSettingEvent: (
      setting: "parties" | "categories" | "payment-modes" | "custom-fields",
      from: "entryFieldSettings" | "filters"
    ) => {
      switch (setting) {
        case "parties":
          trackEvent(TrackingEvents.PARTY_SETTINGS_SCREEN_VIEWED, {
            from,
            partyCount: book.parties?.length || 0,
            sharedBook: isSharedBook(book),
          })
          break
        case "categories":
          trackEvent(TrackingEvents.CATEGORY_SETTINGS_SCREEN_VIEWED, {
            from,
          })
          break
        case "payment-modes":
          trackEvent(TrackingEvents.PAYMENT_MODE_SETTINGS_SCREEN_VIEWED, {
            from,
          })
          break
        default:
          return
      }
    },
  }
}

const ArrowAnimation = () => {
  return (
    <div className="flex items-center">
      <div className="w-6 h-6 animate-arrow ml-2">
        <svg
          width="37"
          height="16"
          viewBox="0 0 37 16"
          fill="none"
          className="animate-pulse text-blue-500"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M2.08088 0L0.200928 1.88L6.30745 8L0.200928 14.12L2.08088 16L10.0807 8L2.08088 0Z"
            fill="#127F41"
          />
          <path
            opacity="0.4"
            d="M11.9606 0L10.0807 1.88L16.1872 8L10.0807 14.12L11.9606 16L19.9604 8L11.9606 0Z"
            fill="#127F41"
          />
          <path
            d="M21.8404 0L19.9604 1.88L26.067 8L19.9604 14.12L21.8404 16L29.8402 8L21.8404 0Z"
            fill="#127F41"
          />
        </svg>
      </div>
    </div>
  )
}
