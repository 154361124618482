import {
  DataLoadingFallback,
  PageMeta,
  Text,
  Heading,
  Box,
  Stack,
  Inline,
} from "@cashbook/web-components"
import { Link, useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import { useBook, usePartyOrContact } from "@cashbook/data-store/books"
import ErrorBoundary from "../ErrorBoundary"
import { getAllEntryFields } from "../Books/EntryFields"
import { useMemo } from "react"
import { useSyncedStorageState } from "@cashbook/data-store/storage"
import { Tag } from "../common"
import { useProfile } from "@cashbook/data-store/users"

export default function BookFieldSettingsPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_book_details"
      >
        <BookFieldSettings
          key={bookId}
          bookId={bookId}
          businessId={businessId}
        />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

const from = "entryFieldSettings"

function BookFieldSettings({
  bookId,
  businessId,
}: {
  bookId: string
  businessId: string
}) {
  const { book } = useBook(bookId)
  const { partyOrContact } = usePartyOrContact()
  const { user } = useProfile()
  const { entryFields, trackEntryFieldSettingEvent } = getAllEntryFields(
    book,
    partyOrContact
  )
  // The book has been deleted (but sub-collections are not!!)
  // https://firebase.google.com/docs/firestore/manage-data/delete-data#delete_documents

  const [newFeaturesIntroduced, setNewFeaturesIntroduced] =
    useSyncedStorageState<{ [key: string]: string[] }>("newFeatures", {})

  const showNewBannerForCustomFields: string | undefined = useMemo(() => {
    return !newFeaturesIntroduced[user.uid]?.includes("custom-fields")
      ? "custom-fields"
      : undefined
  }, [newFeaturesIntroduced, user.uid])

  if (!book.name) return null
  return (
    <>
      <PageMeta>
        <title>Fields - {book.name}</title>
      </PageMeta>
      <Box
        key={bookId}
        bgColor="white"
        paddingY="6"
        paddingX={{ xs: "4", md: "8" }}
        minHeight={{ xs: "screen", sm: "0" }}
      >
        <Box maxWidth="xl">
          <Stack gap="6">
            <Heading fontSize="sm" fontWeight="medium" color="gray500">
              Entry Field
            </Heading>
            <Stack gap="6" as="ul">
              {entryFields.map(({ to, label, description, disabled }) => (
                <Box as="li" key={label}>
                  <Box
                    as={Link}
                    to={`/businesses/${businessId}/cashbooks/${book.id}/settings/fields/${to}`}
                    display="block"
                    padding="6"
                    rounded="md"
                    borderWidth="1"
                    borderColor="gray100"
                    bgColor={{ hover: "blue50" }}
                    onClick={() => {
                      trackEntryFieldSettingEvent(to, from)
                      if (
                        showNewBannerForCustomFields &&
                        showNewBannerForCustomFields === to
                      ) {
                        setNewFeaturesIntroduced({
                          ...newFeaturesIntroduced,
                          [`${user.uid}`]: [
                            ...(newFeaturesIntroduced[user.uid] ?? []),
                            to,
                          ],
                        })
                      }
                    }}
                  >
                    <Inline alignItems="center" gap="8">
                      <Stack gap="2" flex="1">
                        <Inline alignItems="center" gap="3">
                          <Heading as="h3" fontSize="md" fontWeight="semibold">
                            {label}
                          </Heading>
                          {showNewBannerForCustomFields === to ? <Tag /> : null}
                        </Inline>
                        <Text fontWeight="medium" fontSize="sm" color="gray500">
                          {description}
                        </Text>
                      </Stack>
                      {to === "custom-fields" ? null : (
                        <Box
                          display="inlineBlock"
                          bgColor={disabled ? "gray100" : "blue100"}
                          paddingX="3"
                          paddingY="1"
                          rounded="md"
                        >
                          <Text
                            textTransform="uppercase"
                            color={disabled ? "gray900" : "blue900"}
                            fontWeight="medium"
                            fontSize="sm"
                          >
                            {disabled ? "OFF" : "ON"}
                          </Text>
                        </Box>
                      )}
                    </Inline>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Box>
      </Box>
    </>
  )
}
