import {
  getRoleDetails,
  getRoleDetailsForMember,
  TBook,
  T_AVAILABLE_ROLES,
  useBusinessBooksSearch,
} from "@cashbook/data-store/books"
import {
  getAllBusinessRolesWithPermissions,
  getBusinessRoleDetails,
  OperationalBookRoles,
  SharedBooksForAddToBooks,
  TBusiness,
  TBusinessInvitation,
  TBusinessMember,
  T_AVAILABLE_BUSINESS_ROLES,
  useAddTeamMember,
  useBusiness,
  useShareBusinessInvitations,
  useUpdateBusinessUser,
} from "@cashbook/data-store/businesses"
import {
  arePhoneNumbersSame,
  EmailValidator,
  isPhoneNumberIndian,
  isVisitorIndian,
  PhoneNumberValidator,
  pluralize,
} from "@cashbook/util-general"
import {
  Alert,
  ArrowDownIcon,
  Box,
  Button,
  CancelIcon,
  CheckIcon,
  FormField,
  formikOnSubmitWithErrorHandling,
  Inline,
  Modal,
  ModalBody,
  ModalFooter,
  SearchIcon,
  SearchSelect,
  SpinnerIcon,
  Stack,
  Text,
  Circle,
  useOverlayTriggerState,
  BookIcon,
  Heading,
  PhoneInput,
  parsePhoneNumber,
  InformationCircleIcon,
  CheckCircleSolidIcon,
  CancelFilledIcon,
  PlusIcon,
  LinkIcon,
  CopyIcon,
  SecurityCheckIcon,
  CopyToClipboard,
  AdminVectorIcon,
  DataOperatorIcon,
  ViewerIcon,
  ArrowUpIcon,
  ModalFooterActions,
  BankGradientIcon,
  GearFilledIcon,
  AddMoneyIcon,
  ScanIcon,
  WalletInIcon,
  UnHideIcon,
  InformationWarningIcon,
  WalletWithCloseIcon,
  BookWithCloseIcon,
} from "@cashbook/web-components"
import { FieldProps, Form, Formik, useField } from "formik"
import React, { useEffect, useMemo, useReducer, useState } from "react"
import toast from "react-hot-toast"
import { SuspenseWithPerf, useUser } from "reactfire"
import {
  AllRolesAndPermissionsView,
  MemberAvatar,
  PermissionsAndRestrictionsBox,
  SelectRolesForStaff,
  ShareInvitationActions,
} from "../Books"
import * as Validator from "yup"
import NoBooksFound from "./NoBooks.png"
import config from "../config"
import { useCheckUsersExists } from "@cashbook/data-store/users"
import { isPossiblePhoneNumber } from "react-phone-number-input"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import { useSyncedStorageState } from "@cashbook/data-store/storage"
import { useSearchParams } from "react-router-dom"

//Business Roles Chip Color management service
type Colors = React.ComponentProps<typeof Box>["color"]
type BorderColors = React.ComponentProps<typeof Box>["borderColor"]
type BackgroundColors = React.ComponentProps<typeof Box>["backgroundColor"]
type BusinessRolesColorCodes = {
  [key in T_AVAILABLE_BUSINESS_ROLES]: {
    color: Colors
    borderColor: BorderColors
    backgroundColor: BackgroundColors
  }
}

export const businessRolesColorCodes: BusinessRolesColorCodes = {
  staff: {
    color: "paymentMode",
    borderColor: "paymentMode",
    backgroundColor: "paymentModeBg",
  },
  partner: {
    color: "orange900",
    borderColor: "orange200",
    backgroundColor: "orange100",
  },
  owner: {
    color: "green900",
    borderColor: "green500",
    backgroundColor: "green100",
  },
}

export function BusinessRoleDetails({
  role,
}: {
  role: ReturnType<typeof getBusinessRoleDetails>
}) {
  return (
    <Box
      display="inlineBlock"
      paddingX="2"
      paddingY="1"
      borderWidth="1"
      rounded="md"
      alignSelf="center"
      bgColor={businessRolesColorCodes[role.id].backgroundColor}
      color={businessRolesColorCodes[role.id].color}
    >
      <Text fontSize="sm" fontWeight="semibold">
        {role.title}
      </Text>
    </Box>
  )
}

export function AddAndAssignRoleInBooksInModal({
  title,
  children,
  onSuccess,
  ...props
}: React.ComponentProps<typeof AddAndAssignRoleInBooks> & {
  title?: string
  children: (props: { onAddClick: () => void }) => React.ReactNode
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  let action: "addToBooks" | undefined = undefined
  if (searchParams.get("action") || "") {
    action = searchParams.get("action") as never as "addToBooks"
  }

  const state = useOverlayTriggerState({})

  const { teamMember } = props
  const [staffInfoTransitioned, setStaffInfoTransitioned] =
    useSyncedStorageState<boolean>("staffInfoTransitioned", false)

  useEffect(() => {
    if (action === "addToBooks" && !state.isOpen) {
      state.open()
    }
  }, [action, state])

  function onCloseModal() {
    state.close()
    setSearchParams("")
  }

  return (
    <>
      {children({ onAddClick: () => state.open() })}
      <Modal
        isOpen={state.isOpen}
        onClose={onCloseModal}
        placement="right"
        title={
          !staffInfoTransitioned
            ? "Staff Info"
            : `Add ${teamMember.name} to Books`
        }
        isDismissable
      >
        <SuspenseWithPerf
          traceId="loading_business_details"
          fallback={
            <div className="text-center py-16">
              <SpinnerIcon />
            </div>
          }
        >
          {!staffInfoTransitioned ? (
            <StaffInfo onNextClick={() => setStaffInfoTransitioned(true)} />
          ) : (
            <AddAndAssignRoleInBooks
              {...props}
              onSuccess={() => {
                onCloseModal()
                onSuccess?.()
              }}
              onClose={onCloseModal}
            />
          )}
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

type BOOK_LEVEL_ROLES_FOR_BOOK_ID = {
  [key: string]: "admin" | "editor" | "viewer"
}

type TeamMemberToOperate = {
  name: string
  userId: string
  email?: string
  phoneNumber?: string
  isInvited?: boolean
  role?: T_AVAILABLE_BUSINESS_ROLES
  sharedBooks?: Array<string>
  sharedBooksWithRole?: Array<{
    id: string
    role: "admin" | "editor" | "viewer"
  }>
}

function AddAndAssignRoleInBooks({
  businessId,
  teamMember,
  onClose,
  onSuccess,
}: {
  businessId: string
  teamMember: TeamMemberToOperate
  onSuccess?: () => void
  onClose?: () => void
}) {
  const {
    allBooks,
    books: filteredBooks,
    handleParamsChange,
    setParamValue,
    params,
  } = useBusinessBooksSearch(businessId)
  const [showAllRolesAndPermissions, setShowAllRolesAndPermissions] =
    useState<boolean>(false)
  const updateBusinessUser = useUpdateBusinessUser("add_books")
  if (allBooks.length < 1) {
    return (
      <>
        <ModalBody>
          <Stack
            gap="6"
            className="flex items-center justify-center"
            justifyContent="center"
          >
            <Circle size="16">
              <BookIcon size="6" />
            </Circle>
            <Stack textAlign="center" paddingX="6" gap="2">
              <Heading as="h1" fontSize="lg" className="">
                No books found in this business!
              </Heading>
              <Text color="gray500">
                You can add books from Cashbooks dashboard and then add team
                members
              </Text>
            </Stack>
            <Box width="full" paddingTop="4">
              <img src={NoBooksFound} alt="Add New Book" />
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button size="lg" level="primary" onClick={onClose}>
            Ok, Got it
          </Button>
        </ModalFooter>
      </>
    )
  }
  return (
    <Formik
      initialValues={{
        books: [] as Array<string>,
        defaultRole: "editor" as T_AVAILABLE_ROLES,
        bookRole: {} as BOOK_LEVEL_ROLES_FOR_BOOK_ID,
      }}
      onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
        const sharedBooks: SharedBooksForAddToBooks = values.books.map(
          (bookId) => {
            if (values.bookRole[bookId]) {
              return { id: bookId, role: values.bookRole[bookId] }
            }
            return { id: bookId, role: "editor" }
          }
        )
        await updateBusinessUser({
          userId: teamMember.userId,
          businessId: businessId,
          email: teamMember?.email,
          phoneNumber: teamMember?.phoneNumber,
          sharedBooks,
        })
        trackEvent(TrackingEvents.ADDED_MEMBER_TO_MULTIPLE_BOOKS, {
          numOfBooksAddedIn: sharedBooks.length,
          isInvited: !teamMember.userId,
        })
        toast.success(
          `${teamMember.name} as been added to ${
            values.books.length
          } ${pluralize("book", values.books.length)}`
        )
        onSuccess?.()
      })}
    >
      {({ isSubmitting, status, setValues, setFieldValue, values }) => (
        <Form noValidate>
          <ModalBody>
            <Stack gap="4">
              <div className="flex relative bg-opacity-20 rounded focus-within:border-blue-900 focus-within:ring-1 ring-blue-900 h-10 pr-2 items-stretch gap-2 max-w-lg w-full border">
                <input
                  type="search"
                  name="q"
                  placeholder="Search by book name..."
                  value={params.q}
                  onChange={handleParamsChange}
                  className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
                />
                <button
                  type="button"
                  className="flex items-center justify-center text-gray-500"
                  onClick={() => setParamValue("q", params.q ? "" : params.q)}
                >
                  {params.q ? <CancelIcon /> : <SearchIcon />}
                </button>
              </div>
              <Stack gap="4">
                <Stack
                  onClick={() =>
                    setShowAllRolesAndPermissions((prevVal) => !prevVal)
                  }
                  rounded="md"
                  paddingY="2"
                  cursor="pointer"
                  borderWidth="1"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Inline alignItems="center" gap="2">
                    <Text fontWeight="semibold" color="gray500">
                      Roles & Permissions in books
                    </Text>
                    <Box>
                      {showAllRolesAndPermissions ? (
                        <ArrowUpIcon color="gray900" size="6" />
                      ) : (
                        <ArrowDownIcon color="gray900" size="6" />
                      )}
                    </Box>
                  </Inline>
                </Stack>
                {showAllRolesAndPermissions ? (
                  <AllRolesAndPermissionsView
                    defaultRole={values.defaultRole}
                    exceptRoles={["owner", "partner"]}
                  />
                ) : null}
              </Stack>
              <Stack gap="4" paddingTop="6" as="ul">
                {!filteredBooks.length ? (
                  <Box as="li">
                    <Text color="gray500">
                      No books for this search. Please try a different search.
                    </Text>
                  </Box>
                ) : (
                  filteredBooks.map((book) => {
                    const isChecked = values.books.some(
                      (bId) => bId === book.id
                    )
                    const isDisabled = teamMember.userId
                      ? book.sharedWith.includes(teamMember.userId)
                      : teamMember.sharedBooks?.length
                      ? teamMember.sharedBooks.includes(book.id)
                      : false
                    const sharedBookRole = teamMember.sharedBooksWithRole?.find(
                      (sharedBook) => sharedBook.id === book.id
                    )?.role
                    return (
                      <BookViewForAddingStaff
                        key={book.id}
                        book={book}
                        isChecked={isChecked}
                        isDisabled={isDisabled}
                        teamMember={teamMember}
                        selectRoleField={{
                          label:
                            values.bookRole[book.id] === "viewer" ||
                            values.defaultRole === "viewer"
                              ? "Viewer"
                              : values.bookRole[book.id] === "admin" ||
                                values.defaultRole === "admin"
                              ? "Admin"
                              : "Data Operator",
                          value:
                            `${values.bookRole[book.id]}` || values.defaultRole,
                          onChange: (optionId: string) =>
                            setFieldValue("bookRole", {
                              ...values.bookRole,
                              [book.id]: optionId,
                            }),
                        }}
                        sharedBookRole={sharedBookRole}
                        onChange={() => {
                          isChecked
                            ? setValues({
                                ...values,
                                books: values.books.filter(
                                  (bId) => bId !== book.id
                                ),
                              })
                            : setValues({
                                ...values,
                                books: [...values.books, book.id],
                                bookRole: {
                                  ...values.bookRole,
                                  [book.id]:
                                    values.bookRole[book.id] || "editor",
                                },
                              })
                        }}
                      />
                    )
                  })
                )}
              </Stack>
            </Stack>
            {status ? <Alert status="error">{status}</Alert> : null}
          </ModalBody>
          <ModalFooter>
            <Button
              level="primary"
              type="submit"
              disabled={!values.books.length}
            >
              {isSubmitting ? (
                "Adding..."
              ) : (
                <Inline alignItems="center">
                  <CheckIcon />
                  Add in {values.books.length} books
                </Inline>
              )}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

const SELECT_ROLE_FROM = [
  {
    id: "admin",
    label: "Admin",
  },
  {
    id: "editor",
    label: "Data Operator",
  },
  {
    id: "viewer",
    label: "Viewer",
  },
]

function BookViewForAddingStaff({
  book,
  isChecked,
  isDisabled,
  teamMember,
  sharedBookRole,
  selectRoleField,
  onChange,
}: {
  book: TBook
  isChecked: boolean
  isDisabled: boolean
  teamMember: TeamMemberToOperate
  sharedBookRole?: OperationalBookRoles
  selectRoleField: {
    label: string
    value: OperationalBookRoles
    onChange: (optionId: string) => void
  }
  onChange: () => void
}) {
  const role = teamMember.userId.length
    ? getRoleDetailsForMember(book, teamMember.userId)
    : getRoleDetails(sharedBookRole || "editor")
  return (
    <Box as="li" paddingBottom="4" borderBottomWidth="1" borderColor="gray100">
      <FormField
        name="book"
        type="checkbox"
        id={`book_${book.id}`}
        value={book.id}
        noMargin
        style={{
          borderWidth: 2,
          height: 18,
          width: 18,
          borderColor: isChecked ? "#4E62CD" : "#757575",
        }}
        onChange={onChange}
        checked={isChecked}
        disabled={isDisabled}
        label={
          <Stack gap="2" marginLeft="2" opacity={isDisabled ? "50" : "100"}>
            <Text fontSize="md" fontWeight="semibold">
              {book.name}
            </Text>
            <Inline alignItems="center" gap={isDisabled ? "1" : "2"}>
              <Text color="gray500" fontWeight="medium">
                {isDisabled ? "Already added as" : "Role:"}
              </Text>
              {isDisabled ? (
                <Text>{role.title}</Text>
              ) : (
                <Inline alignItems="center" gap="2">
                  <SearchSelect
                    searchDisabled
                    removeActionButtons
                    hasValue={Boolean(selectRoleField.value)}
                    label={selectRoleField.label}
                    onChange={(option) =>
                      selectRoleField.onChange(option?.id || "")
                    }
                    options={SELECT_ROLE_FROM}
                    value={selectRoleField.value}
                  />
                </Inline>
              )}
            </Inline>
          </Stack>
        }
      />
    </Box>
  )
}

function StaffInfo({ onNextClick }: { onNextClick: () => void }) {
  return (
    <>
      <ModalBody className="relative">
        <Stack gap="8">
          <Stack gap="2">
            <Heading as="h1" fontSize="xl">
              Roles of staff members in books
            </Heading>
            <Text color="gray500">
              Give them limited access to books of your choice
            </Text>
          </Stack>
          <Box backgroundColor="gray100" rounded="full" height="1" width="10" />
          <Inline alignItems="center" gap="4">
            <AdminVectorIcon size="16" />
            <Stack gap="2">
              <Text fontWeight="semibold">Admin</Text>
              <Text color="gray500">
                Full access to entries & book settings
              </Text>
            </Stack>
          </Inline>
          <Inline alignItems="center" gap="4">
            <DataOperatorIcon size="16" />
            <Stack gap="2">
              <Text fontWeight="semibold">Data Operator</Text>
              <Text color="gray500">Only add entry access</Text>
            </Stack>
          </Inline>
          <Inline alignItems="center" gap="4">
            <ViewerIcon size="16" />
            <Stack gap="2">
              <Text fontWeight="semibold">Viewer</Text>
              <Text color="gray500">Only view entries & reports access</Text>
            </Stack>
          </Inline>
        </Stack>
        <Inline
          backgroundColor="gray100"
          position="absolute"
          className="bottom-0"
          width="full"
          left="0"
          paddingY="3"
          paddingX="8"
          gap="1"
        >
          <Text fontSize="sm" color="gray500">
            Next Step:{" "}
          </Text>
          <Text fontSize="sm" fontWeight="semibold">
            Select books
          </Text>
        </Inline>
      </ModalBody>
      <ModalFooter>
        <Button size="lg" level="primary" onClick={onNextClick}>
          Next
        </Button>
      </ModalFooter>
    </>
  )
}

export function RemoveTeamMemberFromBookInModal({
  children,
  ...props
}: React.ComponentProps<typeof RemoveTeamMemberFromBook> & {
  children: (props: { onRemoveClick: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const { teamMember } = props
  return (
    <>
      {children({ onRemoveClick: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title={`Remove ${teamMember.name} from ${props.book.name}?`}
        size="sm"
      >
        <SuspenseWithPerf
          traceId="loading_business_details"
          fallback={
            <div className="text-center py-16">
              <SpinnerIcon />
            </div>
          }
        >
          <RemoveTeamMemberFromBook
            {...props}
            onSuccess={state.close}
            onCancel={state.close}
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

function RemoveTeamMemberFromBook({
  book,
  teamMember,
  onCancel,
  onSuccess,
}: {
  book: TBook
  teamMember: TeamMemberToOperate
  onCancel?: () => void
  onSuccess?: () => void
}) {
  const updateBusinessUser = useUpdateBusinessUser("remove_book")
  const removeMemberPointers = useMemo(() => {
    return [
      `${teamMember.name} will lose access to this book`,
      `We will also notify ${teamMember.name} that they have been
        removed from this book.`,
    ]
  }, [teamMember.name])
  return (
    <Formik
      initialValues={{}}
      onSubmit={formikOnSubmitWithErrorHandling(async () => {
        const data = await updateBusinessUser({
          userId: teamMember.userId,
          email: teamMember?.email,
          phoneNumber: teamMember?.phoneNumber,
          bookId: book.id,
        })
        if (data) {
          toast.success(`${teamMember.name} removed from ${book.name} book.`)
          onSuccess?.()
        }
      })}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <ModalBody>
            <Stack gap="4">
              <Text>Are you sure ?</Text>
              {removeMemberPointers.map((information) => (
                <Inline gap="4" key={information} alignItems="center">
                  <Circle size="2" backgroundColor="gray400" />
                  <Text>{information}</Text>
                </Inline>
              ))}
              <Alert status="info" marginTop="5">
                <Text fontSize="sm">
                  {teamMember.name} will still be a part of your business
                </Text>
              </Alert>
              {status ? <Alert status="error">{status}</Alert> : null}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              disabled={isSubmitting}
              size="lg"
              level="primary"
            >
              {isSubmitting ? `Removing ${teamMember.name}...` : "Remove"}
            </Button>
            <Button size="lg" onClick={onCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

export function EditTeamMemberRoleInBookInModal({
  children,
  ...props
}: React.ComponentProps<typeof EditTeamMemberRoleInBook> & {
  children: (props: { onEditClick: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const { teamMember } = props
  return (
    <>
      {children({ onEditClick: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title={`Change ${teamMember.name}'s Role`}
        placement="right"
        isDismissable
      >
        <SuspenseWithPerf
          traceId="loading_business_details"
          fallback={
            <div className="text-center py-16">
              <SpinnerIcon />
            </div>
          }
        >
          <EditTeamMemberRoleInBook {...props} onSuccess={state.close} />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

function EditTeamMemberRoleInBook({
  book,
  bookRole,
  teamMember,
  onSuccess,
}: {
  book: TBook
  bookRole: T_AVAILABLE_ROLES
  teamMember: TeamMemberToOperate
  onSuccess?: () => void
}) {
  const memberBookLevelRole = getRoleDetails(bookRole)
  const updateBusinessUser = useUpdateBusinessUser("book_role_change")
  return (
    <Formik
      initialValues={{
        fromRole: memberBookLevelRole.id,
        toRole: memberBookLevelRole.id as OperationalBookRoles,
      }}
      validationSchema={Validator.object().shape({
        fromRole: Validator.string(),
        toRole: Validator.string()
          .required()
          .when(
            "fromRole",
            (fromRole: string, schema: Validator.StringSchema) => {
              if (!fromRole) return schema
              return schema.test(
                "different-then-old",
                "Please select a new role to update.",
                (newRole) => fromRole !== newRole
              )
            }
          ),
      })}
      onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
        const data = await updateBusinessUser({
          userId: teamMember.userId,
          email: teamMember?.email,
          phoneNumber: teamMember?.phoneNumber,
          bookId: book.id,
          role: values.toRole,
        })
        if (data) {
          toast.success(
            `${teamMember.name} has been changed to ${
              getRoleDetails(values.toRole).title
            } in ${book.name} book`
          )
          onSuccess?.()
        }
      })}
    >
      {({ isSubmitting, status, values, submitForm }) => (
        <Form>
          <ModalBody className="relative">
            <Inline
              paddingX="8"
              paddingY="3"
              position="absolute"
              top="0"
              left="0"
              width="full"
              alignItems="center"
              gap="1"
              backgroundColor="gray100"
            >
              <Text color="gray500" fontSize="sm">
                Book:
              </Text>
              <Text fontSize="sm" fontWeight="semibold">
                {book.name}
              </Text>
            </Inline>
            <Box paddingY="8">
              <SelectRolesForStaff
                fieldName="toRole"
                hideNetBalance={book.preferences?.hideBalancesAndReports}
                hideEntriesByOthers={book.preferences?.hideEntriesByOthers}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <ConfirmUpdateRoleInBookInModal
              bookName={book.name}
              errorMessage={status}
              isLoading={isSubmitting}
              memberName={teamMember.name}
              role={getRoleDetails(values.toRole).title}
              onSubmit={submitForm}
            >
              {({ onClick }) => (
                <Button
                  type="button"
                  disabled={
                    isSubmitting ||
                    !values.toRole.length ||
                    values.fromRole === values.toRole
                  }
                  size="lg"
                  level="primary"
                  onClick={() => {
                    values.toRole === "admin" ? onClick() : submitForm()
                  }}
                >
                  Update
                </Button>
              )}
            </ConfirmUpdateRoleInBookInModal>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

function ConfirmUpdateRoleInBookInModal({
  bookName,
  isLoading,
  memberName,
  role,
  errorMessage,
  children,
  onSubmit,
}: {
  bookName: string
  memberName: string
  isLoading?: boolean
  errorMessage?: string
  role: string
  onSubmit: () => void
  children: (props: { onClick: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const confirmUpdateRolePointers = useMemo(() => {
    return [
      `${memberName} will have full access to book activities`,
      "They will be able to add data operator or viewer from your team",
    ]
  }, [memberName])
  return (
    <>
      {children({ onClick: state.open })}
      <Modal
        size="sm"
        isOpen={state.isOpen}
        onClose={state.close}
        title={`Change ${memberName}’s role to ${role} in ${bookName}?`}
      >
        <ModalBody>
          <Stack gap="4">
            <Text>Are you sure ?</Text>
            {confirmUpdateRolePointers.map((information) => (
              <Inline gap="4" key={information} alignItems="center">
                <Circle size="2" backgroundColor="gray400" />
                <Text>{information}</Text>
              </Inline>
            ))}
            <Alert status="info" marginTop="5">
              <Text fontSize="sm">
                This role will only be limited to this book
              </Text>
            </Alert>
            {errorMessage ? <Alert status="error">{errorMessage}</Alert> : null}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            level="primary"
            type="submit"
            onClick={onSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Changing..." : "Change"}
          </Button>
          <Button size="lg" onClick={state.close}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export function RemoveTeamMemberFromBusinessInModal({
  walletIssued,
  children,
  onSuccess,
  ...props
}: React.ComponentProps<typeof RemoveTeamMemberFromBusiness> & {
  walletIssued?: boolean
  children: (props: { onRemoveClick: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const { teamMember } = props
  return (
    <>
      {children({ onRemoveClick: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title={`Remove ${teamMember.name}?`}
        size="sm"
        placement={walletIssued ? "right" : undefined}
      >
        <SuspenseWithPerf
          traceId="loading_business_details"
          fallback={
            <div className="text-center py-16">
              <SpinnerIcon />
            </div>
          }
        >
          <RemoveTeamMemberFromBusiness
            {...props}
            walletIssued={walletIssued}
            onCancel={state.close}
            onSuccess={() => {
              state.close()
              onSuccess?.()
            }}
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

function RemoveTeamMemberFromBusiness({
  businessId,
  teamMember,
  walletIssued,
  onCancel,
  onSuccess,
}: {
  businessId: string
  walletIssued?: boolean
  teamMember: TeamMemberToOperate
  onCancel?: () => void
  onSuccess?: () => void
}) {
  const updateBusinessUser = useUpdateBusinessUser("remove_business")
  const removeTeamMemberPointer = useMemo(() => {
    return [
      `${teamMember.name} will lose access to this business & it’s books`,
      `We will also notify ${teamMember.name} that they have been removed from this business.`,
    ]
  }, [teamMember.name])

  const removeMemberPointersForWalletIssued = useMemo(() => {
    return [
      {
        title: `Deactivate ${teamMember.role} Card`,
        subTitle: "Card money will be sent to your business bank account",
        icon: <WalletWithCloseIcon size="10" />,
      },
      {
        title: "Remove Cashbook Access",
        subTitle: `${teamMember.name} won’t be able to access your books`,
        icon: <BookWithCloseIcon size="10" />,
      },
    ]
  }, [teamMember.role, teamMember.name])

  return (
    <Formik
      initialValues={{}}
      onSubmit={formikOnSubmitWithErrorHandling(async () => {
        const data = await updateBusinessUser({
          userId: teamMember.userId,
          email: teamMember?.email,
          phoneNumber: teamMember?.phoneNumber,
          businessId: businessId,
        })
        if (teamMember.isInvited) {
          trackEvent(TrackingEvents.INVITATION_CANCELLED, {
            invitedFrom: "business",
          })
        } else {
          trackEvent(TrackingEvents.MEMBER_REMOVED, {
            from: "businessUserProfile",
          })
        }
        trackEvent(TrackingEvents.BUSINESS_TEAM_MEMBER_REMOVED, {
          memberRole: teamMember.role || "staff",
        })
        if (data) {
          toast.success(`${teamMember.name} removed from your business`)
          onSuccess?.()
        }
      })}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <ModalBody
            style={{
              background: walletIssued
                ? "linear-gradient(180deg, rgba(201, 59, 59, 0.1) 0%, rgba(201, 59, 59, 0) 100%)"
                : undefined,
            }}
          >
            {walletIssued ? (
              <Box>
                <Stack
                  paddingY="16"
                  textAlign="center"
                  alignItems="center"
                  gap="4"
                >
                  <InformationWarningIcon size="12" color="red900" />
                  <Text fontSize="lg" fontWeight="semibold">
                    If you remove {teamMember.name}, we will
                  </Text>
                </Stack>
                <Stack gap="10">
                  {removeMemberPointersForWalletIssued.map((pointer) => (
                    <Inline key={pointer.title} alignItems="center" gap="4">
                      {pointer.icon}
                      <Stack gap="2">
                        <Text fontSize="md" fontWeight="semibold">
                          {pointer.title}
                        </Text>
                        <Text color="gray500">{pointer.subTitle}</Text>
                      </Stack>
                    </Inline>
                  ))}
                </Stack>
              </Box>
            ) : (
              <Stack gap="4">
                <Text>Are you sure?</Text>
                <Stack as="ul" gap="4">
                  {removeTeamMemberPointer.map((information) => (
                    <Inline
                      as="li"
                      gap="4"
                      key={information}
                      alignItems="center"
                    >
                      <Box>
                        <Circle size="2" backgroundColor="gray400" />
                      </Box>
                      <Text>{information}</Text>
                    </Inline>
                  ))}
                </Stack>
              </Stack>
            )}
            {status ? <Alert status="error">{status}</Alert> : null}
          </ModalBody>
          <ModalFooter>
            {walletIssued ? (
              <>
                <Button
                  disabled={isSubmitting}
                  level="primary"
                  size="lg"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  size="lg"
                  disabled={isSubmitting}
                  status="error"
                  level="secondary"
                  type="submit"
                >
                  {isSubmitting ? "Removing..." : "Remove"}
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="lg"
                  disabled={isSubmitting}
                  status="error"
                  level="primary"
                  type="submit"
                >
                  {isSubmitting ? "Removing..." : "Remove"}
                </Button>
                <Button disabled={isSubmitting} size="lg" onClick={onCancel}>
                  Cancel
                </Button>
              </>
            )}
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

export function ChangeRoleToPartnerOrStaffInModal({
  children,
  onSuccess,
  businessRole,
  enabledPayments,
  ...props
}: React.ComponentProps<typeof ChangeRoleToPartnerOrStaff> & {
  enabledPayments?: boolean
  children: (props: { onChangeRoleClick: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const isStaffToPartner = businessRole === "staff"

  return (
    <>
      {children({ onChangeRoleClick: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title={
          isStaffToPartner
            ? enabledPayments
              ? `Make ${props.teamMember.name} Partner?`
              : "Change Role to Partner"
            : "Change Role to Staff"
        }
        isDismissable
        placement="right"
      >
        <SuspenseWithPerf
          traceId="loading_business_details"
          fallback={
            <div className="text-center py-16">
              <SpinnerIcon />
            </div>
          }
        >
          <ChangeRoleToPartnerOrStaff
            {...props}
            businessRole={businessRole}
            enabledPayments={enabledPayments}
            isStaffToPartner={isStaffToPartner}
            onSuccess={() => {
              state.close()
              onSuccess?.()
            }}
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

function getStaffToPartnerPointerForPaymentsEnabled(memberName: string) {
  return [
    {
      title: "Access to Business Bank A/c",
      subTitle: `Partner will get full access to ${config.appTitle} payments.`,
      subActions: {
        title: "Actions Partner can take",
        actions: [
          {
            icon: (
              <Circle size="8">
                <AddMoneyIcon />
              </Circle>
            ),
            title: "Add money to Business Bank A/c",
          },
          {
            icon: (
              <Circle size="8">
                <ScanIcon />
              </Circle>
            ),
            title: "Make payments from partner card",
          },
          {
            icon: (
              <Circle size="8">
                <WalletInIcon />
              </Circle>
            ),
            title: "Send money to member cards",
          },
          {
            icon: (
              <Circle size="8">
                <UnHideIcon color="iconPrimary" />
              </Circle>
            ),
            title: "View all transactions",
          },
        ],
      },
      icon: (
        <Box>
          <BankGradientIcon size="8" />
        </Box>
      ),
    },
    {
      title: "Access to all books",
      subTitle: `${memberName} will be added to all your books`,
      icon: (
        <Box className="text-[#578BFF]">
          <BookIcon size="8" />
        </Box>
      ),
    },
    {
      title: "Access to business settings",
      subTitle: `${memberName} will be able to add/remove members`,
      icon: (
        <Box className="text-[#578BFF]">
          <GearFilledIcon size="8" />
        </Box>
      ),
    },
  ]
}

function ChangeRoleToPartnerOrStaff({
  businessId,
  teamMember,
  businessRole,
  enabledPayments,
  isStaffToPartner,
  onSuccess,
}: {
  businessId: string
  enabledPayments?: boolean
  isStaffToPartner?: boolean
  teamMember: TeamMemberToOperate
  businessRole: T_AVAILABLE_BUSINESS_ROLES
  onSuccess?: () => void
}) {
  const updateBusinessUser = useUpdateBusinessUser("business_role_change")
  const businessRoleDetails = getBusinessRoleDetails(
    isStaffToPartner ? "partner" : "staff"
  )
  const { title, permissionsDescription, restrictionsDescription } =
    businessRoleDetails

  const pointersForPaymentsEnabled = useMemo(() => {
    return getStaffToPartnerPointerForPaymentsEnabled(teamMember.name)
  }, [teamMember?.name])

  return (
    <Formik
      initialValues={{}}
      onSubmit={formikOnSubmitWithErrorHandling(async () => {
        const data = await updateBusinessUser({
          userId: teamMember.userId,
          email: teamMember?.email,
          phoneNumber: teamMember?.phoneNumber,
          businessId: businessId,
          role: isStaffToPartner ? "partner" : "staff",
        })
        trackEvent(TrackingEvents.BUSINESS_TEAM_MEMBER_ROLE_CHANGED, {
          fromRole: businessRole,
          toRole: isStaffToPartner ? "partner" : "staff",
        })
        if (data) {
          toast.success(
            isStaffToPartner
              ? `${teamMember.name} is now Partner in this business`
              : `${teamMember.name} is now Staff in this business`
          )
          onSuccess?.()
        }
      })}
    >
      {({ isSubmitting, status, submitForm }) => (
        <Form>
          <ModalBody>
            <Stack gap="4">
              <Box borderWidth="1" padding="6" rounded="md">
                <Inline gap="4" alignItems="center">
                  <MemberAvatar id={teamMember.userId} name={teamMember.name} />
                  <Stack gap="2">
                    <Text fontSize="md" fontWeight="semibold">
                      {teamMember.name}
                    </Text>
                    {teamMember.email ? (
                      <Text color="textMedium">{teamMember.email}</Text>
                    ) : null}
                    {teamMember.phoneNumber ? (
                      <Text color="textMedium">{teamMember.phoneNumber}</Text>
                    ) : null}
                  </Stack>
                </Inline>
              </Box>
              {enabledPayments &&
              isStaffToPartner &&
              isPhoneNumberIndian(teamMember.phoneNumber) ? (
                <Box padding="6" borderWidth="1">
                  <Stack gap="8">
                    <Text fontWeight="semibold" color="gray500">
                      If you make {teamMember.name} partner, they will get
                    </Text>
                    <Stack as="ul" gap="8">
                      {pointersForPaymentsEnabled.map((pointer) => (
                        <Inline as="ul" gap="4" key={pointer.title}>
                          {pointer.icon}
                          <Stack gap="2">
                            <Text fontWeight="semibold">{pointer.title}</Text>
                            <Text fontSize="sm" color="gray500">
                              {pointer.subTitle}
                            </Text>
                            {pointer.subActions ? (
                              <Box marginTop="4">
                                <Stack as="ul" gap="4">
                                  <Text fontSize="sm" color="gray500">
                                    {pointer.subActions.title}
                                  </Text>
                                  {pointer.subActions.actions.map((pointer) => (
                                    <Inline
                                      alignItems="center"
                                      gap="4"
                                      as="li"
                                      key={pointer.title}
                                    >
                                      {pointer.icon}
                                      <Text>{pointer.title}</Text>
                                    </Inline>
                                  ))}
                                </Stack>
                              </Box>
                            ) : null}
                          </Stack>
                        </Inline>
                      ))}
                    </Stack>
                  </Stack>
                </Box>
              ) : (
                <PermissionsAndRestrictionsBox
                  roleTitle={title}
                  permissions={permissionsDescription}
                  restrictions={restrictionsDescription}
                />
              )}
            </Stack>
            {status && teamMember.isInvited ? (
              <Alert status="error">{status}</Alert>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <ConfirmChangeRoleToPartnerOrStaff
              errorMessage={status}
              isLoading={isSubmitting}
              memberName={teamMember.name}
              isStaffToPartner={Boolean(isStaffToPartner)}
              onSubmit={submitForm}
            >
              {({ onClick }) => (
                <Button
                  size="lg"
                  disabled={isSubmitting}
                  onClick={() =>
                    teamMember.isInvited ? submitForm() : onClick()
                  }
                  level="primary"
                >
                  {isStaffToPartner
                    ? "Change Role to Partner"
                    : "Change Role to Staff"}
                </Button>
              )}
            </ConfirmChangeRoleToPartnerOrStaff>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

function ConfirmChangeRoleToPartnerOrStaff({
  isLoading,
  memberName,
  errorMessage,
  isStaffToPartner,
  children,
  onSubmit,
}: {
  memberName: string
  isLoading?: boolean
  errorMessage?: string
  isStaffToPartner: boolean
  onSubmit: () => void
  children: (props: { onClick: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const pointsToBeNotes = useMemo(() => {
    return [
      isStaffToPartner
        ? `${memberName} will get access to all the books of this business`
        : `${memberName} will be made ‘Admin’ in all books`,
      isStaffToPartner
        ? `They will get full access to book & business settings`
        : `You can remove them from few books or change their role`,
    ]
  }, [isStaffToPartner, memberName])
  return (
    <>
      {children({ onClick: state.open })}
      <Modal
        size="sm"
        isOpen={state.isOpen}
        onClose={state.close}
        title={`Change ${memberName}’s Role to ${
          isStaffToPartner ? "Partner" : "Staff"
        }?`}
      >
        <ModalBody>
          <Stack gap="4">
            <Text>Are you sure ?</Text>
            {pointsToBeNotes.map((information) => (
              <Inline gap="4" key={information} alignItems="center">
                <Circle size="2" backgroundColor="gray400" />
                <Text>{information}</Text>
              </Inline>
            ))}
            {errorMessage ? <Alert status="error">{errorMessage}</Alert> : null}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            level="primary"
            type="submit"
            onClick={onSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Changing..." : "Change"}
          </Button>
          <Button size="lg" onClick={state.close}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

//Adding member in business
type StepToAddMember =
  | "ask_number"
  | "ask_email"
  | "show_member_status"
  | "show_invitation_details"

type Status =
  | "checking_user_status"
  | "check_user_status_failed"
  | "checked_user_status"

type TExtendedState = {
  email?: string
  phoneNumber?: string
  step: StepToAddMember
  guestName: string
  status?: Status
  userStatusDetails?: {
    email?: string
    phoneNumber?: string
    isAppUser: boolean
  }
  error?: string | Error | null
  invitationDetails?: TBusinessInvitation
}

type CheckUserStatusProps =
  | {
      type: "email"
      email: string
      phoneNumber?: string
      setError: (key: string, value: string) => void
    }
  | {
      type: "phone"
      email?: string
      phoneNumber: string
      setError: (key: string, value: string) => void
    }
const initialExtendedStateForAddMember: TExtendedState = {
  step: "ask_email",
  guestName: "",
  phoneNumber: "",
  email: "",
}

type TYPE_AND_PAYLOAD =
  | { type: "ASK_PHONE_NUMBER" }
  | { type: "EDIT_USER_DETAILS"; payload: "ask_number" | "ask_email" }
  | {
      type: "CHECK_USER_STATUS"
      payload: {
        name: string
        email?: string
        phoneNumber?: string
        isAppUser: boolean
      }
    }
  | { type: "CHECK_USER_STATUS_FAILED"; payload: Error }
  | { type: "CHECKING_USER_STATUS" }
  | {
      type: "CHECKED_USER_STATUS"
      payload: { email?: string; phoneNumber?: string; isAppUser: boolean }
    }
  | {
      type: "SHOW_INVITATION_DETAILS"
      payload: TBusinessInvitation
    }

const reducer = (
  state: TExtendedState,
  action: TYPE_AND_PAYLOAD
): TExtendedState => {
  switch (action.type) {
    case "ASK_PHONE_NUMBER":
      return {
        ...initialExtendedStateForAddMember,
        step: "ask_number",
        userStatusDetails: undefined,
        error: null,
      }
    case "EDIT_USER_DETAILS":
      return {
        ...state,
        step: action.payload,
        userStatusDetails: undefined,
        error: null,
      }
    case "CHECK_USER_STATUS":
      return {
        ...state,
        step: "show_member_status",
        guestName: action.payload.name,
        ...action.payload,
        userStatusDetails: {
          ...action.payload,
          isAppUser: action.payload.isAppUser,
        },
      }
    case "CHECKING_USER_STATUS":
      return {
        ...state,
        status: "checking_user_status",
        error: null,
      }
    case "CHECK_USER_STATUS_FAILED":
      return {
        ...state,
        status: "check_user_status_failed",
        error: action.payload,
      }
    case "CHECKED_USER_STATUS":
      return {
        ...state,
        status: "checked_user_status",
        userStatusDetails: action.payload,
      }
    case "SHOW_INVITATION_DETAILS":
      return {
        ...state,
        step: "show_invitation_details",
        invitationDetails: action.payload,
      }
  }
}

export function AddTeamMemberInBusinessInModal({
  businessId,
  defaultState,
  children,
  onSuccess,
}: {
  businessId: string
  defaultState?: boolean
  onSuccess: (user: {
    name?: string
    email?: string
    phoneNumber?: string
    isInvited?: boolean
    role: T_AVAILABLE_BUSINESS_ROLES
  }) => void
  children?: (props: { onAddClick: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const { business, businessTeam } = useBusiness(businessId)
  const { invitations } = useShareBusinessInvitations(businessId)

  const [
    {
      step,
      email,
      guestName,
      phoneNumber,
      userStatusDetails,
      invitationDetails,
    },
    dispatch,
  ] = useReducer(reducer, initialExtendedStateForAddMember)

  const { checkIfPhonesExists, checkIfEmailsExists } = useCheckUsersExists()

  async function checkUserStatus({
    type,
    phoneNumber,
    email,
    setError,
  }: CheckUserStatusProps) {
    if (type === "phone") {
      try {
        const { data: usersInfo } = await checkIfPhonesExists([phoneNumber])
        if (
          parsePhoneNumber(usersInfo[0].phone)?.country !== "IN" &&
          !usersInfo[0].isAppUser
        ) {
          setError(
            "phoneNumber",
            `Mobile number is not registered on ${config.appTitle}. Try adding via email.`
          )
        } else {
          dispatch({
            type: "CHECK_USER_STATUS",
            payload: {
              name: `${
                usersInfo[0]?.name ||
                parsePhoneNumber(usersInfo[0].phone)?.nationalNumber ||
                "CB"
              }`,
              phoneNumber: usersInfo[0].phone,
              isAppUser: usersInfo[0].isAppUser,
            },
          })
        }
      } catch (error) {
        dispatch({ type: "CHECK_USER_STATUS_FAILED", payload: error as Error })
      }
    } else if (type === "email") {
      try {
        const { data: usersInfo } = await checkIfEmailsExists([email])
        dispatch({
          type: "CHECK_USER_STATUS",
          payload: {
            name: `${usersInfo[0].name || email || "CB"}`,
            email: usersInfo[0].email,
            isAppUser: usersInfo[0].isAppUser,
          },
        })
      } catch (error) {
        dispatch({ type: "CHECK_USER_STATUS_FAILED", payload: error as Error })
      }
    }
  }

  useEffect(() => {
    if (defaultState) {
      state.open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultState])

  function onSuccessHandler(role: T_AVAILABLE_BUSINESS_ROLES) {
    dispatch({
      type: "ASK_PHONE_NUMBER",
    })
    state.close()
    onSuccess({
      email: email,
      name: guestName,
      phoneNumber: phoneNumber,
      isInvited: Boolean(
        invitationDetails?.guestPhone || invitationDetails?.guestEmail
      ),
      role,
    })
  }

  function onBackHandler(type: "ask_number" | "ask_email") {
    dispatch({
      type: "EDIT_USER_DETAILS",
      payload: type,
    })
  }

  return (
    <>
      {children?.({ onAddClick: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={() => {
          if (step === "show_invitation_details" && invitationDetails) {
            return onSuccessHandler(invitationDetails.role)
          }
          state.close()
          onBackHandler("ask_email")
        }}
        title={
          step === "ask_number" || step === "ask_email"
            ? "Add team member"
            : step === "show_invitation_details"
            ? "Share Invite"
            : userStatusDetails && !userStatusDetails.isAppUser
            ? "Choose Role & Invite"
            : "Choose Role & Add"
        }
        isDismissable
        placement="right"
        onBackPress={
          step === "show_member_status"
            ? () => onBackHandler("ask_email")
            : undefined
        }
      >
        <SuspenseWithPerf
          traceId="loading_business_details"
          fallback={
            <div className="text-center py-16">
              <SpinnerIcon />
            </div>
          }
        >
          {step === "ask_number" || step === "ask_email" ? (
            <AskForMemberPhoneNumberOrEmail
              email={email}
              initiallyAskFor={step === "ask_email" ? "email" : "phone"}
              guestName={guestName}
              phoneNumber={phoneNumber}
              teamMembers={businessTeam}
              invitations={invitations}
              onSubmit={async (values) =>
                await checkUserStatus(
                  values.type === "phone"
                    ? {
                        type: values.type,
                        setError: values.setError,
                        phoneNumber: values?.phoneNumber || "",
                      }
                    : {
                        type: values.type,
                        setError: values.setError,
                        email: values?.email || "",
                      }
                )
              }
              onCancel={state.close}
            />
          ) : step === "show_member_status" ? (
            <CheckIfUsersExists
              step={step}
              business={business}
              guestName={guestName}
              userStatusDetails={userStatusDetails}
              onCancel={(type: "email" | "phone") =>
                dispatch({
                  type: "EDIT_USER_DETAILS",
                  payload: type === "email" ? "ask_email" : "ask_number",
                })
              }
              onSuccess={(role) => onSuccessHandler(role)}
              onShowInvitationDetails={(data) => {
                dispatch({
                  type: "SHOW_INVITATION_DETAILS",
                  payload: data,
                })
              }}
            />
          ) : step === "show_invitation_details" && invitationDetails ? (
            <BusinessInvitationDetails
              invitation={invitationDetails}
              onSuccess={(role) => {
                dispatch({
                  type: "ASK_PHONE_NUMBER",
                })
                state.close()
                onSuccess({
                  name: guestName,
                  email: email,
                  phoneNumber: phoneNumber,
                  isInvited: Boolean(invitationDetails?.guestPhone),
                  role,
                })
              }}
            />
          ) : null}
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

function AskForMemberPhoneNumberOrEmail({
  email,
  guestName,
  phoneNumber,
  teamMembers,
  invitations,
  initiallyAskFor,
  onSubmit,
  onCancel,
}: {
  initiallyAskFor?: "phone" | "email"
  teamMembers: Array<TBusinessMember>
  invitations: Array<TBusinessInvitation>
  guestName: string
  email?: string
  phoneNumber?: string
  onSubmit: ({
    type,
    email,
    phoneNumber,
    setError,
  }: {
    type: "phone" | "email"
    phoneNumber?: string
    email?: string
    setError: (key: string, value: string) => void
  }) => void
  onCancel: () => void
}) {
  const { data: user } = useUser()
  const { phoneNumber: myPhoneNumber } = user || { phoneNumber: "" }
  const defaultCountry = useMemo(
    () =>
      (myPhoneNumber ? parsePhoneNumber(myPhoneNumber)?.country : "IN") || "IN",
    [myPhoneNumber]
  )
  const isUserIndian = myPhoneNumber
    ? isPhoneNumberIndian(myPhoneNumber)
    : isVisitorIndian()

  return (
    <Formik
      initialValues={{
        name: guestName,
        phoneNumber,
        askFor: isUserIndian ? "phone" : (initiallyAskFor as "phone" | "email"),
        email,
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Validator.object().shape({
        email: EmailValidator.nullable().when(["askFor"], (askFor) => {
          if (askFor === "email") {
            return Validator.string()
              .email("Please enter a valid email address")
              .required("Email address is required!")
              .test({
                name: "valid-email-number",
                test: (value: string | undefined | null) => {
                  if (!value) {
                    // required attribute should handle the empty case
                    return true
                  }
                  value = value?.toLowerCase()
                  return (
                    !teamMembers.some((m) => {
                      return m.email === value
                    }) &&
                    !invitations.some((m) => {
                      return m.guestEmail === value
                    })
                  )
                },
                message: function test(params) {
                  const value = params.value?.toLowerCase()
                  const existingMember = teamMembers.find(
                    (m) => m.email === value
                  )
                  const pendingInvitation = invitations.find(
                    (m) => m.guestEmail === value
                  )
                  if (existingMember?.uid === user?.uid) {
                    return `You are not allowed to add your email to the business`
                  }
                  if (existingMember) {
                    return `Member with this email address (${existingMember.email}) already added to this business.`
                  }
                  if (pendingInvitation) {
                    return `Invitation to ${pendingInvitation.guestName} is already pending. Please share the link
                      (${pendingInvitation.inviteLink}) and ask to join`
                  }
                  return `Please enter a valid email address`
                },
              })
          }
          return Validator.string().nullable()
        }),
        phoneNumber: PhoneNumberValidator.nullable().when(
          ["askFor"],
          (askFor) => {
            if (askFor === "phone") {
              return Validator.string()
                .required("Phone number is required")
                .test({
                  name: "valid-phone-number",
                  test: (value: string | undefined | null) => {
                    if (!value) {
                      // required attribute should handle the empty case
                      return true
                    }
                    return (
                      isPossiblePhoneNumber(value) &&
                      !teamMembers.some((m) =>
                        arePhoneNumbersSame(m.phoneNumber, value)
                      ) &&
                      !invitations.some((m) =>
                        arePhoneNumbersSame(m.guestPhone, value)
                      )
                    )
                  },
                  message: function test(params) {
                    const existingMember = teamMembers.find((m) =>
                      arePhoneNumbersSame(m.phoneNumber, params.value)
                    )
                    const pendingInvitation = invitations.find((m) =>
                      arePhoneNumbersSame(m.guestPhone, params.value)
                    )
                    if (existingMember?.uid === user?.uid) {
                      return `You are not allowed to add your number to the business`
                    }
                    if (existingMember) {
                      return `Member with this phone (${existingMember?.phoneNumber}) already added to this business.`
                    }
                    if (pendingInvitation) {
                      return `Invitation to ${pendingInvitation.guestName} is already pending. Please share the link
                  (${pendingInvitation.inviteLink}) and ask to join`
                    }
                    return `Please enter a valid mobile number`
                  },
                })
            }
            return Validator.string().nullable()
          }
        ),
      })}
      onSubmit={async (values, actions) =>
        await onSubmit({
          type: values.askFor,
          ...values,
          setError: actions.setFieldError,
        })
      }
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form noValidate>
          <ModalBody>
            {values.askFor === "email" ? (
              <Stack gap="3">
                <FormField
                  type="text"
                  name="email"
                  label={`Add Email`}
                  placeholder="eg. xyz123@gmail.com"
                  noMargin
                  onChange={(e) =>
                    setFieldValue("email", e.target.value.toLowerCase())
                  }
                />
              </Stack>
            ) : (
              <Stack gap="3">
                <Box as="label" htmlFor="phoneNumber">
                  Mobile number
                </Box>
                <FormField
                  name="phoneNumber"
                  renderInput={({
                    field: { onChange, ...otherFieldProps },
                    form,
                  }: FieldProps<string>) => (
                    <Box className="max-w-xs">
                      <PhoneInput
                        {...otherFieldProps}
                        id="phoneNumber"
                        onChange={(phoneNumber) =>
                          form.setFieldValue(otherFieldProps.name, phoneNumber)
                        }
                        defaultCountry={defaultCountry}
                        required
                        autoFocus
                      />
                    </Box>
                  )}
                />
                {!isUserIndian ? (
                  <Inline gap="2" alignItems="center">
                    <Box
                      width="full"
                      className="h-[1px]"
                      backgroundColor="borderOutline"
                    />
                    <Text fontSize="b3">Or</Text>
                    <Box
                      width="full"
                      className="h-[1px]"
                      backgroundColor="borderOutline"
                    />
                  </Inline>
                ) : null}
                {!isUserIndian ? (
                  <Stack
                    paddingTop="5"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      size="lg"
                      onClick={() => setFieldValue("askFor", "email")}
                    >
                      Add Email
                    </Button>
                  </Stack>
                ) : null}
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="submit" disabled={isSubmitting} size="lg">
              {isSubmitting ? `Verifying...` : "Next"}
            </Button>
            {isUserIndian ? (
              <Button size="lg" onClick={onCancel}>
                Cancel
              </Button>
            ) : values.askFor === "email" ? (
              <Button
                onClick={() => setFieldValue("askFor", "phone")}
                size="lg"
                disabled={isSubmitting}
              >
                Add With Mobile Number
              </Button>
            ) : null}
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

function CheckIfUsersExists({
  step,
  business,
  guestName,
  initialRole,
  userStatusDetails,
  onCancel,
  onSuccess,
  onShowInvitationDetails,
}: {
  guestName: string
  business: TBusiness
  step: StepToAddMember
  initialRole?: T_AVAILABLE_BUSINESS_ROLES
  userStatusDetails?: {
    email?: string
    phoneNumber?: string
    isAppUser: boolean
  }
  onCancel: (type: "email" | "phone") => void
  onSuccess: (role: T_AVAILABLE_BUSINESS_ROLES) => void
  onShowInvitationDetails: (data: TBusinessInvitation) => void
}) {
  const addTeamMember = useAddTeamMember()
  const invitingUser: { name: string; email?: string; phone?: string } = {
    name:
      guestName ||
      userStatusDetails?.email ||
      userStatusDetails?.phoneNumber ||
      "CB",
    email: userStatusDetails?.email,
    phone: userStatusDetails?.phoneNumber,
  }
  return (
    <Box>
      <Box>
        {step === "show_member_status" && userStatusDetails ? (
          <Formik
            initialValues={{
              role: initialRole || "staff",
              guestName: invitingUser.name,
            }}
            onSubmit={formikOnSubmitWithErrorHandling(
              async ({ role, guestName }) => {
                const { data } = await addTeamMember({
                  role,
                  guestName,
                  guestEmail: userStatusDetails.email,
                  guestPhone: userStatusDetails.phoneNumber,
                  businessId: business.id,
                })
                trackEvent(
                  TrackingEvents.BUSINESS_TEAM_MEMBER_ADDED_OR_INVITED,
                  {
                    role: role,
                    isInvited: data.inviteLink ? true : false,
                    from: "manually",
                    via: userStatusDetails.email?.length ? "email" : "phone",
                  }
                )
                if (data.message) {
                  onSuccess(role)
                  return
                }
                if (data.inviteLink) {
                  onShowInvitationDetails({ ...data, role: data.role || role })
                  return
                }
              }
            )}
          >
            {({ isSubmitting, status, values }) => (
              <Box>
                {status ? (
                  <Alert status="error" marginBottom="0" borderRadius="none">
                    {status}
                  </Alert>
                ) : null}
                <>
                  <ModalBody className="relative">
                    <Stack gap="4" paddingBottom="12">
                      <Text fontSize="b3">
                        {userStatusDetails.isAppUser
                          ? `${invitingUser.name} is already using ${config.appTitle}
                          app. Choose their role in this business and add`
                          : `${
                              invitingUser?.email || invitingUser?.phone
                            } is a new user. Send invite to ${
                              invitingUser?.email || invitingUser?.phone
                            } to join this business`}
                      </Text>
                      <Stack as="section" gap="4">
                        <Inline
                          paddingY="4"
                          paddingX="6"
                          borderWidth="1"
                          borderColor="borderOutline"
                          rounded="md"
                          gap="6"
                          alignItems="center"
                        >
                          <Box>
                            <MemberAvatar
                              size="12"
                              fontSize="s1"
                              id={invitingUser.name}
                              name={invitingUser.name}
                            />
                          </Box>
                          <Stack flex="1" gap="2">
                            <Heading
                              as="h4"
                              fontSize="s3"
                              className="break-all line-clamp-1"
                            >
                              {invitingUser.name}
                            </Heading>
                            <Text
                              color="textMedium"
                              fontSize="c2"
                              className="break-all line-clamp-2"
                            >
                              {invitingUser?.email || invitingUser?.phone}
                            </Text>
                          </Stack>
                          <Box>
                            <Box
                              bgColor={
                                userStatusDetails.isAppUser
                                  ? "surfacePrimaryLowest"
                                  : "surfaceNeutralLowest"
                              }
                              rounded="md"
                              paddingX="3"
                              paddingY="1"
                            >
                              <Text
                                color={
                                  userStatusDetails.isAppUser
                                    ? "textPrimary"
                                    : "textMedium"
                                }
                                fontSize="c2"
                              >
                                {userStatusDetails.isAppUser ? "" : "Not a"}{" "}
                                {config.appTitle} User
                              </Text>
                            </Box>
                          </Box>
                        </Inline>
                        <Box width="full">
                          <SelectFromBusinessRoles fieldName="role" />
                        </Box>
                      </Stack>
                    </Stack>
                  </ModalBody>
                  <ModalFooter position="relative" onlyActions={false}>
                    {values.role === "partner" ? (
                      <Box
                        position="absolute"
                        left="0"
                        style={{ bottom: "100%" }}
                        className="bottom-0"
                        width="full"
                      >
                        <Alert
                          status="warning"
                          marginBottom="0"
                          borderWidth="0"
                          rounded="none"
                        >
                          <Text fontSize="sm" color="gray900">
                            Partner will get full access to your business
                          </Text>
                        </Alert>
                      </Box>
                    ) : null}
                    <Form>
                      <ModalFooterActions>
                        <Button type="submit" disabled={isSubmitting} size="lg">
                          {isSubmitting ? (
                            <>
                              <SpinnerIcon />{" "}
                              {userStatusDetails.isAppUser
                                ? "Adding"
                                : "Inviting"}{" "}
                              member...
                            </>
                          ) : (
                            <>
                              <PlusIcon />{" "}
                              {userStatusDetails.isAppUser ? "Add" : "Invite"}{" "}
                              as {values.role}
                            </>
                          )}
                        </Button>
                        {!isSubmitting ? (
                          <Button
                            size="lg"
                            onClick={() =>
                              onCancel(
                                userStatusDetails?.email ? "email" : "phone"
                              )
                            }
                          >
                            {userStatusDetails?.email
                              ? "Change Email"
                              : "Change Mobile Number"}
                          </Button>
                        ) : null}
                      </ModalFooterActions>
                    </Form>
                  </ModalFooter>
                </>
              </Box>
            )}
          </Formik>
        ) : (
          <Text>
            Something went wrong. Please try after some time or contact our
            support.
          </Text>
        )}
      </Box>
    </Box>
  )
}

function BusinessInvitationDetails({
  invitation,
  onSuccess,
}: {
  invitation: TBusinessInvitation
  onSuccess: (role: T_AVAILABLE_BUSINESS_ROLES) => void
}) {
  const { title } = getBusinessRoleDetails(invitation.role)
  const invitedUser = {
    name:
      invitation?.guestName ||
      invitation?.guestPhone ||
      invitation?.guestEmail ||
      "CB User",
    email: invitation?.guestEmail,
    phone: invitation?.guestPhone,
  }
  return (
    <>
      <ModalBody>
        <Stack gap="6">
          <Text fontSize="b3">
            Share invite link with {invitedUser.name} and ask them to signup on
            CashBook
          </Text>
          <Stack gap="4">
            <Inline
              paddingY="4"
              paddingX="6"
              borderWidth="1"
              rounded="md"
              gap="6"
              borderColor="borderOutline"
              alignItems="center"
            >
              <Box>
                <MemberAvatar
                  size="12"
                  fontSize="s1"
                  id={invitedUser.name}
                  name={invitedUser.name}
                />
              </Box>
              <Stack flex="1" gap="2">
                <Heading
                  as="h4"
                  fontSize="s3"
                  className="line-clamp-1 break-all"
                >
                  {invitedUser.name}
                </Heading>
                <Text
                  color="textMedium"
                  fontSize="c2"
                  className="line-clamp-2 break-all"
                >
                  {invitedUser?.email || invitedUser?.phone || invitedUser.name}
                </Text>
              </Stack>
              <Box>
                <Box
                  bgColor="surfaceNeutralLowest"
                  rounded="md"
                  paddingX="3"
                  paddingY="1"
                  whiteSpace="preserve"
                >
                  <Text color="textMedium" fontSize="c2">
                    Not a {config.appTitle} User
                  </Text>
                </Box>
              </Box>
            </Inline>
            <Inline
              borderWidth="1"
              rounded="md"
              borderColor="borderOutline"
              alignItems="center"
              gap="2"
              paddingX="6"
              paddingY="4"
            >
              <Text color="textMedium" fontSize="b3">
                Business Role:{" "}
              </Text>
              <Text textTransform="capitalize" fontSize="s4">
                {title}
              </Text>
            </Inline>
          </Stack>
          <Stack gap="4">
            <Text fontSize="b3">
              Unique Invite Link for {invitation.businessName}
            </Text>
            <Inline
              className="bg-[#EBEEFD]"
              alignItems="center"
              justifyContent="between"
              paddingY="2"
              paddingX="4"
              rounded="md"
            >
              <Inline alignItems="center" gap="4">
                <Circle backgroundColor="blue900" color="white" size="8">
                  <LinkIcon />
                </Circle>
                <Text fontSize="b3">{invitation.inviteLink}</Text>
              </Inline>
              <Box>
                <CopyToClipboard>
                  {({ copy, copied, copying }) => (
                    <Button
                      inline
                      disabled={copied || copying}
                      onClick={() => {
                        copy(invitation.inviteLink)
                      }}
                    >
                      {copied ? (
                        <CheckIcon color="green500" />
                      ) : (
                        <CopyIcon color="blue900" />
                      )}
                    </Button>
                  )}
                </CopyToClipboard>
              </Box>
            </Inline>
            <Alert status="success">
              <Inline gap="3">
                <Box>
                  <SecurityCheckIcon color="green500" size="6" />
                </Box>
                <Text color="textHigh" fontSize="b3">
                  This link is 100% secure. Only{" "}
                  {invitedUser?.email || invitedUser?.phone} can access this
                  business by logging in with their{" "}
                  {invitedUser?.email ? "email address" : `phone number`}.
                </Text>
              </Inline>
            </Alert>
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <ShareInvitationActions
          invitation={invitation}
          onSuccess={() => onSuccess(invitation.role)}
        />
      </ModalFooter>
    </>
  )
}

const RESEND_INVITATION_POINTERS = [
  "Share this invitation link with user and ask them to make an account on Cashbook",
  "They can access your business once they login into Cashbook app",
]

export function ResendInvitationInModal({
  invitation,
  children,
}: {
  invitation: TBusinessInvitation
  children: (props: { onResendClick: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const { guestName, guestPhone } = invitation
  return (
    <>
      {children({ onResendClick: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title={`Resend Invitation to ${guestName || guestPhone}`}
        isDismissable
        placement="right"
      >
        <ModalBody>
          <Stack gap="6">
            <Inline
              paddingY="2"
              paddingX={{ xs: "3", md: "4" }}
              rounded="md"
              bgColor="blue100"
              alignItems="center"
              justifyContent="between"
              gap={{ xs: "2", md: "4" }}
            >
              <Circle backgroundColor="blue900" color="white" size="8">
                <LinkIcon />
              </Circle>
              <Text className="text-ellipsis overflow-hidden">
                {invitation.inviteLink}
              </Text>
              <Box flex="1" textAlign="right">
                <CopyToClipboard>
                  {({ copy, copied, copying }) => (
                    <Button
                      inline
                      disabled={copied || copying}
                      onClick={() => {
                        copy(invitation.inviteLink)
                      }}
                    >
                      {copied ? (
                        <CheckIcon color="green500" />
                      ) : (
                        <CopyIcon color="blue900" />
                      )}
                    </Button>
                  )}
                </CopyToClipboard>
              </Box>
            </Inline>
            <Stack gap="4" as="ul">
              {RESEND_INVITATION_POINTERS.map((pointer) => (
                <Inline key={pointer} alignItems="center" gap="3" as="li">
                  <Circle size="2" backgroundColor="gray400" />
                  <Text>{pointer}</Text>
                </Inline>
              ))}
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ShareInvitationActions isReinvited invitation={invitation} />
        </ModalFooter>
      </Modal>
    </>
  )
}

export function AddedOrInvitedMemberDialog({
  role,
  footer,
  isInvited,
  memberName,
  memberCount,
  onClose,
}: {
  isInvited?: boolean
  memberName: string
  memberCount: string
  role: string
  onClose: () => void
  footer: React.ReactNode
}) {
  const state = useOverlayTriggerState({ isOpen: true })
  return (
    <Modal
      isOpen={state.isOpen}
      onClose={() => {
        state.close()
        onClose()
      }}
      size="sm"
      isDismissable
      title={isInvited ? "Invited in Business Team" : "Added in Business Team"}
    >
      <ModalBody>
        <Stack gap="6" justifyContent="center" alignItems="center">
          <Box>
            <CheckCircleSolidIcon color="green500" size="18" />
          </Box>
          <Stack textAlign="center" gap="3">
            <Text fontSize="lg" fontWeight="semibold">
              {isInvited
                ? `${memberName} invited as ${role}!`
                : `${memberName} added as ${role}!`}
            </Text>
            <Text color="gray500">
              Your new team count is {memberCount}.{" "}
              {role.toLowerCase() === "staff" && !isInvited
                ? ` You can add ${memberName} to books of this business`
                : "Add more members from your team"}
            </Text>
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </Modal>
  )
}

function SelectFromBusinessRoles({ fieldName }: { fieldName: string }) {
  const roles = getAllBusinessRolesWithPermissions().filter(
    (role) => role.id !== "owner"
  )

  const [{ value }] = useField<T_AVAILABLE_BUSINESS_ROLES>(fieldName)

  const { permissionsDescription, restrictionsDescription } =
    getBusinessRoleDetails(value)

  return (
    <Stack gap="4">
      <Box borderWidth="1" borderColor="borderOutline" rounded="md">
        <Box
          borderBottomWidth="1"
          borderColor="borderOutline"
          paddingX="6"
          paddingY="5"
        >
          <Text fontSize="s3">Choose Role</Text>
        </Box>
        <Box paddingX="6" paddingY="5">
          <Inline as="ul" gap="4">
            {roles.map((role) => (
              <FormField
                key={role.id}
                label={
                  <Box
                    as="li"
                    backgroundColor={
                      value === role.id
                        ? businessRolesColorCodes[role.id].backgroundColor
                        : "surfaceNeutralLowest"
                    }
                    key={role.id}
                    rounded="full"
                    display="inlineBlock"
                    paddingX="4"
                    paddingY="2"
                    borderWidth="1"
                    color={
                      value === role.id
                        ? businessRolesColorCodes[role.id].color
                        : "textMedium"
                    }
                    borderColor={
                      value === role.id
                        ? businessRolesColorCodes[role.id].borderColor
                        : "borderOutline"
                    }
                    cursor="pointer"
                  >
                    <Text as="span" fontWeight="medium" fontSize="base">
                      {role.title}
                    </Text>
                  </Box>
                }
                type="radio"
                name={fieldName}
                value={role.id}
                id={`${fieldName}.${role.id}`}
                noMargin
                invisibleInput
                hideError
              />
            ))}
          </Inline>
          <Box paddingTop="8">
            <Stack gap="6">
              {permissionsDescription.length ? (
                <Stack gap="4">
                  <Text color="textMedium" fontSize="s4">
                    Permissions
                  </Text>
                  <Stack as="ul" gap="4">
                    {permissionsDescription.map((permission) => (
                      <Inline
                        as="li"
                        key={permission}
                        gap="3"
                        alignItems="center"
                      >
                        <Box>
                          <CheckCircleSolidIcon color="iconSuccess" />
                        </Box>
                        <Text fontSize="b3">{permission}</Text>
                      </Inline>
                    ))}
                  </Stack>
                </Stack>
              ) : null}
              {restrictionsDescription.length ? (
                <Stack gap="4">
                  <Text color="textMedium" fontSize="s4">
                    Restrictions
                  </Text>
                  <Stack as="ul" gap="4">
                    {restrictionsDescription.map((restriction) => (
                      <Inline
                        as="li"
                        key={restriction}
                        gap="3"
                        alignItems="center"
                      >
                        <Box>
                          <CancelFilledIcon color="iconError" />
                        </Box>
                        <Text fontSize="b3">{restriction}</Text>
                      </Inline>
                    ))}
                  </Stack>
                </Stack>
              ) : null}
            </Stack>
          </Box>
        </Box>
      </Box>
      <Inline alignItems="center" color="textMedium" gap="3">
        <InformationCircleIcon size="5" color="iconMedium" />
        <Text fontSize="c2">You can change this role later</Text>
      </Inline>
    </Stack>
  )
}

export function ShowAllBusinessRolesAndPermissionsInModal({
  userRole,
  children,
}: {
  userRole?: T_AVAILABLE_BUSINESS_ROLES
  children: (props: { onDisplay: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const roles = getAllBusinessRolesWithPermissions()
  const [selectedRole, setSelectedRole] = useState<T_AVAILABLE_BUSINESS_ROLES>(
    userRole || "staff"
  )
  const { permissionsDescription, restrictionsDescription } =
    getBusinessRoleDetails(selectedRole)
  return (
    <>
      {children({ onDisplay: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title={"Business Roles & Permissions"}
        isDismissable
        placement="right"
      >
        <ModalBody>
          <Stack gap="6" borderWidth="1" rounded="md" padding="6">
            <Inline gap="4" as="ul" flexWrap="wrap">
              {roles.reverse().map(({ id, title }) => {
                const isSelectedRole = selectedRole === id
                return (
                  <Box
                    as="li"
                    key={id}
                    paddingX="4"
                    paddingY="2"
                    borderWidth="1"
                    rounded="full"
                    cursor="pointer"
                    onClick={() => setSelectedRole(id)}
                    borderColor={
                      isSelectedRole
                        ? businessRolesColorCodes[id].borderColor
                        : "gray200"
                    }
                    color={
                      isSelectedRole
                        ? businessRolesColorCodes[id].color
                        : "gray500"
                    }
                    backgroundColor={
                      isSelectedRole
                        ? businessRolesColorCodes[id].backgroundColor
                        : "gray100"
                    }
                  >
                    <Text fontWeight="semibold">
                      {title} {userRole && userRole === id ? "(You)" : ""}
                    </Text>
                  </Box>
                )
              })}
            </Inline>
            {selectedRole === "owner" ? (
              <Alert status="info" marginBottom="0">
                <Text fontSize="sm" color="black">
                  Each business can have only one owner
                </Text>
              </Alert>
            ) : null}
            <Stack gap="6">
              {permissionsDescription.length ? (
                <Stack gap="4">
                  <Text fontWeight="semibold" color="gray500">
                    Permissions
                  </Text>
                  <Stack as="ul" gap="4">
                    {permissionsDescription.map((p) => (
                      <Inline as="li" key={p} gap="4" alignItems="center">
                        <Box>
                          <CheckCircleSolidIcon color="green500" />
                        </Box>
                        <Text>{p}</Text>
                      </Inline>
                    ))}
                  </Stack>
                </Stack>
              ) : null}
              {restrictionsDescription.length ? (
                <Stack gap="4">
                  <Text fontWeight="semibold" color="gray500">
                    Restrictions
                  </Text>
                  <Stack as="ul" gap="4">
                    {restrictionsDescription.map((r) => (
                      <Inline as="li" key={r} gap="4" alignItems="center">
                        <Box>
                          <CancelFilledIcon color="red900" />
                        </Box>
                        <Text>{r}</Text>
                      </Inline>
                    ))}
                  </Stack>
                </Stack>
              ) : null}
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button size="lg" level="primary" onClick={state.close}>
            Ok, Got it
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export function BusinessUserRoleAndPermissionsForInModal({
  role,
  businessName,
  onClose,
  children,
}: {
  businessName: string
  role: T_AVAILABLE_BUSINESS_ROLES
  onClose?: () => void
  children: (props: { onDisplay: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const { title, permissionsDescription, restrictionsDescription } =
    getBusinessRoleDetails(role)
  return (
    <>
      {children({ onDisplay: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={() => {
          onClose?.()
          state.close()
        }}
        title={"Business Roles & Permissions"}
        isDismissable
        placement="right"
      >
        <ModalBody>
          <Stack gap="6">
            <Box borderWidth="1" rounded="md" paddingX="6" paddingY="4">
              <Text as="span" color="gray500">
                Your current role in {businessName}:{" "}
                <Text as="span" fontWeight="semibold" color="black">
                  {title}
                </Text>
              </Text>
            </Box>
            <Stack
              gap="6"
              borderWidth="1"
              rounded="md"
              paddingX="6"
              paddingY="4"
            >
              {role === "owner" ? (
                <Alert status="info" marginBottom="0">
                  <Text fontSize="sm" color="black">
                    Each business can have only one owner
                  </Text>
                </Alert>
              ) : null}
              <Stack gap="6">
                {permissionsDescription.length ? (
                  <Stack gap="4">
                    <Text fontWeight="semibold" color="gray500">
                      Permissions
                    </Text>
                    <Stack as="ul" gap="4">
                      {permissionsDescription.map((p) => (
                        <Inline as="li" key={p} gap="4" alignItems="center">
                          <Box>
                            <CheckCircleSolidIcon color="green500" />
                          </Box>
                          <Text>{p}</Text>
                        </Inline>
                      ))}
                    </Stack>
                  </Stack>
                ) : null}
                {restrictionsDescription.length ? (
                  <Stack gap="4">
                    <Text fontWeight="semibold" color="gray500">
                      Restrictions
                    </Text>
                    <Stack as="ul" gap="4">
                      {restrictionsDescription.map((r) => (
                        <Inline as="li" key={r} gap="4" alignItems="center">
                          <Box>
                            <CancelFilledIcon color="red900" />
                          </Box>
                          <Text>{r}</Text>
                        </Inline>
                      ))}
                    </Stack>
                  </Stack>
                ) : null}
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            level="primary"
            onClick={() => {
              onClose?.()
              state.close()
            }}
          >
            Ok, Got it
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
