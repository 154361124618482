import {
  Alert,
  ArrowRightIcon,
  Box,
  Button,
  CBButton,
  CancelIcon,
  CannotActivateCardIcon,
  CardIssuedIcon,
  CheckCircleSolidIcon,
  CheckIcon,
  Circle,
  CopyIcon,
  CopyToClipboard,
  FormField,
  GradientCardIcon,
  InformationWarningIcon,
  Inline,
  Modal,
  ModalBody,
  ModalFooter,
  PhoneInput,
  PlusIcon,
  SearchIcon,
  SpinnerIcon,
  Stack,
  Text,
  UnHideIcon,
  UserAddIcon,
  WhatsAppIcon,
  formikOnSubmitWithErrorHandling,
  useOverlayTriggerState,
} from "@cashbook/web-components"
import { FieldProps, Form, Formik } from "formik"
import { Amount } from "../support/Intl"
import { toast } from "react-hot-toast"
import {
  CashbookCard,
  WalletEnabledResponse,
  useCheckIfPaymentsEnabled,
  useNewCardIssue,
} from "@cashbook/data-store/payments"
import config from "../config"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import { useMemo, useState } from "react"
import {
  TBusiness,
  TBusinessMember,
  useAddTeamMember,
  useBusiness,
  useShareBusinessInvitations,
} from "@cashbook/data-store/businesses"
import { Avatar } from "."
import { useUser } from "reactfire"
import { parsePhoneNumber } from "react-phone-number-input"
import * as Validator from "yup"
import {
  PhoneNumberValidator,
  arePhoneNumbersSame,
  isPhoneNumberIndian,
} from "@cashbook/util-general"
import { isPossiblePhoneNumber } from "react-phone-number-input"
import { useCheckUsersExists } from "@cashbook/data-store/users"
import { WhatsAppContactLink } from "../HelpDocs"

export function UpdateWalletLimitsInDialog({
  children,
  ...props
}: React.ComponentProps<typeof UpdateWalletLimitsForm> & {
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title={
          props.type === "daily"
            ? "Daily Transaction Limit"
            : "Per Transaction Limit"
        }
        isDismissable
      >
        <UpdateWalletLimitsForm {...props} />
      </Modal>
    </>
  )
}

function UpdateWalletLimitsForm({
  type,
  isStaff,
  maxLimit,
  currentLimit,
  updateLimits,
}: {
  currentLimit: number
  maxLimit: number
  isStaff?: boolean
  type: "daily" | "perTransaction"
  updateLimits: (
    type: "daily" | "perTransaction",
    value: number
  ) => Promise<void>
}) {
  return (
    <Formik
      initialValues={{
        limit: currentLimit as number,
        isSliderUsed: false as boolean,
      }}
      onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
        await updateLimits(type, values.limit)
        trackEvent(TrackingEvents.LIMITS_CHANGED, {
          type: type,
          isStaffWallet: Boolean(isStaff),
          usingSlider: values.isSliderUsed,
        })
        toast.success(
          type === "perTransaction"
            ? "Per transaction limit updated"
            : "Daily transaction limit updated"
        )
      })}
    >
      {({ status, values, isSubmitting, setFieldValue }) => {
        const progressInPercentage = ((values.limit || 0) / maxLimit) * 100
        return (
          <Form noValidate>
            <ModalBody>
              <Stack gap="14">
                <FormField
                  type="text"
                  name="limit"
                  label="Current Limit"
                  inputMode="numeric"
                  placeholder="₹1000"
                  noMargin
                  maxLength={maxLimit}
                  value={values.limit}
                  autoComplete="off"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (Number(e.target.value) <= maxLimit) {
                      setFieldValue("limit", Number(e.target.value))
                    }
                  }}
                />
                <Stack gap="2" paddingBottom="10">
                  <Box
                    position="relative"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      as="input"
                      type="range"
                      width="full"
                      rounded="full"
                      height="2"
                      value={values.limit}
                      name="limit"
                      max={maxLimit}
                      step="100"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue("limit", Number(e.target.value))
                        setFieldValue("isSliderUsed", true)
                      }}
                      borderWidth="0"
                      style={{
                        accentColor: "#179F51",
                        appearance: "none",
                        outline: "none",
                        background: `linear-gradient(to right, #179F51 0%, #179F51 ${progressInPercentage}%, #F5F5F5 ${progressInPercentage}%, #F5F5F5 100%)`,
                      }}
                    />
                    <Box
                      position="absolute"
                      className="w-[2px]"
                      height="3"
                      onClick={() => {
                        setFieldValue("limit", maxLimit / 2)
                      }}
                      backgroundColor="surfaceSuccessLowest"
                    ></Box>
                  </Box>
                  <Inline
                    position="relative"
                    alignItems="center"
                    justifyContent="between"
                  >
                    <Inline fontSize="c2" gap="1" alignItems="center">
                      <Text>Min: </Text>
                      <Amount amount={0} currency="inr" />
                    </Inline>
                    <Box
                      fontSize="c2"
                      alignItems="center"
                      position="absolute"
                      className="right-[45%]"
                    >
                      <Amount amount={maxLimit / 2} currency="inr" />
                    </Box>
                    <Inline fontSize="c2" gap="1" alignItems="center">
                      <Text>Max: </Text>
                      <Amount amount={maxLimit} currency="inr" />
                    </Inline>
                  </Inline>
                  {status ? (
                    <Alert marginTop="6" status="error">
                      {status}
                    </Alert>
                  ) : null}
                </Stack>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                size="lg"
                level="primary"
                type="submit"
                disabled={values.limit === currentLimit}
              >
                {isSubmitting ? <SpinnerIcon /> : null}
                Apply
              </Button>
            </ModalFooter>
          </Form>
        )
      }}
    </Formik>
  )
}

export function CashbookPrepaidCard({
  nameOnCard,
  cardDetails,
  widget,
  widgetLoading,
  onViewCardDetails,
}: {
  nameOnCard: string
  widgetLoading?: boolean
  cardDetails?: CashbookCard
  widget?: string
  onViewCardDetails?: (kitNo: string) => void
}) {
  const cardExpiryMonth = cardDetails?.card_expiry?.slice(0, 2)
  const cardExpiryYear = cardDetails?.card_expiry?.slice(2, 4)
  return (
    <>
      {widget?.length ? (
        <Box width="full" height="full">
          <iframe
            src={widget}
            width="100%"
            height="100%"
            title="User card details"
          />
        </Box>
      ) : cardDetails ? (
        <Stack gap="6" width="full">
          <Box
            className="bg-[#294AA1]"
            rounded="lg"
            marginTop="4"
            marginX="4"
            color="textOnSurface"
          >
            <Inline
              paddingX="6"
              paddingTop="3"
              paddingBottom="2"
              justifyContent="end"
            >
              <Text fontWeight="bold" color="textOnSurface">
                {config.appTitle.toUpperCase()}
              </Text>
            </Inline>
            <Box
              position="relative"
              borderWidth="2"
              borderColor="borderSuccess"
              display="flex"
              alignItems="center"
            >
              <Box position="absolute" className="left-8">
                <UpiAndTapIcon />
              </Box>
            </Box>
            <Stack gap="8" paddingX="6" paddingTop="5" paddingBottom="4">
              <Inline gap="2" justifyContent="between">
                <Stack gap="1" textAlign="left">
                  <Text fontSize="s4" className="line-clamp-1">
                    {nameOnCard}
                  </Text>
                  <Text fontSize="b3">
                    {formatMaskedCardNumber(cardDetails.card_masked_no)}
                  </Text>
                </Stack>
                <Text fontSize="c1">PLATINUM</Text>
              </Inline>
              <Inline alignItems="center" justifyContent="between">
                <Inline gap="4" alignItems="center">
                  <Stack gap="1">
                    <Text fontSize="c2">Expiry</Text>
                    <Text fontSize="c1">{`${cardExpiryMonth}/${cardExpiryYear}`}</Text>
                  </Stack>
                  <Stack gap="1">
                    <Text fontSize="c2">CVV</Text>
                    <Text fontSize="c1">***</Text>
                  </Stack>
                </Inline>
                <RupayPrepaidIcon />
              </Inline>
            </Stack>
          </Box>
          <Box
            width="full"
            height="full"
            display="flex"
            alignItems="end"
            paddingBottom="2"
          >
            <Button
              fullWidth
              level="tertiary"
              disabled={widgetLoading}
              onClick={() => {
                onViewCardDetails?.(cardDetails.kit_no)
                trackEvent(TrackingEvents.VIEW_CARD_DETAILS_CLICKED, {
                  from: "yourWallet",
                })
              }}
            >
              {widgetLoading ? <SpinnerIcon /> : <UnHideIcon />}
              View Card Details
            </Button>
          </Box>
        </Stack>
      ) : null}
    </>
  )
}

function UpiAndTapIcon() {
  return (
    <svg
      width="37"
      height="26"
      viewBox="0 0 37 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.2067 26H0.900849C0.396659 26 -0.00245251 25.5906 -0.00240423 25.0868L-8.65119e-05 0.902706C-3.82262e-05 0.39887 0.409655 0 0.90334 0L35.2092 0C35.7134 0 36.123 0.409367 36.123 0.902706L36.1207 25.0868C36.1206 25.5906 35.7109 26 35.2067 26Z"
        fill="#294AA1"
      />
      <path
        d="M31.0775 12.1238C31.0983 14.664 30.4888 17.0467 29.3016 19.293C29.0075 19.8493 28.6608 20.4161 27.9361 20.0172C27.2218 19.6289 27.5055 19.062 27.7997 18.4847C29.9219 14.2441 29.9328 9.99301 27.8009 5.75239C27.5173 5.18558 27.2023 4.62926 27.9376 4.21989C28.6834 3.81053 29.009 4.39833 29.2925 4.94416C30.4897 7.17992 31.0882 9.57314 31.0775 12.1133"
        fill="#FAFBFC"
      />
      <path
        d="M4.30589 12.3431C4.30597 11.6086 4.28503 10.8636 4.30611 10.1292C4.34822 9.14286 5.03101 8.75464 5.87127 9.24779C7.04764 9.94029 8.203 10.6538 9.36886 11.3673C10.4612 12.0493 10.4611 12.7628 9.40018 13.4238C8.2972 14.1058 7.19423 14.7878 6.08076 15.4488C5.0198 16.0784 4.32658 15.6797 4.30569 14.4416C4.29526 13.7386 4.30583 13.0356 4.3059 12.3326"
        fill="#F26A21"
      />
      <path
        d="M27.6153 12.0924C27.6152 14.0553 27.1528 15.9132 26.2388 17.6556C25.9551 18.2014 25.64 18.7997 24.8837 18.4323C24.117 18.065 24.4006 17.4772 24.6843 16.9103C26.3232 13.7089 26.3235 10.5074 24.6747 7.31648C24.4017 6.78115 24.1601 6.21434 24.8114 5.84696C25.5152 5.44809 25.8828 5.96242 26.1978 6.52924C27.0695 8.09323 27.5945 10.161 27.6048 12.0819"
        fill="#FBFCFC"
      />
      <path
        d="M24.0815 12.1556C24.1129 13.5936 23.735 14.9267 23.0634 16.1862C22.78 16.711 22.4232 17.2044 21.7518 16.858C21.0908 16.5221 21.2272 15.9763 21.5211 15.42C22.6964 13.2472 22.6966 11.064 21.5532 8.89124C21.2699 8.35592 21.0391 7.8206 21.7107 7.44273C22.4557 7.02288 22.8019 7.57919 23.0956 8.125C23.767 9.38457 24.1236 10.7281 24.092 12.1556"
        fill="#FAFBFC"
      />
      <path
        d="M14.4885 12.2994C14.4884 12.8241 14.1208 13.1283 13.7426 13.3697C12.5031 14.1671 11.2425 14.9121 9.98199 15.678C9.57231 15.9298 9.13114 15.9718 8.85808 15.5416C8.52201 15.0065 9.15225 14.954 9.39386 14.7337C9.57244 14.5658 9.77203 14.4189 9.98212 14.293C11.7889 13.2228 11.8101 11.544 10.035 10.4633C9.82494 10.3373 9.56235 10.2429 9.43632 10.0541C9.24728 9.78125 8.49099 9.88617 8.82717 9.31958C9.10031 8.85791 9.6255 8.91037 10.0561 9.1517C11.117 9.76026 12.1568 10.4003 13.1966 11.0403C13.5537 11.2607 13.9108 11.4915 14.2469 11.7328C14.4255 11.8588 14.499 12.0686 14.499 12.2889"
        fill="#0C8241"
      />
      <path
        d="M20.8396 12.0381C20.8185 13.1713 20.5246 14.0946 19.958 14.9235C19.6537 15.3642 19.2444 15.637 18.7094 15.2803C18.1953 14.9445 18.2058 14.5038 18.5206 14.0107C19.3286 12.7726 19.3497 11.5135 18.5315 10.2649C18.2167 9.78229 18.1748 9.32064 18.6785 8.98488C19.2346 8.61765 19.6648 8.87996 20.0005 9.38359C20.5565 10.223 20.8187 11.1568 20.8396 12.0381Z"
        fill="#F9FBFB"
      />
    </svg>
  )
}

function RupayPrepaidIcon() {
  return (
    <svg
      width="61"
      height="28"
      viewBox="0 0 61 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#RupayPrepaidIcon)">
        <path
          d="M57.5492 1.18742L60.9892 7.99807L53.7578 14.812L57.5492 1.18742Z"
          fill="#008C44"
        />
        <path
          d="M55.1667 1.18742L58.6015 7.99807L51.375 14.812L55.1667 1.18742Z"
          fill="#F47920"
        />
        <path
          d="M0.015625 12.334L3.44535 0.0132418H8.92859C10.6422 0.0132418 11.787 0.283745 12.3694 0.841172C12.9476 1.39412 13.059 2.2967 12.7104 3.56532C12.4996 4.31263 12.1792 4.93993 11.7391 5.43555C11.3025 5.93207 10.7267 6.32469 10.0176 6.6134C10.6192 6.75761 11.0019 7.04394 11.1734 7.47328C11.3441 7.90263 11.3244 8.52872 11.1172 9.34949L10.7 11.0704L10.6988 11.117C10.5785 11.5989 10.6141 11.8569 10.8102 11.8778L10.6839 12.334H6.97448C6.98705 12.0441 7.01011 11.7849 7.03466 11.5673C7.06161 11.3445 7.09485 11.172 7.12779 11.0534L7.47364 9.82302C7.64791 9.18319 7.65869 8.73683 7.49699 8.47976C7.3338 8.21582 6.96849 8.08595 6.39178 8.08595H4.83292L3.64417 12.334H0.015625ZM5.60427 5.30955H7.27391C7.85901 5.30955 8.29079 5.22625 8.55699 5.05398C8.82438 4.88021 9.02291 4.58672 9.13939 4.16215C9.26006 3.73042 9.23042 3.42947 9.05734 3.25839C8.88457 3.08462 8.47105 3.00012 7.82098 3.00012H6.24715L5.60427 5.30955Z"
          fill="white"
        />
        <path
          d="M22.8879 3.24212L20.3595 12.3338H17.287L17.6646 11.0019C17.1238 11.5316 16.5704 11.9338 16.0126 12.1908C15.4592 12.4524 14.8753 12.5811 14.2606 12.5811C13.7525 12.5811 13.3162 12.4891 12.965 12.3067C12.6095 12.1248 12.3448 11.8484 12.1667 11.4838C12.0086 11.1646 11.9397 10.7705 11.9666 10.2997C11.9951 9.83631 12.1592 9.05466 12.4634 7.9601L13.7737 3.24212H17.1346L15.8269 7.93801C15.6359 8.62532 15.5904 9.1084 15.6835 9.37144C15.7805 9.63687 16.0395 9.77391 16.46 9.77391C16.8834 9.77391 17.2394 9.62104 17.5334 9.30904C17.8317 8.99972 18.0619 8.53843 18.235 7.92457L19.5318 3.24212H22.8879Z"
          fill="white"
        />
        <path
          d="M21.6719 12.334L25.098 0.0132418H29.8111C30.851 0.0132418 31.6559 0.0744476 32.2299 0.208804C32.8027 0.337487 33.2519 0.546485 33.5858 0.841172C34.0041 1.22663 34.2604 1.70374 34.3658 2.27609C34.4649 2.84845 34.4062 3.51515 34.1891 4.298C33.8053 5.6753 33.1324 6.73074 32.1736 7.46821C31.2119 8.19612 30.0219 8.56187 28.6017 8.56187H26.3961L25.3483 12.334H21.6719ZM27.2312 5.55289H28.4163C29.1826 5.55289 29.7213 5.45854 30.0369 5.27641C30.3423 5.0919 30.5594 4.76407 30.6917 4.298C30.8241 3.82567 30.79 3.49545 30.5899 3.31093C30.3974 3.12731 29.9054 3.03446 29.1182 3.03446H27.9354L27.2312 5.55289Z"
          fill="white"
        />
        <path
          d="M38.1463 12.3341L38.1804 11.4707C37.6384 11.8752 37.0896 12.181 36.5377 12.372C35.9882 12.5667 35.4032 12.6655 34.7764 12.6655C33.8245 12.6655 33.1607 12.4076 32.7768 11.9084C32.3977 11.4083 32.3354 10.6902 32.5942 9.77419C32.8418 8.87012 33.2823 8.20461 33.9177 7.77915C34.5495 7.34861 35.6047 7.04049 37.0851 6.84343C37.2728 6.81298 37.5243 6.7885 37.8394 6.75058C38.9338 6.62429 39.5482 6.33348 39.6806 5.85905C39.7494 5.5993 39.7075 5.40762 39.5458 5.28998C39.3898 5.16787 39.0982 5.10785 38.6772 5.10785C38.3277 5.10785 38.0466 5.18011 37.816 5.33029C37.5857 5.48196 37.4139 5.7035 37.2974 6.0152H34.0207C34.3165 4.99171 34.9211 4.21842 35.8286 3.7013C36.7329 3.17522 37.9253 2.92203 39.4021 2.92203C40.0965 2.92203 40.7184 2.98682 41.2682 3.12745C41.8188 3.2621 42.2213 3.45408 42.4824 3.68637C42.8037 3.97628 42.9938 4.30679 43.048 4.67224C43.1106 5.03679 43.042 5.55929 42.8504 6.24301L41.4409 11.3118C41.3957 11.4778 41.387 11.6259 41.4116 11.7606C41.4409 11.8889 41.4972 11.9988 41.5967 12.0773L41.5209 12.3341H38.1463ZM38.9631 8.27448C38.6062 8.41749 38.1424 8.55453 37.566 8.70501C36.6608 8.94715 36.1514 9.2702 36.0412 9.66999C35.9649 9.92705 35.9957 10.125 36.1218 10.2755C36.2461 10.4185 36.462 10.4908 36.7662 10.4908C37.324 10.4908 37.772 10.3501 38.1058 10.0725C38.4406 9.79121 38.6909 9.34962 38.8661 8.74054C38.8967 8.61096 38.9236 8.51781 38.941 8.44824L38.9631 8.27448Z"
          fill="white"
        />
        <path
          d="M41.5391 15.9163L42.285 13.2253H43.2467C43.568 13.2253 43.8208 13.162 43.9998 13.0468C44.1816 12.9271 44.3052 12.7252 44.3765 12.4535C44.4121 12.3338 44.434 12.2078 44.449 12.0648C44.4585 11.9131 44.4585 11.7528 44.449 11.5671L43.9357 3.24208H47.3376L47.2849 8.75844L50.2547 3.24208H53.4182L48.1679 12.2899C47.5721 13.3012 47.1391 13.9959 46.8642 14.3751C46.5929 14.7495 46.3354 15.0406 46.0854 15.2377C45.7614 15.5103 45.3997 15.7034 45.0092 15.816C44.6178 15.931 44.022 15.9883 43.221 15.9883C42.9904 15.9883 42.7251 15.9838 42.4383 15.9689C42.1535 15.9578 41.8496 15.9408 41.5391 15.9163Z"
          fill="white"
        />
      </g>
      <path
        d="M26.056 20.4H23.752V26H24.792V24.384H26.056C27.528 24.384 28.44 23.624 28.44 22.392C28.44 21.152 27.528 20.4 26.056 20.4ZM26.008 23.504H24.792V21.28H26.008C26.92 21.28 27.392 21.688 27.392 22.392C27.392 23.096 26.92 23.504 26.008 23.504ZM34.2626 26L32.9746 24.16C33.7346 23.864 34.1666 23.24 34.1666 22.392C34.1666 21.152 33.2546 20.4 31.7826 20.4H29.4786V26H30.5186V24.368H31.7826C31.8546 24.368 31.9266 24.368 31.9986 24.36L33.1426 26H34.2626ZM33.1186 22.392C33.1186 23.096 32.6466 23.512 31.7346 23.512H30.5186V21.28H31.7346C32.6466 21.28 33.1186 21.688 33.1186 22.392ZM36.3701 25.128V23.584H39.0741V22.728H36.3701V21.272H39.4181V20.4H35.3301V26H39.5301V25.128H36.3701ZM42.9935 20.4H40.6895V26H41.7295V24.384H42.9935C44.4655 24.384 45.3775 23.624 45.3775 22.392C45.3775 21.152 44.4655 20.4 42.9935 20.4ZM42.9455 23.504H41.7295V21.28H42.9455C43.8575 21.28 44.3295 21.688 44.3295 22.392C44.3295 23.096 43.8575 23.504 42.9455 23.504ZM50.3268 26H51.4148L48.8868 20.4H47.8628L45.3428 26H46.4148L46.9668 24.704H49.7668L50.3268 26ZM47.3188 23.888L48.3668 21.44L49.4228 23.888H47.3188ZM52.127 26H53.167V20.4H52.127V26ZM54.6817 26H57.1297C58.9457 26 60.1857 24.88 60.1857 23.2C60.1857 21.52 58.9457 20.4 57.1297 20.4H54.6817V26ZM55.7217 25.12V21.28H57.0817C58.3297 21.28 59.1377 22.04 59.1377 23.2C59.1377 24.36 58.3297 25.12 57.0817 25.12H55.7217Z"
        fill="white"
      />
      <defs>
        <clipPath id="RupayPrepaidIcon">
          <rect width="61" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const formatMaskedCardNumber = (cardNo: string) => {
  const cNumber = cardNo.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ")
  return cNumber.replaceAll("X", "*")
}

type IssueWalletSteps =
  | "choose_member"
  | "set_limits"
  | "card_issued"
  | "check_user_status"

type SelectedMemberForCard = {
  name: string
  phone: string
  id: string
  isExistingUser?: boolean
  inviteLink?: string
}

type PropsForStepChange = {
  step: IssueWalletSteps
  member?: SelectedMemberForCard
  limits?: WalletLimits
  inviteLink?: string
}
export function IssueWalletInModal({
  children,
  ...props
}: CommonPropsForIssueCard & {
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const walletExistState = useOverlayTriggerState({})
  const { walletLimits } = useNewCardIssue(props.businessId)
  const { checkIfPaymentsEnabled } = useCheckIfPaymentsEnabled(props.businessId)
  const { business, authTeamMemberDetails, businessTeam } = useBusiness(
    props.businessId
  )

  const [step, setStep] = useState<{
    prevStep?: IssueWalletSteps
    current: IssueWalletSteps
  }>({ current: "choose_member" })

  const [selectedMember, setSelectedMember] =
    useState<SelectedMemberForCard | null>(null)
  const [updatedWalletLimits, setUpdatedWalletLimits] =
    useState<WalletLimits | null>(null)

  function onStepChange({
    step,
    member,
    limits,
    inviteLink,
  }: PropsForStepChange) {
    if (step === "choose_member") {
      setStep({ current: step })
      setSelectedMember(null)
      setUpdatedWalletLimits(null)
      return
    }
    if (inviteLink?.length) {
      setSelectedMember((prev) => {
        if (prev) {
          return { ...prev, inviteLink }
        }
        return null
      })
    }
    if (member?.id) {
      setSelectedMember(member)
    }
    if (limits) {
      setUpdatedWalletLimits(limits)
    }
    setStep((prev) => {
      return {
        prevStep: prev.current,
        current: step,
      }
    })
  }

  function onBackHandler() {
    setStep((prev) => {
      return {
        current: prev?.prevStep || "choose_member",
        prevStep: "choose_member",
      }
    })
  }

  const selectedUserRole = useMemo(() => {
    if (selectedMember?.id && selectedMember?.id !== selectedMember?.phone) {
      const found = businessTeam.find(({ id }) => selectedMember.id === id)
      if (found && found.role.id !== "owner") {
        return found.role.id
      }
      return undefined
    }
  }, [businessTeam, selectedMember?.id, selectedMember?.phone])

  const onClose = () => {
    state.close()
    setStep({ current: "choose_member" })
    setSelectedMember(null)
    setUpdatedWalletLimits(null)
  }

  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        title={
          step.current === "choose_member"
            ? "Issue Member Cards"
            : step.current === "set_limits"
            ? "Set Limits & Issue"
            : step.current === "card_issued"
            ? "Share Card Invite"
            : step.current === "check_user_status"
            ? "Issue New Card"
            : ""
        }
        placement="right"
        isOpen={state.isOpen}
        onClose={onClose}
        onBackPress={
          step.current !== "choose_member" && step.current !== "card_issued"
            ? onBackHandler
            : undefined
        }
      >
        {step.current === "choose_member" ? (
          <ChooseMember
            {...props}
            authTeamMemberDetails={authTeamMemberDetails}
            businessTeam={businessTeam}
            onStepChange={onStepChange}
            checkIfPaymentsEnabled={checkIfPaymentsEnabled}
            enableWalletExistModal={() => walletExistState.open()}
          />
        ) : step.current === "check_user_status" ? (
          <CheckIfUsersExists
            {...props}
            onStepChange={onStepChange}
            checkIfPaymentsEnabled={checkIfPaymentsEnabled}
            enableWalletExistModal={() => walletExistState.open()}
          />
        ) : step.current === "set_limits" &&
          selectedMember?.id &&
          walletLimits?.daily_debit ? (
          <SetLimits
            {...props}
            business={business}
            member={selectedMember}
            walletLimits={{
              daily_debit: walletLimits.daily_debit.value,
              each: walletLimits.each,
            }}
            onStepChange={onStepChange}
            selectedUserRole={selectedUserRole}
          />
        ) : updatedWalletLimits && selectedMember ? (
          <ShareCardInvite
            member={selectedMember}
            businessName={business.name}
            walletLimits={updatedWalletLimits}
            onStepChange={onStepChange}
          />
        ) : null}
      </Modal>
      <Modal
        title="Unable to Issue Card"
        isOpen={walletExistState.isOpen}
        onClose={walletExistState.close}
      >
        <ModalBody>
          <Stack gap="6" textAlign="center" alignItems="center">
            <CannotActivateCardIcon size="24" />
            <Stack gap="6" alignItems="center">
              <Text fontSize="s1">
                Multiple cards can't exist with same phone number
              </Text>
              <Box
                backgroundColor="gray400"
                rounded="full"
                width="6"
                height="2"
              />
              <Stack as="ul" gap="4">
                {pointers_for_multiple_cards.map((pointer) => (
                  <Inline id={pointer} alignItems="center" gap="3">
                    <Circle size="2" backgroundColor="gray400" />
                    <Text>{pointer}</Text>
                  </Inline>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <CBButton level="primary" size="lg" onClick={walletExistState.close}>
            <CheckIcon />
            Okay, Got It
          </CBButton>
        </ModalFooter>
      </Modal>
    </>
  )
}

function sortContacts(a: TBusinessMember, b: TBusinessMember) {
  const contact1 = a?.name?.toUpperCase()
  const contact2 = b?.name?.toUpperCase()
  return contact1 < contact2 ? -1 : contact1 > contact2 ? 1 : 0
}

type CommonPropsForIssueCard = {
  businessId: string
  onStepChange?: (props: PropsForStepChange) => void
}
function ChooseMember({
  businessTeam,
  authTeamMemberDetails,
  onStepChange,
  checkIfPaymentsEnabled,
  enableWalletExistModal,
}: CommonPropsForIssueCard & {
  businessTeam: TBusinessMember[]
  authTeamMemberDetails: TBusinessMember
  enableWalletExistModal?: () => void
  checkIfPaymentsEnabled: (
    memberId?: string | undefined
  ) => Promise<WalletEnabledResponse>
}) {
  const [q, setQuery] = useState<string>("")

  const issuedMembers = useMemo(
    () =>
      businessTeam
        .filter(
          (user) =>
            !!user.can_enable_wallet && user.uid !== authTeamMemberDetails.uid
        )
        .sort(sortContacts),
    [businessTeam, authTeamMemberDetails.uid]
  )

  const nonIssuedMembers = useMemo(
    () =>
      businessTeam.filter((user) => !user.can_enable_wallet).sort(sortContacts),
    [businessTeam]
  )

  const businessMembers = useMemo(
    () =>
      [...nonIssuedMembers, ...issuedMembers].filter(
        (user) =>
          user.phoneNumber?.includes(q) ||
          user.name.toLowerCase().includes(q.toLowerCase())
      ),
    [issuedMembers, nonIssuedMembers, q]
  )

  return (
    <>
      <ModalBody>
        <Stack gap="6">
          <Inline
            position="relative"
            rounded="md"
            height="10"
            paddingRight="2"
            alignItems="stretch"
            gap="6"
            borderWidth="1"
            backgroundColor="backgroundLight1"
            width="full"
            borderColor="borderOutline"
            className="bg-opacity-20 focus-within:border-blue-900 focus-within:ring-1 ring-blue-900"
          >
            <input
              type="search"
              name="q"
              autoComplete="off"
              placeholder="Search by name or number"
              value={q}
              onChange={(e) => setQuery(e.currentTarget.value)}
              className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
            />
            <Inline
              as="button"
              type="button"
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                if (q) setQuery("")
              }}
            >
              {q.length ? (
                <CancelIcon color="gray900" />
              ) : (
                <SearchIcon color="gray500" />
              )}
            </Inline>
          </Inline>
          <Inline
            as="button"
            rounded="md"
            borderWidth="1"
            padding="4"
            gap="4"
            alignItems="center"
            justifyContent="between"
            onClick={() => onStepChange?.({ step: "check_user_status" })}
          >
            <Inline gap="4">
              <Circle size="12">
                <UserAddIcon />
              </Circle>
              <Stack gap="2" textAlign="left">
                <Text fontSize="s3">Add New Member</Text>
                <Text fontSize="c2" color="textMedium">
                  Add member to team & issue card
                </Text>
              </Stack>
            </Inline>
            <ArrowRightIcon />
          </Inline>
          <Stack gap="4">
            <Text fontSize="s4" color="textMedium">
              Issue card to existing members
            </Text>
            <Stack as="ul">
              {businessMembers.length
                ? businessMembers.map((member) => {
                    const isCardIssued = member.can_enable_wallet
                    return (
                      <Inline
                        as="li"
                        key={member.id}
                        paddingY="4"
                        borderBottomWidth="1"
                        borderColor="borderDividers"
                        alignItems="center"
                      >
                        <Inline
                          as="button"
                          width="full"
                          alignItems="center"
                          disabled={isCardIssued}
                          justifyContent="between"
                          onClick={async () => {
                            if (!isPhoneNumberIndian(member.phoneNumber)) {
                              return toast.error(
                                "You can only issue card to Indian phone number!"
                              )
                            }
                            const response = await checkIfPaymentsEnabled(
                              member.uid
                            )
                            if (response.data?.walletEnabled) {
                              return enableWalletExistModal?.()
                            }
                            const selectedMember = {
                              id: member.id,
                              phone: member?.phoneNumber || "",
                              name: member.name,
                              isExistingUser: true,
                            }
                            onStepChange?.({
                              step: "set_limits",
                              member: selectedMember,
                            })
                          }}
                        >
                          <Inline gap="6">
                            <Avatar
                              size="12"
                              id={member.id}
                              name={member.name}
                            />
                            <Stack gap="2" textAlign="left">
                              <Text fontSize="s3">{member.name}</Text>
                              <Text fontSize="b3" color="textMedium">
                                {member?.phoneNumber || member?.email || ""}
                              </Text>
                            </Stack>
                          </Inline>
                          <Inline gap="2" alignItems="center">
                            {isCardIssued ? (
                              <CheckCircleSolidIcon color="iconSuccess" />
                            ) : null}
                            <Text
                              fontSize="bt"
                              color={
                                isCardIssued ? "textMedium" : "textPrimary"
                              }
                            >
                              {isCardIssued ? "ISSUED" : "ISSUE"}
                            </Text>
                          </Inline>
                        </Inline>
                      </Inline>
                    )
                  })
                : null}
            </Stack>
          </Stack>
        </Stack>
      </ModalBody>
    </>
  )
}

const pointers_for_multiple_cards = [
  "Another card already exists for this number in some other business",
  "Try another number",
]
function CheckIfUsersExists({
  businessId,
  onStepChange,
  checkIfPaymentsEnabled,
  enableWalletExistModal,
}: CommonPropsForIssueCard & {
  enableWalletExistModal?: () => void
  checkIfPaymentsEnabled: (
    memberId?: string | undefined
  ) => Promise<WalletEnabledResponse>
}) {
  const { data: user } = useUser()
  const { checkIfPhonesExists } = useCheckUsersExists()
  const { businessTeam } = useBusiness(businessId)
  const { invitations } = useShareBusinessInvitations(businessId)

  const { phoneNumber: myPhoneNumber } = user || { phoneNumber: "" }
  const defaultCountry = useMemo(
    () =>
      (myPhoneNumber ? parsePhoneNumber(myPhoneNumber)?.country : "IN") || "IN",
    [myPhoneNumber]
  )

  async function checkUserStatus(phone: string) {
    try {
      const { data: usersInfo } = await checkIfPhonesExists([phone])
      if (usersInfo[0]?.isAppUser && usersInfo[0]?.uid) {
        const response = await checkIfPaymentsEnabled(usersInfo[0].uid)
        if (response.data?.walletEnabled) {
          return enableWalletExistModal?.()
        }
      }
      if (usersInfo[0]) {
        const selectedMember = {
          name:
            usersInfo[0]?.name ||
            parsePhoneNumber(usersInfo[0].phone)?.nationalNumber ||
            "CashBook User",
          phone: usersInfo[0]?.phone || "",
          id: usersInfo[0]?.uid || usersInfo[0].phone,
          isExistingUser: usersInfo[0]?.isAppUser || false,
        }
        onStepChange?.({ step: "set_limits", member: selectedMember })
      }
    } catch (e) {
      const error = e as Error
      throw error
    }
  }

  return (
    <>
      <Formik
        initialValues={{ phoneNumber: "" as string }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Validator.object().shape({
          phoneNumber: PhoneNumberValidator.test({
            name: "valid-phone-number",
            test: (value: string | undefined | null) => {
              if (!value) {
                // required attribute should handle the empty case
                return true
              }
              if (!isPhoneNumberIndian(value)) {
                return false
              }
              return (
                isPossiblePhoneNumber(value) &&
                !businessTeam.some((m) =>
                  arePhoneNumbersSame(m.phoneNumber, value)
                ) &&
                !invitations.some((m) =>
                  arePhoneNumbersSame(m.guestPhone, value)
                )
              )
            },
            message: function test(params) {
              const existingMember = businessTeam.find((m) =>
                arePhoneNumbersSame(m.phoneNumber, params.value)
              )
              const existingInvitation = invitations.find((m) =>
                arePhoneNumbersSame(m.guestPhone, params.value)
              )
              if (!isPhoneNumberIndian(params.value)) {
                return "You can only issue card to Indian phone number!"
              }
              if (existingMember?.uid === user?.uid) {
                return `You are not allowed to add your number to the business`
              }
              if (existingInvitation) {
                return `Member with this phone (${existingInvitation?.guestPhone}) already invited in the business.`
              }
              if (existingMember) {
                return `Member with this phone (${existingMember?.phoneNumber}) already added to this business.`
              }
              return `Please enter a valid mobile number`
            },
          }),
        })}
        onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
          await checkUserStatus(values.phoneNumber)
        })}
      >
        {({ isSubmitting, status }) => (
          <Form noValidate>
            <ModalBody>
              <Stack gap="6">
                <Stack gap="1">
                  <Box as="label" htmlFor="phoneNumber" fontSize="c2">
                    Mobile number{" "}
                    <Text as="span" fontSize="s3" color="iconError">
                      *
                    </Text>
                  </Box>
                  <FormField
                    name="phoneNumber"
                    renderInput={({
                      field: { onChange, ...otherFieldProps },
                      form,
                    }: FieldProps<string>) => (
                      <Box className="max-w-xs">
                        <PhoneInput
                          {...otherFieldProps}
                          id="phoneNumber"
                          onChange={(phoneNumber) =>
                            form.setFieldValue(
                              otherFieldProps.name,
                              phoneNumber
                            )
                          }
                          defaultCountry={defaultCountry}
                          required
                          autoFocus
                        />
                      </Box>
                    )}
                  />
                </Stack>
                {status ? <Alert status="error">{status}</Alert> : null}
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CBButton
                type="submit"
                level="primary"
                size="lg"
                loading={isSubmitting}
              >
                {isSubmitting ? "Verifying Number..." : "Next"}
              </CBButton>
              <CBButton size="lg" disabled={isSubmitting}>
                Cancel
              </CBButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </>
  )
}

type WalletLimits = {
  daily_debit: number
  each: number
}

function SetLimits({
  member,
  business,
  walletLimits,
  selectedUserRole,
  onStepChange,
}: CommonPropsForIssueCard & {
  business: TBusiness
  walletLimits?: WalletLimits
  member: SelectedMemberForCard
  selectedUserRole?: "staff" | "partner"
}) {
  const addToBusiness = useAddTeamMember()
  const memberExistence = useMemo(() => {
    if (selectedUserRole?.length) {
      return "businessUser"
    } else if (member?.id !== member?.phone) {
      return "cashbookUser"
    } else {
      return "newUser"
    }
  }, [member?.id, member?.phone, selectedUserRole?.length])
  return (
    <Formik
      initialValues={{
        transaction_limit: walletLimits?.each as number,
        daily_limit: walletLimits?.daily_debit as number,
        addingToBusiness: false as boolean,
        issuingCard: false as boolean,
        role: "Staff" as string,
        dragger: { daily_limit: null, each: null } as {
          daily_limit: boolean | null
          each: boolean | null
        },
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Validator.object().shape({
        transaction_limit: Validator.number().required(
          "Please enter a valid number!"
        ),
        daily_limit: Validator.number().required(
          "Please enter a valid number!"
        ),
      })}
      onSubmit={formikOnSubmitWithErrorHandling(async (values, actions) => {
        actions.setFieldValue("issuingCard", true)
        if (memberExistence === "newUser") {
          actions.setFieldValue("addingToBusiness", true)
        }
        const role = selectedUserRole || "staff"
        actions.setFieldValue("role", role)
        const { data } = await addToBusiness({
          businessId: business.id,
          guestName: member.name,
          guestPhone: member.phone,
          role: role,
          can_enable_wallet: true,
          wallet_issued_limits: {
            each: values.transaction_limit,
            daily: values.daily_limit,
          },
        })
        trackEvent(TrackingEvents.WALLET_ISSUED, {
          memberType: memberExistence,
          dailyLimitAltered:
            values.dragger.daily_limit === null
              ? null
              : values.dragger.daily_limit
              ? "usingDragger"
              : "withoutDragger",
          perTransactionLimitAltered:
            values.dragger.each === null
              ? null
              : values.dragger.each
              ? "usingDragger"
              : "withoutDragger",
        })
        actions.setFieldValue("addingToBusiness", false)
        setTimeout(() => {
          actions.setFieldValue("issuingCard", false)
          onStepChange?.({
            step: "card_issued",
            inviteLink: data.inviteLink,
            limits: {
              daily_debit: values.daily_limit,
              each: values.transaction_limit,
            },
          })
        }, 3000)
      })}
    >
      {({ values, setFieldValue }) => {
        const transactionLimitInPercentage =
          ((values.transaction_limit || 0) / (walletLimits?.each || 0)) * 100
        const dailyLimitInPercentage =
          ((values.daily_limit || 0) / (walletLimits?.daily_debit || 0)) * 100
        return values.addingToBusiness || values.issuingCard ? (
          <ModalBody>
            <Stack
              height="full"
              width="full"
              alignItems="center"
              justifyContent="center"
            >
              {values.addingToBusiness ? (
                <Stack gap="4">
                  <Inline
                    position="relative"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <SpinnerIcon size="16" className="text-[#5A72D8]" />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      className="absolute"
                    >
                      <path
                        d="M13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12C11.21 12 13 10.21 13 8ZM15 10V12H18V15H20V12H23V10H20V7H18V10H15ZM1 18V20H17V18C17 15.34 11.67 14 9 14C6.33 14 1 15.34 1 18Z"
                        fill="#578BFF"
                      />
                    </svg>
                  </Inline>
                  <Stack gap="3" textAlign="center">
                    <Text fontSize="s3" color="textPrimary">
                      Adding {member.name} to Team
                    </Text>
                    <Text fontSize="b3">
                      Role:{" "}
                      <Text as="span" textTransform="capitalize">
                        {values.role}
                      </Text>
                    </Text>
                  </Stack>
                </Stack>
              ) : (
                <Stack gap="4">
                  <Inline
                    position="relative"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <SpinnerIcon size="16" className="text-[#5A72D8]" />
                    <GradientCardIcon className="absolute" />
                  </Inline>
                  <Stack gap="3" textAlign="center">
                    <Text fontSize="s3" color="textPrimary">
                      Creating card for {member.name}
                    </Text>
                    <Text fontSize="b3">Please wait...</Text>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </ModalBody>
        ) : (
          <Form noValidate>
            <ModalBody>
              <Stack gap="10" marginBottom="24">
                <Inline
                  borderWidth="1"
                  borderColor="borderOutline"
                  paddingY="4"
                  paddingX="6"
                  rounded="md"
                  gap="6"
                  alignItems="center"
                >
                  <Avatar size="12" name={member.name} id={member.id} />
                  <Stack gap="2">
                    <Text fontSize="s3">{member.name}</Text>
                    <Text fontSize="b3" color="textMedium">
                      {member.phone}
                    </Text>
                  </Stack>
                </Inline>
                {walletLimits?.each && walletLimits?.daily_debit ? (
                  <Stack gap="4">
                    <Text fontSize="s4" color="textMedium">
                      Card Limits
                    </Text>
                    <Inline gap="4" alignItems="center" color="textWarning">
                      <InformationWarningIcon size="5" />
                      <Text fontSize="c2">
                        These limits will be applicable after full KYC
                      </Text>
                    </Inline>
                    <Stack
                      gap="2"
                      padding="4"
                      rounded="md"
                      backgroundColor="surfaceNeutralLowest"
                    >
                      <Inline alignItems="center" justifyContent="between">
                        <Text fontSize="b1">Per Transaction Limit</Text>

                        <FormField
                          type="text"
                          name="transaction_limit"
                          inputMode="numeric"
                          placeholder="₹1000"
                          noMargin
                          className="w-[120px] text-right"
                          maxLength={walletLimits.each}
                          value={`₹${values.transaction_limit}`}
                          autoComplete="off"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const numericVal = e.currentTarget.value.replace(
                              /[^0-9]/g,
                              ""
                            )
                            if (Number(numericVal) <= walletLimits.each) {
                              setFieldValue(
                                "transaction_limit",
                                Number(numericVal)
                              )
                            }
                          }}
                        />
                      </Inline>
                      <Stack gap="2" paddingY="4">
                        <Box
                          position="relative"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box
                            as="input"
                            type="range"
                            width="full"
                            rounded="full"
                            height="2"
                            value={values.transaction_limit}
                            name="transaction_limit"
                            max={walletLimits.each}
                            step="100"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setFieldValue(
                                "transaction_limit",
                                Number(e.target.value)
                              )
                              setFieldValue("dragger", {
                                ...values.dragger,
                                each: true,
                              })
                            }}
                            borderWidth="0"
                            style={{
                              accentColor: "#179F51",
                              appearance: "none",
                              outline: "none",
                              background: `linear-gradient(to right, #179F51 0%, #179F51 ${transactionLimitInPercentage}%, #FFF ${transactionLimitInPercentage}%, #FFF 100%)`,
                            }}
                          />
                          <Box
                            position="absolute"
                            className="w-[2px]"
                            height="3"
                            onClick={() => {
                              setFieldValue(
                                "transaction_limit",
                                walletLimits.each / 2
                              )
                            }}
                            backgroundColor="surfaceSuccessLowest"
                          ></Box>
                        </Box>
                        <Inline
                          position="relative"
                          alignItems="center"
                          justifyContent="between"
                        >
                          <Inline fontSize="c2" gap="1" alignItems="center">
                            <Text>Min: </Text>
                            <Amount amount={0} currency="inr" />
                          </Inline>
                          <Box
                            fontSize="c2"
                            alignItems="center"
                            position="absolute"
                            className="right-[45%]"
                          >
                            <Amount amount={100000 / 2} currency="inr" />
                          </Box>
                          <Inline fontSize="c2" gap="1" alignItems="center">
                            <Text>Max: </Text>
                            <Amount amount={100000} currency="inr" />
                          </Inline>
                        </Inline>
                      </Stack>
                    </Stack>
                    <Stack
                      gap="2"
                      padding="4"
                      rounded="md"
                      backgroundColor="surfaceNeutralLowest"
                    >
                      <Inline alignItems="center" justifyContent="between">
                        <Text fontSize="b1">Daily Transaction Limit</Text>

                        <FormField
                          type="text"
                          name="daily_limit"
                          inputMode="numeric"
                          placeholder="₹1000"
                          noMargin
                          className="w-[120px] text-right"
                          maxLength={walletLimits.daily_debit}
                          value={`₹${values.daily_limit}`}
                          autoComplete="off"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const numericVal = e.currentTarget.value.replace(
                              /[^0-9]/g,
                              ""
                            )
                            if (
                              Number(numericVal) <= walletLimits.daily_debit
                            ) {
                              setFieldValue("daily_limit", Number(numericVal))
                              setFieldValue("dragger", {
                                ...values.dragger,
                                daily_limit: true,
                              })
                            }
                          }}
                        />
                      </Inline>
                      <Stack gap="2" paddingY="4">
                        <Box
                          position="relative"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box
                            as="input"
                            type="range"
                            width="full"
                            rounded="full"
                            height="2"
                            value={values.daily_limit}
                            name="daily_limit"
                            max={walletLimits.daily_debit}
                            step="100"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setFieldValue(
                                "daily_limit",
                                Number(e.target.value)
                              )
                            }}
                            borderWidth="0"
                            style={{
                              accentColor: "#179F51",
                              appearance: "none",
                              outline: "none",
                              background: `linear-gradient(to right, #179F51 0%, #179F51 ${dailyLimitInPercentage}%, #FFF ${dailyLimitInPercentage}%, #FFF 100%)`,
                            }}
                          />
                          <Box
                            position="absolute"
                            className="w-[2px]"
                            height="3"
                            onClick={() => {
                              setFieldValue(
                                "daily_limit",
                                walletLimits.each / 2
                              )
                            }}
                            backgroundColor="surfaceSuccessLowest"
                          ></Box>
                        </Box>
                        <Inline
                          position="relative"
                          alignItems="center"
                          justifyContent="between"
                        >
                          <Inline fontSize="c2" gap="1" alignItems="center">
                            <Text>Min: </Text>
                            <Amount amount={0} currency="inr" />
                          </Inline>
                          <Box
                            fontSize="c2"
                            alignItems="center"
                            position="absolute"
                            className="right-[45%]"
                          >
                            <Amount amount={100000 / 2} currency="inr" />
                          </Box>
                          <Inline fontSize="c2" gap="1" alignItems="center">
                            <Text>Max: </Text>
                            <Amount amount={100000} currency="inr" />
                          </Inline>
                        </Inline>
                      </Stack>
                    </Stack>
                  </Stack>
                ) : null}
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CBButton iconPlacement="right" size="lg" type="submit">
                Issue Card <ArrowRightIcon />{" "}
              </CBButton>
            </ModalFooter>
          </Form>
        )
      }}
    </Formik>
  )
}

function ShareCardInvite({
  member,
  inviteLink,
  walletLimits,
  businessName,
  onStepChange,
}: Pick<CommonPropsForIssueCard, "onStepChange"> & {
  inviteLink?: string
  businessName?: string
  walletLimits: WalletLimits
  member: SelectedMemberForCard
}) {
  const shareInviteText = useMemo(() => {
    return `Hey ${member.name}, I have created card for you to make business expense for ${businessName}. Here is the link to activate card - ${config.appDownloadLink}`
  }, [businessName, member.name])

  return (
    <>
      <ModalBody>
        <Stack gap="6" alignItems="center">
          <Stack gap="3" alignItems="center">
            <CardIssuedIcon size="16" />
            <Text fontSize="s1">Card Issued!</Text>
          </Stack>
          <Inline
            gap="6"
            paddingY="4"
            paddingX="6"
            borderWidth="1"
            rounded="md"
            width="full"
            borderColor="borderDividers"
          >
            <Avatar id={member.id} name={member.name} size="12" />
            <Stack gap="4">
              <Stack gap="2">
                <Text fontSize="s3">{member.name}</Text>
                <Text fontSize="b3" color="textMedium">
                  {member.isExistingUser
                    ? member.phone
                    : `Not a ${config.appTitle} user`}
                </Text>
              </Stack>
              <Stack gap="2">
                <Text color="textMedium" fontSize="b3">
                  Per transaction limit:{" "}
                  <Amount amount={walletLimits.each} currency="inr" as="span" />
                </Text>
                <Text color="textMedium" fontSize="b3">
                  Daily transaction limit:{" "}
                  <Amount
                    amount={walletLimits.daily_debit}
                    currency="inr"
                    as="span"
                  />
                </Text>
              </Stack>
            </Stack>
          </Inline>
          <Box>
            <Inline
              backgroundColor="surfaceNeutralLowest"
              paddingY="2"
              paddingX="4"
              roundedTop="md"
            >
              <Text fontSize="c2" color="textMedium">
                Share this message with {member.name}
              </Text>
            </Inline>
            <Stack
              gap="3"
              paddingY="5"
              paddingX="4"
              borderXWidth="1"
              roundedBottom="md"
              borderBottomWidth="1"
              borderColor="borderDividers"
            >
              <Text fontSize="s3">
                <Text
                  as="span"
                  aria-labelledby="hello-emoji"
                  aria-label="hello emoji"
                  role="img"
                >
                  {" "}
                  👋🏼{" "}
                </Text>
                Hey {member.name},
              </Text>
              <Text fontSize="b3" color="textMedium">
                I have created card for you to make business expense for
                {businessName}. Here is the link to activate card -{" "}
                {config.appDownloadLink}
              </Text>
            </Stack>
          </Box>
          <Inline
            gap="2"
            paddingY="3"
            rounded="md"
            backgroundColor="surfaceAlt2Lowest"
            color="textPrimary"
            alignItems="center"
            width="full"
            justifyContent="center"
            as="button"
            onClick={() => {
              onStepChange?.({ step: "choose_member" })
            }}
          >
            <PlusIcon />
            <Text>Issue More Cards</Text>
          </Inline>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <CopyToClipboard>
          {({ copy, copied }) => (
            <CBButton
              size="lg"
              level="primary"
              disabled={copied}
              onClick={() => {
                copy(shareInviteText)
                toast.success("Copied invite link successfully!")
              }}
            >
              {copied ? <CheckIcon /> : <CopyIcon />} Copy Link
            </CBButton>
          )}
        </CopyToClipboard>
        <WhatsAppContactLink
          phoneNumber={member.phone}
          size="lg"
          text={shareInviteText}
        >
          <WhatsAppIcon />
          Share on WhatsApp
        </WhatsAppContactLink>
      </ModalFooter>
    </>
  )
}
