import { useCallback, useEffect, useState } from "react"
import {
  CopyTransactionsToCashbookPayload,
  DownloadBusinessTransactionsReportPayload,
  copyTransactionsToCashbook,
  deleteTransactionAttachment,
  downloadBusinessTransactions,
  getTransactionAttachment,
  getTransactionDetails,
  updateTransaction,
  updateTransactionAttachment,
} from "./services"
import { PaymentsTransaction } from "./types"
import { fileToBase64, saveBlobAs } from "@cashbook/util-general"
import { toast } from "react-hot-toast"
import { logError } from "@cashbook/util-logging"

export type Pagination = {
  skip: number
  take: number
}

export type DownloadBusinessTransactionsReportFilters = {
  reportType: "pdf" | "csv"
  memberId?: string
  from_datetime?: Date
  to_datetime?: Date
  partyId?: string
  mode?: "CARD" | "UPI"
  status?: "SUCCESS" | "PENDING" | "REFUNDED"
  attachments?: "attached" | "missing"
}

export function useDownloadBusinessTransactionsReport() {
  const [status, setStatus] = useState<{
    loading: "init" | "in_progress" | "success" | "failed"
    type?: "pdf" | "csv"
  }>({ loading: "init" })
  const downloadReport = useCallback(
    async (
      businessId: string,
      fileName: string,
      filters: DownloadBusinessTransactionsReportFilters
    ) => {
      setStatus({ loading: "in_progress", type: filters.reportType })
      try {
        const constructedPayload: DownloadBusinessTransactionsReportPayload = {
          businessId,
          reportType: filters.reportType,
          mode: "CARD",
        }
        if (filters.from_datetime) {
          constructedPayload.from_datetime = filters.from_datetime.toISOString()
        }
        if (filters.to_datetime) {
          constructedPayload.to_datetime = filters.to_datetime.toISOString()
        }
        if (filters.status) {
          constructedPayload.status = filters.status
        }
        if (filters.memberId) {
          constructedPayload.memberId = filters.memberId
        }
        if (filters.partyId) {
          constructedPayload.partyId = filters.partyId
        }
        if (filters.attachments) {
          constructedPayload.attachments = filters.attachments
        }
        const response = await downloadBusinessTransactions<Blob>({
          ...constructedPayload,
        })
        if (!response) {
          throw new Error(
            `Not able to generate ${
              filters.reportType === "pdf" ? "PDF" : "Excel"
            } report at the moment.`
          )
        }
        setStatus((prev) => {
          return { ...prev, loading: "success" }
        })
        saveBlobAs(response, fileName)
        toast.success(`Successfully Downloaded. Please check your downloads.`)
      } catch (e) {
        const error = e as Error
        logError(error)
        setStatus((prev) => {
          return { ...prev, loading: "failed" }
        })
        toast.error(
          `${
            error.message || `Can't generate PDF at the moment.`
          } Please try again later!`
        )
      }
    },
    []
  )
  return {
    status,
    downloadReport,
  }
}

type AttachmentResponse = {
  thumbUrl: string[]
  url?: string[]
}
export function useGetAttachment() {
  const [state, setState] = useState<
    | { error: null; data: null; status: "init" }
    | { error: null; data: null; status: "in_progress" }
    | { error: null; data: AttachmentResponse; status: "success" }
    | { error: Error; data: null; status: "failed" }
  >({ status: "init", error: null, data: null })
  const getTransactionAttachmentDetails = useCallback(
    async (attachmentId: number) => {
      setState({ status: "in_progress", error: null, data: null })
      try {
        const response = await getTransactionAttachment<AttachmentResponse>({
          id: attachmentId,
        })
        setState({ status: "success", error: null, data: response })
        return response
      } catch (e) {
        const err = e as Error
        setState({ status: "failed", error: err, data: null })
        throw err
      }
    },
    []
  )

  return {
    state,
    getAttachment: getTransactionAttachmentDetails,
  }
}

export function useTransactionDetails(
  businessId: string,
  transactionId: string,
  attachmentId?: number
) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>(undefined)
  const [shouldApiCall, setShouldApiCall] = useState<boolean>(true)
  const [transaction, setTransaction] = useState<
    PaymentsTransaction | undefined
  >(undefined)
  const [attachment, setAttachment] = useState<AttachmentResponse | undefined>(
    undefined
  )

  const getTransaction = useCallback(async () => {
    try {
      const response = await getTransactionDetails<PaymentsTransaction>({
        transactionId,
        businessId,
      })
      if (attachmentId) {
        const attachment = await getTransactionAttachment<AttachmentResponse>({
          id: attachmentId,
        })
        if (attachment.url) {
          setAttachment(attachment)
        }
      }
      setIsLoading(false)
      if (!response.id) {
        throw new Error(
          "Something went wrong. While fetching transaction details for you."
        )
      }
      setTransaction(response)
    } catch (e) {
      const err = e as Error
      setIsLoading(false)
      setError(err)
    }
  }, [transactionId, attachmentId, businessId])

  function retryFetchingDetails() {
    setIsLoading(true)
    setShouldApiCall(true)
  }

  useEffect(() => {
    if (shouldApiCall) {
      getTransaction()
      setShouldApiCall(false)
    }
  }, [shouldApiCall, getTransaction])

  return {
    error,
    attachment,
    transaction,
    loading: isLoading,
    retry: retryFetchingDetails,
  }
}

export function useUpdateTransactionDetails(businessId: string) {
  const updateAttachment = useCallback(
    async (file: File, transactionId: number) => {
      try {
        const base64String = await fileToBase64(file)
        const response = await updateTransactionAttachment<{
          data: {
            id: string
            transaction_id: string
          }
        }>({
          fileName: file.name,
          mimeType: file.type,
          transactionId,
          fileBase64: base64String,
        })
        return response
      } catch (e) {
        const err = e as Error
        throw err
      }
    },
    []
  )

  const deleteAttachment = useCallback(async (attachmentId: number) => {
    try {
      const response = await deleteTransactionAttachment({ id: attachmentId })
      return response
    } catch (e) {
      const error = e as Error
      throw error
    }
  }, [])

  const updateDescription = useCallback(
    async ({
      description,
      transactionId,
    }: {
      description: string
      transactionId: string
    }) => {
      try {
        await updateTransaction({ businessId, transactionId, description })
      } catch (e) {
        const err = e as Error
        throw err
      }
    },
    [businessId]
  )

  return {
    deleteAttachment,
    updateAttachment,
    updateDescription,
  }
}

export function useCopyToCashbook() {
  return useCallback(async (data: CopyTransactionsToCashbookPayload) => {
    try {
      await copyTransactionsToCashbook(data)
      return true
    } catch (e) {
      const err = e as Error
      throw err
    }
  }, [])
}
