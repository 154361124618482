import {
  ArrowRightIcon,
  Box,
  Circle,
  DataLoadingFallback,
  HideEntriesIcon,
  Inline,
  PageMeta,
  Stack,
  Text,
  UnHideIcon,
  Button,
  AddMoneyIcon,
  SkeletonTitle,
  TransactionDate,
  Time,
  ExcelFileIcon,
  SkeletonRows,
  SkeletonAvatar,
  CardPendingIcon,
  CardDeactivatedIcon,
} from "@cashbook/web-components"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import { TBusinessUser, useBusiness } from "@cashbook/data-store/businesses"
import { Amount, currencies_supported } from "../support/Intl"
import { SyntheticEvent, useMemo, useState } from "react"
import {
  PaymentsTransaction,
  Wallet,
  getKycStatus,
  transformDate,
  useCashback,
  useDashboard,
  useStaffWallets,
} from "@cashbook/data-store/payments"
import {
  Avatar,
  GeneralErrorHandler,
  PaymentsIcons,
  TransactionDetailsInModal,
  getTransactionModeOfPayment,
} from "../Payments"
import { TUser, useProfile } from "@cashbook/data-store/users"
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "@cashbook/util-dates"
import config from "../config"
import { ViewMasterWalletDetailsInModal } from "../Payments/MasterWallet"
import { pluralize, useMount } from "@cashbook/util-general"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import { userWalletStore } from "@cashbook/data-store/storage"

export default function PaymentsDashboardPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading payments details..." />}
        traceId="loading_payments_details"
      >
        <PaymentsDashboard key={businessId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

const todaysDate = new Date()

export type OverviewFilterOptionsIds = "today" | "this_week" | "this_month"
export type OverviewFilterOption = {
  id: OverviewFilterOptionsIds
  title: string
  dates: [Date, Date]
}
const overviewFilterOptions: OverviewFilterOption[] = [
  {
    id: "today",
    title: "Today",
    dates: [startOfDay(todaysDate), endOfDay(todaysDate)],
  },
  {
    id: "this_week",
    title: "This Week",
    dates: [startOfWeek(todaysDate), endOfWeek(todaysDate)],
  },
  {
    id: "this_month",
    title: "This Month",
    dates: [startOfMonth(todaysDate), endOfMonth(todaysDate)],
  },
]

function PaymentsDashboard({ businessId }: { businessId: string }) {
  const [activeOverviewFilter, setOverviewFilter] =
    useState<OverviewFilterOption>(overviewFilterOptions[0])

  const navigate = useNavigate()
  const { user } = useProfile()
  const { pathname } = useLocation()
  const { business, authTeamMemberDetails, getTeamMemberInfoForId } =
    useBusiness(businessId)
  const {
    error,
    status,
    masterWalletDetails,
    totalExpenses,
    transactionsCount,
    businessTransactions,
    refreshPage,
    handleDurationChange,
  } = useDashboard(businessId, {
    from_datetime: activeOverviewFilter.dates?.[0],
    to_datetime: activeOverviewFilter.dates?.[1],
  })

  const { teamWallets } = useStaffWallets(businessId)
  const { totalCashback } = useCashback(businessId, {
    from_datetime: activeOverviewFilter.dates[0],
    to_datetime: activeOverviewFilter.dates[1],
  })

  useMount(() => {
    trackEvent(TrackingEvents.PAYMENTS_FILTER_APPLIED, {
      from: "businessTransactionsSummaryCard",
      dateFilter: activeOverviewFilter.id,
    })
  })

  return (
    <>
      <PageMeta>
        <title> Dashboard (Payments) - {business.name}</title>
      </PageMeta>
      <Box
        key={businessId}
        backgroundColor="backgroundLight2"
        paddingX="8"
        height="full"
      >
        {status === "in_progress" || status === "init" ? (
          <Inline height="full">
            <Stack
              width="full"
              paddingY="8"
              gap="8"
              maxWidth="3xl"
              minWidth="screenMd"
              borderRightWidth="1"
              paddingRight="8"
            >
              <Inline
                borderWidth="1"
                borderColor="borderOutline"
                rounded="md"
                paddingY="4"
                paddingX="6"
                justifyContent="between"
                backgroundColor="backgroundLight1"
              >
                <Stack gap="2" width="full" maxWidth="xs">
                  <SkeletonTitle height="3" />
                  <SkeletonTitle width="full" />
                </Stack>
              </Inline>
              <Inline
                borderWidth="1"
                borderColor="borderOutline"
                rounded="md"
                paddingY="4"
                paddingX="6"
                justifyContent="between"
                backgroundColor="backgroundLight1"
              >
                <Stack gap="2" width="full" maxWidth="xs">
                  <SkeletonTitle height="3" />
                  <SkeletonTitle width="full" />
                  <SkeletonTitle width="full" />
                  <SkeletonTitle width="full" />
                </Stack>
              </Inline>
              <Box
                as="table"
                backgroundColor="backgroundLight1"
                rounded="md"
                borderWidth="1"
              >
                <SkeletonRows numOfRows={6} numOfCols={4} />
              </Box>
            </Stack>
            <Stack gap="8" width="full" padding="8">
              <Inline
                borderWidth="1"
                borderColor="borderOutline"
                rounded="md"
                paddingY="4"
                paddingX="6"
                gap="3"
                alignItems="center"
                backgroundColor="backgroundLight1"
              >
                <SkeletonAvatar />
                <Stack gap="2" width="full" maxWidth="xs">
                  <SkeletonTitle height="3" />
                  <SkeletonTitle width="full" />
                </Stack>
              </Inline>
              <Inline
                borderWidth="1"
                borderColor="borderOutline"
                rounded="md"
                paddingY="4"
                paddingX="6"
                gap="3"
                alignItems="center"
                backgroundColor="backgroundLight1"
              >
                <SkeletonAvatar />
                <Stack gap="2" width="full" maxWidth="xs">
                  <SkeletonTitle height="3" />
                  <SkeletonTitle width="full" />
                </Stack>
              </Inline>
              <Inline
                borderWidth="1"
                borderColor="borderOutline"
                rounded="md"
                paddingY="4"
                paddingX="6"
                gap="3"
                alignItems="center"
                backgroundColor="backgroundLight1"
              >
                <SkeletonAvatar />
                <Stack gap="2" width="full" maxWidth="xs">
                  <SkeletonTitle height="3" />
                  <SkeletonTitle width="full" />
                </Stack>
              </Inline>
            </Stack>
          </Inline>
        ) : error ? (
          <Stack height="full">
            <GeneralErrorHandler onRetry={refreshPage} />
          </Stack>
        ) : (
          <Inline>
            <Stack
              width="full"
              maxWidth={{ xl: "3xl", lg: "4xl", xs: "full" }}
              paddingRight={{ lg: "8", xs: "0" }}
              paddingY="6"
              gap="10"
              className="lg:border-r-[1px] xl:min-w-[760px]"
              borderColor="borderOutline"
            >
              {Boolean(masterWalletDetails) && (
                <Inline
                  backgroundColor="surfaceDefault"
                  borderColor="borderOutline"
                  paddingY="4"
                  borderWidth="1"
                  rounded="md"
                  paddingX="6"
                  alignItems="center"
                  justifyContent="between"
                  as={Link}
                  gap="2"
                  onClick={() => {
                    trackEvent(TrackingEvents.PAYMENTS_TAB_SWITCHED, {
                      currentTab: "masterWallet",
                      previousTab: "dashboard",
                    })
                  }}
                  to={pathname.replace("dashboard", "master-wallet")}
                  className="hover:bg-blue-5"
                >
                  <Stack gap="6">
                    <Inline gap="2" alignItems="center">
                      <Text fontSize="s3">Master Wallet</Text>
                      <Text fontSize="b4" color="textMedium">
                        (
                        {hideAccountString(
                          masterWalletDetails?.account_no || ""
                        )}
                        )
                      </Text>
                      <ArrowRightIcon color="textMedium" />
                    </Inline>
                    <Stack gap="2">
                      <Text fontSize="c3">Available Balance</Text>
                      <HideAbleBalance
                        amount={masterWalletDetails?.balance || "0"}
                      />
                    </Stack>
                  </Stack>
                  <ViewMasterWalletDetailsInModal
                    from="addMoney"
                    businessName={business.name}
                    ifscCode={masterWalletDetails?.ifsc_code}
                    accountNumber={masterWalletDetails?.account_no}
                  >
                    {({ open }) => (
                      <Button
                        size="lg"
                        status="success"
                        onClick={(e) => {
                          e.preventDefault()
                          open()
                        }}
                      >
                        <Box>
                          <AddMoneyIcon />
                        </Box>
                        <Text className="xl:block hidden">Add Money</Text>
                      </Button>
                    )}
                  </ViewMasterWalletDetailsInModal>
                </Inline>
              )}
              <Stack gap="4">
                <Inline
                  flexDirection={{ xl: "row", xs: "col" }}
                  alignItems={{ xl: "center" }}
                  gap={{ xl: "2", lg: "4" }}
                  justifyContent="between"
                >
                  <Text fontSize="s1">Overall Business Expenses</Text>
                  <Inline as="ul" gap="4">
                    {overviewFilterOptions.map((option) => {
                      const isSelected = option.id === activeOverviewFilter.id
                      return (
                        <Box
                          key={option.id}
                          borderWidth="1"
                          rounded="md"
                          borderColor={
                            isSelected ? "borderPrimaryLow" : "borderOutline"
                          }
                          paddingY="2"
                          paddingX="3"
                          backgroundColor={
                            isSelected
                              ? "surfacePrimaryLowest"
                              : "surfaceDefault"
                          }
                          cursor="pointer"
                          onClick={() => {
                            if (isSelected) return
                            trackEvent(TrackingEvents.PAYMENTS_FILTER_APPLIED, {
                              from: "businessTransactionsSummaryCard",
                              dateFilter: option.id,
                            })
                            setOverviewFilter(option)
                            handleDurationChange(
                              option.dates[0],
                              option.dates[1]
                            )
                          }}
                          className="hover:bg-blue-5"
                        >
                          <Text
                            fontSize={isSelected ? "c1" : "c2"}
                            color={isSelected ? "textPrimary" : "textHigh"}
                          >
                            {option.title}
                          </Text>
                        </Box>
                      )
                    })}
                  </Inline>
                </Inline>
                <Stack gap="8">
                  <Stack
                    backgroundColor="surfaceDefault"
                    rounded="md"
                    borderWidth="1"
                    borderColor="borderOutline"
                  >
                    <Inline
                      alignItems={{ xl: "center" }}
                      justifyContent="between"
                      padding="6"
                      gap={{ xl: "0", lg: "6" }}
                      flexDirection={{ xl: "row", lg: "col" }}
                    >
                      <Stack gap="2">
                        <Inline fontSize="c2" gap="2">
                          <Text>Total Expenses</Text>
                          <Text color="textMedium">
                            ({transactionsCount} Txns)
                          </Text>
                        </Inline>
                        <Amount
                          fontSize="s1"
                          color="textCashOut"
                          currency="inr"
                          amount={
                            totalExpenses && totalExpenses > 0
                              ? totalExpenses
                              : 0
                          }
                        />
                      </Stack>

                      <Button
                        onClick={() =>
                          navigate(
                            pathname.replace("dashboard", "all-transactions")
                          )
                        }
                        level="primary"
                        size="lg"
                      >
                        <Box>
                          <ExcelFileIcon />
                        </Box>
                        <Text>View Reports</Text>
                      </Button>
                    </Inline>
                    <Inline
                      alignItems="center"
                      gap="2"
                      paddingX="4"
                      paddingY="2"
                      cursor="pointer"
                      backgroundColor="surfaceSuccessLowest"
                      onClick={(e: SyntheticEvent) => {
                        e.stopPropagation()
                        navigate(
                          pathname.replace(
                            "dashboard",
                            "all-transactions?type=cashback"
                          )
                        )
                      }}
                    >
                      <Inline gap="4" alignItems="center">
                        <Box>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.43188 11.7382C4.64381 10.3721 7.10892 7.472 10.9402 5.26049C14.7701 3.04897 18.5155 2.36314 19.3036 3.72932C19.7399 4.48602 20.1776 5.24277 20.6139 5.99947C21.4033 7.36564 18.9368 10.2657 15.1056 12.4772C11.2756 14.6887 7.5316 15.3746 6.74353 14.0084C6.30586 13.2517 5.86819 12.495 5.43188 11.7382Z"
                              fill="#FE9923"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.9403 5.26049C14.7702 3.04897 18.5156 2.36314 19.3036 3.72932C20.0917 5.09413 17.6252 7.99419 13.7953 10.2057C9.96403 12.4172 6.21999 13.1031 5.43188 11.7382C4.64381 10.3721 7.10892 7.472 10.9403 5.26049Z"
                              fill="#FECD3D"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M11.6068 6.4166C14.6541 4.6564 17.4655 3.82057 17.8854 4.54732C18.3054 5.27403 16.1743 7.29059 13.127 9.04943C10.0797 10.8096 7.26961 11.6455 6.8497 10.9187C6.42975 10.192 8.55947 8.17545 11.6068 6.4166Z"
                              fill="#FEA832"
                            />
                            <path
                              d="M19.0161 7.56343L19.7142 8.7728C19.8805 8.56829 20.0291 8.36785 20.1587 8.17289L19.6092 7.22119L19.0161 7.56343ZM18.4189 10.1104C18.2512 10.2617 18.074 10.4117 17.8913 10.5631L17.2259 9.41091L17.819 9.07004L18.4189 10.1104ZM16.4447 11.6388C16.2579 11.7656 16.067 11.8897 15.8706 12.0137L15.2366 10.9134L15.8297 10.5726L16.4447 11.6388ZM14.3217 12.9082C14.1159 13.0159 13.9113 13.1195 13.7095 13.219L13.0933 12.1528L13.6864 11.8092L14.3217 12.9082ZM12.0543 13.9321C11.8321 14.0166 11.6126 14.093 11.3985 14.1639L10.7972 13.1222L11.3904 12.7814L12.0543 13.9321ZM9.59331 14.6166C9.33287 14.6588 9.08477 14.6874 8.85025 14.7011L8.30078 13.7494L8.89389 13.4071L9.59331 14.6166Z"
                              fill="#C85929"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.28125 18.4996C5.28125 16.9221 8.86714 15.6445 13.2902 15.6445C17.7132 15.6445 21.2991 16.9221 21.2991 18.4996V21.1215C21.2991 22.6977 17.7132 23.9766 13.2902 23.9766C8.86714 23.9766 5.28125 22.6977 5.28125 21.1215C5.28125 20.2475 5.28125 19.3735 5.28125 18.4996Z"
                              fill="#FE9923"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13.2902 15.6445C17.7132 15.6445 21.2991 16.9221 21.2991 18.4996C21.2991 20.0758 17.7132 21.3533 13.2902 21.3533C8.86714 21.3533 5.28125 20.0758 5.28125 18.4996C5.28125 16.9221 8.86714 15.6445 13.2902 15.6445Z"
                              fill="#FECD3D"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13.2933 16.978C16.8123 16.978 19.6647 17.6598 19.6647 18.4996C19.6647 19.3382 16.8123 20.0199 13.2933 20.0199C9.77417 20.0199 6.92188 19.3381 6.92188 18.4996C6.92183 17.6597 9.77417 16.978 13.2933 16.978Z"
                              fill="#FEA832"
                            />
                            <path
                              d="M19.1337 21.6777V23.0738C19.3805 22.9798 19.6095 22.8802 19.8182 22.7753V21.6763L19.1337 21.6777ZM17.3435 23.5838C17.1226 23.6301 16.8935 23.6723 16.6604 23.7119V22.3812L17.3435 22.3825V23.5838ZM14.8688 23.9206C14.6453 23.9369 14.4162 23.9492 14.1857 23.9587V22.688L14.8688 22.6893V23.9206ZM12.3955 23.9587C12.1637 23.9491 11.9347 23.9369 11.7097 23.9206V22.6893H12.3955V23.9587ZM9.92086 23.7119C9.68634 23.6723 9.45727 23.6301 9.23639 23.5838V22.3812L9.92086 22.3825V23.7119ZM7.44619 23.0738C7.20075 22.9798 6.97036 22.8802 6.76172 22.7753V21.6777L7.44619 21.6763V23.0738Z"
                              fill="#C85929"
                            />
                            <path
                              d="M3.2485 15.6772V16.6207H4.192V17.2943H3.2485V18.2364H2.57631V17.2943H1.63281V16.6207H2.57631V15.6772H3.2485Z"
                              fill="#FEA832"
                            />
                            <path
                              d="M6.21333 1.64733H6.88687V4.16564H6.21333V1.64733ZM4.94531 6.68527H5.61886V1.64733H4.94531V6.68527ZM20.4219 1.64733V3.76069H21.0954V1.64733H20.4219ZM12.6843 2.78578H13.3578V0.0234375H12.6843V2.78578Z"
                              fill="#D4E1F4"
                            />
                            <path
                              d="M21.4257 12.5508V13.4943H22.3692V14.1678H21.4257V15.1114H20.7521V14.1678H19.8086V13.4943H20.7521V12.5508H21.4257Z"
                              fill="#FEA832"
                            />
                          </svg>
                        </Box>
                        <Text fontSize="b3">
                          Total Cashback Earned:{" "}
                          <Amount
                            currency="inr"
                            as="span"
                            fontSize="s4"
                            className="pl-2"
                            color="textSuccess"
                            amount={totalCashback || 0}
                          />
                        </Text>
                        <ArrowRightIcon color="iconSuccess" />
                      </Inline>
                    </Inline>
                  </Stack>
                  <Stack gap="4">
                    <Inline alignItems="center" justifyContent="between">
                      <Text fontSize="s4">
                        Recent {pluralize("Transaction", transactionsCount)} (
                        {businessTransactions?.length || 0})
                      </Text>
                      <Button
                        inline
                        onClick={() =>
                          navigate(
                            pathname.replace("dashboard", "all-transactions")
                          )
                        }
                      >
                        <Text>View All</Text>
                        <Box>
                          <ArrowRightIcon />
                        </Box>
                      </Button>
                    </Inline>
                    {businessTransactions?.length ? (
                      <Stack
                        as="ul"
                        rounded="md"
                        borderWidth="1"
                        borderColor="borderOutline"
                        backgroundColor="surfaceDefault"
                      >
                        {businessTransactions.map((transaction) => (
                          <Box
                            key={transaction.id}
                            borderBottomWidth={"1"}
                            as="li"
                            rounded="md"
                            cursor="pointer"
                            className="last:border-b-[0px] hover:bg-blue-5"
                            borderColor="borderOutline"
                          >
                            <TransactionItem
                              user={user}
                              getBusinessUser={getTeamMemberInfoForId}
                              transaction={transaction}
                            />
                          </Box>
                        ))}
                      </Stack>
                    ) : (
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        gap="2"
                        paddingY="24"
                      >
                        <Text fontSize="s1">No transactions found</Text>
                        <Text fontSize="b3" color="textMedium">
                          Make payments from your card on {config.appTitle} App
                          to see all transactions
                        </Text>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
                <Box className="lg:hidden block">
                  <StaffWallets
                    pathName={pathname}
                    teamWallets={teamWallets}
                    currentUserWallet={authTeamMemberDetails}
                    onViewAllClick={() => {
                      navigate(pathname.replace("dashboard", "staff-wallets"))
                    }}
                  />
                </Box>
              </Stack>
            </Stack>
            <Box
              width="full"
              maxWidth={{ xl: "sm", lg: "xs" }}
              className="lg:block hidden"
            >
              <StaffWallets
                pathName={pathname}
                teamWallets={teamWallets}
                currentUserWallet={authTeamMemberDetails}
                onViewAllClick={() => {
                  navigate(pathname.replace("dashboard", "staff-wallets"))
                }}
              />
            </Box>
          </Inline>
        )}
      </Box>
    </>
  )
}

function StaffWallets({
  pathName,
  teamWallets,
  currentUserWallet,
  onViewAllClick,
}: {
  pathName: string
  currentUserWallet: TBusinessUser
  teamWallets?: Wallet[]
  onViewAllClick: () => void
}) {
  return (
    <Stack
      gap="6"
      width="full"
      maxWidth={{ xl: "md", lg: "md" }}
      paddingY="6"
      paddingLeft={{ lg: "8", xs: "0" }}
    >
      <Stack gap="4">
        <Inline alignItems="center" justifyContent="between">
          <Text fontSize="s4">Member Cards</Text>
          <Button inline onClick={onViewAllClick}>
            View All
            <ArrowRightIcon />
          </Button>
        </Inline>
        {currentUserWallet?.phoneNumber?.length ? (
          <WalletItem
            wallet={{
              name: "Your Card",
              id: currentUserWallet.id,
              phoneNumber: currentUserWallet.phoneNumber,
              balance: Number(currentUserWallet.wallet?.balance),
              minKycPending: !currentUserWallet.wallet?.min_kyc,
            }}
            onClick={() => {
              userWalletStore.setUserWallet({
                uid: currentUserWallet.id,
                name: "Your Card",
                phoneNumber: currentUserWallet.phoneNumber || "",
                kycStatus: getKycStatus(
                  currentUserWallet.wallet?.min_kyc,
                  currentUserWallet.wallet?.full_kyc
                ),
                balance: Number(currentUserWallet.wallet?.balance),
              })
            }}
            to={pathName.replace(
              "dashboard",
              `staff-wallets/${currentUserWallet.id}?header=none`
            )}
          />
        ) : null}
      </Stack>
      {teamWallets?.length ? (
        <>
          <Box borderBottomWidth="1" borderColor="borderOutline" />
          <Stack as="ul" gap="4">
            {teamWallets.map((member) => (
              <WalletItem
                key={member.uid}
                wallet={{
                  name: member.name,
                  id: member.uid,
                  balance: member.balance,
                  phoneNumber: member.phoneNumber,
                  minKycPending: member.kycStatus === "init",
                  isDeactivated: Boolean(member?.deactivation),
                }}
                onClick={() => {
                  userWalletStore.setUserWallet(member)
                }}
                to={pathName.replace(
                  "dashboard",
                  `staff-wallets/${
                    member.deactivation ? member.walletId : member.uid
                  }?header=none`
                )}
              />
            ))}
          </Stack>
        </>
      ) : null}
    </Stack>
  )
}

function WalletItem({
  to,
  wallet,
  onClick,
}: {
  to: string
  wallet: {
    name: string
    id: string
    phoneNumber: string
    balance?: number
    minKycPending?: boolean
    isDeactivated?: boolean
  }
  onClick?: () => void
}) {
  return (
    <Inline
      backgroundColor="surfaceDefault"
      borderWidth="1"
      rounded="md"
      padding="4"
      justifyContent="between"
      gap="4"
      as={Link}
      to={to}
      onClick={onClick}
      borderColor="borderOutline"
      className="hover:bg-blue-5"
    >
      <Inline gap="2">
        {wallet.minKycPending || wallet.isDeactivated ? (
          <Circle
            size="10"
            backgroundColor={
              wallet?.isDeactivated
                ? "surfaceNeutralLowest"
                : "surfaceWarningLowest"
            }
          >
            {wallet.isDeactivated ? (
              <CardDeactivatedIcon color="iconMedium" />
            ) : (
              <CardPendingIcon color="iconWarning" />
            )}
          </Circle>
        ) : (
          <Avatar size="6" fontSize="c2" id={wallet.id} name={wallet.name} />
        )}
        <Stack gap="2">
          <Text fontSize="s4" className="max-w-full line-clamp-1">
            {wallet.name}
          </Text>
          <Text color="textMedium" fontSize="c2">
            {wallet.phoneNumber}
          </Text>
          {wallet.minKycPending || wallet?.isDeactivated ? (
            <Box
              backgroundColor={
                wallet.isDeactivated
                  ? "surfaceNeutralLowest"
                  : "surfaceWarningLowest"
              }
              paddingX="2"
              paddingY="1"
              rounded="md"
              marginTop="2"
            >
              <Text
                fontSize="c1"
                color={wallet.isDeactivated ? "textMedium" : "textWarning"}
              >
                {wallet.isDeactivated ? "Deactivated!" : "KYC Pending"}
              </Text>
            </Box>
          ) : null}
        </Stack>
      </Inline>
      {wallet.minKycPending || wallet.isDeactivated ? null : (
        <Stack gap="2" alignItems="end">
          <Amount
            fontSize="s4"
            currency="inr"
            color={wallet?.balance === 0 ? "textCashOut" : undefined}
            amount={Number(wallet.balance || 0)}
          />
          <Text color="textMedium" fontSize="c2">
            Balance
          </Text>
        </Stack>
      )}
    </Inline>
  )
}

function HideAbleBalance({ amount }: { amount: string }) {
  const [visible, setVisible] = useState<boolean>(false)
  return (
    <Inline fontSize="s1" gap="2">
      <Text>{currencies_supported["inr"]}</Text>
      {visible ? (
        <Amount fontSize="s1" amount={Number(amount) || 0} />
      ) : (
        <Inline gap="2" alignItems="center" justifyContent="center">
          <Circle size="2" backgroundColor="iconHigh" />
          <Circle size="2" backgroundColor="iconHigh" />
          <Circle size="2" backgroundColor="iconHigh" />
        </Inline>
      )}
      <Box
        cursor="pointer"
        onClick={(e: SyntheticEvent) => {
          e.preventDefault()
          e.stopPropagation()
          setVisible((prev) => !prev)
        }}
      >
        {visible ? (
          <HideEntriesIcon size="5" color="iconMedium" />
        ) : (
          <UnHideIcon size="5" color="iconMedium" />
        )}
      </Box>
    </Inline>
  )
}

function hideAccountString(string: string, length?: number) {
  return `${string.slice(0, length || 6).replace(/./g, "*")}${string.slice(
    length || 6,
    string.length
  )}`
}

function TransactionItem({
  user,
  transaction,
  getBusinessUser,
}: {
  user: TUser
  transaction: PaymentsTransaction
  getBusinessUser?: (id: string) => TBusinessUser | null
}) {
  const {
    party,
    id,
    timestamp,
    attachments,
    created_at,
    type,
    amount,
    business_id,
    transaction_type,
  } = transaction

  const [attachmentId, setAttachmentId] = useState<number | undefined>(
    attachments?.length ? Number(attachments?.[0]?.id) : undefined
  )

  const transactionBy: string = useMemo(() => {
    const bUser = getBusinessUser?.(transaction.created_by || "")
    if (user.uid === bUser?.id) {
      return type === "DEBIT" ? "by You" : "to You"
    }
    return type === "DEBIT" && bUser?.name
      ? `by ${bUser?.name}`
      : bUser?.name
      ? `to ${bUser?.name}`
      : ""
  }, [getBusinessUser, transaction.created_by, type, user.uid])

  const transactionType = useMemo(() => {
    return getTransactionModeOfPayment(transaction_type)
  }, [transaction_type])

  function onAttachmentAdded(id: number) {
    setAttachmentId(id)
  }

  function onAttachmentDeleted() {
    setAttachmentId(undefined)
  }

  return (
    <TransactionDetailsInModal
      businessId={business_id || ""}
      transactionId={id}
      attachmentId={attachmentId}
      onAttachmentAdded={(id) => onAttachmentAdded(Number(id))}
      onAttachmentDeleted={onAttachmentDeleted}
    >
      {({ open }) => (
        <Inline padding="4" justifyContent="between" onClick={open}>
          <Inline gap="4">
            <Avatar
              size="8"
              fontSize="b1"
              id={party?.id || id}
              name={party?.name || ""}
              type={party?.entity_type === "ENTITY" ? "merchant" : "individual"}
            />
            <Stack gap="4">
              <Text fontSize="b3">{party?.name || "CB User"}</Text>
              <Stack gap="1" fontSize="c2" color="textMedium">
                <Inline gap="1">
                  <TransactionDate
                    timeStamp={transformDate(timestamp || created_at)}
                  />
                  <Text>•</Text>
                  <Time
                    timeStamp={transformDate(timestamp || created_at)}
                    format="hh:mm a"
                  />
                </Inline>
                <Text>{transactionBy}</Text>
              </Stack>
            </Stack>
          </Inline>
          <Box>
            <Inline alignItems="center" gap="2">
              <Amount
                fontSize="s4"
                type={type === "DEBIT" ? "cash-out" : "cash-in"}
                currency="inr"
                amount={Number(amount) || 0}
              />
              <PaymentsIcons
                color="iconMedium"
                name={transactionType}
                size="3"
              />
            </Inline>
          </Box>
        </Inline>
      )}
    </TransactionDetailsInModal>
  )
}
