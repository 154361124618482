import {
  TBusinessUser,
  useBusiness,
  useShareBusinessInvitations,
} from "@cashbook/data-store/businesses"
import { useFormik } from "formik"
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react"
import {
  UpdateWalletLimitPayload,
  getUserCard,
  getUserProfile,
  getUserWalletLimits,
  updateOnlineCardTransactions,
  updateWalletLimits,
  getUserCardDetails as getUserCardWidget,
  getDeactivatedWallets,
  WalletTransactionsPayload,
  WalletStatusFilterTypes,
  getUserWalletTransactions,
  UserProfilePayload,
  checkIfPaymentsActiveInAnyBusiness,
} from "./services"
import { toast } from "react-hot-toast"
import {
  CashbookCard,
  PaymentModeForPayments,
  PaymentTransactionTypes,
  PaymentsTransaction,
} from "./types"
import { useMount } from "@cashbook/util-general"
import {
  CollectionReference,
  Timestamp,
  collection,
  doc,
  getDoc,
  getFirestore,
} from "firebase/firestore"
import { usePagination } from "./utils"
import {
  userPaymentsProfileStore,
  userWalletStore,
} from "@cashbook/data-store/storage"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"

export type Wallet = {
  uid: string
  name: string
  phoneNumber: string
  kycStatus: "init" | "min_kyc" | "full_kyc"
  balance?: number
  walletId?: string
  deactivation?: "init" | "completed"
}

type WalletFilters = {
  q?: string
  sortBy: WalletSortMethods
}

const initialWalletsFiltersParams: WalletFilters = {
  sortBy: "byBalanceAsc",
}

type UserProfile = {
  uid: string
  name: string
  upi?: {
    address: string
    id: string
    disabled?: boolean
    primary?: boolean
  }
  kycStatus: KYC_STATUS
  balance?: number
  fullName?: string
}

type UserPreferences = {
  ecom?: boolean
  atm?: boolean
  pos?: boolean
  contactless?: boolean
  wallet_limit_config?: {
    each?: number
    daily?: number
  }
}

type UserProfileApiResponse = {
  data: {
    id: string
    full_name: string
    upiProfiles: Array<{
      address: string
      id: string
      disabled?: boolean
      primary?: boolean
    }>
    wallet_status: string
    kyc_status: string
    userPreference?: UserPreferences
  }
}
type Transactions = {
  transactionCount?: number
  transactions: PaymentsTransaction[]
  fetching?: boolean
  fetchingMore?: boolean
  totalExpenses?: number
}
type WALLET_STATE = {
  status: "in_progress" | "success" | "failed"
  error?: Error | null
  userProfile?: UserProfile
  transactions: Transactions
  cardDetails?: CashbookCard
  cardWidget?: {
    fetching?: boolean
    widget?: string
  }
}

const initialWalletState: WALLET_STATE = {
  status: "in_progress",
  error: null,
  userProfile: undefined,
  cardDetails: undefined,
  transactions: { transactions: [] },
}

type WalletTransactionFilters = {
  category?: PaymentTransactionTypes
  mode?: {
    id: string
    label: string
    value: PaymentModeForPayments | undefined
  }
  from_datetime?: Date
  to_datetime?: Date
  status?: WalletStatusFilterTypes
  dateFilterLabel?: string
}

const initialTransactionParams: WalletTransactionFilters = {
  category: "EXPENSES",
}

type TYPE_AND_PAYLOAD_FOR_WALLET =
  | { type: "FETCHING_DATA" }
  | {
      type: "FETCHED_DATA"
      payload: {
        userProfile: UserProfile
        cardDetails?: CashbookCard
        transactions: Transactions
      }
    }
  | { type: "FETCHED_DATA_FAILED"; payload: Error }
  | { type: "FETCHING_CARD_WIDGET" }
  | { type: "FETCHED_CARD_WIDGET"; payload: string }
  | { type: "FETCHING_CARD_WIDGET_FAILED" }
  | { type: "REMOVE_CARD_WIDGET" }
  | { type: "FETCHING_TRANSACTIONS" }
  | { type: "FETCHED_TRANSACTIONS"; payload: Transactions }
  | { type: "FETCHED_TRANSACTIONS_FAILED" }
  | { type: "FETCHING_MORE_TRANSACTIONS" }
  | { type: "FETCHED_MORE_TRANSACTIONS"; payload: PaymentsTransaction[] }
  | { type: "FETCHED_MORE_TRANSACTIONS_FAILED" }

const walletReducer = (
  state: WALLET_STATE,
  action: TYPE_AND_PAYLOAD_FOR_WALLET
): WALLET_STATE => {
  switch (action.type) {
    case "FETCHING_DATA":
      return {
        ...state,
        ...initialWalletState,
      }
    case "FETCHED_DATA":
      return {
        ...state,
        status: "success",
        error: null,
        ...action.payload,
      }
    case "FETCHED_DATA_FAILED":
      return {
        ...initialWalletState,
        status: "failed",
        error: action.payload,
      }
    case "FETCHING_CARD_WIDGET":
      return {
        ...state,
        cardWidget: { fetching: true, ...state.cardWidget },
      }
    case "FETCHED_CARD_WIDGET":
      return {
        ...state,
        cardWidget: { fetching: false, widget: action.payload },
      }
    case "FETCHING_CARD_WIDGET_FAILED":
      return {
        ...state,
        cardWidget: { ...state.cardWidget, fetching: false },
      }

    case "REMOVE_CARD_WIDGET":
      return {
        ...state,
        cardWidget: { widget: undefined, fetching: false },
      }
    case "FETCHING_TRANSACTIONS":
      return {
        ...state,
        transactions: {
          ...state.transactions,
          fetching: true,
        },
      }
    case "FETCHED_TRANSACTIONS":
      return {
        ...state,
        transactions: {
          ...action.payload,
          fetching: false,
          fetchingMore: false,
        },
      }
    case "FETCHED_TRANSACTIONS_FAILED":
      return {
        ...state,
        transactions: {
          ...state.transactions,
          fetching: false,
          fetchingMore: false,
        },
      }
    case "FETCHING_MORE_TRANSACTIONS":
      return {
        ...state,
        transactions: {
          ...state.transactions,
          fetchingMore: true,
        },
      }
    case "FETCHED_MORE_TRANSACTIONS":
      return {
        ...state,
        transactions: {
          ...state.transactions,
          fetching: false,
          fetchingMore: false,
          transactions: [...state.transactions.transactions, ...action.payload],
        },
      }
    case "FETCHED_MORE_TRANSACTIONS_FAILED":
      return {
        ...state,
        transactions: {
          ...state.transactions,
          fetching: false,
          fetchingMore: false,
        },
      }
    default:
      return state
  }
}

type DeactivatedWalletDoc = {
  businessId: string
  businessUserId: string
  causerId?: string
  createdAt?: Timestamp
  customerAgent?: string
  status?: string
  type?: string
  updatedAt?: Timestamp
  userId: string
  walletBalance: number
}

export function useWallet(
  businessId: string,
  userId: string,
  initialParamProps: WalletTransactionFilters = initialTransactionParams
) {
  const { business, authTeamMemberDetails, getTeamMemberInfoForId } =
    useBusiness(businessId)

  const userWallet = userWalletStore.getUserWallet()

  const [state, dispatch] = useReducer(walletReducer, initialWalletState)

  const isAuthenticatedUser = useRef<boolean>(
    authTeamMemberDetails.id === userId
  )

  const {
    current,
    pagination,
    lastPage,
    next,
    reset: resetPagination,
  } = usePagination({
    skip: 0,
    take: 25,
    totalItems: state.transactions.transactionCount || 0,
  })

  const initialSearchParams = useMemo(() => {
    if (!initialParamProps) return initialTransactionParams
    return {
      ...initialTransactionParams,
      ...initialParamProps,
    }
  }, [initialParamProps])

  const { values: params, setValues } = useFormik<WalletTransactionFilters>({
    initialValues: initialSearchParams,
    onSubmit: () => undefined,
  })

  const callApis = useCallback(() => {
    if (userWallet?.kycStatus === "init") return
    try {
      const profilePayload: { businessId: string; memberId?: string } = {
        businessId,
        memberId: userId,
      }
      const userProfileApi = userWallet?.deactivation
        ? undefined
        : getUserProfile<UserProfileApiResponse>(profilePayload)
      const transactionsPayload: WalletTransactionsPayload = {
        businessId,
        skip: pagination.skip,
        take: pagination.take,
        memberId: userId,
        mode: "CARD",
      }
      if (params.from_datetime) {
        transactionsPayload["from_datetime"] =
          params.from_datetime.toISOString()
      }
      if (params.to_datetime) {
        transactionsPayload["to_datetime"] = params.to_datetime.toISOString()
      }
      if (params.category) {
        transactionsPayload["category"] = params.category
      }
      if (params.status) {
        transactionsPayload["status"] = params.status
      }
      if (userWallet?.deactivation) {
        transactionsPayload["businessUserId"] = userId
      }
      trackEvent(TrackingEvents.PAYMENTS_FILTER_APPLIED, {
        from: "walletTransactionsExpenses",
        dateFilter: params.dateFilterLabel || "all",
      })
      const transactionApi = getUserWalletTransactions<{
        count: number
        data: PaymentsTransaction[]
        totalCredit: number
        totalDebit: number
      }>(transactionsPayload)
      const cardDetailsApi = isAuthenticatedUser.current
        ? getUserCard<{ data: Array<CashbookCard> }>({
            businessId,
          })
        : undefined

      Promise.all([userProfileApi, transactionApi, cardDetailsApi])
        .then((responses) => {
          const [userProfile, transactions, cardDetails] = responses

          if (!transactions) {
            throw new Error(
              "Something went wrong while fetching user wallet data!"
            )
          }
          const wallet: UserProfile = {
            uid: userId,
            name: userProfile?.data.full_name || "",
            upi: userProfile?.data.upiProfiles.length
              ? userProfile.data.upiProfiles.find((upi) => upi.primary)
              : undefined,
            kycStatus:
              userProfile?.data.kyc_status === "FULL_KYC"
                ? "full_kyc"
                : userProfile?.data.kyc_status === "MIN_KYC"
                ? "min_kyc"
                : "init",
            fullName: userProfile?.data.full_name || "",
            balance: Number(userWallet?.balance || 0),
          }
          const transactionsData: Transactions = {
            transactions: transactions.data,
            transactionCount: transactions.count,
            totalExpenses:
              (transactions.totalDebit || 0) - (transactions.totalCredit || 0),
          }
          const activatedCard = cardDetails?.data.find(
            (card) => card.card_status !== "REPLACED"
          )
          dispatch({
            type: "FETCHED_DATA",
            payload: {
              userProfile: wallet,
              transactions: transactionsData,
              cardDetails: activatedCard,
            },
          })
        })
        .catch((e) => {
          dispatch({ type: "FETCHED_DATA_FAILED", payload: e as Error })
        })
    } catch (e) {
      dispatch({ type: "FETCHED_DATA_FAILED", payload: e as Error })
    }
  }, [
    userWallet?.kycStatus,
    userWallet?.deactivation,
    userWallet?.balance,
    businessId,
    userId,
    pagination.skip,
    pagination.take,
    params.from_datetime,
    params.to_datetime,
    params.category,
    params.status,
    params.dateFilterLabel,
  ])

  const getTransactions = useCallback(async () => {
    try {
      const transactionsPayload: WalletTransactionsPayload = {
        businessId,
        skip: pagination.skip,
        take: pagination.take,
        memberId: userId,
        mode: "CARD",
      }
      const payloadForEvent: {
        from: "walletTransactionsExpenses"
        dateFilter?: string
        statusFilter?: "success" | "pending" | "refunded" | "failed"
      } = {
        from: "walletTransactionsExpenses",
        dateFilter: params.dateFilterLabel || "all",
      }
      if (params.from_datetime) {
        transactionsPayload["from_datetime"] =
          params.from_datetime.toISOString()
      }
      if (params.to_datetime) {
        transactionsPayload["to_datetime"] = params.to_datetime.toISOString()
      }
      if (params.category) {
        transactionsPayload["category"] = params.category
      }
      if (params.status) {
        payloadForEvent.statusFilter =
          params.status === "SUCCESS"
            ? "success"
            : params.status === "FAILURE"
            ? "failed"
            : params.status === "REFUNDED"
            ? "refunded"
            : "pending"
        transactionsPayload["status"] = params.status
      }
      if (userWallet?.deactivation) {
        transactionsPayload["businessUserId"] = userId
      }
      trackEvent(TrackingEvents.PAYMENTS_FILTER_APPLIED, payloadForEvent)
      const response = await getUserWalletTransactions<{
        count: number
        data: PaymentsTransaction[]
        totalCredit: number
        totalDebit: number
      }>(transactionsPayload)
      if (current === 1) {
        const transactionsData = {
          transactions: response.data,
          transactionCount: response.count,
          totalExpenses:
            (response.totalDebit || 0) - (response.totalCredit || 0),
        }
        return dispatch({
          type: "FETCHED_TRANSACTIONS",
          payload: transactionsData,
        })
      }
      return dispatch({
        type: "FETCHED_MORE_TRANSACTIONS",
        payload: response.data,
      })
    } catch {
      if (current === 1) {
        return dispatch({
          type: "FETCHED_TRANSACTIONS_FAILED",
        })
      }
      return dispatch({
        type: "FETCHED_MORE_TRANSACTIONS_FAILED",
      })
    }
  }, [
    businessId,
    current,
    pagination.skip,
    pagination.take,
    params.category,
    params.from_datetime,
    params.status,
    params.to_datetime,
    userId,
    userWallet?.deactivation,
    params.dateFilterLabel,
  ])

  const getWalletBalanceForDeactivatedWallet = useCallback(
    async (userId: string): Promise<number | undefined> => {
      const store = getFirestore()
      const customerRequestDocRef = await getDoc(
        doc(
          collection(
            store,
            "CustomerRequests"
          ) as CollectionReference<DeactivatedWalletDoc>,
          userId
        )
      )
      return customerRequestDocRef.data()?.walletBalance
    },
    []
  )

  useMount(() => {
    callApis()
  })

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined
    if (state.cardWidget?.widget) {
      timer = setTimeout(() => {
        dispatch({ type: "REMOVE_CARD_WIDGET" })
      }, 120000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [state.cardWidget?.widget])

  useEffect(() => {
    if (state.transactions.fetching || state.transactions.fetchingMore) {
      getTransactions()
    }
  }, [
    getTransactions,
    state.transactions.fetching,
    state.transactions.fetchingMore,
  ])

  const getCardWidget = useCallback(
    async (kitNo: string) => {
      dispatch({ type: "FETCHING_CARD_WIDGET" })
      try {
        const response = await getUserCardWidget<{
          data: {
            widget?: string
          }
        }>({ businessId, kitNo })
        if (!response) {
          throw new Error("Something went wrong while fetching your card!")
        }
        dispatch({
          type: "FETCHED_CARD_WIDGET",
          payload: response.data.widget || "",
        })
      } catch (e) {
        const err = e as Error
        dispatch({ type: "FETCHING_CARD_WIDGET_FAILED" })
        toast.error(
          err.message || "Something went wrong while fetching your card!"
        )
      }
    },
    [businessId]
  )

  function handleDateChange(from?: Date, to?: Date, label?: string) {
    resetPagination()
    setValues({
      ...params,
      from_datetime: from,
      to_datetime: to,
      dateFilterLabel: label,
    })
    dispatch({ type: "FETCHING_TRANSACTIONS" })
  }

  function handleParamsChange(key: string, value: unknown) {
    resetPagination()
    setValues({ ...params, [key]: value })
    dispatch({ type: "FETCHING_TRANSACTIONS" })
  }

  function handleCategoryChange(value: PaymentTransactionTypes) {
    resetPagination()
    setValues({ category: value })
    dispatch({ type: "FETCHING_TRANSACTIONS" })
  }

  const areFiltersApplied: boolean = useMemo(() => {
    return Boolean(
      params.from_datetime ||
        params.to_datetime ||
        params.mode?.id ||
        params.status
    )
  }, [params])

  function fetchMore() {
    if (current === lastPage) return
    next()
    dispatch({ type: "FETCHING_MORE_TRANSACTIONS" })
  }

  function retry() {
    setValues(initialSearchParams)
    dispatch({ type: "FETCHING_DATA" })
    callApis()
  }

  return {
    business,
    user: userWallet,
    error: state.error,
    status: state.status,
    userProfile: state.userProfile,
    cardDetails: state.cardDetails,
    authUser: authTeamMemberDetails,
    cardWidget: state.cardWidget?.widget,
    widgetFetching: state.cardWidget?.fetching,

    //Transactions
    transactions: state.transactions.transactions,
    totalExpenses: state.transactions.totalExpenses,
    fetchingTransactions: state.transactions.fetching,
    transactionsCount: state.transactions.transactionCount,
    fetchingMoreTransactions: state.transactions.fetchingMore,

    //Filters
    params,
    areFiltersApplied,
    handleDateChange,
    handleParamsChange,
    refreshPage: retry,
    handleCategoryChange,

    //Pagination
    lastPage,
    currentPage: current,
    fetchMore,

    getCardWidget,
    getTeamMemberInfoForId,
    getWalletBalanceForDeactivatedWallet,
  }
}

type WalletLimitsResponse = {
  data: { each: number; daily_debit: { type?: string; value: number } }
}
export function useWalletLimits(businessId: string, userId: string) {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>(undefined)
  const [shouldApiCall, setShouldApiCall] = useState<boolean>(true)

  const [walletLimits, setWalletLimits] = useState<
    { daily: number; perTransaction: number } | undefined
  >(undefined)
  const [maxWalletLimits, setMaxWalletLimits] = useState<
    WalletLimitsResponse | undefined
  >(undefined)
  const [userPreferences, setUserPreferences] =
    useState<UserPreferences | null>(null)
  const [togglingOption, setTogglingOption] = useState<{
    ecom?: boolean
    pos?: boolean
    atm?: boolean
    contactless?: boolean
  } | null>(null)

  const getWalletLimitsAndProfile = useCallback(async () => {
    setError(undefined)
    try {
      const profile = await getUserProfile<UserProfileApiResponse>({
        businessId,
        memberId: userId,
      })
      const response = await getUserWalletLimits<WalletLimitsResponse>({
        businessId,
        memberId: userId,
      })
      setLoading(false)
      if (!response || !profile) {
        throw new Error("Something went wrong!")
      }
      setMaxWalletLimits(response)
      setUserPreferences(profile.data.userPreference || null)
      setWalletLimits({
        daily:
          profile.data.userPreference?.wallet_limit_config?.daily ||
          response.data.daily_debit.value ||
          0,
        perTransaction:
          profile.data.userPreference?.wallet_limit_config?.each ||
          response.data.each ||
          0,
      })
    } catch (e) {
      const err = e as Error
      setLoading(false)
      setError(err)
    }
  }, [businessId, userId])

  useEffect(() => {
    if (shouldApiCall) {
      setShouldApiCall(false)
      getWalletLimitsAndProfile()
    }
  }, [getWalletLimitsAndProfile, shouldApiCall])

  const toggleCardOption = useCallback(
    async (
      type: "atm" | "pos" | "contactless" | "ecom",
      value: boolean,
      isStaff?: boolean
    ) => {
      setTogglingOption({ [type]: true })
      try {
        const payload = {
          businessId,
          [type]: value,
          memberId: userId,
        }
        const response = await updateOnlineCardTransactions(payload)
        trackEvent(TrackingEvents.CARD_PERMISSIONS_CHANGED, {
          type:
            type === "atm"
              ? "atmTransactions"
              : type === "contactless"
              ? "contactlessTransactions"
              : type === "pos"
              ? "posTransactions"
              : "onlineTransactions",
          value: value ? "enabled" : "disabled",
          isStaffWallet: Boolean(isStaff),
        })
        setTogglingOption(null)
        if (!response) {
          throw new Error("Something went wrong!")
        }
        toast.success(
          `${getTitleForToggleToastFromCardOption(type)} are ${
            value ? "enabled" : "disabled"
          } for this card`
        )
        setUserPreferences((prev) => {
          return {
            ...prev,
            [type]: value,
          }
        })
      } catch (e) {
        const err = e as Error
        setTogglingOption(null)
        toast.error(err.message)
      }
    },
    [businessId, userId]
  )

  const updateWalletLimit = useCallback(
    async (type: "daily" | "perTransaction", value: number) => {
      try {
        const payload: UpdateWalletLimitPayload = {
          businessId,
          memberId: userId,
        }
        if (type === "daily") {
          payload["daily"] = value
        } else if (type === "perTransaction") {
          payload["each"] = value
        }
        const response = await updateWalletLimits(payload)
        if (!response) {
          throw new Error(
            "Something went wrong while updating your wallet limits!"
          )
        }
        return setWalletLimits((prev) => {
          return prev
            ? {
                daily: type === "daily" ? value : prev.daily,
                perTransaction:
                  type === "perTransaction" ? value : prev.perTransaction,
              }
            : undefined
        })
      } catch (e) {
        const err = e as Error
        throw err
      }
    },
    [businessId, userId]
  )

  return {
    error,
    loading,
    walletLimits,
    togglingOption,
    maxWalletLimits,
    userPreferences,

    toggleCardOption,
    updateWalletLimit,
  }
}

type WALLETS_STATE = {
  status: "in_progress" | "success" | "failed"
  error?: Error | null
  wallets: DeactivatedWallet[]
}
type TYPE_AND_PAYLOAD_FOR_WALLETS =
  | { type: "FETCHING_WALLETS" }
  | {
      type: "FETCHED_WALLETS"
      payload: DeactivatedWallet[]
    }
  | { type: "FETCHED_WALLETS_FAILED"; payload: Error }

const reducerForWallets = (
  state: WALLETS_STATE,
  action: TYPE_AND_PAYLOAD_FOR_WALLETS
): WALLETS_STATE => {
  switch (action.type) {
    case "FETCHING_WALLETS":
      return {
        ...state,
        ...initialWallets,
      }
    case "FETCHED_WALLETS":
      return {
        ...state,
        error: null,
        status: "success",
        wallets: action.payload,
      }
    case "FETCHED_WALLETS_FAILED":
      return {
        ...state,
        status: "failed",
        error: action.payload,
        wallets: [],
      }
    default:
      return state
  }
}
const initialWallets: WALLETS_STATE = {
  status: "in_progress",
  error: null,
  wallets: [],
}

export type DeactivatedWallet = {
  business_id?: string
  created_at: Date | Timestamp | null
  deactivated_at: Date | Timestamp | null
  dob?: string
  document_type?: string
  full_name: string
  id: string
  kyc_status?: string
  updated_at?: Date | Timestamp | null
  user_id?: string
  vkyc_approved_at?: Date | Timestamp | null
  wallet_status: string
}
type Deactivated_Wallets_Response = {
  data: DeactivatedWallet[]
}

type BusinessUserWithDeactivationStatus = TBusinessUser & {
  walletId: string
  deactivation?: "init" | "completed"
}

export function useStaffWallets(
  businessId: string,
  initialSearchParamsProp: WalletFilters = initialWalletsFiltersParams
) {
  const {
    business,
    businessTeam,
    authTeamMemberDetails,
    getTeamMemberInfoForId,
  } = useBusiness(businessId)
  const { invitations } = useShareBusinessInvitations(businessId)
  const [state, dispatch] = useReducer(reducerForWallets, initialWallets)

  const getFilteredWallets = useFilterWallets(authTeamMemberDetails.uid)

  const initialSearchParams = useMemo(() => {
    if (!initialSearchParamsProp) return initialWalletsFiltersParams
    return {
      ...initialWalletsFiltersParams,
      ...initialSearchParamsProp,
    }
  }, [initialSearchParamsProp])

  const {
    values: params,
    setValues,
    setFieldValue,
    handleChange: handleParamChange,
  } = useFormik<WalletFilters>({
    initialValues: initialSearchParams,
    onSubmit: () => undefined,
  })

  const callApis = useCallback(async () => {
    try {
      const response =
        await getDeactivatedWallets<Deactivated_Wallets_Response>({
          businessId,
        })
      if (!response) {
        throw new Error("Error while fetching staff wallets!")
      }
      dispatch({ type: "FETCHED_WALLETS", payload: response.data })
    } catch (e) {
      dispatch({ type: "FETCHED_WALLETS_FAILED", payload: e as Error })
    }
  }, [businessId])

  const { q, sortBy } = params

  const deactivatedWallets: BusinessUserWithDeactivationStatus[] =
    useMemo(() => {
      return state.wallets.map((wallet) => {
        const member = getTeamMemberInfoForId(wallet.user_id)
        return {
          id: member?.uid || "",
          walletId: wallet.id || "",
          name: member?.name || wallet.full_name || "",
          joinedAt: member?.joinedAt,
          phoneNumber: member?.phoneNumber || "",
          can_enable_wallet: member?.can_enable_wallet,
          wallet: member?.wallet,
          wallet_issued_by: member?.wallet_issued_by,
          deactivation:
            wallet.deactivated_at || wallet.wallet_status === "DEACTIVATED"
              ? "completed"
              : "init",
        }
      })
    }, [getTeamMemberInfoForId, state.wallets])

  const teamWallets: Wallet[] = useMemo(() => {
    const wallets = deactivatedWallets
    const invitedUsers: TBusinessUser[] = invitations.map((invite) => {
      return {
        id: invite.id,
        name: invite.guestName || invite.guestPhone,
        phoneNumber: invite.guestPhone,
        email: invite.guestEmail,
        can_enable_wallet: invite.wallet?.can_enable_wallet,
        joinedAt: invite.createdAt,
        wallet: {
          min_kyc: invite.wallet?.min_kyc || false,
          full_kyc: false,
          balance: "0",
        },
      }
    })
    let filteredWallets = getFilteredWallets(sortBy, [
      ...businessTeam,
      ...invitedUsers,
      ...wallets,
    ])
    if (q?.trim()) {
      filteredWallets = filteredWallets.filter(
        (wallet) =>
          wallet.name.toLowerCase().includes(q.toLowerCase()) ||
          wallet.phoneNumber.includes(q.toLowerCase())
      )
    }
    return filteredWallets
  }, [
    sortBy,
    deactivatedWallets,
    invitations,
    getFilteredWallets,
    businessTeam,
    q,
  ])

  const currentUserWallet: Wallet = useMemo(() => {
    return {
      uid: authTeamMemberDetails.id,
      name: authTeamMemberDetails.name,
      phoneNumber: authTeamMemberDetails?.phoneNumber || "",
      kycStatus: getKycStatus(
        authTeamMemberDetails.wallet?.min_kyc,
        authTeamMemberDetails.wallet?.full_kyc
      ),
      balance: Number(authTeamMemberDetails.wallet?.balance || 0),
    }
  }, [authTeamMemberDetails])

  const allWallets = useMemo(() => {
    return [...businessTeam, ...deactivatedWallets]
  }, [businessTeam, deactivatedWallets])

  useMount(() => {
    callApis()
  })

  function reset() {
    setValues({ ...initialSearchParamsProp })
    dispatch({ type: "FETCHING_WALLETS" })
    callApis()
  }

  return {
    ...state,
    business,
    allWallets,
    teamWallets,
    currentUserWallet,

    //Filters
    params,
    setFieldValue,
    handleParamChange,
    refreshPage: reset,
  }
}

export type WalletSortMethods =
  | "byBalanceAsc"
  | "byBalanceDesc"
  | "byNameAsc"
  | "byNameDesc"
  | "byLastCreated"

export function useFilterWallets(currentUserId: string) {
  return useCallback(
    (
      type: WalletSortMethods = "byBalanceAsc",
      users: Array<TBusinessUser & { deactivation?: string; walletId?: string }>
    ): Wallet[] => {
      const filteredWallets = users
        .filter(
          (user) =>
            user.id !== currentUserId &&
            (user.can_enable_wallet || user?.deactivation)
        )
        .map((user) => {
          return {
            uid: user.id,
            name: user.name,
            phoneNumber: user?.phoneNumber || "",
            balance: Number(user.wallet?.balance || 0),
            kycStatus: getKycStatus(
              user.wallet?.min_kyc,
              user.wallet?.full_kyc
            ),
            walletId: user.walletId,
            deactivation:
              user?.deactivation === "completed"
                ? "completed"
                : user?.deactivation
                ? "init"
                : (undefined as "init" | "completed" | undefined),
            createdAt: user.wallet?.created_at || user?.joinedAt,
          }
        })
      switch (type) {
        case "byBalanceAsc": {
          const updatedWallets = filteredWallets
          updatedWallets.sort((memberA, memberB) => {
            const balanceA = Number(memberA?.balance)
            const balanceB = Number(memberB?.balance)
            if (memberA?.deactivation && !memberB?.deactivation) {
              return 1 // move memberA with deactivation to the end
            } else if (!memberA?.deactivation && memberB?.deactivation) {
              return -1 // move memberB with deactivation to the end
            }
            return balanceA - balanceB
          })
          return updatedWallets
        }
        case "byBalanceDesc": {
          const updatedWallets = filteredWallets
          updatedWallets.sort((memberA, memberB) => {
            const balanceA = Number(memberA?.balance)
            const balanceB = Number(memberB?.balance)
            if (memberA?.deactivation && !memberB?.deactivation) {
              return 1 // move memberA with deactivation to the end
            } else if (!memberA?.deactivation && memberB?.deactivation) {
              return -1 // move memberB with deactivation to the end
            }
            return balanceB - balanceA
          })
          return updatedWallets
        }
        case "byNameAsc": {
          const updatedWallets = filteredWallets
          updatedWallets.sort((memberA, memberB) => {
            const nameA = memberA.name || memberA.name
            const nameB = memberB.name || memberB.name
            return nameA.localeCompare(nameB)
          })
          return updatedWallets
        }
        case "byNameDesc": {
          const updatedWallets = filteredWallets
          updatedWallets
            .sort((memberA, memberB) => {
              const nameA = memberA.name || memberA.name
              const nameB = memberB.name || memberB.name
              return nameA.localeCompare(nameB)
            })
            .reverse()
          return updatedWallets
        }
        case "byLastCreated": {
          const updatedWallets = filteredWallets
          updatedWallets.sort((memberA, memberB) => {
            const createdAtA = memberA?.createdAt
              ? new Date(memberA?.createdAt.toDate()).getTime()
              : new Date().getTime()
            const createdAtB = memberB?.createdAt
              ? new Date(memberB?.createdAt.toDate()).getTime()
              : new Date().getTime()
            return createdAtB - createdAtA
          })
          return updatedWallets
        }
        default:
          return filteredWallets
      }
    },
    [currentUserId]
  )
}

type KYC_STATUS = "init" | "min_kyc" | "full_kyc"
export function getKycStatus(minKyc?: boolean, fullKyc?: boolean): KYC_STATUS {
  if (fullKyc) {
    return "full_kyc"
  } else if (minKyc) {
    return "min_kyc"
  } else {
    return "init"
  }
}

export function useUserPaymentsProfile(businessId: string, userId: string) {
  const isProfileStored = userPaymentsProfileStore.getUserProfile()
  const callApis = useCallback(() => {
    if (isProfileStored?.businessId === businessId) return
    try {
      const userProfileApi = getUserProfile<UserProfileApiResponse>({
        businessId,
      })
      const cardDetailsApi = getUserCard<{ data: Array<CashbookCard> }>({
        businessId,
      })
      Promise.all([userProfileApi, cardDetailsApi])
        .then((responses) => {
          const [userProfile, cardDetails] = responses
          const activatedCard = cardDetails?.data.find(
            (card) => card.card_status !== "REPLACED"
          )
          if (userProfile.data) {
            const userProfilePayload = {
              uid: userId,
              name: userProfile.data.full_name,
              upi: userProfile.data.upiProfiles.length
                ? userProfile.data.upiProfiles.find((upi) => upi.primary)
                : undefined,
              kycStatus:
                userProfile.data.kyc_status === "FULL_KYC"
                  ? "full_kyc"
                  : userProfile.data.kyc_status === "MIN_KYC"
                  ? "min_kyc"
                  : "init",
              fullName: userProfile.data.full_name || "",
              cardDetails: {
                card_expiry: activatedCard?.card_expiry,
                card_masked_no: activatedCard?.card_masked_no,
              },
              entityId: userProfile.data.id,
              businessId,
            }
            userPaymentsProfileStore.updateProfile(userProfilePayload)
          }
        })
        .catch(() => {
          return
        })
    } catch {
      return
    }
  }, [businessId, userId, isProfileStored])

  useMount(() => {
    callApis()
  })
}

export function getTitleForToggleToastFromCardOption(
  type: "atm" | "pos" | "ecom" | "contactless"
) {
  switch (type) {
    case "ecom":
      return "Online card transactions"
    case "atm":
      return "ATM transactions"
    case "contactless":
      return "Tap & Pay transactions"
    case "pos":
      return "Merchant Outlet transactions"
  }
}

export function useDeactivatedWallets(businessId: string) {
  const [state, setState] = useState<DeactivatedWallet[]>([])
  const [shouldApiCall, setShouldApiCall] = useState<boolean>(true)
  const getDeactivatedUserWallets = useCallback(async () => {
    try {
      const response =
        await getDeactivatedWallets<Deactivated_Wallets_Response>({
          businessId,
        })
      if (!response) {
        throw new Error("Error while fetching staff wallets!")
      }
      setState(response.data)
    } catch (e) {
      const error = e as Error
      throw error
    }
  }, [businessId])

  useEffect(() => {
    if (shouldApiCall) {
      setShouldApiCall(false)
      getDeactivatedUserWallets()
    }
  }, [getDeactivatedUserWallets, shouldApiCall])

  return {
    deactivatedWallets: state,
  }
}

export function useNewCardIssue(businessId: string) {
  const [shouldApiCall, setShouldApiCall] = useState<boolean>(true)
  const [walletLimits, setWalletLimits] = useState<WalletLimitsResponse | null>(
    null
  )

  const getWalletLimitsAndProfile = useCallback(async () => {
    try {
      const payload: UserProfilePayload = {
        businessId,
      }
      const response = await getUserWalletLimits<WalletLimitsResponse>(payload)
      if (!response.data) {
        throw new Error("Could not fetch card limits! Please try again later.")
      }
      setWalletLimits(response)
    } catch (e) {
      const err = e as Error
      toast.error(
        err.message || "Something went wrong! Please try again later."
      )
    }
  }, [businessId])

  useEffect(() => {
    if (shouldApiCall) {
      setShouldApiCall(false)
      getWalletLimitsAndProfile()
    }
  }, [shouldApiCall, getWalletLimitsAndProfile])
  return {
    walletLimits: walletLimits?.data,
  }
}

export type WalletEnabledResponse = {
  data?: { walletEnabled?: boolean }
}

export function useCheckIfPaymentsEnabled(businessId: string) {
  const checkIfPaymentsEnabled = useCallback(
    async (memberId?: string): Promise<WalletEnabledResponse> => {
      try {
        const response =
          await checkIfPaymentsActiveInAnyBusiness<WalletEnabledResponse>({
            businessId,
            memberId,
          })
        return response
      } catch (e) {
        const err = e as Error
        throw new Error(err.message)
      }
    },
    [businessId]
  )

  return {
    checkIfPaymentsEnabled,
  }
}
