import React, { useMemo } from "react"
import { useParams } from "react-router-dom"
import ErrorBoundary from "../ErrorBoundary"
import { SuspenseWithPerf } from "reactfire"
import {
  Alert,
  Box,
  Button,
  Circle,
  DataLoadingFallback,
  DesktopIcon,
  Inline,
  PageMeta,
  PencilOutlinedIcon,
  SkeletonTitle,
  SpinnerIcon,
  Stack,
  Switch,
  Text,
} from "@cashbook/web-components"
import { useBusiness } from "@cashbook/data-store/businesses"
import { BreadCrumbsForPayments, PAYMENTS_ICON_NAMES } from "../Payments"
import {
  getTitleForToggleToastFromCardOption,
  useWalletLimits,
} from "@cashbook/data-store/payments"
import { Amount } from "../support/Intl"
import { UpdateWalletLimitsInDialog } from "../Payments/StaffWallet"

export function WalletLimitsPage() {
  const { staffId, businessId } = useParams()
  if (!staffId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading wallet limits..." />}
        traceId="loading_wallets_limits"
      >
        <WalletLimits key={staffId} staffId={staffId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

const CARD_ATM_LIMIT = 2000
const CARD_TAP_LIMIT = 5000

type OptionIds = "atm" | "ecom" | "pos" | "contactless"
const cardSettingOptions: Array<{
  id: OptionIds
  title: string
  subtitle: string
  icon: PAYMENTS_ICON_NAMES
}> = [
  {
    id: "ecom",
    title: "Online Transactions",
    subtitle: "E-commerce apps & online websites",
    icon: "ecom",
  },
  {
    id: "atm",
    title: "ATM Transactions",
    subtitle: `Max limit per transaction:  ₹${CARD_ATM_LIMIT}`,
    icon: "atm",
  },
  {
    id: "pos",
    title: "Merchant Outlet",
    subtitle: "Swipe on POS terminal",
    icon: "pos",
  },
  {
    id: "contactless",
    title: "Tap & Pay",
    subtitle: `Max limit per transaction: ₹${CARD_TAP_LIMIT}`,
    icon: "tap&pay",
  },
]

function WalletLimits({
  businessId,
  staffId,
}: {
  businessId: string
  staffId: string
}) {
  const { business, authTeamMemberDetails, getTeamMemberInfoForId } =
    useBusiness(businessId)
  const {
    loading,
    walletLimits,
    togglingOption,
    maxWalletLimits,
    userPreferences,
    toggleCardOption,
    updateWalletLimit,
  } = useWalletLimits(businessId, staffId)

  const user = useMemo(() => {
    return authTeamMemberDetails.uid === staffId
      ? authTeamMemberDetails
      : getTeamMemberInfoForId(staffId)
  }, [authTeamMemberDetails, getTeamMemberInfoForId, staffId])

  const options = useMemo(() => {
    return cardSettingOptions.map((option) => {
      return {
        ...option,
        value: userPreferences?.[option.id] || false,
      }
    })
  }, [userPreferences])

  return (
    <>
      <PageMeta>
        <title>
          {" "}
          {user?.name} (Card) - {business.name}
        </title>
      </PageMeta>
      <Box key={businessId} marginBottom="10">
        <BreadCrumbsForPayments
          position="relative"
          title={`Limit & Card Settings`}
          removeBorderLine
          links={[
            { to: "payments", title: "Payments" },
            { to: "staff-wallets", title: "Member Cards" },
            {
              to: staffId,
              header: "none",
              title:
                authTeamMemberDetails.uid === user?.uid
                  ? "Your Card"
                  : user?.name || "Staff Card Details",
            },
            { to: "wallets-limits", title: "Limit & Card Settings" },
          ]}
        />
        {loading ? (
          <Stack gap="6" paddingX="8">
            <Box
              borderWidth="1"
              borderColor="borderOutline"
              maxWidth="lg"
              rounded="md"
              padding="6"
            >
              <Stack gap="2">
                <SkeletonTitle />
                <SkeletonTitle width="full" />
              </Stack>
            </Box>
            <Box
              borderWidth="1"
              borderColor="borderOutline"
              maxWidth="lg"
              rounded="md"
              padding="6"
            >
              <Stack gap="2">
                <SkeletonTitle />
                <SkeletonTitle width="full" />
              </Stack>
            </Box>
          </Stack>
        ) : (
          <Stack paddingX="8" gap="6">
            <Stack gap="4">
              <Text fontSize="s4" color="textMedium">
                Card Limits
              </Text>
              <Box maxWidth="lg">
                <Alert status="warning" borderWidth="0">
                  These limits will be applicable after Full KYC
                </Alert>
              </Box>
              <Stack gap="6">
                {walletLimits?.perTransaction !== undefined &&
                maxWalletLimits?.data.each !== undefined ? (
                  <Box
                    borderWidth="1"
                    borderColor="borderOutline"
                    maxWidth="lg"
                    rounded="md"
                    padding="6"
                  >
                    <Inline
                      gap="2"
                      alignItems="center"
                      justifyContent="between"
                    >
                      <Stack gap="2">
                        <Text fontSize="s3">Per Transaction Limit</Text>
                        <Amount
                          currency="inr"
                          fontSize="b3"
                          color="textMedium"
                          amount={walletLimits.perTransaction}
                        />
                      </Stack>
                      <UpdateWalletLimitsInDialog
                        type="perTransaction"
                        isStaff={user?.role.id === "staff"}
                        maxLimit={maxWalletLimits?.data.each}
                        currentLimit={walletLimits?.perTransaction}
                        updateLimits={updateWalletLimit}
                      >
                        {({ open }) => (
                          <Button level="tertiary" onClick={open}>
                            <PencilOutlinedIcon />
                            Change
                          </Button>
                        )}
                      </UpdateWalletLimitsInDialog>
                    </Inline>
                  </Box>
                ) : null}
                {walletLimits?.daily !== undefined &&
                maxWalletLimits?.data.daily_debit !== undefined ? (
                  <Box
                    borderWidth="1"
                    borderColor="borderOutline"
                    maxWidth="lg"
                    rounded="md"
                    padding="6"
                  >
                    <Inline
                      gap="2"
                      alignItems="center"
                      justifyContent="between"
                    >
                      <Stack gap="2">
                        <Text fontSize="s3">Daily Transaction Limit</Text>
                        <Amount
                          currency="inr"
                          fontSize="b3"
                          color="textMedium"
                          amount={walletLimits.daily}
                        />
                      </Stack>
                      <UpdateWalletLimitsInDialog
                        currentLimit={walletLimits?.daily}
                        type="daily"
                        maxLimit={maxWalletLimits?.data.daily_debit.value}
                        updateLimits={updateWalletLimit}
                      >
                        {({ open }) => (
                          <Button level="tertiary" onClick={open}>
                            <PencilOutlinedIcon />
                            Change
                          </Button>
                        )}
                      </UpdateWalletLimitsInDialog>
                    </Inline>
                  </Box>
                ) : null}
              </Stack>
            </Stack>
            <Stack gap="4">
              <Text fontSize="s4" color="textMedium">
                CashBook Card Settings
              </Text>
              {options.map(({ id, title, subtitle, value }) => (
                <Box
                  key={id}
                  borderWidth="1"
                  borderColor="borderOutline"
                  maxWidth="lg"
                  rounded="md"
                  padding="6"
                >
                  <Inline gap="2" alignItems="center" justifyContent="between">
                    <Inline gap="6" alignItems="center">
                      <Circle size="10">
                        <DesktopIcon />
                      </Circle>
                      <Stack gap="2">
                        <Text fontSize="s3">{title}</Text>
                        <Text
                          fontSize="b3"
                          color="textMedium"
                          className="line-clamp-1"
                        >
                          {subtitle}
                        </Text>
                      </Stack>
                    </Inline>
                    {togglingOption?.[id] ? (
                      <SpinnerIcon size="5" />
                    ) : (
                      <Switch
                        label={`Switch for ${getTitleForToggleToastFromCardOption(
                          id
                        )}`}
                        on={value}
                        onToggle={() =>
                          toggleCardOption(
                            id,
                            !value,
                            user?.role.id === "staff"
                          )
                        }
                      />
                    )}
                  </Inline>
                </Box>
              ))}
            </Stack>
          </Stack>
        )}
      </Box>
    </>
  )
}
