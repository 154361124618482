import { Options as ApiConfig, callApiService } from "./api"
import endpoints from "./endpoints"
import { PaymentModeForPayments, PaymentTransactionTypes } from "./types"

export type MasterWalletTransactionsPayload = {
  businessId: string
  skip: number
  take: number
  transaction_type?: string
  from_datetime?: string
  to_datetime?: string
}

export function getMasterWalletTransactions<T>({
  transaction_type,
  from_datetime,
  to_datetime,
  businessId,
  skip,
  take,
  ...rest
}: MasterWalletTransactionsPayload & ApiConfig): Promise<T> {
  const payload: MasterWalletTransactionsPayload = {
    businessId,
    skip,
    take,
  }
  if (transaction_type) {
    payload["transaction_type"] = transaction_type
  }
  if (from_datetime) {
    payload["from_datetime"] = from_datetime
  }
  if (to_datetime) {
    payload["to_datetime"] = to_datetime
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.masterWalletTransactions,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export type BusinessTransactionsPayload = {
  businessId: string
  memberId?: string
  from_datetime?: string
  to_datetime?: string
  skip: number
  take: number
  partyId?: string
  status?: "SUCCESS" | "PENDING" | "REFUNDED"
  attachments?: "attached" | "missing"
  mode?: PaymentModeForPayments
  businessUserId?: string
}
export function getBusinessTransactions<T>({
  from_datetime,
  to_datetime,
  status,
  mode,
  memberId,
  businessId,
  partyId,
  skip,
  take,
  businessUserId,
  attachments,
  ...rest
}: BusinessTransactionsPayload & ApiConfig): Promise<T> {
  const payload: BusinessTransactionsPayload = {
    businessId,
    skip,
    take,
  }
  if (from_datetime) {
    payload["from_datetime"] = from_datetime
  }
  if (to_datetime) {
    payload["to_datetime"] = to_datetime
  }
  if (status) {
    payload["status"] = status
  }
  if (memberId) {
    payload["memberId"] = memberId
  }
  if (businessUserId) {
    payload["businessUserId"] = businessUserId
  }
  if (businessId)
    if (partyId) {
      payload["partyId"] = partyId
    }
  if (mode) {
    payload["mode"] = mode
  }
  if (attachments) {
    payload["attachments"] = attachments
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.businessTransactions,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export function getMasterWalletBalance<T>(
  businessId: string,
  options?: ApiConfig
): Promise<T> {
  return callApiService({
    method: "GET",
    endpoint: endpoints.masterWalletBalance,
    options: {
      payload: { businessId },
      ...options,
    },
  }) as Promise<T>
}

export function getAllParties<T>(businessId: string): Promise<T> {
  return callApiService({
    method: "GET",
    endpoint: endpoints.getAllParties,
    options: {
      payload: { businessId },
    },
  }) as Promise<T>
}

export type DownloadBusinessTransactionsReportPayload = {
  businessId: string
  reportType: "pdf" | "csv"
  memberId?: string
  from_datetime?: string
  to_datetime?: string
  partyId?: string
  mode?: "CARD" | "UPI"
  status?: "SUCCESS" | "PENDING" | "REFUNDED"
  attachments?: "attached" | "missing"
}
export function downloadBusinessTransactions<T>({
  from_datetime,
  to_datetime,
  status,
  mode,
  memberId,
  businessId,
  partyId,
  reportType,
  attachments,
  ...rest
}: DownloadBusinessTransactionsReportPayload & ApiConfig): Promise<T> {
  const payload: DownloadBusinessTransactionsReportPayload = {
    businessId,
    reportType,
  }
  if (from_datetime) {
    payload["from_datetime"] = from_datetime
  }
  if (to_datetime) {
    payload["to_datetime"] = to_datetime
  }
  if (status) {
    payload["status"] = status
  }
  if (memberId) {
    payload["memberId"] = memberId
  }
  if (partyId) {
    payload["partyId"] = partyId
  }
  if (mode) {
    payload["mode"] = mode
  }
  if (attachments) {
    payload["attachments"] = attachments
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.downloadBusinessTransactionsReport,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

type GetTransactionAttachment = {
  id: number
}
export function getTransactionAttachment<T>(
  payload: GetTransactionAttachment
): Promise<T> {
  return callApiService({
    method: "POST",
    endpoint: endpoints.getTransactionsAttachment,
    options: {
      payload,
    },
  }) as Promise<T>
}

type GetTransactionDetailsPayload = {
  businessId: string
  transactionId: string
}
export function getTransactionDetails<T>({
  businessId,
  transactionId,
  ...rest
}: GetTransactionDetailsPayload & ApiConfig): Promise<T> {
  return callApiService({
    method: "GET",
    endpoint: endpoints.transactionDetails,
    options: {
      payload: { businessId, transactionId },
      ...rest,
    },
  }) as Promise<T>
}

type UpdateTransactionAttachment = {
  fileName: string
  mimeType: string
  transactionId: number
  fileBase64: string
}
export function updateTransactionAttachment<T>({
  fileName,
  mimeType,
  transactionId,
  fileBase64,
  ...rest
}: UpdateTransactionAttachment & ApiConfig): Promise<T> {
  return callApiService({
    method: "POST",
    endpoint: endpoints.updateAttachment,
    options: {
      payload: { mimeType, fileName, fileBase64, transactionId },
      ...rest,
    },
  }) as Promise<T>
}

export function deleteTransactionAttachment<T>({
  id,
  ...rest
}: { id: number } & ApiConfig) {
  return callApiService({
    method: "POST",
    endpoint: endpoints.deleteAttachment,
    options: {
      payload: { id },
      ...rest,
    },
  }) as Promise<T>
}

export function getUserJourney<T>({
  businessId,
  ...rest
}: { businessId: string } & ApiConfig) {
  return callApiService({
    method: "GET",
    endpoint: endpoints.userJourney,
    options: {
      payload: { businessId },
      ...rest,
      version: "v2",
    },
  }) as Promise<T>
}

export type UserProfilePayload = {
  businessId: string
  memberId?: string
}
export function getUserProfile<T>({
  businessId,
  memberId,
  ...rest
}: UserProfilePayload & ApiConfig) {
  const payload: UserProfilePayload = {
    businessId,
  }
  if (memberId) {
    payload["memberId"] = memberId
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.userProfile,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export type WalletSummaryPayload = {
  businessId: string
  memberId: string
  from_datetime?: string
  to_datetime?: string
}

export function getUserWalletSummary<T>({
  businessId,
  memberId,
  from_datetime,
  to_datetime,
  ...rest
}: WalletSummaryPayload & ApiConfig) {
  const payload: WalletSummaryPayload = {
    businessId,
    memberId,
  }
  if (from_datetime) {
    payload["from_datetime"] = from_datetime
  }
  if (to_datetime) {
    payload["to_datetime"] = to_datetime
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.walletSummary,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export type WalletStatusFilterTypes =
  | "FAILURE"
  | "PENDING"
  | "SUCCESS"
  | "REJECTED"
  | "EXPIRED"
  | "REQUESTED"
  | "DEEMED"
  | "ALL"
  | "REFUNDED"
export type WalletTransactionsPayload = {
  businessId: string
  memberId?: string
  from_datetime?: string
  to_datetime?: string
  category?: PaymentTransactionTypes
  mode?: PaymentModeForPayments
  businessUserId?: string
  status?: WalletStatusFilterTypes
  skip: number
  take: number
}

export function getUserWalletTransactions<T>({
  businessId,
  memberId,
  skip,
  take,
  category,
  mode,
  from_datetime,
  to_datetime,
  businessUserId,
  status,
  ...rest
}: WalletTransactionsPayload & ApiConfig) {
  const payload: WalletTransactionsPayload = {
    businessId,
    memberId,
    skip,
    take,
  }
  if (category) {
    payload["category"] = category
  }
  if (mode) {
    payload["mode"] = mode
  }
  if (from_datetime) {
    payload["from_datetime"] = from_datetime
  }
  if (to_datetime) {
    payload["to_datetime"] = to_datetime
  }
  if (businessUserId) {
    payload["businessUserId"] = businessUserId
  }
  if (status) {
    payload["status"] = status
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.walletTransactions,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export function getUserWalletLimits<T>({
  businessId,
  memberId,
  ...rest
}: UserProfilePayload & ApiConfig) {
  const payload: UserProfilePayload = {
    businessId,
  }
  if (memberId) {
    payload["memberId"] = memberId
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.walletLimits,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

type ToggleOnlineTransactionsPayload = {
  businessId: string
  ecom?: boolean
  pos?: boolean
  atm?: boolean
  contactless?: boolean
  memberId?: string
}
export function updateOnlineCardTransactions<T>({
  businessId,
  memberId,
  ecom,
  pos,
  atm,
  contactless,
  ...rest
}: ToggleOnlineTransactionsPayload & ApiConfig) {
  const payload: ToggleOnlineTransactionsPayload = {
    businessId,
  }
  if (ecom !== undefined) {
    payload["ecom"] = ecom
  }
  if (pos !== undefined) {
    payload["pos"] = pos
  }
  if (atm !== undefined) {
    payload["atm"] = atm
  }
  if (contactless !== undefined) {
    payload["contactless"] = contactless
  }
  if (memberId) {
    payload["memberId"] = memberId
  }
  return callApiService({
    method: "POST",
    endpoint: endpoints.userPreferences,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export type UpdateWalletLimitPayload = {
  businessId: string
  memberId?: string
  daily?: number
  each?: number
}

export function updateWalletLimits<T>({
  businessId,
  memberId,
  each,
  daily,
  ...rest
}: UpdateWalletLimitPayload & ApiConfig) {
  const requestPayload: UpdateWalletLimitPayload = {
    businessId,
  }
  if (memberId) {
    requestPayload["memberId"] = memberId
  }
  if (daily || daily === 0) {
    requestPayload["daily"] = daily
  }
  if (each || each === 0) {
    requestPayload["each"] = each
  }
  return callApiService({
    method: "POST",
    endpoint: endpoints.walletLimitPreferences,
    options: {
      payload: requestPayload,
      ...rest,
    },
  }) as Promise<T>
}

export function getUserCard<T>({
  businessId,
  ...rest
}: { businessId: string } & ApiConfig) {
  const payload = {
    businessId,
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.userCard,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

type CardWidgetPayload = { businessId: string; kitNo: string }
export function getUserCardDetails<T>({
  kitNo,
  businessId,
  ...rest
}: CardWidgetPayload & ApiConfig) {
  const payload: CardWidgetPayload = {
    businessId,
    kitNo,
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.userCardDetails,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export function getDeactivatedWallets<T>({
  businessId,
  ...rest
}: { businessId: string } & ApiConfig) {
  const payload = {
    businessId,
  }
  return callApiService({
    method: "GET",
    endpoint: endpoints.deactivatedWallets,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

type UpdateTransactionDetailsPayload = {
  businessId: string
  transactionId: string
  description: string
}
export function updateTransaction<T>({
  businessId,
  transactionId,
  description,
  ...rest
}: UpdateTransactionDetailsPayload & ApiConfig) {
  const payload = {
    businessId,
    transactionId,
    description,
  }
  return callApiService({
    method: "POST",
    endpoint: endpoints.updateTransaction,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export type CashbackTransactionsPayload = {
  businessId: string
  memberId?: string
  from_datetime?: string
  to_datetime?: string
  skip: number
  take: number
  businessUserId?: string
}
export function getCashbackTransactions<T>({
  skip,
  take,
  businessId,
  memberId,
  from_datetime,
  to_datetime,
  businessUserId,
  ...rest
}: CashbackTransactionsPayload & ApiConfig) {
  const payload: CashbackTransactionsPayload = {
    skip,
    take,
    businessId,
  }
  if (memberId) {
    payload["memberId"] = memberId
  }
  if (businessUserId) {
    payload["businessUserId"] = businessUserId
  }
  if (from_datetime) {
    payload["from_datetime"] = from_datetime
  }
  if (to_datetime) {
    payload["to_datetime"] = to_datetime
  }

  return callApiService({
    method: "GET",
    endpoint: endpoints.cashbackTransactions,
    options: {
      payload,
      ...rest,
    },
  }) as Promise<T>
}

export const checkIfPaymentsActiveInAnyBusiness = <T>(
  payload: {
    businessId: string
    memberId?: string
  },
  options: ApiConfig = {}
) => {
  return callApiService({
    method: "GET",
    endpoint: endpoints.checkWalletEnabled,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}

export type CopyTransactionsToCashbookPayload = {
  businessId: string
  transactionIds: string[]
  destBookId: string
}

export function copyTransactionsToCashbook<T>(
  { businessId, transactionIds, destBookId }: CopyTransactionsToCashbookPayload,
  options: ApiConfig = {}
) {
  const payload = {
    businessId,
    transactionIds,
    destBookId,
  }
  return callApiService({
    method: "POST",
    endpoint: endpoints.copyTransactions,
    options: {
      payload,
      ...options,
    },
  }) as Promise<T>
}
